import React from 'react';
import classNames from 'classnames';

export interface CheckboxProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: never;
}

const Checkbox: React.FC<CheckboxProps> = ({
  className,
  disabled,
  ...props
}) => (
  <input
    {...props}
    disabled={disabled}
    type="checkbox"
    className={classNames(
      'form-checkbox rounded-sm border-grey-600 transition-colors duration-200 focus:ring-0',
      className,
      disabled && 'cursor-not-allowed text-grey-500',
      !props.checked && 'hover:border-grey-700',
    )}
  />
);

export default Checkbox;
