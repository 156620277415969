import React from 'react';
import Checkbox, { CheckboxProps } from './Checkbox';
import { useField } from 'formik';

interface Props extends Omit<CheckboxProps, 'name' | 'value' | 'onChange'> {
  name: string;
  value?: never;
  onChange?: never;
}

const FormikCheckbox: React.FC<Props> = ({ name, ...props }) => {
  const [field, meta, helpers] = useField(name);
  return <Checkbox {...props} {...field} />;
};

export default FormikCheckbox;
