import React from 'react';
import PageHeader from '../../../components/Layout/PageHeader';
import CustomerFormFields from '../../../components/CustomerForm/CustomerFormFields';
import FormikErrorMessage from '../../../components/Form/FormikErrorMessage';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Button } from '../../../components/Form/Button';
import EditCustomerForm from '../../../components/CustomerForm/EditCustomerForm';
import Message from '../../../components/Form/Message';
import useCustomerById from '../../../hooks/useCustomerById';
import PageLayout from '../../../components/Layout/PageLayout';
import { getErrorMessage } from '../../../api/ApiError';

interface Props {}

const EditCustomerPage: React.FC<Props> = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer, error } = useCustomerById(customerId!);
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const backDestination = params.get('backDestination') || '/';
  const updatedDestination = params.get('updatedDestination');

  return (
    <PageLayout header={<PageHeader>Update customer account</PageHeader>}>
      {!!error && (
        <Message error>
          Failed to fetch customer by ID <code>{customerId}</code>:{' '}
          {getErrorMessage(error)}{' '}
        </Message>
      )}

      {customer && (
        <EditCustomerForm
          customer={customer}
          onSuccessfulSubmit={() => {
            if (updatedDestination) {
              navigate(decodeURIComponent(updatedDestination));
            }
          }}
        >
          <div className="space-y-4">
            <CustomerFormFields />

            <FormikErrorMessage />
            <div className="flex justify-end space-x-4">
              <Link to={decodeURIComponent(backDestination)} className="block">
                <Button variant="text">Back</Button>
              </Link>
              <Button type="submit">Update</Button>
            </div>
          </div>
        </EditCustomerForm>
      )}
    </PageLayout>
  );
};

export default EditCustomerPage;
