import React from 'react';
import useCurrentWarehouseQuery from '../../../hooks/warehouse/useCurrentWarehouseQuery';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import useCurrentPicklistId from '../../../hooks/warehouse/useCurrentPicklistId';
import { useNavigate, useParams } from 'react-router-dom';
import useCurrentPicklistQuery from '../../../hooks/warehouse/useCurrentPicklistQuery';
import PageLayout from '../../../components/Layout/PageLayout';
import WarehouseBreadcrumbLink from '../../../components/Warehouse/WarehouseBreadcrumbLink';
import PageHeader from '../../../components/Layout/PageHeader';
import PageSubheader from '../../../components/Layout/PageSubheader';
import PicklistItemDetails from '../../../components/Warehouse/Picklists/PicklistItemDetails';
import { Button } from '../../../components/Form/Button';
import { useMutation } from 'react-query';
import { togglePickStatus } from '../../../api/picklists';

interface Props {}

const WarehousePicklistViewItem: React.FC<Props> = () => {
  const { data: warehouse } = useCurrentWarehouseQuery();
  const warehouseId = useCurrentWarehouseId()!;
  const picklistId = useCurrentPicklistId()!;
  const { itemId } = useParams<{ itemId?: string }>();
  const navigation = useNavigate();

  const { data: picklist, isLoading, refetch } = useCurrentPicklistQuery();

  const item = picklist?.items?.find(item => item.item_id === itemId) ?? null;

  function returnToList() {
    navigation(`/warehouse/${warehouseId}/picklist/${picklistId}`);
  }

  const { mutate: pickToggle } = useMutation(togglePickStatus, {
    onSuccess: () => refetch(),
  });

  return (
    <PageLayout
      header={
        <>
          <WarehouseBreadcrumbLink to="picklistById" />
          <PageHeader>Warehouse - {warehouse?.facility}</PageHeader>
          <PageSubheader>Pick List Item</PageSubheader>
        </>
      }
    >
      <div className="mt-4">
        {item ? (
          <PicklistItemDetails item={item} />
        ) : (
          <div className="text-center text-2xl font-semibold text-slate-green-500">
            Item not found
          </div>
        )}

        <div className="mt-4">
          <Button
            fluid
            size="large"
            disabled={!item}
            onClick={() => {
              if (item) {
                pickToggle(item);
              }
            }}
            className="mb-4"
          >
            Mark as{' '}
            {item?.warehouse_order_state === 'PICKED' ? 'Unpicked' : 'Picked'}
          </Button>

          <Button fluid size="large" onClick={returnToList} variant="outlined">
            Return to List
          </Button>
        </div>
      </div>
    </PageLayout>
  );
};

export default WarehousePicklistViewItem;
