import React from 'react';
import PageLayout from '../../components/Layout/PageLayout';
import PageHeader from '../../components/Layout/PageHeader';
import { Link } from 'react-router-dom';
import useCurrentWarehouseQuery from '../../hooks/warehouse/useCurrentWarehouseQuery';
import NavigationButton from '../../components/Layout/NavigationButton';

interface Props {}

const WarehouseHomepage: React.FC<Props> = () => {
  const { data: warehouse } = useCurrentWarehouseQuery();

  return (
    <PageLayout
      header={
        <>
          <PageHeader>Warehouse - {warehouse?.facility}</PageHeader>
          <Link to="/warehouse" className="mt-2 inline-block underline">
            Change warehouse
          </Link>
        </>
      }
    >
      <div className="mx-auto mt-8 max-w-sm space-y-8">
        <NavigationButton to={`/warehouse/${warehouse?.id}/receive`}>
          Receive
        </NavigationButton>

        <NavigationButton to={`/warehouse/${warehouse?.id}/location`}>
          Update Locations
        </NavigationButton>

        <NavigationButton to={`/warehouse/${warehouse?.id}/pick`}>
          Pick
        </NavigationButton>

        <NavigationButton to={`/warehouse/${warehouse?.id}/swaps`}>
          Ship (Inbox)
        </NavigationButton>

        <NavigationButton to={`/warehouse/${warehouse?.id}/audit`}>
          Audit
        </NavigationButton>
      </div>
    </PageLayout>
  );
};

export default WarehouseHomepage;
