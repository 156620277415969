import React, { useState } from 'react';
import { Quote } from '../../api/intf/item';
import displayPrice from '../../utils/displayPrice';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { putReminder } from '../../api/item';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../api/ApiError';
import QuotePrintModal from '../QuotePrintModal';
import { useEventCustomerQuoteQuery } from '../../hooks/routing/useEventCustomerQuoteQuery';
import toTitleCase from '../../utils/toTitleCase';
import classNames from 'classnames';
import { BellIcon, EyeIcon, PrinterIcon } from '@heroicons/react/24/outline';
import { fetchCustomerById } from '../../api/customer';

interface Props {
  quote: Quote;
}

const QuoteCard: React.FC<Props> = ({ quote }) => {
  const { eventId } = useEventCustomerQuoteQuery();
  const [printModalOpen, setPrintModalOpen] = useState(false);

  const { data: contact } = useQuery(
    ['contact', quote.contact_id],
    () => fetchCustomerById(quote.contact_id!),
    {
      enabled: !!quote.contact_id,
    },
  );

  const { mutate: sendReminder } = useMutation(
    (quoteId: string) => putReminder(quoteId),
    {
      onSuccess: () => {
        toast('Sent a reminder', { type: 'success' });
      },
      onError: (error: Error) => {
        toast(`Couldn't send a reminder: ${getErrorMessage(error)}`, {
          type: 'error',
        });
      },
    },
  );

  return (
    <div className="rounded border px-3 py-2 shadow">
      <div className="mb-2 text-lg font-semibold">
        {contact?.last_name && contact?.first_name ? (
          <span className="font-semibold">
            {contact.last_name}, {contact.first_name}
          </span>
        ) : (
          <i>No name</i>
        )}
      </div>

      <div className="mb-4 grid grid-cols-2 gap-1">
        <div>Quote ID: {quote.display_id}</div>

        <div className="flex items-center justify-end space-x-1.5 text-sm font-semibold">
          <div
            className={classNames('h-2 w-2 rounded-full', {
              'bg-red-500': quote.status === 'DELETED',
              'bg-green-500': quote.status === 'COMPLETED',
              'bg-yellow-500': quote.status === 'PENDING',
            })}
          />
          <div>{toTitleCase(quote.status)}</div>
        </div>

        <div className="text-sm text-slate-green-500">
          Offer Amount: <b>{displayPrice(quote.cash_offer_amount)}</b>
        </div>

        <div className="text-right text-sm text-slate-green-500">
          Item Count: <b>{quote.listing_count}</b>
        </div>

        <div className="text-xs text-slate-green-500">
          Created: {new Date(quote.created_at).toLocaleString()}
        </div>

        <div className="text-right text-sm text-slate-green-500">
          Removed: <b>{quote.deleted_listing_count || 0}</b>
        </div>
      </div>

      <div className="flex space-x-4 text-sm text-slate-green-500">
        <Link
          to={`/event/${quote.trade_in_event_id}/customer/${quote.contact_id}/quote/${quote.id}/summary`}
          className="flex items-center space-x-1 font-semibold underline"
        >
          <EyeIcon className="h-4 w-4" />
          <div>View</div>
        </Link>

        <button
          className="flex items-center space-x-1 font-semibold underline"
          onClick={() => setPrintModalOpen(true)}
        >
          <PrinterIcon className="h-4 w-4" />
          <div>Print Quote Label</div>
        </button>

        <button
          className="flex items-center space-x-1 font-semibold underline"
          onClick={() => sendReminder(quote.id)}
        >
          <BellIcon className="h-4 w-4" />
          <div>Remind</div>
        </button>
      </div>

      <QuotePrintModal
        eventId={eventId}
        quoteId={quote.id}
        onClose={() => setPrintModalOpen(false)}
        open={printModalOpen}
      />
    </div>
  );
};

export default QuoteCard;
