import React from 'react';

interface Props {}

const CardContainer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => (
  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
    {children}
  </div>
);

export default CardContainer;
