import qsStringify from './qsStringify';

interface FastlyImageOptimizerOptions {
  auto?: 'webp';
  'bg-color'?: string;
  blur?: string;
  brightness?: number | string;
  canvas?: string;
  contrast?: number | string;
  crop?: number | string;
  disable?: 'upscale';
  dpr?: number;
  enable?: 'upscale';
  fit?: 'bounds' | 'cover' | 'crop';
  format?:
    | 'gif'
    | 'png'
    | 'png8'
    | 'jpg'
    | 'jpeg'
    | 'bjpg'
    | 'pjpg'
    | 'webp'
    | 'webpll'
    | 'webply'
    | 'mp4';
  frame?: 1;
  height?: string | number;
  level?: string;
  optimize?: 'low' | 'medium' | 'high';
  orient?: 'r' | 'l' | 'h' | 'v' | 'hv' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  pad?: string;
  precrop?: FastlyImageOptimizerOptions['crop'];
  profile?: 'baseline' | 'main' | 'high';
  quality?: number | string;
  'resize-filter'?:
    | 'nearest'
    | 'bilinear'
    | 'linear'
    | 'cubic'
    | 'bicubic'
    | 'lanczos2'
    | 'lanczos'
    | 'lanczos3';
  saturation?: string | number;
  sharpen?: string;
  trim?: string; // top, right, bottom, left
  width?: number | string;
}

const imagesUrlRe = new RegExp('^https://images\\.sidelineswap\\.com');

export default function generateFastlyImageUrl(
  src: string,
  options?: FastlyImageOptimizerOptions | true,
) {
  if (imagesUrlRe.test(src)) {
    // Replace the first 31 characters with the CDN
    return `https://edge.images.sidelineswap.com${src.slice(31)}${qsStringify(
      typeof options === 'object' ? options : null,
    )}`;
  }

  return src;
}
