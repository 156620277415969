import React, { useState } from 'react';
import SimpleModal from '../Modal/SimpleModal';
import IsolatedTagForm from './IsolatedTagForm';
import { getItemByTag } from '../../api/partnerTagging';
import { Link } from 'react-router-dom';

interface Props {
  onSuccessfullyAdded: (tag: string) => void;
}

const TransferAddMissingItems: React.FC<Props> = ({ onSuccessfullyAdded }) => {
  const [open, setOpen] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <div>
      <div>
        Have a tagged item that's not showing here?{' '}
        <button
          className="my-4 text-sm font-semibold underline"
          type="button"
          onClick={() => setOpen(true)}
        >
          Add it now
        </button>
      </div>

      <SimpleModal
        onClose={() => setOpen(false)}
        open={open}
        title="Add a tagged item"
        description={
          <>
            To add items, they must be added via the{' '}
            <Link to="/partner/dashboard/tag" className="text-blue-500">
              Tag Items
            </Link>{' '}
            flow first.
          </>
        }
        initialFocus={inputRef}
        className="w-full max-w-xs"
      >
        <IsolatedTagForm
          ref={inputRef}
          onCancel={() => setOpen(false)}
          onSubmit={async tag => {
            await getItemByTag(tag);
            setOpen(false);
            onSuccessfullyAdded(tag);
          }}
        />
      </SimpleModal>
    </div>
  );
};

export default TransferAddMissingItems;
