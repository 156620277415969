import React from 'react';
import { Link } from 'react-router-dom';
import formattedEventDates from '../../../utils/formattedEventDates';
import useCurrentEventQuery from '../../../hooks/query/useCurrentEventQuery';

interface Props {
  eventId: string;
}

const SideMenuEventCard: React.FC<Props> = ({ eventId }) => {
  const { data: event } = useCurrentEventQuery();

  if (!event) {
    return null;
  }

  const { startDate, endDate } = formattedEventDates(
    event.start_date,
    event.end_date,
  );

  return (
    <div className="mx-4 mt-4 mb-2 rounded bg-pastel-green-500 px-3 py-2 font-bold uppercase text-turf-green-900 shadow">
      <div className="mb-1 text-lg leading-tight tracking-wide">
        {event.partner.name}
      </div>

      {event && (
        <div className="text-xs">
          {event.partner_address.address_1}, {event.partner_address.city},{' '}
          {event.partner_address.state} {event.partner_address.zip}
        </div>
      )}

      <div className="text-xs">
        {startDate} - {endDate}
      </div>

      <div className="flex justify-between">
        <Link
          to={`/event/${eventId}/stats`}
          className="mt-2 inline-block text-sm underline"
        >
          Show Stats
        </Link>

        <Link
          to={`/event/${eventId}/edit`}
          className="mt-2 inline-block text-sm underline"
        >
          Edit
        </Link>
      </div>
    </div>
  );
};

export default SideMenuEventCard;
