import React, { useMemo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { FormikConfig, FormikProps } from 'formik/dist/types';
import { z, ZodSchema } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { formatFormikError } from '../../api/ApiError';

interface Props<S extends ZodSchema, V = z.input<S>, O = z.output<S>>
  extends Omit<FormikConfig<V>, 'onSubmit' | 'validationSchema'> {
  children?: React.ReactNode | ((props: FormikProps<V>) => React.ReactNode);
  onSubmit: (
    values: O,
    formikHelpers: FormikHelpers<O>,
  ) => void | Promise<void>;
  schema: S;
}

function FormikZodSchema<S extends ZodSchema = ZodSchema>({
  children,
  schema,
  onSubmit,
  ...props
}: Props<S>) {
  return (
    <Formik<z.input<S>>
      validateOnChange={false}
      validateOnBlur={false}
      {...props}
      onSubmit={async (values, formikHelpers) => {
        try {
          const parsedValues = await schema.parseAsync(values);
          return await onSubmit(parsedValues, formikHelpers);
        } catch (e) {
          formikHelpers.setErrors(formatFormikError(e));
        }
      }}
      validationSchema={useMemo(
        () => toFormikValidationSchema(schema),
        [schema],
      )}
    >
      {children}
    </Formik>
  );
}

export default FormikZodSchema;
