import { DecodedJwt } from '../../api/intf/auth';
import { useAuth } from './AuthContext';
import { useMemo } from 'react';
import {
  ROLE_ORG_ADMIN,
  ROLE_TRADE_IN_DISTRICT_MANAGER,
  ROLE_TRADE_IN_STORE_EMPLOYEE,
  ROLE_TRADE_IN_STORE_MANAGER,
  ROLE_USER,
  TokenRole,
} from './roles';

type PermissionTypes =
  | 'accessTradeInExperience'
  | 'accessWarehouseExperience'
  | 'accessPartnerExperience'
  | 'partnerManageUsers'
  | 'partnerStartQuotes'
  | 'partnerCreateStore'
  | 'partnerChangeStores';

const PERMISSION_MATRIX: Record<PermissionTypes, TokenRole[]> = {
  accessTradeInExperience: [ROLE_USER, ROLE_ORG_ADMIN],
  accessWarehouseExperience: [ROLE_ORG_ADMIN],
  accessPartnerExperience: [
    ROLE_TRADE_IN_DISTRICT_MANAGER,
    ROLE_TRADE_IN_STORE_MANAGER,
    ROLE_TRADE_IN_STORE_EMPLOYEE,
  ],

  partnerManageUsers: [
    ROLE_TRADE_IN_DISTRICT_MANAGER,
    ROLE_TRADE_IN_STORE_MANAGER,
  ],
  partnerStartQuotes: [
    ROLE_TRADE_IN_DISTRICT_MANAGER,
    ROLE_TRADE_IN_STORE_MANAGER,
    ROLE_TRADE_IN_STORE_EMPLOYEE,
  ],
  partnerCreateStore: [ROLE_TRADE_IN_DISTRICT_MANAGER],
  partnerChangeStores: [ROLE_TRADE_IN_DISTRICT_MANAGER],
};

type Permissions = Record<PermissionTypes, boolean>;

export function evaluatePermissions(jwt?: DecodedJwt | null): Permissions {
  const roles = jwt?.scopes;

  const permissions: Permissions = {
    accessTradeInExperience: false,
    accessWarehouseExperience: false,
    accessPartnerExperience: false,
    partnerManageUsers: false,
    partnerStartQuotes: false,
    partnerCreateStore: false,
    partnerChangeStores: false,
  };
  const keys = Object.keys(PERMISSION_MATRIX) as PermissionTypes[];
  for (let i = 0; i < keys.length; i++) {
    const permission = keys[i];
    permissions[permission] = PERMISSION_MATRIX[permission].some(role =>
      roles?.includes(role),
    );
  }

  return permissions;
}

export default function usePermissions(): Permissions {
  const jwt = useAuth().decodedJwt;
  return useMemo(() => evaluatePermissions(jwt), [jwt]);
}
