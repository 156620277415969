import React from 'react';
import { Modal } from 'react-responsive-modal';

import { Button } from '../Form/Button';

import 'react-responsive-modal/styles.css';

interface ModalProps {
  isOpen: boolean;
  handleCloseModal: () => void;
  alertDescription: string;
  alertSubDescription?: string;
  handleOK: (...args: any) => void;
  handleCancel: () => void;
}

const ConfirmModal: React.FC<ModalProps> = ({
  isOpen,
  handleCloseModal,
  alertDescription,
  alertSubDescription,
  handleOK,
  handleCancel,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      center
      classNames={{ modal: 'confirm-modal' }}
      aria-describedby="my-modal-description"
    >
      <div id="my-modal-description" className="flex flex-col text-center">
        <span className="mt-8 text-xl">{alertDescription}</span>
        <span className="mt-2 text-xl">{alertSubDescription}</span>
        <div className="mt-8 flex w-full justify-between space-x-2 px-4">
          <Button className="w-6/12" variant="text" onClick={handleCancel}>
            No
          </Button>
          <Button className="w-6/12" onClick={handleOK}>
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
