export default function getEnv<E extends string | undefined>(
  env?: E,
): E extends undefined
  ? string | undefined
  : 'production' | 'development' | 'staging' {
  if (env) {
    return import.meta.env[env];
  }

  return import.meta.env.REACT_APP_ENVIRONMENT ?? 'development';
}
