import React, { useRef, useState } from 'react';
import { CameraIcon } from '@heroicons/react/24/outline';
import SimpleModal from '../../Modal/SimpleModal';
import { Button } from '../../Form/Button';
import { useMutation, useQueryClient } from 'react-query';
import { fetchWarehouseSwaps, putPrintSwapLabel } from '../../../api/warehouse';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import { getErrorMessage } from '../../../api/ApiError';
import delay from '../../../utils/delay';

interface Props {}

const WarehouseInboxScanToPrint: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const warehouseId = useCurrentWarehouseId();
  const inputRef = useRef<HTMLInputElement>(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const {
    mutate: processScanData,
    data: swapDetail,
    error,
    isLoading,
    variables,
    isSuccess,
    isIdle,
    reset,
  } = useMutation(
    async (itemId: string) => {
      // TODO: Maybe make a backend endpoint for this instead?
      const swapsResponse = await fetchWarehouseSwaps(warehouseId!, {
        keyword: itemId,
        pageSize: 80,
      });

      const firstPrintableSwap =
        swapsResponse.data?.find(swap =>
          swap.actions?.find(action => action.slug === 'print_label'),
        ) || swapsResponse.data?.find(swap => swap.tracker.print_url);

      if (!firstPrintableSwap) {
        throw new Error('No printable swap found');
      }

      return putPrintSwapLabel(firstPrintableSwap.id);
    },
    {
      onMutate: () => {
        setIframeLoaded(false);
      },
    },
  );

  function onClose() {
    setModalOpen(false);
    reset();
  }

  let status: React.ReactNode;

  if (isLoading || (swapDetail && !iframeLoaded)) {
    status = (
      <span className="font-semibold text-yellow-500">
        Processing{variables ? ` ${variables}` : ''}...
      </span>
    );
  } else if (isIdle || isSuccess) {
    status = <span className="font-semibold text-green-500">Ready</span>;
  } else if (error) {
    status = (
      <span className="font-semibold text-red-500">
        Failed to process: {getErrorMessage(error)}
      </span>
    );
  }

  return (
    <>
      <div className="relative inline-block text-left">
        <button type="button" onClick={() => setModalOpen(true)}>
          <span className="sr-only">Scan to Print</span>
          <CameraIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <SimpleModal
        open={modalOpen}
        onClose={onClose}
        initialFocus={inputRef}
        title="Scan to Print"
        className="w-full max-w-md"
        description="Scan an item tag with your barcode scanner. Once successful, print thesubsequent shipping label."
      >
        <div className="my-4 text-xl">{status}</div>

        <input
          ref={inputRef}
          type="text"
          className="sr-only"
          autoFocus
          onBlur={e => e.target.focus()}
          onKeyDown={e => {
            if (e.key === 'Enter' && !isLoading) {
              e.preventDefault();
              e.stopPropagation();

              const value = e.currentTarget.value;
              e.currentTarget.value = '';

              const lastPath = value.split('/').pop()!;

              processScanData(lastPath);
            }
          }}
        />

        {swapDetail && (
          <iframe
            src={`/warehouse/${warehouseId}/print_bulk?swap_ids=${swapDetail.uuid}&auto_print=1`}
            style={{
              width: 1920,
              height: 1080,
              position: 'absolute',
              top: -50000,
              left: -50000,
            }}
            onLoad={() => setIframeLoaded(true)}
          />
        )}

        <Button fluid className="mt-4" onClick={() => setModalOpen(false)}>
          Close
        </Button>
      </SimpleModal>
    </>
  );
};

export default WarehouseInboxScanToPrint;
