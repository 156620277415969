import axios from 'axios';
import { ApiResponse } from './intf/generic';
import { PayoutMethod } from './intf/payoutMethods';
import { getBaseUrl } from './axiosInitialization';

export const fetchAllPayoutMethods = async () => {
  const { data } = await axios.get<ApiResponse<PayoutMethod[]>>(
    '/trade-in/v1/payout_methods/all',
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data) {
    return data.data;
  }

  throw new Error('Could not fetch all payout methods');
};

interface TogglePayoutMethodRequest {
  payout_method_id: PayoutMethod['id'];
  enabled: boolean;
}

export const togglePayoutMethod = async (
  payoutMethodId: PayoutMethod['id'],
  enabled: boolean,
) => {
  const { data } = await axios.post<ApiResponse<PayoutMethod>>(
    '/trade-in/v1/payout_methods',
    {
      enabled,
      payout_method_id: payoutMethodId,
    } as TogglePayoutMethodRequest,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data) {
    return data.data;
  }

  throw new Error('Could not fetch all payout methods');
};

/**
 * @param payoutMethodId
 * @param creditOfferMarkup If value is over 1, it will be divided by 100.
 */
export const putUpdateCreditOfferMarkup = async (
  payoutMethodId: PayoutMethod['id'],
  creditOfferMarkup: number,
) => {
  const { data } = await axios.put<ApiResponse<PayoutMethod>>(
    `/trade-in/v1/payout_methods/${payoutMethodId}`,
    {
      credit_offer_markup:
        creditOfferMarkup > 1 ? creditOfferMarkup / 100 : creditOfferMarkup,
    },
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data) {
    return data.data;
  }

  throw new Error('Failed to update the credit offer amount');
};
