import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/Layout/PageHeader';
import { useSearchParams } from 'react-router-dom';
import { putPrintSwapLabel } from '../../api/warehouse';
import Message from '../../components/Form/Message';
import WarehouseBreadcrumbLink from '../../components/Warehouse/WarehouseBreadcrumbLink';
import { Button } from '../../components/Form/Button';
import classNames from 'classnames';
import getEnv from '../../utils/getEnv';
import { useMutation } from 'react-query';

interface Props {}

const WarehouseSwapsPrintBulkPage: React.FC<Props> = () => {
  const [searchParams] = useSearchParams();
  const swapIds = searchParams.get('swap_ids')?.split(',') ?? [];
  const labelUrls = searchParams.get('label_urls')?.split(',') ?? [];
  const autoPrint = searchParams.get('auto_print') === '1';

  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (autoPrint && imageLoaded) {
      window.print();
    }
  }, [autoPrint, imageLoaded]);

  return (
    <>
      <div className="print-hidden container mt-12 px-4">
        <WarehouseBreadcrumbLink to="home" />
      </div>

      <PageHeader className="print-hidden container mt-4 mb-8 px-4">
        Print All Swaps
      </PageHeader>

      {labelUrls.map((labelUrl, i, arr) => (
        <React.Fragment key={labelUrl}>
          <LabelImage
            imageUrl={labelUrl}
            onImageLoaded={() => {
              setImageLoaded(true);
            }}
          />

          {(i < arr.length - 1 || swapIds.length > 0) && (
            <hr className="print-hidden my-8 break-after-page" />
          )}
        </React.Fragment>
      ))}

      {swapIds.map((swapId, i, arr) => (
        <React.Fragment key={swapId}>
          <SwapPrintRow
            swapId={swapId}
            onImageLoaded={() => {
              setImageLoaded(true);
            }}
          />

          {i < arr.length - 1 && (
            <hr className="print-hidden my-8 break-after-page" />
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export function SwapPrintRow({
  onImageLoaded,
  swapId,
}: {
  swapId: string;
  onImageLoaded?: () => void;
}) {
  const {
    mutate,
    data: swapDetail,
    error,
    isLoading,
  } = useMutation(() => putPrintSwapLabel(swapId));

  useEffect(() => {
    mutate();
  }, []);

  if (error) {
    return (
      <div className="print-hidden container px-4">
        <Message error>Failed to print swap ID: {swapId}</Message>
      </div>
    );
  }

  if (isLoading || !swapDetail) {
    return (
      <>
        <h1 className="pt-8 text-center text-2xl font-semibold">
          Processing...
        </h1>
      </>
    );
  }

  return (
    <LabelImage
      imageUrl={swapDetail.tracker?.label_url ?? ''}
      onImageLoaded={onImageLoaded}
    />
  );
}

export function LabelImage({
  imageUrl,
  onImageLoaded,
}: {
  imageUrl: string;
  onImageLoaded?: () => void;
}) {
  return (
    <>
      <div className="print-hidden container mb-4 px-4">
        <div className="flex items-center space-x-2">
          <Button onClick={() => window.print()}>Print Label</Button>
        </div>
      </div>

      <div className="flex break-inside-avoid justify-center print:block">
        <img
          src={imageUrl}
          alt={imageUrl ? 'Print label' : 'Missing label'}
          className={classNames(
            'h-[6in] w-[4in] print:m-auto print:h-screen print:w-auto print:max-w-none',
            getEnv() !== 'production' && 'border-2 border-red-500',
          )}
          onLoad={onImageLoaded}
        />
      </div>
    </>
  );
}

export default WarehouseSwapsPrintBulkPage;
