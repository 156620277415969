import React from 'react';
import classNames from 'classnames';

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string;
}

const TextArea: React.FC<Props> = ({
  className,
  error,
  disabled,
  ...props
}) => (
  <textarea
    {...props}
    disabled={disabled}
    className={classNames(
      className,
      'block w-full rounded border py-2 px-4 placeholder-slate-green-500 focus:border-primary-500 focus:outline-none focus:ring',
      error && 'border-red-500',
      disabled && 'bg-pastel-green-500 text-slate-green-500',
    )}
  />
);

export default TextArea;
