import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import Profile from '../../../img/profile.png';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { removeCookie } from '../../../lib/KnownCookies';
import { useAuth, useAuthDispatch } from '../../../lib/auth/AuthContext';
import { useQueryClient } from 'react-query';
import useOrgConfigQuery from '../../../hooks/query/useOrgConfigQuery';

const HeaderUserSection = () => {
  const authDispatch = useAuthDispatch();
  const auth = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: orgConfig } = useOrgConfigQuery();

  const handleLogout = () => {
    // @ts-ignore
    authDispatch({ type: 'logged out' });
    navigate('/login', { replace: true });
    removeCookie('access_token');
    removeCookie('refresh_token');
    removeCookie('sls_client_id');
    removeCookie('sls_user_type');
    queryClient.removeQueries();
  };

  return (
    <Menu
      as="div"
      className="relative flex flex-1 flex-shrink-0 items-center justify-end"
    >
      <Menu.Button>
        <img
          src={orgConfig?.image_url || Profile}
          alt="Profile"
          className="h-10 w-10"
        />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 top-full z-10 mt-2 w-56 origin-top-right divide-y rounded-md bg-white shadow-lg">
          <div className="px-4 py-3">
            <p className="">Logged in as</p>
            <p className="truncate font-semibold">{auth.decodedJwt?.sub}</p>
          </div>

          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/settings"
                  className={classNames(
                    active && 'bg-pastel-green-500',
                    'block px-4 py-2 transition-colors duration-100',
                  )}
                >
                  Settings
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  type="submit"
                  className={classNames(
                    active && 'bg-pastel-green-500',
                    'block w-full px-4 py-2 text-left transition-colors duration-100',
                  )}
                  onClick={handleLogout}
                >
                  Logout
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default HeaderUserSection;
