import useOrgId from '../useOrgId';
import { useQuery } from 'react-query';
import { fetchIamOrg } from '../../api/orgs';

export default function useOrgQuery() {
  const orgId = useOrgId();

  return useQuery(['iam-org', orgId], () => fetchIamOrg(orgId!), {
    enabled: !!orgId,
  });
}
