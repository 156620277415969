import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { Button } from '../Form/Button';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { ListingItem } from '../../api/intf/item';
import { deleteListingItemById } from '../../api/item';
import displayPrice from '../../utils/displayPrice';
import qsStringify from '../../utils/qsStringify';
import { useEventCustomerQuoteQuery } from '../../hooks/routing/useEventCustomerQuoteQuery';

interface Props {
  listingItem: ListingItem;
  quoteId: string;
  customerId: string;
}

const QuoteSummaryItem: React.FC<Props> = ({
  listingItem,
  quoteId,
  customerId,
}) => {
  const navigate = useNavigate();
  const { eventId } = useEventCustomerQuoteQuery();
  const [isShowModal, setShowModal] = useState<boolean>(false);
  const notify = () => toast.success('Deleted quote item successfully!');

  const queryClient = useQueryClient();
  const { mutate: handleDelete } = useMutation(
    () => deleteListingItemById(quoteId, listingItem.id!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['quotes', quoteId]);
        notify();
        setShowModal(false);
      },
    },
  );

  const handleCopy = () => {
    const query = {
      mode: 'copy',
      categoryId: listingItem.category_id,
      listingId: listingItem.id,
    };
    const queryItem = qsStringify(query);
    navigate(
      `/event/${eventId}/customer/${customerId}/quote/${quoteId}/listing/edit${queryItem}`,
    );
  };

  return (
    <div className="mt-4 rounded-lg bg-white p-6 shadow-md">
      <div className="flex justify-between space-x-2">
        <span className="">{listingItem.name}</span>
        <span className="font-semibold">
          {displayPrice(listingItem.cash_offer_amount)}
        </span>
      </div>

      <div className="flex">
        <Button variant="text" className="np-button p-0" onClick={handleCopy}>
          Copy
        </Button>
        <Button
          variant="text"
          className="np-button"
          onClick={() => setShowModal(true)}
        >
          Delete
        </Button>
      </div>

      <ConfirmModal
        isOpen={isShowModal}
        handleCloseModal={() => setShowModal(false)}
        alertDescription="Sure you want to delete?"
        alertSubDescription="Item"
        handleOK={handleDelete}
        handleCancel={() => setShowModal(false)}
      />
    </div>
  );
};

export default QuoteSummaryItem;
