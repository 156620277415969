import React from 'react';
import useMobile from '../../../hooks/useMobile';
import classNames from 'classnames';
import WarningModalLink from '../../WarningModalLink';
import { XMarkIcon } from '@heroicons/react/24/outline';
import useCurrentWarehouseQuery from '../../../hooks/warehouse/useCurrentWarehouseQuery';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import FullLogo from '../../Icons/FullLogo';

interface Props {
  children?: React.ReactNode;
  exitButton?: boolean;
  headerRightContent?: React.ReactNode;
  warnOnExit?: boolean;
}

const WarehouseAuditLayout: React.FC<Props> = ({
  children,
  exitButton,
  headerRightContent,
  warnOnExit,
}) => {
  const mobile = useMobile();
  const warehouseId = useCurrentWarehouseId();
  const { data: warehouse } = useCurrentWarehouseQuery();

  const header = (
    <div className="sticky top-0 z-40 flex space-x-4 bg-white py-4 shadow-lg print:hidden">
      <div className="w-full pl-4">
        <div
          className={classNames(
            mobile
              ? 'flex flex-col space-y-4 pr-4'
              : 'flex items-center justify-between space-x-4',
          )}
        >
          <WarningModalLink
            disabled={!warnOnExit}
            to={`/warehouse/${warehouseId}/audit`}
          >
            <FullLogo />
          </WarningModalLink>
        </div>
      </div>

      {headerRightContent}
      {exitButton ? (
        <WarningModalLink
          // Only warn the user if they have made progress in the flow
          disabled={!warnOnExit}
          to={`/warehouse/${warehouseId}/audit`}
          className={classNames('px-4', mobile && 'absolute top-4 right-4')}
        >
          <div className="sr-only">Exit</div>
          <XMarkIcon className="h-8 w-8" strokeWidth={2.5} />
        </WarningModalLink>
      ) : (
        <div className="pr-4" />
      )}
    </div>
  );

  return (
    <>
      {header}

      <main className="mx-auto w-full px-4 pt-8 pb-40 md:max-w-screen-md print:p-0 print:m-0">
        {children}
      </main>
    </>
  );
};

export default WarehouseAuditLayout;
