import React from 'react';
import { useEventCustomerQuoteQuery } from '../../../hooks/routing/useEventCustomerQuoteQuery';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import WarningModalLink from '../../WarningModalLink';

interface Props {
  to: 'summary' | 'contact';
  warnOnExit?: boolean;
}

const PartnerQuoteFlowBackButton: React.FC<Props> = ({ to, warnOnExit }) => {
  const { quoteId } = useEventCustomerQuoteQuery();
  let url: string;
  let text: string;

  if (!quoteId) {
    return null;
  }

  switch (to) {
    case 'summary':
      url = `/partner/quote/${quoteId}/summary`;
      text = 'Return to Cart';
      break;

    case 'contact':
      url = `/partner/quote/${quoteId}/customer`;
      text = 'Return to Customer Info';
      break;
  }

  return (
    <div className="-mt-4 mb-4">
      <WarningModalLink
        disabled={!warnOnExit}
        to={url}
        className="inline-flex items-center space-x-2"
      >
        <ArrowLeftIcon className="h-4 w-4" />
        <span>{text}</span>
      </WarningModalLink>
    </div>
  );
};

export default PartnerQuoteFlowBackButton;
