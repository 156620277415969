import React, { useLayoutEffect, useRef, useState } from 'react';
import WarehouseAuditLayout from '../../../../components/Layout/Layouts/WarehouseAuditLayout';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchTransferById } from '../../../../api/partnerTransfers';
import {
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import SimpleReactTable from '../../../../components/Tables/SimpleReactTable';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import ItemListingQrLabel from '../../../../components/Printing/ItemListingQrLabel';
import { Button, ButtonLink } from '../../../../components/Form/Button';
import Message from '../../../../components/Form/Message';
import useCurrentWarehouseId from '../../../../hooks/warehouse/useCurrentWarehouseId';
import qsStringify from '../../../../utils/qsStringify';
import { transferItemStatusColumn } from '../../../../components/Warehouse/Audit/WarehouseAuditItemsTable';
import { clsx } from 'clsx';

export default function WarehouseAuditTransferPage() {
  const warehouseId = useCurrentWarehouseId();
  const params = useParams<{ transferId: string }>();
  const { data: transfer } = useQuery(['transfers', params.transferId], () =>
    fetchTransferById(params.transferId!),
  );

  const [error, setError] = useState<string | null>();

  const printAttempts = useRef(0);
  const [printingItem, setPrintingItem] = useState<{
    legacy_id: number;
    name: string;
  }>();
  const [printAll, setPrintAll] = useState(false);

  useLayoutEffect(() => {
    if (!printingItem) {
      return;
    }

    function handlePrint() {
      window.print();
      setPrintingItem(undefined);
    }

    const element = document.getElementById(
      `qr-code-${printingItem?.legacy_id}`,
    ) as HTMLImageElement | null;
    if (!element) {
      if (printAttempts.current > 5) {
        setError(
          'Failed to print tag, QR code never generated. There may not be an ID associated with this item.',
        );
        setPrintingItem(undefined);

        printAttempts.current = 0;
        return;
      }

      printAttempts.current += 1;
      const timeout = setTimeout(() => {
        setPrintingItem({ ...printingItem });
      }, 200);
      return () => clearTimeout(timeout);
    }

    printAttempts.current = 0;

    if (element.complete) {
      handlePrint();
    } else {
      element.addEventListener('load', handlePrint);
      return () => element.removeEventListener('load', handlePrint);
    }
  }, [printingItem]);

  useLayoutEffect(() => {
    if (printAll) {
      const timeout = setTimeout(() => {
        window.print();
        setPrintAll(false);
      }, 150);

      return () => clearTimeout(timeout);
    }
  }, [printAll]);

  const table = useReactTable({
    data: transfer?.items || [],
    columns: [
      {
        accessorKey: 'name',
        header: 'Item Name',
        cell: info => {
          return (
            <span className="flex gap-2 items-center">
              <button
                onClick={() => info.row.toggleExpanded()}
                type="button"
                className="bg-grey-100 p-2 rounded"
              >
                {info.row.getIsExpanded() ? (
                  <ChevronDownIcon className="h-4 w-4" />
                ) : (
                  <ChevronRightIcon className="h-4 w-4" />
                )}
              </button>
              <span>{info.row.original.name}</span>
            </span>
          );
        },
      },
      {
        accessorFn: item => new Date(item.created_at).toLocaleDateString(),
        header: 'Date',
      },
      {
        accessorFn: item => item?.legacy_item_id || 'N/A',
        header: 'Tag ID',
      },
      transferItemStatusColumn,
      {
        id: 'actions',
        header: 'Actions',
        cell: info => {
          return (
            <div className="flex gap-2">
              <Button
                variant="outlined"
                onClick={() => {
                  setError(null);
                  setPrintingItem({
                    legacy_id: info.row.original?.legacy_item_id!,
                    name: info.row.original.name,
                  });
                }}
              >
                Print Tag
              </Button>

              <ButtonLink
                to={`/warehouse/${warehouseId}/audit/scan${qsStringify({
                  expected_legacy_id: info.row.original.legacy_item_id || '',
                  transfer_id: transfer?.id,
                  type: 'item',
                })}`}
              >
                Scan Item
              </ButtonLink>
            </div>
          );
        },
      },
    ],
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    enableExpanding: true,
    manualExpanding: true,
  });

  return (
    <WarehouseAuditLayout exitButton>
      <div className="print:hidden">
        <div className="flex w-full">
          <div className="flex-1">
            <div className="prose mb-4">
              <div className="font-semibold">
                In order to finalize this quote, you must do the following:
              </div>

              <ol>
                <li>Review and print the item tags below</li>
                <li>Affix each tag to its corresponding item</li>
                <li>Scan each item and confirm its condition and value</li>
                <li>Submit the quote</li>
              </ol>
            </div>
          </div>

          <div>
            <Button variant="outlined" onClick={() => setPrintAll(true)}>
              Print All Tags
            </Button>
          </div>
        </div>

        {error && <Message error>{error}</Message>}

        <SimpleReactTable
          table={table}
          getExpandedRowContent={row => {
            const item = row.original;

            return (
              <div className="flex gap-4">
                <div>
                  <img
                    className="h-14 w-14 rounded"
                    src={item.image_urls?.[0]}
                  />
                </div>
                <div className="text-lg font-semibold max-w-md">
                  {item.name}
                </div>
              </div>
            );
          }}
        />
      </div>

      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: `
            @page {
              size: 2.125in 1in;
            
              /* Y, X */
              margin: 0.05in 0.1in;
            }`,
        }}
      />

      {transfer?.items?.map((transferItem, i) => (
        <div
          className={clsx(
            'hidden',
            (printAll ||
              printingItem?.legacy_id == transferItem.legacy_item_id) &&
              'print:block',
            printAll && i !== 0 && 'break-before-page',
          )}
        >
          <ItemListingQrLabel
            listing={{
              legacy_id: transferItem.legacy_item_id,
              name: transferItem.name,
            }}
          />
        </div>
      ))}
    </WarehouseAuditLayout>
  );
}
