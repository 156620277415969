import React from 'react';
import InStoreQuoteLayout from '../../../components/Layout/Layouts/InStoreQuoteLayout';
import FormikSubmitButton from '../../../components/Form/FormikSubmitButton';
import PartnerContactForm from '../../../components/Partner/Quote/PartnerContactForm';
import useCurrentQuoteQuery from '../../../hooks/query/useCurrentQuoteQuery';
import useEventQuery from '../../../hooks/query/useEventQuery';
import useCustomerById from '../../../hooks/useCustomerById';
import { Link, useNavigate } from 'react-router-dom';
import PartnerQuoteFlowBackButton from '../../../components/Partner/Quote/PartnerQuoteFlowBackButton';
import { useEventCustomerQuoteQuery } from '../../../hooks/routing/useEventCustomerQuoteQuery';
import { useMount } from 'react-use';
import { fireVisitedCustomerInfo } from '../../../services/analytics/partner/quotes';
import { Button } from '../../../components/Form/Button';
import { Quote } from '../../../api/intf/item';
import getQuoteListings, {
  hasQuoteListings,
} from '../../../utils/getQuoteListings';

interface Props {}

const PartnerQuoteCustomerPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { quoteId } = useEventCustomerQuoteQuery();
  const { data: quote, refetch: refetchQuote } = useCurrentQuoteQuery();
  const { data: event } = useEventQuery(quote?.trade_in_event_id);
  const { data: contact, isLoading } = useCustomerById(quote?.contact_id);

  useMount(() => fireVisitedCustomerInfo(quoteId));

  const hasItems = hasQuoteListings(quote);

  if (isLoading) {
    return <InStoreQuoteLayout step="customer">Loading...</InStoreQuoteLayout>;
  }

  function handleChangeCustomer(newQuote: Quote) {
    refetchQuote();

    if (getQuoteListings(newQuote).length > 0) {
      navigate(`/partner/quote/${newQuote.id}/summary`);
    } else {
      navigate(`/partner/quote/${newQuote.id}/add`);
    }
  }

  return (
    <InStoreQuoteLayout step="customer">
      {hasItems && <PartnerQuoteFlowBackButton to="summary" />}

      <h1 className="mb-2 flex space-x-4 text-lg font-semibold">
        <span>Customer Info</span>
      </h1>

      <PartnerContactForm
        existingContact={contact}
        quote={quote}
        onSuccessfulSubmit={handleChangeCustomer}
      >
        <div className="flex gap-8 pt-4">
          <FormikSubmitButton>Continue</FormikSubmitButton>
          <Link
            to={
              hasItems
                ? `/partner/quote/${quote?.id}/summary`
                : `/partner/quote/new/add`
            }
          >
            <Button variant="text">Skip</Button>
          </Link>
        </div>
      </PartnerContactForm>
    </InStoreQuoteLayout>
  );
};

export default PartnerQuoteCustomerPage;
