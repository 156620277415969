import React from 'react';
import { Button } from '../../../components/Form/Button';
import { Link, useSearchParams } from 'react-router-dom';
import PageLayout from '../../../components/Layout/PageLayout';
import PageHeader from '../../../components/Layout/PageHeader';
import PageSubheader from '../../../components/Layout/PageSubheader';
import EventSelector from '../../../components/EventForm/EventSelector';

interface Props {}

const EventSelectionPage: React.FC<Props> = () => {
  const [searchParams] = useSearchParams();
  const destination = searchParams.get('to');

  return (
    <PageLayout
      header={
        <div className="mx-auto max-w-sm">
          <PageHeader>Welcome</PageHeader>
          <PageSubheader>Select an event to get started</PageSubheader>
        </div>
      }
    >
      <div className="mx-auto max-w-sm space-y-8">
        <EventSelector
          destination={
            destination
              ? eventId => `/event/${eventId}${decodeURIComponent(destination)}`
              : undefined
          }
        />

        <Link to="/event/create" className="block">
          <Button fluid>Create a New Event</Button>
        </Link>
      </div>
    </PageLayout>
  );
};

export default EventSelectionPage;
