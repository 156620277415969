import axios from 'axios';
import { ApiResponse } from './intf/generic';
import { getBaseUrl } from './axiosInitialization';
import { PayoutMethod } from './intf/payoutMethods';
import { Partner, PartnerAddress } from './partners';

export interface TradeInEvent {
  id: string;
  org_id: string;
  name: string;
  partner: Partner;
  partner_address: PartnerAddress;
  start_date: string;
  end_date?: string;
  payout_methods?: PayoutMethod[];
  test_event?: boolean;
  staff_count?: number;
  freight_boxes?: number;
  retro_notes?: string;
  event_type?: string;
}

interface EventRequestBody {
  partner_id: Partner['id'];
  partner_address_id: PartnerAddress['id'];
  start_date: string; // 2015-05-29
  end_date?: string; // 2015-05-29
  event_type?: string;
  payout_methods: Pick<
    PayoutMethod,
    'id' | 'enabled' | 'credit_offer_amount' | 'charities'
  >[];
  staff_count?: number;
  freight_boxes?: number;
  retro_notes?: string;
}

export const fetchEvents = async () => {
  const { data } = await axios.get<ApiResponse<TradeInEvent[]>>(
    '/trade-in/v1/events',
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch events error');
};

export const fetchEventById = async (id: string) => {
  const { data } = await axios.get<ApiResponse<TradeInEvent>>(
    `/trade-in/v1/events/${id}`,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error(`Failed to fetch event by ID "${id}"`);
};

export interface EventStats {
  inventory_cost?: number;
  items_purchased?: number;
  items_quoted?: number;
  resale_value?: number;
  total_customers?: number;
}

export const fetchEventStats = async (id: string) => {
  const { data } = await axios.get<ApiResponse<EventStats>>(
    `/trade-in/v1/events/${id}/stats`,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error(`Failed to fetch event stats "${id}"`);
};

export const postCreateEvent = async (body: EventRequestBody) => {
  const { data } = await axios.post<ApiResponse<TradeInEvent>>(
    `/trade-in/v1/events`,
    body,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error(`Failed to create an event`);
};

export const putUpdateEvent = async (
  eventId: string,
  body: EventRequestBody,
) => {
  const { data } = await axios.put<ApiResponse<TradeInEvent>>(
    `/trade-in/v1/events/${eventId}`,
    body,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error(`Failed to edit this event`);
};
