import React, { useState } from 'react';
import PageLayout from '../../components/Layout/PageLayout';
import PageHeader from '../../components/Layout/PageHeader';
import Field from '../../components/Form/Field';
import FieldLabel from '../../components/Form/FieldLabel';
import Input from '../../components/Form/Input';
import ItemListingQrCode from '../../components/Printing/ItemListingQrCode';
import NewTabLink from '../../components/NewTabLink';
import { Button } from '../../components/Form/Button';
import { useQuery } from 'react-query';
import { fetchWarehouseItemOrRailsItemById } from '../../api/warehouse';
import qsStringify from '../../utils/qsStringify';

interface Props {}

const WarehouseReprintLabelsPage: React.FC<Props> = () => {
  const [itemId, setItemId] = useState('');

  const { data: warehouseItem } = useQuery(
    ['warehouse-item', itemId],
    () => fetchWarehouseItemOrRailsItemById(itemId),
    {
      keepPreviousData: true,
      staleTime: 30000,
      enabled: !!itemId && itemId.length > 2,
    },
  );

  let listingName: string | undefined;
  let listingId: number | string | undefined;

  if (warehouseItem) {
    if ('quote_listing' in warehouseItem) {
      listingName = warehouseItem.quote_listing.name;
      listingId =
        warehouseItem.quote_listing.legacy_item_id ||
        warehouseItem.quote_listing.legacy_id;
    } else {
      listingName = warehouseItem.name;
      listingId = warehouseItem.id;
    }
  }

  return (
    <PageLayout
      header={
        <>
          <PageHeader>Reprint Labels</PageHeader>
        </>
      }
    >
      <Field className="mb-4">
        <FieldLabel>Item ID</FieldLabel>
        <Input value={itemId} onChange={e => setItemId(e.target.value)} />
      </Field>

      {!!listingId && (
        <NewTabLink
          href={`/print${qsStringify({
            listing_id: listingId,
            listing_name: listingName,
            type: 'listing',
          })}`}
        >
          <Button>Print QR Code</Button>
        </NewTabLink>
      )}

      <div className="mt-8 h-40 w-40">
        <ItemListingQrCode
          listing={{
            legacy_id: Number(itemId) || listingId,
          }}
          unsized
        />
      </div>
    </PageLayout>
  );
};

export default WarehouseReprintLabelsPage;
