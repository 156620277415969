import { useQuery, UseQueryOptions } from 'react-query';
import { fetchQuoteById } from '../../api/item';
import { Quote } from '../../api/intf/item';

export default function useQuoteQuery(
  quoteId?: string,
  options?: UseQueryOptions<Quote, any, Quote, any>,
) {
  return useQuery(['quotes', quoteId], () => fetchQuoteById(quoteId!), {
    ...options,
    enabled: !!quoteId,
    staleTime: 30000,
  });
}
