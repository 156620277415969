import React, { useState } from 'react';
import SimpleModal, { ModalProps } from '../Modal/SimpleModal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  fetchPayoutsByQuoteId,
  postResendPayoutNotification,
} from '../../api/payouts';
import LoadingIndicator from '../Form/LoadingIndicator';
import { getErrorMessage } from '../../api/ApiError';
import Message from '../Form/Message';
import { Button } from '../Form/Button';
import displayPrice from '../../utils/displayPrice';
import useCustomerById from '../../hooks/useCustomerById';
import useQuoteQuery from '../../hooks/query/useQuoteQuery';
import ManageQuotePayoutsEditEmailModal from './ManageQuotePayoutsEditEmailModal';
import { Link } from 'react-router-dom';
import { useEventCustomerQuoteQuery } from '../../hooks/routing/useEventCustomerQuoteQuery';
import { toast } from 'react-toastify';
import qsStringify from '../../utils/qsStringify';
import { useLocation } from 'react-router';

interface Props extends ModalProps {
  allowFullContactEdit?: boolean;
  quoteId?: string;
}

const ManageQuotePayoutsModal: React.FC<Props> = ({
  allowFullContactEdit,
  quoteId,
  ...props
}) => {
  const location = useLocation();
  const { eventId, customerId } = useEventCustomerQuoteQuery();
  const queryClient = useQueryClient();
  const { data: quote } = useQuoteQuery(quoteId);
  const { data: contact } = useCustomerById(quote?.contact_id);
  const [editEmailModalOpen, setEditEmailModalOpen] = useState(false);

  const {
    error,
    data: payouts,
    isLoading,
  } = useQuery(
    ['quotePayouts', quoteId],
    () => fetchPayoutsByQuoteId(quoteId!),
    {
      enabled: props.open && !!quoteId,
      staleTime: 10000,
    },
  );

  const refetchAndInvalidateQuote = () => {
    queryClient.invalidateQueries({
      exact: false,
    });
  };

  const {
    error: resendError,
    mutate: resendNotification,
    isSuccess: isResendSuccess,
    isLoading: isResendingNotification,
    reset: resendReset,
  } = useMutation(
    (sms: boolean) => postResendPayoutNotification(quoteId!, sms),
    {
      onSuccess: refetchAndInvalidateQuote,
    },
  );

  const onSuccessfulEditEmail = () => {
    refetchAndInvalidateQuote();
    setEditEmailModalOpen(false);
    toast.success('Email updated');
  };

  return (
    <SimpleModal {...props} title="Manage Payouts" className="w-full max-w-lg">
      {contact && (
        <div className="mb-2">
          Email: {contact.email}{' '}
          {allowFullContactEdit ? (
            <Link
              className="font-semibold underline"
              to={`/event/${eventId}/customer/${customerId}/edit${qsStringify({
                backDestination: encodeURIComponent(
                  location.pathname + location.search,
                ),
                updatedLocation: encodeURIComponent(
                  location.pathname + location.search,
                ),
              })}`}
            >
              edit
            </Link>
          ) : (
            <button
              className="font-semibold underline"
              type="button"
              onClick={() => setEditEmailModalOpen(true)}
            >
              edit
            </button>
          )}
        </div>
      )}

      {isLoading ? (
        <LoadingIndicator size="large" className="mx-auto" />
      ) : (
        <>
          {payouts?.length === 0 && (
            <Message info>There are no payouts to manage.</Message>
          )}

          {payouts?.length! > 0 && (
            <table className="w-full">
              <thead>
                <tr>
                  <th className="px-2 py-1 text-left">Date</th>
                  <th className="px-2 py-1 text-left">Email</th>
                  <th className="px-2 py-1 text-left">Amount</th>
                  <th className="px-2 py-1 text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                {payouts?.map(payout => (
                  <tr key={payout.id}>
                    <td className="px-2 py-1">
                      {new Date(payout.created_at).toLocaleString()}
                    </td>
                    <td className="px-2 py-1">{payout.email ?? <i>none</i>}</td>
                    <td className="px-2 py-1">{displayPrice(payout.amount)}</td>
                    <td className="px-2 py-1">{payout.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}

      {!!(error || resendError) && (
        <Message error>{getErrorMessage(error || resendError)}</Message>
      )}

      {isResendSuccess && <Message success>Notification resent</Message>}

      <div className="mt-4 grid grid-cols-2 gap-4">
        <div />

        <Button
          onClick={() => resendNotification(false)}
          disabled={isResendingNotification}
        >
          Resend Notification
        </Button>
      </div>

      {!allowFullContactEdit && (
        <ManageQuotePayoutsEditEmailModal
          onSuccessfulSubmit={onSuccessfulEditEmail}
          open={editEmailModalOpen}
          onClose={() => setEditEmailModalOpen(false)}
          quoteId={quoteId}
        />
      )}
    </SimpleModal>
  );
};

export default ManageQuotePayoutsModal;
