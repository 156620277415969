import React from 'react';
import { useField } from 'formik';
import FieldError from './FieldError';

interface Props {
  className?: string;
  name: string;
}

const FormikFieldError: React.FC<Props> = ({ className, name }) => {
  const [field, meta] = useField(name);

  console.log(meta.error);

  return (
    <FieldError className={className} id={name}>
      {meta.error}
    </FieldError>
  );
};

export default FormikFieldError;
