import qsParse from '../../utils/qsParse';
import getEnv from '../../utils/getEnv';
import * as amplitude from '@amplitude/analytics-browser';

export function fireAnalytics(
  name: string,
  { src, ...params }: Record<string, any> = {},
) {
  const urlSrc = qsParse(window.location.search).src;
  const combinedSrc = src || urlSrc;

  if (getEnv() !== 'production') {
    console.groupCollapsed(name);
    if (!combinedSrc) {
      console.warn('No Source');
    } else {
      console.log(
        'Source:',
        combinedSrc,
        !src && urlSrc ? '(using src from URL)' : '',
      );
    }
    console.log('Params:', params);
    console.groupEnd();
  }

  amplitude?.track(name, {
    ...params,
    src: combinedSrc || '',
  });
}
