import qs from 'qs';
import qsParse from './qsParse';

const defaultOptions: qs.IStringifyOptions = {
  addQueryPrefix: true,
  arrayFormat: 'brackets',
  skipNulls: true,
  allowDots: true,
  encode: false,
};

/**
 * Returns a query string prepended by a "?"
 */
export default function qsStringify<
  Q extends Record<string, any> | undefined | null,
>(query?: Q, options: qs.IStringifyOptions = {}) {
  if (!query) {
    return '';
  }

  if (query instanceof URLSearchParams) {
    return qs.stringify(qsParse(query.toString()), {
      ...defaultOptions,
      ...options,
    });
  }

  return qs.stringify(query, {
    ...defaultOptions,
    ...options,
  });
}
