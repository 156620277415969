import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchPartners, PartnerAddress } from '../../api/partners';
import FormikField from '../Form/FormikField';
import { Button } from '../Form/Button';
import FormikSearchableSelect from '../Form/Select/FormikSearchableSelect';
import { ArrayHelpers, FieldArray, useFormikContext } from 'formik';
import Message from '../Form/Message';
import CreatePartnerModal from './CreatePartnerModal';
import { EventFormValues } from './CreateEventForm';
import CreatePartnerLocationModal from './CreatePartnerLocationModal';
import FieldLabel from '../Form/FieldLabel';
import Checkbox from '../Form/Checkbox';
import FormikErrorMessage from '../Form/FormikErrorMessage';
import Field from '../Form/Field';
import FormikNumberInput from '../Form/FormikNumberField';
import FormikNumberField from '../Form/FormikNumberField';
import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import FormikTextArea from '../Form/FormikTextArea';

export const EVENT_TYPES = [
  {
    id: 'SLS_EVENT_WEEKEND',
    name: 'SLS Event (Weekend)',
    description:
      'SidelineSwap pop-up events hosted over a weekend, usually with a retail partner',
  },
  {
    id: 'SLS_EVENT_EXTRA',
    name: 'SLS Event (Extra)',
    description:
      'SidelineSwap pop-up event planned in addition to our normal weekend schedule. These are usually hosted during the week at a local sports facility or local shop.',
  },
  {
    id: 'SLS_LOCAL_PICKUP',
    name: 'SLS Local Pick-up',
    description:
      'Events the Trade-in Team creates to track local pick-ups completed outside their normal event schedule',
  },
  {
    id: 'SLS_DIRECT_BUY',
    name: 'SLS Direct Buy',
    description:
      'Events created to track direct buys, like returns or demo buys from a retail partner.',
  },
  {
    id: 'SLS_DONATION_BIN',
    name: 'SLS Donation Bin',
    description: 'Items collected from SidelineSwap donation bins',
  },
  {
    id: 'SELLER_EVENT',
    name: 'Seller Event',
    description:
      'Events created by sellers outside of the SidelineSwap organization. This event id will be automatically assigned for these orgs.',
  },
  {
    id: 'WAREHOUSE',
    name: 'Warehouse',
    description:
      'Items received and processed through the warehouse (Demos, Returns, Opportunity Buys, SLS Returns)',
  },
  {
    id: 'AOIS_PROGRAM',
    name: 'In-store Program',
    description:
      'Always On In-store Trade-in Program: Events associated with our AOIS program. This event id will be automatically assigned for these orgs.',
  },
  {
    id: 'TEST',
    name: 'Test',
    description:
      'Test events and stores. All data from these events will be ignored in our tracking. Test events should not be able to issue payouts to actual gift cards (prizeout, fiserv, etc). Consider migrating data from test_event field and deprecating the old one.',
  },
];

function stringifyAddress(p: PartnerAddress) {
  let str = `${p.address_1}, ${p.city}, ${p.state} ${p.zip}, ${p.country}`;

  if (p.store_number) {
    str = `#${p.store_number}: ${str}`;
  }

  return str;
}

interface Props {
  editMode?: boolean;
}

const EventFormFields: React.FC<Props> = ({ editMode }) => {
  const formik = useFormikContext<EventFormValues>();

  const {
    data: partners,
    error,
    refetch: refetchPartners,
  } = useQuery('partners', fetchPartners);
  const [partnerModalOpen, setPartnerModalOpen] = useState(false);
  const [locationModalOpen, setLocationModalOpen] = useState(false);

  return (
    <div className="space-y-4">
      {!!error && <Message error>Failed to retrieve partners list</Message>}

      <FormikSearchableSelect
        clearable
        name="partner"
        placeholder="Select or create a partner..."
        items={partners}
        renderItem={p => p.name}
        itemToString={p => p?.name ?? ''}
        label="Partner"
        filterItem={(input, item) => item.name.includes(input)}
        postItemsContent={
          <Button fluid onClick={() => setPartnerModalOpen(true)}>
            Create Partner
          </Button>
        }
      />

      <FormikSearchableSelect
        clearable
        name="location"
        items={formik?.values?.partner?.addresses as PartnerAddress[]}
        renderItem={stringifyAddress}
        itemToString={p => (p ? stringifyAddress(p) : '')}
        label="Event Location"
        placeholder="Select a location"
        filterItem={(input, item) => {
          const lowerCase = input.toLowerCase();
          return (
            item.address_1.toLowerCase().includes(lowerCase) ||
            item.address_2?.includes(lowerCase) ||
            item.state.toLowerCase().includes(lowerCase) ||
            item.country.toLowerCase().includes(lowerCase)
          );
        }}
        postItemsContent={
          <Button
            fluid
            disabled={!formik.values.partner}
            onClick={() => setLocationModalOpen(true)}
          >
            Create Location
          </Button>
        }
      />

      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <FormikField name="start_date" label="Start Date" type="date" />
        <FormikField name="end_date" label="End Date" type="date" />
      </div>

      <FormikSearchableSelect
        name="event_type"
        label="Event Type"
        items={EVENT_TYPES}
        filterItem={(input, item) => {
          const searchString = input.toLowerCase();
          return (
            item.name.toLowerCase().includes(searchString) ||
            item.description.toLowerCase().includes(searchString)
          );
        }}
        itemToString={item => item?.name || ''}
        renderItem={item => (
          <div>
            <span className="text-base font-semibold">{item.name}</span>
            <br />
            <span className="text-sm">{item.description}</span>
          </div>
        )}
      />

      <div>
        <FieldLabel>Payout Methods</FieldLabel>

        <div className="space-y-2">
          {formik.values.payout_methods?.map((payoutMethod, i) => (
            <div key={payoutMethod.id}>
              <label className="flex items-center space-x-2">
                <Checkbox
                  checked={formik.values.payout_methods[i].enabled}
                  onChange={e => {
                    formik.setFieldValue(
                      `payout_methods[${i}].enabled`,
                      e.target.checked,
                    );

                    // When disabling this payout method, remove charities (if there are any)
                    if (!e.target.checked) {
                      formik.setFieldValue(
                        `payout_methods[${i}].charities`,
                        undefined,
                      );
                    }
                  }}
                  className="text-green-900"
                />

                <div>{payoutMethod.name}</div>
              </label>

              {payoutMethod.name === 'Donate' &&
                formik.values.payout_methods[i].enabled && (
                  <div className="py-2 pl-6">
                    <FieldLabel>Charities</FieldLabel>
                    <FieldArray
                      name={`payout_methods[${i}].charities`}
                      render={(arrayHelpers: ArrayHelpers) => (
                        <div className="space-y-2">
                          {payoutMethod.charities?.map(
                            (payoutMethod: string, index: number) => (
                              <div
                                key={index}
                                className="flex items-center space-x-2"
                              >
                                <FormikField
                                  name={`payout_methods[${i}].charities[${index}]`}
                                  autoFocus
                                />
                                <button
                                  type="button"
                                  onClick={() =>
                                    arrayHelpers.insert(index + 1, '')
                                  }
                                >
                                  <PlusCircleIcon className="h-6 w-6 text-green-500" />
                                </button>
                                <button
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <MinusCircleIcon className="h-6 w-6 text-red-500" />
                                </button>
                              </div>
                            ),
                          )}

                          {!payoutMethod.charities?.length && (
                            <button
                              type="button"
                              className="flex items-center space-x-1 text-sm font-semibold"
                              onClick={() => arrayHelpers.push('')}
                            >
                              <PlusCircleIcon className="h-6 w-6 text-green-500" />
                              <div>Add a Charity</div>
                            </button>
                          )}
                        </div>
                      )}
                    />
                  </div>
                )}

              {formik.values.payout_methods[i].enabled &&
                payoutMethod.credit_offer_enabled && (
                  <Field className="pl-6">
                    <FieldLabel htmlFor={`bonus-${payoutMethod.id}`}>
                      Bonus Amount (optional)
                    </FieldLabel>
                    <div className="flex items-center space-x-2">
                      <div className="w-32">
                        <FormikNumberInput
                          name={`payout_methods.${i}.credit_offer_amount`}
                          id={`bonus-${payoutMethod.id}`}
                          changeProperty="floatValue"
                          suffix="%"
                          decimalScale={2}
                          placeholder="(ex: 10%)"
                          max={100}
                          // disabled={
                          // import.meta.env.ENVIRONMENT == 'staging' ||
                          // !payoutMethod.credit_offer_enabled
                          // }
                        />
                      </div>
                    </div>
                  </Field>
                )}
            </div>
          ))}
        </div>
      </div>

      <FormikNumberField name="staff_count" label="Part-time Hours" />
      <FormikNumberField name="freight_boxes" label="Freight Boxes" />

      {editMode && (
        <Field>
          <FieldLabel htmlFor="notes">Retro Notes</FieldLabel>
          <FormikTextArea name="retro_notes" rows={4} />
        </Field>
      )}

      <FormikErrorMessage />

      <CreatePartnerModal
        open={partnerModalOpen}
        onClose={() => setPartnerModalOpen(false)}
        onSuccess={partner => {
          formik.setFieldValue('partner', partner);
          setPartnerModalOpen(false);
        }}
      />

      {formik.values.partner && (
        <CreatePartnerLocationModal
          open={locationModalOpen}
          onClose={() => setLocationModalOpen(false)}
          partner={formik.values.partner}
          onSuccess={address => {
            refetchPartners();
            formik.setFieldValue('location', address);
            setLocationModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default EventFormFields;
