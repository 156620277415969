interface AnyIdRecord<T extends AnyIdRecord<any>> {
  id: number | string;
  children?: T[];

  [key: string]: any;
}

export function getDetailPathById<T extends AnyIdRecord<any>>(
  details?: T[],
  id?: number | string,
  lineage: T[] = [],
): undefined | null | T[] {
  if (!details) {
    return lineage;
  }

  for (const detail of details) {
    const newLineage = [...lineage, detail];
    if (detail.id == id) {
      return newLineage;
    }

    // Navigate down the path and test it
    if (detail.children) {
      const foundPath = getDetailPathById(detail.children, id, newLineage);
      if (foundPath?.length! > 0) {
        return foundPath;
      }
    }
  }

  return null;
}
