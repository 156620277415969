import thousandsSeparator from './thousandsSeparator';

const formatterDecimals =
  typeof Intl !== 'undefined' &&
  typeof Intl.NumberFormat !== 'undefined' &&
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'USD',
    style: 'currency',
  });

const formatterNoDecimals =
  typeof Intl !== 'undefined' &&
  typeof Intl.NumberFormat !== 'undefined' &&
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    currency: 'USD',
    style: 'currency',
  });

const formatterDecimalsNoNegative =
  typeof Intl !== 'undefined' &&
  typeof Intl.NumberFormat !== 'undefined' &&
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: 'USD',
    style: 'currency',
    signDisplay: 'never',
  });

const formatterNoDecimalsNoNegative =
  typeof Intl !== 'undefined' &&
  typeof Intl.NumberFormat !== 'undefined' &&
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    currency: 'USD',
    style: 'currency',
    signDisplay: 'never',
  });

/**
 * Adds dollar sign and formats the price
 * @param {number} price
 * @param {boolean} decimals
 */
export default function displayPrice(
  price: string | number | null | undefined,
  decimals = true,
  negative = true,
) {
  if (price == '--') return '$--';
  if (price == null) return '';
  if (typeof price == 'string' && !price) return '';

  if (decimals && formatterDecimals) {
    if (negative) {
      return formatterDecimals.format(+price);
    } else if (formatterDecimalsNoNegative) {
      return formatterDecimalsNoNegative.format(+price);
    }
  }

  if (!decimals && formatterNoDecimals) {
    if (negative) {
      return formatterNoDecimals.format(+price);
    } else if (formatterNoDecimalsNoNegative) {
      return formatterNoDecimalsNoNegative.format(+price);
    }
  }

  let newPrice: string | number = Number(price);

  let formattedPrice = '';
  if (price < 0) {
    if (negative) {
      formattedPrice = '- ';
    }

    newPrice = Math.abs(Number(price));
  }

  if (!decimals) {
    newPrice = thousandsSeparator(Math.floor(newPrice));
  } else {
    newPrice = thousandsSeparator(newPrice, 2);
  }

  formattedPrice += `$${newPrice}`;
  return formattedPrice;
}
