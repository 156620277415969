import React, { useState } from 'react';
import useCurrentEventQuery from '../../../hooks/query/useCurrentEventQuery';
import CreateCustomerForm from '../../../components/CustomerForm/CreateCustomerForm';
import CustomerFormFields from '../../../components/CustomerForm/CustomerFormFields';
import { Customer } from '../../../api/intf/customer';
import { Link } from 'react-router-dom';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

interface Props {}

const SelfContactFlowPage: React.FC<Props> = () => {
  const { data: event } = useCurrentEventQuery();
  const [existingCustomers, setExistingCustomers] = useState<Customer[]>([]);

  return (
    <div className="container px-4 pt-8">
      <Link to="/check-in" className="mb-4 flex items-center space-x-2">
        <ArrowLeftIcon className="h-4 w-4" />
        <span>Choose a different event</span>
      </Link>

      <h1 className="mb-4 text-2xl font-semibold">Enter your information</h1>

      <div className="text-lg font-semibold">{event?.partner.name}</div>
      <div className="mb-8 text-sm text-slate-green-500">
        <div>{event?.partner_address.address_1}</div>
        {event?.partner_address.address_2 && (
          <div>{event?.partner_address.address_2}</div>
        )}
        <div>
          {event?.partner_address.city}, {event?.partner_address.state}{' '}
          {event?.partner_address.zip}
        </div>
      </div>

      <CreateCustomerForm setMatchedExistingCustomers={setExistingCustomers}>
        <CustomerFormFields />
      </CreateCustomerForm>
    </div>
  );
};

export default SelfContactFlowPage;
