import React from 'react';
import { IdBasedObject } from '../../../types/generic';
import { useField } from 'formik';
import SearchableSelect, { SearchableSelectProps } from './SearchableSelect';
import FieldError from '../FieldError';

interface Props<T extends IdBasedObject>
  extends Omit<SearchableSelectProps<T>, 'value' | 'onChange'> {
  name: string;
  value?: never;
  onChange?: never;
}

function FormikSearchableSelect<T extends IdBasedObject>({
  name,
  ...props
}: Props<T>) {
  const [field, meta, helpers] = useField(name);
  return (
    <div className="w-full">
      <SearchableSelect
        {...props}
        value={field.value}
        onChange={v => helpers.setValue(v)}
        error={meta.error}
      />
      {!!meta.error && <FieldError>{meta.error}</FieldError>}
    </div>
  );
}

export default FormikSearchableSelect;
