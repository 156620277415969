/*

LIST PRICE MULTIPLIER
              High Demand  Medium Demand  Low Demand
Excellent     115%         100%           90%
Very Good     100%         90%            80%
Good          80%          65%            50%
Unacceptable  0%           0%             0%


MARGIN MULTIPLIER
              High Demand  Medium Demand  Low Demand
Excellent     51%          51%            51%
Very Good     60%          60%            70%
Good          60%          70%            75%
Unacceptable  0%           0%             0%
*/

export type Demand = 'high' | 'medium' | 'low';
export type Quality =
  | 'newWithTags'
  | 'newWithoutTags'
  | 'excellent'
  | 'veryGood'
  | 'good'
  | 'damagedParts'
  | 'unacceptable';

export const QUALITY_LABELS: Record<Quality, string> = {
  newWithTags: 'New with Tags',
  newWithoutTags: 'New without Tags',
  excellent: 'Excellent',
  veryGood: 'Very Good',
  good: 'Good',
  damagedParts: 'Damaged / Parts',
  unacceptable: 'Unacceptable',
} as const;

export const DEMAND_LABELS: Record<Demand, string> = {
  high: 'High',
  medium: 'Medium',
  low: 'Low',
} as const;

const VALUE_MATRIX: Record<Demand, Record<Quality, number>> = {
  high: {
    newWithTags: 1,
    newWithoutTags: 1,
    excellent: 1.1,
    veryGood: 1.05,
    good: 1,
    damagedParts: 0,
    unacceptable: 0,
  },
  medium: {
    newWithTags: 1,
    newWithoutTags: 1,
    excellent: 1.1,
    veryGood: 1.05,
    good: 1,
    damagedParts: 0,
    unacceptable: 0,
  },
  low: {
    newWithTags: 1,
    newWithoutTags: 1,
    excellent: 1.1,
    veryGood: 1.05,
    good: 1,
    damagedParts: 0,
    unacceptable: 0,
  },
};

// First dimension is DEMAND (x), second dimension is QUALITY (y)
export const VALUE_MARGIN_MATRIX: {
  min: number;
  max: number;
  margin: Record<Demand, Record<Quality, number>>;
  value: Record<Demand, Record<Quality, number>>;
}[] = [
  {
    min: 0,
    max: 49.99,
    margin: {
      high: {
        newWithTags: 1 - 0.7,
        newWithoutTags: 1 - 0.7,
        excellent: 1 - 0.7,
        veryGood: 1 - 0.7,
        good: 1 - 0.7,
        damagedParts: 0,
        unacceptable: 0,
      },
      medium: {
        newWithTags: 1 - 0.75,
        newWithoutTags: 1 - 0.75,
        excellent: 1 - 0.75,
        veryGood: 1 - 0.75,
        good: 1 - 0.75,
        damagedParts: 0,
        unacceptable: 0,
      },
      low: {
        newWithTags: 1 - 0.8,
        newWithoutTags: 1 - 0.8,
        excellent: 1 - 0.8,
        veryGood: 1 - 0.8,
        good: 1 - 0.8,
        damagedParts: 0,
        unacceptable: 0,
      },
    },
    value: VALUE_MATRIX,
  },
  {
    min: 50,
    max: 99.99,
    margin: {
      high: {
        newWithTags: 1 - 0.6,
        newWithoutTags: 1 - 0.6,
        excellent: 1 - 0.6,
        veryGood: 1 - 0.6,
        good: 1 - 0.6,
        damagedParts: 0,
        unacceptable: 0,
      },
      medium: {
        newWithTags: 1 - 0.67,
        newWithoutTags: 1 - 0.67,
        excellent: 1 - 0.67,
        veryGood: 1 - 0.67,
        good: 1 - 0.67,
        damagedParts: 0,
        unacceptable: 0,
      },
      low: {
        newWithTags: 1 - 0.75,
        newWithoutTags: 1 - 0.75,
        excellent: 1 - 0.75,
        veryGood: 1 - 0.75,
        good: 1 - 0.75,
        damagedParts: 0,
        unacceptable: 0,
      },
    },
    value: VALUE_MATRIX,
  },
  {
    min: 100,
    max: 199.99,
    margin: {
      high: {
        newWithTags: 1 - 0.5,
        newWithoutTags: 1 - 0.5,
        excellent: 1 - 0.5,
        veryGood: 1 - 0.5,
        good: 1 - 0.5,
        damagedParts: 0,
        unacceptable: 0,
      },
      medium: {
        newWithTags: 1 - 0.6,
        newWithoutTags: 1 - 0.6,
        excellent: 1 - 0.6,
        veryGood: 1 - 0.6,
        good: 1 - 0.6,
        damagedParts: 0,
        unacceptable: 0,
      },
      low: {
        newWithTags: 1 - 0.7,
        newWithoutTags: 1 - 0.7,
        excellent: 1 - 0.7,
        veryGood: 1 - 0.7,
        good: 1 - 0.7,
        damagedParts: 0,
        unacceptable: 0,
      },
    },
    value: VALUE_MATRIX,
  },
  {
    min: 200,
    max: Infinity,
    margin: {
      high: {
        newWithTags: 1 - 0.4,
        newWithoutTags: 1 - 0.4,
        excellent: 1 - 0.4,
        veryGood: 1 - 0.4,
        good: 1 - 0.4,
        damagedParts: 0,
        unacceptable: 0,
      },
      medium: {
        newWithTags: 1 - 0.55,
        newWithoutTags: 1 - 0.55,
        excellent: 1 - 0.55,
        veryGood: 1 - 0.55,
        good: 1 - 0.55,
        damagedParts: 0,
        unacceptable: 0,
      },
      low: {
        newWithTags: 1 - 0.65,
        newWithoutTags: 1 - 0.65,
        excellent: 1 - 0.65,
        veryGood: 1 - 0.65,
        good: 1 - 0.65,
        damagedParts: 0,
        unacceptable: 0,
      },
    },
    value: VALUE_MATRIX,
  },
];

export const QUALITY_DESCRIPTION: Record<Quality, string> = {
  newWithTags: `This item is brand new, is in the original packaging and/or has the original tags attached, and has never been used.

Our items typically ship within 1 business day. If you have questions, don’t hesitate to send us a message. 
`,

  newWithoutTags: `This item is brand new and has never been used but is no longer in its original packaging or it may be missing the original tags.

Our items typically ship within 1 business day. If you have questions, don’t hesitate to send us a message.`,

  excellent: `This item is in excellent condition. It may have some minor visible signs of limited use but it is in fantastic shape and has been well maintained.

Our items typically ship within 1 business day. If you have questions, don’t hesitate to send us a message.`,

  veryGood: `This item is still in very good condition. It has been used for a season or two and will demonstrate some cosmetic signs of wear but was well cared for.

Our items typically ship within 1 business day. If you have questions, don’t hesitate to send us a message.`,

  good: `This item is used but it is in good working condition. It has markings or blemishes consistent with frequent use but does not show any signs of abuse. There is plenty of life left in this one! 

Our items typically ship within 1 business day. If you have questions, don’t hesitate to send us a message.`,

  damagedParts: `This item is damaged and is being sold for parts or practice. The item should not be used for competition, only in a controlled environment. 

Our items typically ship within 1 business day. If you have questions, don’t hesitate to send us a message.`,

  unacceptable: '',
};
