import axios from 'axios';
import { ApiResponse } from './intf/generic';
import { getBaseUrl } from './axiosInitialization';
import { Customer } from './intf/customer';

export interface Payout {
  id: string;
  audit_id: string;
  phone: string;
  email: string;
  amount: number;
  status: string;
  external_id: string;
  processor: string;
  message: string;
  created_at: string;
  updated_at: string;
}

export const fetchPayoutsByQuoteId = async (quoteId: string) => {
  const { data } = await axios.get<ApiResponse<Payout[]>>(
    `/trade-in/v1/quotes/${quoteId}/payouts`,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch quote payouts error');
};

export const postVoidPayout = async (quoteId: string, payoutId: string) => {
  const { data } = await axios.post<ApiResponse<Payout[]>>(
    `/trade-in/v1/quotes/${quoteId}/payouts/${payoutId}/void`,
    {},
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Void quote payout error');
};

export const postResendPayoutNotification = async (
  quoteId: string,
  sms?: boolean,
) => {
  await axios.post<ApiResponse<Payout[]>>(
    `/trade-in/v1/quotes/${quoteId}/payouts/resend-notification`,
    {},
    {
      baseURL: getBaseUrl('inventoryApi'),
      params: {
        'send-sms': sms ? 'true' : undefined,
      },
    },
  );
};

export const putIssue5DollarGiftCard = async (
  quoteId: string,
  dsgPayoutMethodId: string,
  contact: Customer,
) => {
  await axios.put<ApiResponse<Payout[]>>(
    `/trade-in/v1/quotes/${quoteId}/complete`,
    {
      payouts: [{ payout_method_id: dsgPayoutMethodId }],
      issue_dsg_gift_card: true,
      contact_email: contact.email,
      contact_phone_number: contact.phone,
    },
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );
};
