import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default function useSearchParamState<T extends string>(
  key: string,
  {
    defaultValue,
    replace,
  }: {
    defaultValue?: T;
    replace?: boolean;
  },
) {
  const [searchParams, setSearchParams] = useSearchParams(
    defaultValue
      ? {
          [key]: defaultValue,
        }
      : undefined,
  );
  const [state, setState] = useState(
    () => searchParams.get(key) || defaultValue,
  );

  useEffect(() => {
    const newParamValue = searchParams.get(key);
    if (newParamValue !== state) {
      setState(searchParams.get(key) || defaultValue);
    }
  }, [searchParams]);

  function setParam(value: T) {
    const newParams = new URLSearchParams(searchParams);
    newParams.set(key, value);
    setSearchParams(newParams, {
      replace,
    });
    setState(value);
  }

  return [state, setParam] as const;
}
