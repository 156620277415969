import React from 'react';
import PageLayout from '../../../components/Layout/PageLayout';
import PageHeader from '../../../components/Layout/PageHeader';
import { useQueries, useQuery, useQueryClient } from 'react-query';
import { fetchQuotesByCustomer } from '../../../api/item';
import DashboardRecentQuoteCard from '../../../components/Partner/Dashboard/DashboardRecentQuoteCard';
import { useMount } from 'react-use';
import { fireVisitedAllQuotes } from '../../../services/analytics/partner/dashboard';
import SearchBarUrlQuery from '../../../components/Partner/Dashboard/ViewAllQuotes/SearchBarUrlQuery';
import { useSearchParams } from 'react-router-dom';
import { fetchCustomerByQuery } from '../../../api/customer';
import { compareDesc } from 'date-fns';
import { Quote } from '../../../api/intf/item';
import LoadingIndicator from '../../../components/Form/LoadingIndicator';
import { fetchPartnerQuotes } from '../../../api/partnerQuote';
import Message from '../../../components/Form/Message';
import { getErrorMessage } from '../../../api/ApiError';

interface Props {}

const PartnerDashboardQuotesPage: React.FC<Props> = () => {
  useMount(fireVisitedAllQuotes);
  const queryClient = useQueryClient();

  const [params] = useSearchParams();
  const search = params.get('q') || '';

  const {
    data: recentQuotes,
    error: recentQuotesError,
    isLoading: isLoadingRecentQuotes,
  } = useQuery(['partner-quotes'], () => fetchPartnerQuotes());

  const { data: searchedCustomers } = useQuery(
    ['customer-quotes', search],
    () => fetchCustomerByQuery(search),
    {
      enabled: !!search,
      onSuccess: customers => {
        for (const customer of customers) {
          queryClient.setQueryData(['contact', customer.id], customer);
        }
      },
    },
  );

  const quotesByCustomers = useQueries(
    searchedCustomers?.map(customer => ({
      queryKey: ['quotes-by-contact', customer.id],
      queryFn: () => fetchQuotesByCustomer(customer.id),
      enabled: !!customer.id,
    })) || [],
  );

  const quotes = search
    ? (quotesByCustomers
        ?.map(q => q.data)
        .flat()
        .filter(q => q != null)
        ?.sort((a, b) => {
          return compareDesc(new Date(a!.created_at), new Date(b!.created_at));
        }) as Quote[])
    : recentQuotes;

  return (
    <PageLayout
      header={
        <>
          <PageHeader>Quotes</PageHeader>
        </>
      }
    >
      <div className="mb-8 max-w-sm">
        <SearchBarUrlQuery label="Search by customer name or quote ID" />
      </div>

      {!!recentQuotesError && (
        <Message error>{getErrorMessage(recentQuotesError)}</Message>
      )}

      {isLoadingRecentQuotes && (
        <LoadingIndicator size="large" className="mx-auto" />
      )}

      {!queryClient.isFetching() && quotes && quotes.length === 0 && (
        <div className="text-center text-lg font-semibold text-slate-green-500">
          {search ? `No quotes matching "${search}"` : 'No recent quotes'}
        </div>
      )}

      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        {quotes?.map(quote => (
          <DashboardRecentQuoteCard quote={quote} key={quote.id} />
        ))}
      </div>
    </PageLayout>
  );
};

export default PartnerDashboardQuotesPage;
