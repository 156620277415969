import React, { useEffect } from 'react';
import useCurrentQuoteQuery from '../../../hooks/query/useCurrentQuoteQuery';
import Message from '../../../components/Form/Message';
import { Button, ButtonLink } from '../../../components/Form/Button';
import { Link } from 'react-router-dom';
import InStoreQuoteLayout from '../../../components/Layout/Layouts/InStoreQuoteLayout';
import PartnerQuoteListingRow from '../../../components/Partner/Quote/PartnerQuoteListingRow';
import useRouteQuery from '../../../hooks/useRouteQuery';
import useMobile from '../../../hooks/useMobile';
import {
  fireClickedAddAnotherItem,
  fireVisitedQuoteSummary,
} from '../../../services/analytics/partner/quotes';
import { Quote } from '../../../api/intf/item';
import useCustomerById from '../../../hooks/useCustomerById';
import PartnerQuoteFlowBackButton from '../../../components/Partner/Quote/PartnerQuoteFlowBackButton';
import getQuoteListings from '../../../utils/getQuoteListings';
import displayPrice from '../../../utils/displayPrice';

interface Props {}

function getSubtotal(quote: Quote) {
  const quoteListings = getQuoteListings(quote);
  return quoteListings.reduce(
    (acc, listing) => acc + listing.cash_offer_amount,
    0,
  );
}

const PartnerQuoteSummaryPage: React.FC<Props> = () => {
  const query = useRouteQuery<{ just_added?: string }>();
  const mobile = useMobile();

  const { data: quote, error } = useCurrentQuoteQuery();
  const quoteListings = getQuoteListings(quote);

  const { data: contact } = useCustomerById(quote?.contact_id);

  useEffect(() => {
    if (quote?.id) {
      if (contact?.id) {
        fireVisitedQuoteSummary({
          quoteId: quote.id,
          cartSize: quoteListings.length,
          cartValue: getSubtotal(quote),
          contactId: contact.id,
          contactEmail: contact.email,
          contactPhone: contact.phone,
          contactFirstName: contact.first_name,
          contactLastName: contact.last_name,
        });
      } else {
        fireVisitedQuoteSummary({
          quoteId: quote.id,
          cartSize: quoteListings?.length!,
          cartValue: getSubtotal(quote),
        });
      }
    }
  }, [quote?.id, contact?.id]);

  if (!quote || error) {
    return (
      <InStoreQuoteLayout step="productDetails">
        {!!error && <Message error>Failed to load quote</Message>}
      </InStoreQuoteLayout>
    );
  }

  const justAddedQuoteListing = query.just_added
    ? quoteListings?.find(listing => listing.id === query.just_added)
    : undefined;
  const otherQuoteListings = query.just_added
    ? quoteListings?.filter(listing => listing.id !== query.just_added)
    : quoteListings;

  const continueDestination = quote.contact_id
    ? `/partner/quote/${quote.id}/payment`
    : `/partner/quote/${quote.id}/customer`;

  const subtotal = getSubtotal(quote);

  return (
    <InStoreQuoteLayout step="productDetails">
      <PartnerQuoteFlowBackButton to="contact" />

      {justAddedQuoteListing && (
        <div className="mb-4 text-2xl font-semibold">
          Success! This item was added to your quote.
        </div>
      )}

      {justAddedQuoteListing ? (
        <PartnerQuoteListingRow listing={justAddedQuoteListing} />
      ) : (
        <div className="space-y-4">
          {quoteListings?.map(listing => (
            <PartnerQuoteListingRow key={listing.id} listing={listing} />
          ))}

          {quoteListings?.length! > 0 && (
            <div className="mt-6">
              Subtotal:{' '}
              <span className="font-semibold">{displayPrice(subtotal)}</span>
            </div>
          )}
        </div>
      )}

      {mobile && justAddedQuoteListing && (
        <>
          <div className="mt-8 mb-2 text-lg">Other items in this quote:</div>

          <div className="space-y-4">
            {otherQuoteListings?.map(listing => (
              <PartnerQuoteListingRow key={listing.id} listing={listing} />
            ))}
          </div>
        </>
      )}

      <hr className="my-8" />

      <div className="flex space-x-4">
        <ButtonLink
          to={`/partner/quote/${quote.id}/add`}
          onClick={() => fireClickedAddAnotherItem(quote?.id)}
          variant="outlined"
        >
          Add Another Item
        </ButtonLink>

        <ButtonLink
          to={continueDestination}
          disabled={!quoteListings || quoteListings?.length == 0}
        >
          Continue
        </ButtonLink>
      </div>
    </InStoreQuoteLayout>
  );
};

export default PartnerQuoteSummaryPage;
