import axios from 'axios';

export const putSquareCode = async (squareCode: string) => {
  await axios.get('/commerce/account/v1/auth/square/authorize', {
    params: {
      code: squareCode,
    },
    headers: {
      'x-sls-client-type': 'IN_STORE',
    },
  });
};
