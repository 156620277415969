import { differenceInSeconds } from 'date-fns';
import { ApiError } from '../api/ApiError';
import { ScanHistory } from '../types/spawnScanning';
import { useRef, useState } from 'react';
import { ReceiveResponse } from '../api/spawn';

const THROTTLE_SECONDS = 3;

interface ScanProcessor {
  clearData: () => void;
  onScanData: (data: string) => Promise<void> | void;
  scanHistory: ScanHistory[];
}

export default function useScanProcessor(
  process: (data: string) => Promise<ReceiveResponse | undefined> | void,
): ScanProcessor {
  const [, setRerender] = useState<any>();
  const scanHistory = useRef<ScanHistory[]>([]);

  function rerender() {
    setRerender(new Date());
  }

  function clearData() {
    scanHistory.current = [];
    rerender();
  }

  async function onScanData(data: string) {
    console.log('SCANNED', data);
    // Look for the LAST scan history
    let found: ScanHistory | undefined;
    for (let i = scanHistory.current.length - 1; i >= 0; i--) {
      if (scanHistory.current[i].data === data) {
        found = scanHistory.current[i];
        break;
      }
    }

    const now = new Date();

    let shouldProcess = false;
    if (!found) {
      shouldProcess = true;
    } else if (found.status !== 'loading') {
      if (
        differenceInSeconds(now, new Date(found.createdAt)) >= THROTTLE_SECONDS
      ) {
        shouldProcess = true;
      } else if (
        found.status === 'error' &&
        differenceInSeconds(now, new Date(found.createdAt)) >= THROTTLE_SECONDS
      ) {
        shouldProcess = true;
      }
    }

    if (shouldProcess) {
      // Vibration.vibrate(100);

      const newLength = scanHistory.current.push({
        data: data,
        status: 'loading',
        createdAt: Number(new Date()),
      });
      rerender();
      const index = newLength - 1;

      try {
        const itemInfo = await process(data);
        if (itemInfo) {
          scanHistory.current[index].itemInfo = itemInfo;
        }
        scanHistory.current[index].status = 'success';
        rerender();
      } catch (e) {
        // Vibration.vibrate([0, 100, 200, 100]);
        scanHistory.current[index].error = e as ApiError;
        scanHistory.current[index].status = 'error';
        rerender();
      }
    }
  }

  return {
    clearData,
    onScanData,
    scanHistory: scanHistory.current,
  };
}
