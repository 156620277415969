import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import {
  fetchPartnerStores,
  postCreatePartnerStore,
} from '../../../api/partnerStores';
import Field from '../../Form/Field';
import FormikNativeSelectField from '../../Form/FormikSelect/FormikNativeSelectField';
import SimpleModal from '../../Modal/SimpleModal';
import { Form, useField } from 'formik';
import { z } from 'zod';
import FormikZodSchema from '../../Form/FormikZodSchema';
import FormikField from '../../Form/FormikField';
import { Button } from '../../Form/Button';
import FormikSubmitButton from '../../Form/FormikSubmitButton';
import { formatFormikError } from '../../../api/ApiError';
import usePermissions from '../../../lib/auth/usePermissions';

const schema = z.object({
  storeNumber: z.string({
    required_error: 'Store Number is required',
  }),
  address1: z.string({
    required_error: 'Address is required',
  }),
  address2: z.string().optional(),
  city: z.string({
    required_error: 'City is required',
  }),
  state: z
    .string({
      required_error: 'State is required',
    })
    .max(2, 'State must be 2 characters'),
  zip: z.string({
    required_error: 'Zip is required',
  }),
  country: z.string({
    required_error: 'Country is required',
  }),
});

interface Props {
  name: string;
}

const PartnerStoreSelector: React.FC<Props> = ({ name }) => {
  const [field, meta, helpers] = useField(name);
  const { partnerCreateStore } = usePermissions();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const { data: stores } = useQuery(['partner-stores'], () =>
    fetchPartnerStores(),
  );

  return (
    <>
      <Field>
        <FormikNativeSelectField
          name={name}
          label={
            <div className="flex space-x-4">
              <span>Store</span>
              {partnerCreateStore && (
                <button
                  type="button"
                  onClick={() => setOpen(true)}
                  className="font-semibold text-green-500"
                >
                  Create Store
                </button>
              )}
            </div>
          }
        >
          <option value="" disabled>
            Select a store
          </option>
          {stores?.map(store => (
            <option key={store.org_id} value={store.org_id}>
              {store.org_name}
            </option>
          ))}
        </FormikNativeSelectField>
      </Field>

      <SimpleModal
        onClose={() => setOpen(false)}
        open={open}
        title="Create Store"
        className="w-full max-w-lg"
      >
        <FormikZodSchema
          onSubmit={async (values, formikHelpers) => {
            try {
              const store = await postCreatePartnerStore({
                store_number: values.storeNumber,
                address1: values.address1,
                city: values.city,
                state: values.state,
                zip: values.zip,
                country: values.country,
              });
              queryClient.invalidateQueries(['partner-stores']);
              helpers.setValue(store.org_id);
              setOpen(false);
            } catch (e) {
              formikHelpers.setErrors(formatFormikError(e));
            }
          }}
          schema={schema}
          initialValues={{
            storeNumber: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            country: 'US',
          }}
        >
          <Form className="space-y-4">
            <FormikField
              name="storeNumber"
              label="Store Number"
              placeholder="1234"
            />
            <FormikField name="address1" label="Address 1" />
            <FormikField name="address2" label="Address 2 (optional)" />
            {/*<FormikSubregionSelector name="state" />*/}
            <FormikField name="state" label="State" />
            <div className="grid grid-cols-2 gap-4">
              <FormikField name="city" label="City" />
              <FormikField name="zip" label="Zip" />
            </div>
            <FormikField name="country" label="Country" disabled />

            <div className="grid grid-cols-2 gap-4">
              <Button
                type="button"
                onClick={() => setOpen(false)}
                variant="outlined"
              >
                Cancel
              </Button>

              <FormikSubmitButton>Create Store</FormikSubmitButton>
            </div>
          </Form>
        </FormikZodSchema>
      </SimpleModal>
    </>
  );
};

export default PartnerStoreSelector;
