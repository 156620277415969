import React, { useEffect } from 'react';
import { TradeInOptionMap } from '../../types/detailSelection';
import { Button } from '../Form/Button';
import displayPrice from '../../utils/displayPrice';
import {
  fireDisplayedNoValueMessage,
  fireDisplayedNoVersionsMessage,
} from '../../services/analytics/partner/quotes';
import { useEventCustomerQuoteQuery } from '../../hooks/routing/useEventCustomerQuoteQuery';
import { getTradeInValueForModelVersion } from '../../utils/modelVersionValues';
import useConditionGuidanceQuery from '../../hooks/query/useConditionGuidanceQuery';
import { CategoryConditionCopy } from '../../constants/inStoreQuoteFlow';
import InStoreAddToCartButton from './InStoreAddToCartButton';
import Loader from '../Form/Loader';
import NewTabLink from '../NewTabLink';

interface Props {
  onContinue: () => void;
  options: TradeInOptionMap;
}

const TradeInContinueToCondition: React.FC<Props> = ({
  options,
  onContinue,
}) => {
  const { quoteId } = useEventCustomerQuoteQuery();
  const modelVersion = options.modelVersions?.selected;
  const modelVersionValue = getTradeInValueForModelVersion(modelVersion);

  const selectedCategorySlug =
    options.categories?.[options.categories.length - 1]?.selected?.slug ||
    modelVersion?.price_statistics?.category_slug ||
    undefined;

  const { guidance: conditions, isLoading: isLoadingConditionGuidance } =
    useConditionGuidanceQuery(selectedCategorySlug);

  const doesThisCategoryHaveButterCMSConditionGuidance = !!conditions;

  const modelVersionBlock = (
    <div className="mb-4 flex space-x-4">
      {modelVersion?.primary_image?.small_url ? (
        <img
          src={modelVersion?.primary_image?.small_url ?? ''}
          data-testid="model-version-image"
          alt=""
          className="h-20 w-20 rounded object-cover"
        />
      ) : (
        <div className="h-12 w-12" />
      )}

      <div className="flex-1">
        <div className="mb-2 text-2xl font-semibold">{modelVersion?.name}</div>

        <div className="text-xl font-semibold text-green-500">
          {displayPrice(modelVersionValue || 0)}
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (!modelVersion) {
      fireDisplayedNoVersionsMessage(quoteId, options);
    } else if (!Number(modelVersionValue)) {
      fireDisplayedNoValueMessage(quoteId, options);
    }
  }, [modelVersion]);

  let content: React.ReactNode;

  if (isLoadingConditionGuidance) {
    content = <Loader loading />;
  } else if (!modelVersion) {
    content = (
      <>
        <div className="text-xl font-semibold">
          Sorry, there are no trade-in values for the details you selected.
          Please update your details or try a different product.
        </div>

        <div className="mt-4">
          Are we missing a product or value?{' '}
          <NewTabLink
            className="underline"
            href="https://airtable.com/appK1wXzh9xUPk8DX/shrUnMmVcztmriwRI"
          >
            Suggest an update
          </NewTabLink>
        </div>
      </>
    );
  } else if (!Number(modelVersionValue)) {
    content = (
      <>
        {modelVersionBlock}

        <div className="mt-8 text-xl font-semibold">
          Sorry, this product is not eligible for trade-in. Try selling it
          yourself on SidelineSwap.com, or consider a local donation option.
        </div>

        <div className="mt-4">
          Are we missing a product or value?{' '}
          <NewTabLink
            className="underline"
            href="https://airtable.com/appK1wXzh9xUPk8DX/shrUnMmVcztmriwRI"
          >
            Suggest an update
          </NewTabLink>
        </div>
      </>
    );
  } else if (doesThisCategoryHaveButterCMSConditionGuidance) {
    content = (
      <>
        {modelVersionBlock}

        <div className="mt-8">
          <Button
            size="large"
            className="w-full md:w-auto"
            onClick={onContinue}
          >
            Continue
          </Button>
        </div>
      </>
    );
  } else {
    content = (
      <>
        <div className="mb-4 flex space-x-4">
          {modelVersion?.primary_image?.small_url ? (
            <img
              src={modelVersion?.primary_image?.small_url ?? ''}
              data-testid="model-version-image"
              alt=""
              className="h-20 w-20 rounded object-cover"
            />
          ) : (
            <div className="h-12 w-12" />
          )}

          <div className="flex-1">
            <div className="mb-2 text-2xl font-semibold">
              {modelVersion?.name}
            </div>

            <div className="text-xl font-semibold text-green-500">
              {displayPrice(modelVersionValue || 0)}
            </div>
          </div>
        </div>

        <div>
          <div className="mb-2 font-semibold">
            Before adding this item to your quote, please review and confirm the
            item’s condition:
          </div>
          <ul className="mb-2 list-disc space-y-1 pl-8">
            <CategoryConditionCopy categorySlug={selectedCategorySlug} />
          </ul>
        </div>

        <div className="mt-8">
          <InStoreAddToCartButton options={options} />
        </div>
      </>
    );
  }

  return (
    <div className="border-t pt-6" id="option-done">
      {content}
    </div>
  );
};

export default TradeInContinueToCondition;
