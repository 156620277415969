import React from 'react';
import PageLayout from '../../components/Layout/PageLayout';
import PageHeader from '../../components/Layout/PageHeader';
import PageSubheader from '../../components/Layout/PageSubheader';
import SimpleSelect from '../../components/Form/Select/SimpleSelect';
import { useQuery } from 'react-query';
import { fetchWarehouses } from '../../api/warehouse';
import Message from '../../components/Form/Message';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from '../../api/ApiError';

interface Props {}

const WarehouseSelectionPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { data: warehouses, error } = useQuery(['warehouses'], fetchWarehouses);

  return (
    <PageLayout
      header={
        <>
          <PageHeader>Welcome</PageHeader>
          <PageSubheader>Select a warehouse to get started</PageSubheader>
        </>
      }
    >
      {!!error && <Message error>{getErrorMessage(error)}</Message>}
      {!!warehouses && (
        <SimpleSelect
          label="Select a Warehouse"
          onChange={v => navigate(`/warehouse/${v?.id}`)}
          renderItem={value => value.facility}
          itemToString={value => value?.id ?? ''}
          items={warehouses}
          value={null}
        />
      )}
    </PageLayout>
  );
};

export default WarehouseSelectionPage;
