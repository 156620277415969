import React, { useMemo, useState } from 'react';
import { ListBulletIcon } from '@heroicons/react/24/outline';
import { usePermission } from 'react-use';
import { useQuery } from 'react-query';
import { fetchEvents, TradeInEvent } from '../../../api/events';
import { differenceInWeeks } from 'date-fns';
import { useNavigate } from 'react-router-dom';

type GeocoderResponse = google.maps.GeocoderResponse;

interface Props {}

function findEventInGeocodingResponse(
  googleGeoData: GeocoderResponse,
  filteredEvents: TradeInEvent[],
) {
  // Find a matching zip against all geocoder results and trade-in events
  for (const result of googleGeoData.results) {
    for (let filteredEvent of filteredEvents) {
      for (const addressComponent of result.address_components) {
        // If addressComponent.types includes zip, state, or city
        if (addressComponent.types.includes('postal_code')) {
          // Check zip
          if (
            addressComponent.long_name === filteredEvent.partner_address.zip ||
            addressComponent.short_name === filteredEvent.partner_address.zip
          ) {
            return filteredEvent;
          }
        } else if (addressComponent.types.includes('locality')) {
          // Check city
          if (
            addressComponent.long_name === filteredEvent.partner_address.city ||
            addressComponent.short_name === filteredEvent.partner_address.city
          ) {
            return filteredEvent;
          }
        } else if (
          addressComponent.types.includes('administrative_area_level_1')
        ) {
          // Check state
          if (
            addressComponent.long_name ===
              filteredEvent.partner_address.state ||
            addressComponent.short_name === filteredEvent.partner_address.state
          ) {
            return filteredEvent;
          }
        }
      }
    }
  }
}

const UserFacingCheckInPage: React.FC<Props> = () => {
  const { data: events } = useQuery(['events'], fetchEvents);
  const geolocationPermissionStatus = usePermission({
    name: 'geolocation',
  });
  const navigate = useNavigate();

  const filteredEvents = useMemo(
    () =>
      events?.filter(event => {
        const startDate = new Date(event.start_date);
        return Math.abs(differenceInWeeks(new Date(), startDate)) < 2;
      }) ?? [],
    [events],
  );

  const [loadingGeocodeResults, setLoadingGeocodeResults] = useState(false);
  const [manuallyFindEvents, setManuallyFindEvents] = useState(false);
  const [error, setError] = useState<
    null | 'geolocationRetrieval' | 'googleMapsLookup' | 'noEventMatched'
  >(null);

  function handleSelectEvent(event: TradeInEvent) {
    navigate(`/check-in/${event.id}`);
  }

  function handleGeolocate() {
    setLoadingGeocodeResults(true);
    navigator.geolocation.getCurrentPosition(
      async position => {
        const { latitude, longitude } = position.coords;

        const googleGeoResponse = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${
            import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY
          }`,
        );

        const googleGeoData =
          (await googleGeoResponse.json()) as GeocoderResponse;
        if (!googleGeoResponse.ok || 'error_message' in googleGeoData) {
          setError('googleMapsLookup');
          setLoadingGeocodeResults(false);
          return;
        }

        const matchingEvent = findEventInGeocodingResponse(
          googleGeoData,
          filteredEvents,
        );

        setLoadingGeocodeResults(false);
        if (matchingEvent) {
          handleSelectEvent(matchingEvent);
        } else {
          setError('noEventMatched');
        }
      },
      error => {
        console.error(error);
        setError('geolocationRetrieval');
        setLoadingGeocodeResults(false);
      },
      {
        enableHighAccuracy: true,
      },
    );
  }

  const showEvents =
    !!error || geolocationPermissionStatus == 'denied' || manuallyFindEvents;
  const showFindEventButton =
    !!error || geolocationPermissionStatus == 'denied';

  return (
    <div className="container px-4 pt-8">
      <h1 className="mb-8 text-4xl font-semibold">Welcome</h1>

      <div className="mb-8 space-y-4">
        <div>
          <button
            disabled={
              !geolocationPermissionStatus ||
              geolocationPermissionStatus === 'denied' ||
              loadingGeocodeResults
            }
            className="flex w-full items-center space-x-4 rounded border p-4 text-left disabled:cursor-not-allowed disabled:bg-grey-200 disabled:opacity-50"
            onClick={handleGeolocate}
          >
            <span className="text-blue-500">
              <LocationArrowIcon />
            </span>

            <span>
              <span className="block text-lg font-semibold">Use Location</span>
              <span className="text-sm text-slate-green-500">
                Allow this webpage to use your location to find the nearest
                event to you
              </span>
            </span>
          </button>

          {geolocationPermissionStatus === 'denied' && (
            <div className="mt-4 font-semibold">
              Location permission denied, select a trade-in event location
              below.
            </div>
          )}
        </div>

        {!showFindEventButton && (
          <button
            disabled={loadingGeocodeResults}
            className="flex w-full items-center space-x-4 rounded border p-4 text-left disabled:cursor-not-allowed disabled:bg-grey-200 disabled:opacity-50"
            onClick={() => setManuallyFindEvents(true)}
          >
            <span className="text-blue-500">
              <ListBulletIcon className="h-8 w-8" />
            </span>

            <span>
              <span className="block text-lg font-semibold">Find an Event</span>
              <span className="text-sm text-slate-green-500">
                Look through the list of active events for the one you are
                attending
              </span>
            </span>
          </button>
        )}
      </div>

      {showEvents && (
        <>
          <h2 className="mb-2 text-xl font-semibold">Select an Event</h2>
          <div className="space-y-4">
            {filteredEvents.map(event => (
              <button
                key={event.id}
                className="w-full rounded border p-4 text-left"
                onClick={() => handleSelectEvent(event)}
              >
                <div className="text-lg font-semibold">
                  {event.partner.name}
                </div>
                <div className="text-sm text-slate-green-500">
                  <div>{event.partner_address.address_1}</div>
                  {event.partner_address.address_2 && (
                    <div>{event.partner_address.address_2}</div>
                  )}
                  <div>
                    {event.partner_address.city}, {event.partner_address.state}{' '}
                    {event.partner_address.zip}
                  </div>
                </div>
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const LocationArrowIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1792 1792"
      className="h-8 w-8"
    >
      <path
        d="M103,703.4L1683,125L1104.6,1705L867.9,940.1L103,703.4z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UserFacingCheckInPage;
