import React, { useRef } from 'react';
import SimpleModal, { ModalProps } from './SimpleModal';
import { Button, ButtonProps } from '../Form/Button';
import classNames from 'classnames';

interface Props extends ModalProps {
  icon?: React.ReactNode;
  iconBackgroundClassName?: string; // This should be a color, like bg-red-100

  handleOK: () => void;
  handleCancel?: () => void;

  okText?: string;
  cancelText?: string;

  okProps?: ButtonProps;
  cancelProps?: ButtonProps;
}

const ConfirmationModal: React.FC<Props> = ({
  description,
  iconBackgroundClassName,
  icon,
  onClose,
  title,
  open,
  cancelProps,
  cancelText,
  handleCancel,
  okText,
  okProps,
  handleOK,
}) => {
  const initialFocus = useRef<HTMLButtonElement>(null);

  const confirmButton = (
    <Button fluid {...okProps} onClick={handleOK}>
      {okText ?? 'OK'}
    </Button>
  );

  const cancelButton = (
    <Button
      fluid
      variant="text"
      {...cancelProps}
      onClick={handleCancel || onClose}
      ref={initialFocus}
    >
      {cancelText ?? 'Cancel'}
    </Button>
  );

  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      className="w-full max-w-sm"
      title={title}
      description={description}
      initialFocus={initialFocus}
    >
      <div className="sm:flex sm:items-start">
        {icon && (
          <div
            className={classNames(
              'mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10',
              iconBackgroundClassName,
            )}
          >
            {icon}
          </div>
        )}
      </div>
      <div className="mt-6 flex justify-end space-x-2">
        {cancelButton}
        {confirmButton}
      </div>
    </SimpleModal>
  );
};

export default ConfirmationModal;
