import React from 'react';
import Select, { Props as SelectProps } from 'react-select';

export interface SelectOption {
  label: string;
  value: string;
  slug?: string;
  isDisabled?: boolean;
  value_guides_required?: boolean;
  uuid?: string;
}

export interface DetailSelectProps extends SelectProps<SelectOption, false> {
  error?: any;
  options: SelectOption[];
}

const DetailSelect = React.forwardRef<
  Select<SelectOption, false>,
  DetailSelectProps
>(({ className, value, error, name, options, onChange, ...props }, ref) => {
  return (
    <Select
      name={name}
      onChange={onChange}
      {...props}
      options={options}
      value={value}
      ref={ref}
      isDisabled={options.length === 0}
      placeholder={`Select ${name}`}
      filterOption={(option, rawInput) =>
        option.label
          .replace(/ > /g, ' ')
          .toLowerCase()
          .includes(rawInput.toLowerCase())
      }
    />
  );
});

export default DetailSelect;
