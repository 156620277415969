import React from 'react';

interface Props {}

const Step: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
  return (
    <div className="mb-4 flex items-center space-x-2">
      <div className="text-xs font-semibold text-slate-green-500">STEP</div>

      <div className="rounded-full bg-pastel-green-500 px-2.5 py-0.5 text-base font-semibold">
        {children}
      </div>
    </div>
  );
};

export default Step;
