import { fireAnalytics } from '../fireAnalytics';
import { TradeInModelVersion } from '../../../api/intf/item';
import { TradeInOptionMap } from '../../../types/detailSelection';
import { getTradeInValueForModelVersion } from '../../../utils/modelVersionValues';

export const fireVisitedStartQuote = () => {
  fireAnalytics('Visited Start Quote');
};

export const fireSelectedCategory = (
  categoryName: string,
  quoteId?: string,
) => {
  fireAnalytics('Selected Category', {
    quote_id: quoteId,
    category_slug: categoryName,
  });
};

interface FireSelectedDetailParams {
  detailType: string;
  detailName: string;
  hasImage: boolean;
  quoteId?: string;
}

export const fireSelectedDetail = ({
  detailType,
  detailName,
  hasImage,
  quoteId,
}: FireSelectedDetailParams) => {
  fireAnalytics('Selected Detail', {
    quote_id: quoteId,
    detail_type: detailType,
    detail_name: detailName,
    has_image: hasImage,
  });
};

function optionMapToDetailPath(options?: TradeInOptionMap) {
  return [
    options?.categories?.[options.categories.length - 1]?.selected?.slug,
    options?.brand?.selected?.slug,
    options?.model?.selected?.slug,
    ...(options?.details?.map(d =>
      d.selected === 'SKIP' ? `${d.detail.slug}-skipped` : d.selected?.slug,
    ) || []),
  ]
    .filter(Boolean)
    .join('/');
}

export const fireDisplayedNoVersionsMessage = (
  quoteId?: string,
  options?: TradeInOptionMap,
) => {
  fireAnalytics('Displayed No Versions Message', {
    quote_id: quoteId,
    detail_path: optionMapToDetailPath(options),
    offer_value: null,
  });
};

export const fireDisplayedNoValueMessage = (
  quoteId?: string,
  options?: TradeInOptionMap,
) => {
  fireAnalytics('Displayed No Value Message', {
    quote_id: quoteId,
    detail_path: optionMapToDetailPath(options),
    offer_value: '$0',
  });
};

export const fireSelectedVersion = (
  options: TradeInOptionMap,
  selection: TradeInModelVersion,
  quoteId?: string,
) => {
  fireAnalytics('Selected Version', {
    quote_id: quoteId,
    version_name: selection?.name,
    category_slug: options.categories?.[options.categories.length - 1]?.slug,
    brand_slug: options.brand?.selected?.slug,
    model_slug: options.modelVersions?.selected?.slug,
    version_value: getTradeInValueForModelVersion(selection),
  });
};

export const fireClickedConfirmCondition = (quoteId?: string) => {
  fireAnalytics('Clicked Confirm Condition', {
    quote_id: quoteId,
  });
};

interface FireVisitedQuoteSummaryParams {
  quoteId?: string;
  cartSize: number;
  cartValue: number;
  contactId?: string;
  contactEmail?: string;
  contactPhone?: string;
  contactFirstName?: string;
  contactLastName?: string;
}

export const fireVisitedQuoteSummary = (
  params: FireVisitedQuoteSummaryParams,
) => {
  fireAnalytics('Visited Quote Summary', {
    quote_id: params.quoteId,
    cart_size: params.cartSize,
    cart_value: params.cartValue,
    contact_id: params.contactId,
    contact_email: params.contactEmail,
    contact_phone: params.contactPhone,
    contact_first_name: params.contactFirstName,
    contact_last_name: params.contactLastName,
  });
};

export const fireClickedAddAnotherItem = (quoteId?: string) => {
  fireAnalytics('Clicked Add Another Item', {
    quote_id: quoteId,
  });
};

interface FireAddedContactParams {
  quoteId?: string;
  contactId: string;
  contactEmail: string;
  contactPhone: string;
  contactFirstName: string;
  contactLastName: string;
  isNew: boolean;
}

export const fireAddedContact = (params: FireAddedContactParams) => {
  fireAnalytics('Added Contact', {
    quote_id: params.quoteId,
    contact_id: params.contactId,
    contact_email: params.contactEmail,
    contact_phone: params.contactPhone,
    contact_first_name: params.contactFirstName,
    contact_last_name: params.contactLastName,
    new: params.isNew,
  });
};

export const fireSelectedPayoutMethod = (
  payoutMethod: string,
  quoteId?: string,
) => {
  fireAnalytics('Selected Payout Method', {
    quote_id: quoteId,
    payout_method: payoutMethod,
  });
};

interface FireCompletedQuoteParams {
  errorMessage?: string;
  payoutAmount: number;
  payoutMethod: string;
  quote_items: number; // count
  quoteId: string;
  storeId: string; // org ID
  processedBy: string; // current user id
}

export const fireCompletedQuote = (params: FireCompletedQuoteParams) => {
  fireAnalytics('Completed Quote', {
    error_message: params.errorMessage,
    payout_amount: params.payoutAmount,
    payout_method: params.payoutMethod,
    quote_items: params.quote_items,
    quote_id: params.quoteId,
    store_id: params.storeId,
    processed_by: params.processedBy,
  });
};

export const fireVoidedQuote = (
  quoteId: string,
  payoutAmount: string | number,
) => {
  fireAnalytics('Voided Quote', {
    quote_id: quoteId,
    payout_amount: payoutAmount,
  });
};

export const fireVisitedCustomerInfo = (quoteId: string) => {
  fireAnalytics('Visited Customer Info', {
    quote_id: quoteId,
  });
};

export const fireAddedToCart = (
  options: TradeInOptionMap,
  quoteId?: string,
) => {
  const version = options.modelVersions?.selected;

  fireAnalytics('Added To Cart', {
    quote_id: quoteId,
    version_name: version?.name,
    category_slug: options.categories?.[options.categories.length - 1]?.slug,
    brand_slug: options.brand?.selected?.slug,
    model_slug: options.modelVersions?.selected?.slug,
    version_value: getTradeInValueForModelVersion(version),
  });
};
