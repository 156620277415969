import classNames from 'classnames';
import React from 'react';
import { ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/24/outline';

interface BaseSelectButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
  error?: string | boolean;
  onClear?: () => void;
}

export const BaseSelectButton = React.forwardRef<
  HTMLDivElement,
  BaseSelectButtonProps
>(({ className, children, disabled, error, onClear, ...props }, ref) => (
  <div
    className={classNames(
      className,
      'flex items-center rounded border px-4 py-2 focus-within:border-green-500 focus-within:ring',
      !!error && 'border-red-500',
      disabled
        ? 'cursor-not-allowed bg-off-white-500 text-slate-green-500'
        : 'bg-white',
    )}
    {...props}
    ref={ref}
  >
    <button
      type="button"
      className={classNames(
        'flex flex-1 items-center text-left outline-none',
        disabled && 'cursor-not-allowed',
      )}
      disabled={disabled}
    >
      {children}
    </button>
    {onClear && (
      <button
        type="button"
        disabled={disabled}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onClear?.();
        }}
        className={classNames(
          '-my-4 -ml-4 -mr-2 p-4',
          disabled && 'cursor-not-allowed',
        )}
        tabIndex={-1}
      >
        <XMarkIcon className="h-4 w-4 text-slate-green-500" />
      </button>
    )}
    <button
      type="button"
      tabIndex={-1}
      disabled={disabled}
      className={disabled ? 'cursor-not-allowed' : undefined}
    >
      <ChevronUpDownIcon className="h-4 w-4" />
    </button>
  </div>
));

interface BaseSelectMenuProps extends React.HTMLAttributes<HTMLUListElement> {
  error?: string | boolean;
  isOpen: boolean;
}

export const BaseSelectMenu = React.forwardRef<
  HTMLUListElement,
  BaseSelectMenuProps
>(({ className, children, error, isOpen, style, ...props }, ref) => (
  <ul
    className={classNames(
      className,
      isOpen ? 'block' : 'hidden',
      !!error && 'border-red-500',
      'absolute top-full z-10 -mt-1.5 w-full rounded-b rounded-bl border-b border-l border-r bg-white py-2 shadow-lg focus:outline-none',
    )}
    {...props}
    ref={ref}
  >
    {children}
  </ul>
));

interface BaseSelectMenuItemProps extends React.HTMLAttributes<HTMLLIElement> {
  highlighted?: boolean;
  selected?: boolean;
}

export const BaseSelectMenuItem = React.forwardRef<
  HTMLLIElement,
  BaseSelectMenuItemProps
>(({ className, children, highlighted, selected, ...props }, ref) => (
  <li
    className={classNames(
      className,
      'overflow-x-hidden px-4 py-3 md:py-3',
      highlighted && 'bg-pastel-green-500',
      selected && 'bg-grey-200 font-semibold',
    )}
    {...props}
    ref={ref}
  >
    {children}
  </li>
));

interface BaseSelectMenuItemsContainerProps
  extends React.HTMLAttributes<HTMLUListElement> {
  className?: string;
}

export const BaseSelectMenuItemsContainer = React.forwardRef<
  HTMLUListElement,
  BaseSelectMenuItemsContainerProps
>(({ children, className, style, ...props }, ref) => {
  return (
    <ul
      className={classNames('relative overflow-y-auto', className)}
      {...props}
      style={{
        maxHeight: 400,
        ...style,
      }}
      ref={ref}
    >
      {children}
    </ul>
  );
});
