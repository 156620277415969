import React from 'react';
import { useSelect } from 'downshift';
import {
  BaseSelectButton,
  BaseSelectMenu,
  BaseSelectMenuItem,
  BaseSelectMenuItemsContainer,
} from './elements';
import FieldLabel from '../FieldLabel';
import { IdBasedObject } from '../../../types/generic';
import { XMarkIcon } from '@heroicons/react/24/outline';

export interface SimpleSelectProps<T extends IdBasedObject> {
  clearable?: boolean;
  error?: string;
  items?: T[];

  label?: React.ReactNode;
  onChange: (value: T | null) => void;
  placeholder?: React.ReactNode;
  renderItem: (item: T, index: number) => React.ReactNode;
  value: string | T | null;
}

function IdBasedSelect<T extends IdBasedObject>({
  clearable,
  error,
  items,
  label,
  placeholder,
  onChange,
  renderItem,
  value,
}: SimpleSelectProps<T>) {
  const selectedItem =
    typeof value === 'string' ? items?.find(i => i.id === value) : value;

  const {
    getMenuProps,
    getItemProps,
    getLabelProps,
    getToggleButtonProps,
    isOpen,
    highlightedIndex,
    reset,
  } = useSelect<T>({
    items: items ?? [],
    selectedItem,
    onSelectedItemChange: changes => onChange(changes.selectedItem ?? null),
  });

  const handleReset: React.MouseEventHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    reset();
    onChange(null);
  };

  return (
    <div>
      {label && (
        <FieldLabel {...getLabelProps()} error={error}>
          {label}
        </FieldLabel>
      )}
      <div className="relative">
        <BaseSelectButton {...getToggleButtonProps()} error={error}>
          {selectedItem ? (
            renderItem(selectedItem, -1)
          ) : (
            <span className="text-slate-green-500">
              {placeholder ?? 'Select...'}
            </span>
          )}

          {clearable && (
            <>
              <div className="flex-1" />
              <button
                onClick={handleReset}
                className="-my-4 -ml-4 -mr-2 p-4"
                type="button"
                tabIndex={-1}
              >
                <XMarkIcon className="h-4 w-4 text-slate-green-500" />
              </button>
            </>
          )}
        </BaseSelectButton>

        <BaseSelectMenu isOpen={isOpen} {...getMenuProps()} error={error}>
          {isOpen && (
            <>
              {!items?.length && (
                <div className="mb-2 text-center text-slate-green-500">
                  No options...
                </div>
              )}

              <BaseSelectMenuItemsContainer>
                {items?.map((item, i) => (
                  <BaseSelectMenuItem
                    highlighted={highlightedIndex === i}
                    selected={item.id === selectedItem?.id}
                    key={item.id}
                    {...getItemProps({
                      item,
                      index: i,
                    })}
                  >
                    {renderItem(item, i)}
                  </BaseSelectMenuItem>
                ))}
              </BaseSelectMenuItemsContainer>
            </>
          )}
        </BaseSelectMenu>
      </div>
    </div>
  );
}

export default IdBasedSelect;
