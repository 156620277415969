import { useParams } from 'react-router-dom';
import { EventCustomerQuoteQuery } from '../../pages/Router';

export function useEventCustomerQuoteQuery() {
  const params = useParams<
    EventCustomerQuoteQuery & { [key: string]: string }
  >();

  return {
    ...params,
    customerId: params.customerId!,
    eventId: params.eventId!,
    quoteId: params.quoteId!,
  };
}
