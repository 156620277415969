import React from 'react';
import { PartnerTransferShipment } from '../../api/partnerTransfers';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import SimpleReactTable from '../Tables/SimpleReactTable';
import NewTabLink from '../NewTabLink';
import { fireClickedPrintLabel } from '../../services/analytics/partner/transfers';

interface Props {
  transferId: string;
  transferShipments: PartnerTransferShipment[];
}

const TransferShipmentsTable: React.FC<Props> = ({
  transferShipments,
  transferId,
}) => {
  const table = useReactTable({
    columns: [
      {
        id: 'created_at',
        accessorFn: box => box.created_at,
        header: 'Created',
        cell: info => new Date(info.getValue()).toLocaleDateString(),
      },
      {
        accessorFn: box => box.shipment_status,
        header: 'Status',
        cell: info => {
          switch (info.row.original.shipment_status) {
            case 'PENDING_SHIPMENT':
              return 'Pending Shipment';
            case 'IN_TRANSIT':
              return 'In Transit';
            case 'DELIVERED':
              return 'Delivered';
            default:
              return '';
          }
        },
      },
      {
        accessorFn: box => box.tracking_number,
        header: 'Tracking Number',
        cell: info => (
          <NewTabLink
            href={info.row.original.tracking_url}
            className="text-blue-500"
          >
            {info.getValue()}
          </NewTabLink>
        ),
      },
      {
        id: 'actions',
        header: props => <span className="sr-only">Actions</span>,
        enableSorting: false,
        cell: info => {
          return (
            <NewTabLink
              href={info.row.original.label_url}
              className="text-sm font-semibold text-green-500"
              onClick={() =>
                fireClickedPrintLabel(
                  transferId,
                  info.row.original.tracking_number!,
                )
              }
            >
              View Label
            </NewTabLink>
          );
        },
      },
    ],
    data: transferShipments,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: 'created_at',
          desc: true,
        },
      ],
    },
  });

  return <SimpleReactTable table={table} />;
};

export default TransferShipmentsTable;
