import React, { useEffect, useMemo, useRef, useState } from 'react';
import PageLayout from '../../../components/Layout/PageLayout';
import PageHeader from '../../../components/Layout/PageHeader';
import useRouteQuery from '../../../hooks/useRouteQuery';
import { useQuery } from 'react-query';
import PartnerTagItemRow from '../../../components/Partner/PartnerTagItemRow';
import Message from '../../../components/Form/Message';
import { getErrorMessage } from '../../../api/ApiError';
import {
  fetchPartnerItemsRecentlyTagged,
  fetchPartnerItemsToTag,
} from '../../../api/partnerTagging';
import useQuoteQuery from '../../../hooks/query/useQuoteQuery';
import PartnerTagGuidance from '../../../components/Partner/Dashboard/PartnerTagGuidance';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import PartnerQuoteManagePayout from '../../../components/Partner/Quote/PartnerQuoteManagePayout';
import {
  fireVisitedQuoteCompleted,
  fireVisitedTagItems,
} from '../../../services/analytics/partner/tagging';
import getQuoteListings from '../../../utils/getQuoteListings';

export interface TagItemsPageQuery {
  quote_id?: string;
  from_quote?: '1';
}

interface Props {}

const PartnerTagItemsPage: React.FC<Props> = () => {
  const analyticsFiredRef = useRef(false);
  const query = useRouteQuery<TagItemsPageQuery>();
  const [showInstructions, setShowInstructions] = useState(true);

  const quoteId = query.quote_id;

  const { data: quote, error: quoteError } = useQuoteQuery(quoteId);

  const { data: itemsToTagData, error: itemsToTagError } = useQuery(
    ['items-to-tag', quoteId],
    () => fetchPartnerItemsToTag(),
    {
      enabled: !quoteId,
    },
  );

  const { data: itemsRecentlyTaggedData, error: itemsRecentlyTaggedError } =
    useQuery(
      ['items-recently-tagged', quoteId],
      () => fetchPartnerItemsRecentlyTagged(),
      {
        enabled: !quoteId,
      },
    );

  const error = quoteError || itemsToTagError || itemsRecentlyTaggedError;
  const listings = getQuoteListings(quote);

  const itemsToTag = useMemo(
    () => itemsToTagData || listings.filter(listing => !listing.tag) || [],
    [itemsToTagData, quote],
  );

  const itemsRecentlyTagged = useMemo(
    () =>
      itemsRecentlyTaggedData ||
      listings?.filter(listing => !!listing.tag) ||
      [],
    [itemsRecentlyTaggedData, quote],
  );

  useEffect(() => {
    if (analyticsFiredRef.current) {
      return;
    }

    if (quote && listings) {
      fireVisitedQuoteCompleted(
        itemsToTag.length,
        itemsRecentlyTagged.length,
        quote.id,
      );
      analyticsFiredRef.current = true;
      return;
    }

    if (!quote && itemsToTagData && itemsRecentlyTaggedData) {
      fireVisitedTagItems(
        itemsToTagData.length,
        itemsRecentlyTaggedData.length,
      );
      analyticsFiredRef.current = true;
      return;
    }
  }, [quote?.id, itemsToTagData, itemsRecentlyTaggedData]);

  return (
    <PageLayout
      header={
        <>
          <PageHeader>Process Items</PageHeader>
        </>
      }
    >
      {!!error && <Message error>{getErrorMessage(error)}</Message>}

      {query.from_quote && quoteId && (
        <>
          <div className="mb-2 text-lg font-semibold">Quote Completed!</div>
        </>
      )}

      {quoteId && quote && <PartnerQuoteManagePayout />}

      <section
        aria-label="Items to Tag"
        className="my-8 space-y-4 rounded border px-4 py-3"
      >
        <div className="flex justify-between">
          <h2 className="text-lg font-semibold">Items to Tag</h2>
          <button
            type="button"
            onClick={() => setShowInstructions(!showInstructions)}
            className="inline-flex items-center space-x-2 text-sm font-semibold text-green-500"
          >
            <span>Tagging Instructions</span>
            {showInstructions ? (
              <ChevronUpIcon className="h-4 w-4" />
            ) : (
              <ChevronDownIcon className="h-4 w-4" />
            )}
          </button>
        </div>

        {showInstructions && (
          <div className="mb-8 rounded bg-grey-100 px-2 py-1 md:px-4 md:py-2.5">
            <PartnerTagGuidance />
          </div>
        )}

        {itemsToTag.map(listing => (
          <PartnerTagItemRow key={listing.id} listing={listing} />
        ))}
      </section>

      <section
        aria-label="Recently Tagged"
        className="my-8 space-y-4 rounded border px-4 py-3"
      >
        <h2 className="text-lg font-semibold">Recently Tagged</h2>

        {itemsRecentlyTagged.map(listing => (
          <PartnerTagItemRow key={listing.id} listing={listing} />
        ))}
      </section>
    </PageLayout>
  );
};

export default PartnerTagItemsPage;
