import React from 'react';
import classNames from 'classnames';
import { Menu } from '@headlessui/react';
import Checkbox from '../Form/Checkbox';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

const SimpleMenuItem: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  ...props
}) => (
  <Menu.Item>
    {({ active }) => (
      <label
        className={classNames(
          active ? 'bg-grey-200' : '',
          'block w-full px-4 py-2 text-left text-sm',
          'flex items-center space-x-4',
          className,
        )}
      >
        <Checkbox {...props} />
        <span>{children}</span>
      </label>
    )}
  </Menu.Item>
);

export default SimpleMenuItem;
