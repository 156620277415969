import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchValueGuide } from '../../api/item';
import displayPrice from '../../utils/displayPrice';
import qsStringify from '../../utils/qsStringify';
import thousandsSeparator from '../../utils/thousandsSeparator';
import NewTabLink from '../NewTabLink';
import RecentlySoldModal from './RecentlySoldModal/RecentlySoldModal';
import LoadingIndicator from '../Form/LoadingIndicator';
import Message from '../Form/Message';
import { getErrorMessage } from '../../api/ApiError';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

interface Props {
  platform: string;
  valueGuideParameters: Record<string, any>;
}

const ValueGuideDataTableColumn: React.FC<Props> = ({
  platform,
  valueGuideParameters,
}) => {
  const [recentlySoldModalOpen, setRecentlySoldModalOpen] = useState(false);
  const {
    data: valueGuide,
    isLoading: isValueGuideLoading,
    error: valueGuideError,
  } = useQuery(
    ['valueGuide', valueGuideParameters, platform],
    () => fetchValueGuide(valueGuideParameters, platform),
    {
      // Use this param to avoid showing "Not enough data" everywhere while it loads
      keepPreviousData: true,
    },
  );

  if (isValueGuideLoading) {
    return (
      <>
        <div className="pt-4 text-center text-base">{platform}</div>

        <div className="row-span-5">
          <LoadingIndicator size="large" className="mx-auto" />
        </div>
      </>
    );
  }

  if (valueGuideError) {
    return (
      <>
        <div className="pt-4 text-center text-base">{platform}</div>

        <Message error className="row-span-5">
          {getErrorMessage(valueGuideError)}
        </Message>
      </>
    );
  }

  return (
    <>
      <div className="pt-4 text-center text-base">{platform}</div>

      <div className="border p-1">
        {valueGuide?.statistics ? (
          <>
            {displayPrice(valueGuide.statistics.median)} (
            {displayPrice(valueGuide.statistics.p30)} -{' '}
            {displayPrice(valueGuide.statistics.p70)})
          </>
        ) : (
          '--'
        )}
      </div>

      <div className="border p-1">
        {valueGuide?.recent_sales != null
          ? thousandsSeparator(valueGuide.recent_sales)
          : '--'}{' '}
        <button
          type="button"
          className="text-sm font-semibold underline"
          onClick={() => setRecentlySoldModalOpen(true)}
        >
          (View <ArrowTopRightOnSquareIcon className="inline-block h-4 w-4" />)
        </button>
      </div>

      {valueGuide?.time_to_sell ? (
        <div className="border p-1">
          {thousandsSeparator(
            (valueGuide.time_to_sell.dom_0_30 /
              valueGuide.time_to_sell.total_items) *
              100,
            1,
          ) || '--'}
          {'% '}/{' '}
          {thousandsSeparator(
            (valueGuide.time_to_sell.dom_30_90 /
              valueGuide.time_to_sell.total_items) *
              100,
            1,
          ) || '--'}
          {'% '}/{' '}
          {thousandsSeparator(
            (valueGuide.time_to_sell.dom_90 /
              valueGuide.time_to_sell.total_items) *
              100,
            1,
          ) || '--'}
          %
        </div>
      ) : (
        <div className="border p-1" />
      )}

      <div className="border p-1">
        {thousandsSeparator(valueGuide?.recently_published_items ?? 0)}
      </div>

      <div className="border p-1">
        {valueGuide?.available_items != null
          ? thousandsSeparator(valueGuide.available_items)
          : '--'}
        {platform === 'sidelineswap' && (
          <>
            {' '}
            <NewTabLink
              href={`https://sidelineswap.com/search?src=trade-in-tool&${qsStringify(
                valueGuideParameters,
                {
                  addQueryPrefix: false,
                },
              )}`}
              className="text-sm font-semibold underline"
            >
              (View{' '}
              <ArrowTopRightOnSquareIcon className="inline-block h-4 w-4" />)
            </NewTabLink>
          </>
        )}
      </div>

      <RecentlySoldModal
        open={recentlySoldModalOpen}
        onClose={() => setRecentlySoldModalOpen(false)}
        platform={platform}
        valueGuideFields={valueGuideParameters}
      />
    </>
  );
};

export default ValueGuideDataTableColumn;
