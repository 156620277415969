import React, { SetStateAction, useEffect, useMemo } from 'react';
import FormikDetailSelectField from '../Form/FormikSelect/FormikDetailSelectField';
import CalculatorIcon from '../Icons/CalculatorIcon';
import CalculatorCheckbox from './CalculatorCheckbox';
import { SelectOption } from '../Form/FormikSelect/DetailSelect';
import { Category, FullCategory } from '../../api/intf/item';
import { TradeInOrganization } from '../../api/orgs';

interface Props {
  brandId?: string;
  categories: Category[] | undefined;
  categoryItem: FullCategory | undefined;
  setSelectedCategoryId: (id: number) => void;
  organization: TradeInOrganization | undefined;

  valueGuideEnabledFields: Record<string, boolean>;
  setValueGuideEnabledFields: React.Dispatch<
    SetStateAction<Record<string, boolean>>
  >;
}

interface AddCategorySelect {
  id?: string;
  name: string;
  label: string;
  required?: boolean;
  slug?: string;
  options: SelectOption[];
}

const ItemInfoFields: React.FC<Props> = ({
  brandId,
  categories,
  setSelectedCategoryId,
  categoryItem,
  setValueGuideEnabledFields,
  valueGuideEnabledFields,
}) => {
  // This is the detail creation stuff commented out
  /*
  const queryClient = useQueryClient();
  const formik = useFormikContext<CreateItemFormValues>();

  const { mutate: createDetail } = useMutation(
    async ({ detail, input }: { detail: AddCategorySelect; input: string }) => {
      if (!categoryItem) {
        throw new Error('No category selected');
      }

      if (detail.name === 'brand') {
        const brand = await postCreateBrand(categoryItem.id, input);
        formik.setFieldValue('brand', {
          label: brand.name,
          value: brand.legacy_id,
        });
        return brand;
      }

      if (detail.name === 'model') {
        if (!formik.values.brand?.value) {
          throw new Error('No brand selected');
        }

        const newModel = await postCreateModel(
          categoryItem.id,
          formik.values.brand.uuid as string,
          input,
        );
        formik.setFieldValue('model', {
          label: newModel.name,
          value: newModel.legacy_id,
          uuid: newModel.id,
        });
        return newModel;
      }

      if (detail.id) {
        const newDetail = await postCreateDetail(
          categoryItem.id,
          detail.id,
          input,
        );
        formik.setFieldValue(detail.slug!, {
          label: newDetail.name,
          value: newDetail.legacy_id,
        });
        return newDetail;
      } else {
        throw new Error(
          'Could not create detail, missing `id` in detail group',
        );
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('fetchCategoryById');
      },
      onError: err => {
        toast.error(`Failed to create detail: ${getErrorMessage(err)}`);
      },
    },
  );
*/

  useEffect(() => {
    if (categoryItem?.details) {
      const enabledFields: Record<string, boolean> = {
        category: true,
        brand: true,
        model: true,
      };

      for (let i = 0; i < categoryItem.details?.length; i++) {
        const detail = categoryItem.details[i];
        if (detail.value_guides_required) {
          enabledFields[detail.slug || detail.name.toLowerCase()] = true;
        }
      }

      setValueGuideEnabledFields(enabledFields);
    }
  }, [categoryItem?.id]);

  // Category select options
  const categoryOptions: SelectOption[] = useMemo(() => {
    if (!categories || categories?.length === 0) {
      return [];
    }

    return categories
      ?.filter(c => c.relatable)
      .map(
        (category): SelectOption => ({
          label: category.full_name,
          slug: category.slug,
          value: category.legacy_id.toString(),
          value_guides_required: true,
          uuid: category.id,
        }),
      );
  }, [categories]);

  // Additional select options which are being changed by category select.
  const details: AddCategorySelect[] = useMemo(() => {
    if (!categoryItem) return [];

    let tmpOptions: AddCategorySelect[] = [];

    if (categoryItem.brands && categoryItem.brands.length) {
      const newBrandOption: AddCategorySelect = {
        name: 'brand',
        label: 'Brand',
        options: categoryItem.brands?.map((brand): SelectOption => {
          if (Object.keys(brand).length === 0) {
            return {
              label: '',
              value: '',
              slug: '',
              uuid: '',
            };
          }

          return {
            label: brand.name,
            slug: brand.slug,
            value: brand.legacy_id.toString(),
            value_guides_required: true,
            uuid: brand.id,
          };
        }),
      };

      tmpOptions.push(newBrandOption);
    } else {
      tmpOptions.push({
        name: 'brand',
        label: 'Brand',
        options: [],
      });
    }

    if (categoryItem.models && categoryItem.models.length) {
      const newModelOption: AddCategorySelect = {
        name: 'model',
        label: 'Model',
        options: categoryItem.models
          ?.filter(model =>
            brandId && model.legacy_brand_detail_id
              ? model.legacy_brand_detail_id == Number(brandId)
              : false,
          )
          .map<SelectOption>(model => {
            if (Object.keys(model).length === 0) {
              return {
                label: '',
                value: '',
                slug: '',
                uuid: '',
              };
            }

            return {
              label: model.name,
              slug: model.slug,
              value: model.legacy_id ? model.legacy_id.toString() : model.id,
              value_guides_required: true,
            };
          }),
      };

      tmpOptions.push(newModelOption);
    } else {
      tmpOptions.push({
        name: 'model',
        label: 'Model',
        options: [],
      });
    }

    if (categoryItem.details && categoryItem.details.length) {
      for (let detailGroup of categoryItem.details) {
        const newAddCategory: AddCategorySelect = {
          name: detailGroup.name,
          label: detailGroup.name,
          required: detailGroup.required,
          slug: detailGroup.slug,
          id: detailGroup.id,
          options: detailGroup.options.map(detail => {
            if (Object.keys(detail).length === 0) {
              return {
                label: '',
                value: '',
                uuid: '',
              };
            }

            return {
              label: detail.name,
              slug: detail.slug,
              value: detail.legacy_id
                ? detail.legacy_id.toString()
                : detail.id.toString(),
              value_guides_required: detailGroup.value_guides_required,
              uuid: detail.id,
            } as SelectOption;
          }),
        };

        tmpOptions.push(newAddCategory);
      }
    }

    return tmpOptions;
  }, [categoryItem, brandId]);

  return (
    <>
      <div className="flex w-full space-x-2">
        <FormikDetailSelectField
          name="category"
          label="Category"
          options={categoryOptions}
          setSelectedCategoryId={setSelectedCategoryId}
          required
        />
      </div>

      <div className="mt-1 mb-4 text-xs italic leading-tight">
        <span>When the toggle</span>
        <span className="text-green-500">
          <CalculatorIcon />
        </span>
        <span>
          is turned ON, that detail will impact the value guide data below.
        </span>
      </div>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        {details.map((detail, index) => (
          <FormikDetailSelectField
            labelRight={
              <div>
                <CalculatorCheckbox
                  checked={
                    valueGuideEnabledFields[
                      detail.slug || detail.name.toLowerCase()
                    ] || false
                  }
                  onChange={e =>
                    setValueGuideEnabledFields(v => ({
                      ...v,
                      [detail.slug || detail.name.toLowerCase()]:
                        e.target.checked,
                    }))
                  }
                />
              </div>
            }
            // formatCreateLabel={input =>
            //   `Create a new ${detail.name} named "${input}"`
            // }
            // createOptionPosition="last"
            // onCreateOption={input => createDetail({ detail, input })}
            key={index}
            name={detail.slug || detail.name}
            label={detail.label}
            options={detail.options}
            isClearable={!detail.required}
            required={detail.required}
          />
        ))}
      </div>
    </>
  );
};

export default ItemInfoFields;
