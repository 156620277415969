import React, { useState } from 'react';
import { Button } from '../Form/Button';
import { Quote } from '../../api/intf/item';
import ConfirmationModal from '../Modal/ConfirmationModal';
import { useMutation, useQueryClient } from 'react-query';
import { putIssue5DollarGiftCard } from '../../api/payouts';
import useCurrentEventQuery from '../../hooks/query/useCurrentEventQuery';
import useCurrentContactQuery from '../../hooks/query/useCurrentContactQuery';
import getQuoteListings from '../../utils/getQuoteListings';

export default function Issue5DollarGiftCardAction({
  quote,
}: {
  quote: Quote;
}) {
  const queryClient = useQueryClient();
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const { data: event } = useCurrentEventQuery();
  const { data: contact } = useCurrentContactQuery();

  const dsgPayoutMethod = event?.payout_methods?.find(
    payout =>
      payout.payout_type == 'DSG_CREDIT' ||
      payout.name.toLowerCase().includes("dick's gift card"),
  );
  const { mutate: issueGiftCard, isLoading } = useMutation(
    () => putIssue5DollarGiftCard(quote.id, dsgPayoutMethod!.id, contact!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        setConfirmationOpen(false);
      },
    },
  );

  if (!dsgPayoutMethod || !contact) {
    return null;
  }

  const disabled =
    getQuoteListings(quote).length === 0 || quote?.status !== 'PENDING';

  return (
    <>
      <Button disabled={disabled} onClick={() => setConfirmationOpen(true)}>
        Issue $5 Gift Card
      </Button>

      <ConfirmationModal
        okProps={{
          disabled: isLoading,
        }}
        okText="Complete Quote"
        handleOK={issueGiftCard}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        title="Issue $5 Gift Card"
        description="By clicking Complete Quote, all items in this quote will be discarded and a $5 Dick’s Gift Card will be issued to the customer."
      />
    </>
  );
}
