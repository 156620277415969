import { useQuery } from 'react-query';
import { fetchWarehouseById } from '../../api/warehouse';
import useCurrentWarehouseId from './useCurrentWarehouseId';

export default function useCurrentWarehouseQuery() {
  const warehouseId = useCurrentWarehouseId();
  return useQuery(
    ['warehouses', warehouseId],
    () => fetchWarehouseById(warehouseId!),
    {
      enabled: !!warehouseId,
    },
  );
}
