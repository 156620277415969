import React, { useState } from 'react';
import { Quote } from '../../api/intf/item';
import Input from '../Form/Input';
import { useMutation, useQueryClient } from 'react-query';
import { Button } from '../Form/Button';
import { postAddBonusCodeToQuote } from '../../api/bonusCodes';
import { getErrorMessage } from '../../api/ApiError';
import FieldError from '../Form/FieldError';

interface Props {
  quote: Quote;
}

const QuoteSummaryPanelBonusCode: React.FC<Props> = ({ quote }) => {
  const queryClient = useQueryClient();

  const [editing, setEditing] = useState(false);
  const [newCode, setNewCode] = useState(
    quote.bonus_code?.organization_name || '',
  );

  const { mutate, error, isSuccess } = useMutation(
    () => postAddBonusCodeToQuote(quote.id, newCode),
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        setEditing(false);
      },
    },
  );

  let content: React.ReactNode;
  if (editing) {
    content = (
      <div>
        <form
          className="flex space-x-2"
          onSubmit={e => {
            e.preventDefault();
            mutate();
          }}
        >
          <Input
            autoFocus
            value={newCode}
            onChange={e => setNewCode(e.target.value)}
            placeholder="Enter bonus code"
          />

          <Button type="submit">{quote.bonus_code ? 'Update' : 'Add'}</Button>
        </form>

        {!!error && <FieldError>{getErrorMessage(error)}</FieldError>}
      </div>
    );
  } else if (quote.bonus_code) {
    content = (
      <div>
        Bonus code: {quote.bonus_code.organization_name}{' '}
        {quote.bonus_code.bonus_amount &&
          `(${quote.bonus_code.bonus_amount * 100}%)`}{' '}
        <button className="underline" onClick={() => setEditing(true)}>
          Edit
        </button>
      </div>
    );
  } else {
    content = (
      <div className="text-sm">
        <button className="underline" onClick={() => setEditing(true)}>
          Add bonus code
        </button>
      </div>
    );
  }

  return (
    <div>
      {content}

      {isSuccess && (
        <div className="mt-1 text-sm text-green-500">Bonus code updated</div>
      )}
    </div>
  );
};

export default QuoteSummaryPanelBonusCode;
