import React from 'react';
import PageLayout from '../../components/Layout/PageLayout';
import PageHeader from '../../components/Layout/PageHeader';
import PageSubheader from '../../components/Layout/PageSubheader';
import Message from '../../components/Form/Message';
import { useSearchParams } from 'react-router-dom';
import useCurrentWarehouseSwapCountsQuery from '../../hooks/warehouse/useCurrentWarehouseSwapCountsQuery';
import useCurrentWarehouseSwapsQuery from '../../hooks/warehouse/useCurrentWarehouseSwapsQuery';
import useCurrentWarehouseQuery from '../../hooks/warehouse/useCurrentWarehouseQuery';
import WarehouseNextPrevPaginator from '../../components/Warehouse/Inbox/WarehouseNextPrevPaginator';
import WarehouseSwapRow from '../../components/Warehouse/Inbox/WarehouseSwapRow';
import { getErrorMessage } from '../../api/ApiError';
import LoadingIndicator from '../../components/Form/LoadingIndicator';
import WarehouseSwapsPageSizeChanger from '../../components/Warehouse/Inbox/WarehouseSwapsPageSizeChanger';
import SwapsInboxFiltersFlyoutMenu from '../../components/Warehouse/Inbox/SwapsInboxFiltersFlyoutMenu';
import WarehouseInboxPrintAction from '../../components/Warehouse/Inbox/WarehouseInboxPrintAction';
import WarehouseInboxSearch from '../../components/Warehouse/Inbox/WarehouseInboxSearch';
import WarehouseBreadcrumbLink from '../../components/Warehouse/WarehouseBreadcrumbLink';
import WarehouseInboxScanToPrint from '../../components/Warehouse/Inbox/WarehouseInboxScanToPrint';

interface Props {}

const WarehouseSwapsPage: React.FC<Props> = () => {
  const { data: warehouse } = useCurrentWarehouseQuery();
  const { data: swapCounts } = useCurrentWarehouseSwapCountsQuery();
  const { data: swaps, error, isLoading } = useCurrentWarehouseSwapsQuery();

  const [searchParams] = useSearchParams();
  const currentScope = searchParams.get('scope') || 'all';

  const currentCount = swapCounts?.find(count => count.slug === currentScope);

  const pagination = swaps?.meta?.paging && (
    <div className="my-4 flex items-center justify-end space-x-2">
      <span className="flex items-center space-x-1 text-sm text-slate-green-500">
        Showing <WarehouseSwapsPageSizeChanger paging={swaps.meta.paging} /> per
        page
      </span>
      <WarehouseNextPrevPaginator paging={swaps?.meta.paging} />
    </div>
  );

  let swapsContent: React.ReactNode = null;

  if (isLoading) {
    swapsContent = <LoadingIndicator size="large" className="mx-auto" />;
  } else if (error) {
    swapsContent = (
      <Message error>
        Failed to load warehouse swaps: {getErrorMessage(error)}
      </Message>
    );
  } else if (!swaps?.data?.length) {
    swapsContent = (
      <div className="mt-8 text-center text-xl font-semibold text-slate-green-500">
        No swaps
      </div>
    );
  } else {
    swapsContent = (
      <>
        {pagination}

        <div className="space-y-2">
          {swaps?.data?.map(swap => (
            <WarehouseSwapRow key={swap.id} swap={swap} />
          ))}
        </div>

        {pagination}
      </>
    );
  }

  return (
    <PageLayout
      header={
        <>
          <WarehouseBreadcrumbLink to="home" />
          <PageHeader>Warehouse - {warehouse?.facility}</PageHeader>
          <PageSubheader>{currentCount?.name} Swaps</PageSubheader>
        </>
      }
    >
      <div className="mb-4 flex items-center space-x-4">
        <SwapsInboxFiltersFlyoutMenu filters={swaps?.meta?.filters} />

        {currentScope === 'ship_now' && <WarehouseInboxPrintAction />}

        <WarehouseInboxScanToPrint />

        <WarehouseInboxSearch />
      </div>

      {swapsContent}
    </PageLayout>
  );
};

export default WarehouseSwapsPage;
