import React from 'react';
import PageLayout from '../../../components/Layout/PageLayout';
import PageHeader from '../../../components/Layout/PageHeader';
import NavigationButton from '../../../components/Layout/NavigationButton';

interface Props {}

const SettingsPage: React.FC<Props> = () => {
  return (
    <PageLayout
      header={
        <>
          <PageHeader>Settings Page</PageHeader>
        </>
      }
    >
      <div className="mx-auto mt-8 max-w-sm space-y-8">
        <NavigationButton to="/settings/payment">
          Manage Payment Methods
        </NavigationButton>

        <NavigationButton to="/settings/bonus-codes">
          Manage Bonus Codes
        </NavigationButton>
      </div>
    </PageLayout>
  );
};

export default SettingsPage;
