import { fireAnalytics } from '../fireAnalytics';

export const fireVisitedQuoteCompleted = (
  itemsToTag: number,
  recentlyTagged: number,
  quoteId: string,
) => {
  fireAnalytics('Visited Quote Completed', {
    quote_id: quoteId,
    items_to_tag: itemsToTag,
    recently_tagged: recentlyTagged,
  });
};

export const fireClickedManagePayout = (quoteId: string) => {
  fireAnalytics('Clicked Manage Payout', {
    quote_id: quoteId,
  });
};

export const fireClickedEditContact = (quoteId: string) => {
  fireAnalytics('Clicked Edit Contact', {
    quote_id: quoteId,
  });
};

export const fireUpdatedContactEmail = (quoteId: string) => {
  fireAnalytics('Updated Contact Email', {
    quote_id: quoteId,
  });
};

export const fireClickedResendNotification = (quoteId: string) => {
  fireAnalytics('Clicked Resend Notification', {
    quote_id: quoteId,
  });
};

interface FireTaggedItemParams {
  tagId: string;
  versionName: string;
  quoteListingId: string;
  src: 'quote' | 'tag_items';
}

export const fireTaggedItem = (params: FireTaggedItemParams) => {
  fireAnalytics('Tagged Item', {
    tag_id: params.tagId,
    version_name: params.versionName,
    quote_listing_id: params.quoteListingId,
    src: params.src,
  });
};

interface FireTaggedItemErrorParams {
  tagId: string;
  versionName?: string;
  quoteListingId?: string;
  errorMessage: string;
  src: 'quote' | 'tag_items';
}

export const fireTaggedItemError = (params: FireTaggedItemErrorParams) => {
  fireAnalytics('Tagged Item Error', {
    tag_id: params.tagId,
    version_name: params.versionName,
    quote_listing_id: params.quoteListingId,
    error_message: params.errorMessage,
    src: params.src,
  });
};

export const fireVisitedTagItems = (
  itemsToTag: number,
  recentlyTagged: number,
) => {
  fireAnalytics('Visited Tag Items', {
    items_to_tag: itemsToTag,
    recently_tagged: recentlyTagged,
  });
};
