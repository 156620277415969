import React from 'react';
import { AccentS } from '../../Icons/AccentS';
import { LogoNoTagline } from '../../Icons/LogoNoTagline';

interface Props {
  children?: React.ReactNode;
  title?: React.ReactNode;
}

const LoginLayout: React.FC<Props> = ({ children, title }) => {
  return (
    <div className="relative flex min-h-screen items-center justify-center overflow-hidden bg-green-500 p-4">
      <AccentS
        height={1800}
        width={2400}
        style={{
          position: 'absolute',
          pointerEvents: 'none',
        }}
      />

      <div className="relative z-20 mb-24 w-full max-w-sm rounded bg-white p-6 shadow">
        <div className="mb-4 flex justify-center">
          <LogoNoTagline className="h-6" />
        </div>

        <div className="mb-8 text-center">{title}</div>

        {children}
      </div>
    </div>
  );
};

export default LoginLayout;
