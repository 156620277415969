import React from 'react';
import { TradeInEvent } from '../../api/events';
import BaseLabel from './BaseLabel';

interface Props {
  event: TradeInEvent;
  quoteId: number;
}

const QuoteLabel: React.FC<Props> = ({ event, quoteId }) => {
  return (
    <BaseLabel>
      <div>
        <div
          style={{
            fontSize: '11pt',
          }}
        >
          Quote ID: <b>{quoteId}</b>
        </div>

        <div
          style={{
            margin: '0',
            fontSize: '10pt',
          }}
        >
          ____ of ____
        </div>

        <div
          style={{
            fontSize: '6pt',
            lineHeight: '1',
          }}
        >
          Event:{' '}
          {event.partner_address.store_number
            ? `#${event.partner_address.store_number}`
            : ''}{' '}
          {event.partner.name}: {event.partner_address.address_1},{' '}
          {event.partner_address.city}, {event.partner_address.state}{' '}
          {event.partner_address.zip}
        </div>
      </div>
    </BaseLabel>
  );
};

export default QuoteLabel;
