import { fireAnalytics } from '../fireAnalytics';

export const fireVisitedCreateTransfer = (itemsToTransfer: number) => {
  fireAnalytics('Visited Create Transfer', {
    items_to_transfer: itemsToTransfer,
  });
};

export const fireCreatedTransfer = (
  boxes: number,
  listingCount: number,
  transferId: string,
) => {
  fireAnalytics('Created Transfer', {
    boxes,
    listing_count: listingCount,
    transfer_id: transferId,
  });
};

export const fireClickedPrintLabel = (
  transferId: string,
  trackingNumber: string,
) => {
  fireAnalytics('Clicked Print Label', {
    transfer_id: transferId,
    tracking_number: trackingNumber,
  });
};

export const fireClickedPrintPackingSlip = (transferId: string) => {
  fireAnalytics('Clicked Print Packing Slip', {
    transfer_id: transferId,
  });
};

export const fireVisitedTransfer = (transferId: string) => {
  fireAnalytics('Visited Transfer', {
    transfer_id: transferId,
  });
};

export const fireVisitedTransferItemDetails = (
  transferId: string,
  listingId: string,
  quoteId: string,
) => {
  fireAnalytics('Visited Transfer Item Details', {
    transfer_id: transferId,
    listing_id: listingId,
  });
};

export const fireAddedMissingItem = (tagId: string) => {
  fireAnalytics('Added Missing Item', {
    tag_id: tagId,
  });
};
