import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import Router from './pages/Router';
import { BrowserRouter as ReactRouter } from 'react-router-dom';
import { AuthProvider } from './lib/auth/AuthContext';
import 'react-toastify/dist/ReactToastify.css';
import { HotkeysProvider } from 'react-hotkeys-hook';
import { initializeAmplitude } from './services/analytics/amplitude';
import AnalyticsInitializer from './components/AnalyticsInitializer';
import initializeSentry from './services/sentry/initializeSentry';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

initializeAmplitude();
initializeSentry();

const App = () => (
  <HotkeysProvider>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ReactRouter>
          <Router />
        </ReactRouter>

        <AnalyticsInitializer />
      </AuthProvider>

      <ToastContainer autoClose={5000} />
      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>
  </HotkeysProvider>
);

export default App;
