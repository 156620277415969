import axios from 'axios';
import { getBaseUrl } from './axiosInitialization';
import { ApiResponse } from './intf/generic';
import { PartnerTransferItem } from './partnerTransfers';
import { PostItemRequest } from './intf/item';
import { WarehouseSwapsPaging } from './intf/warehouseSwap';

export const fetchWarehouseAuditItems = async (
  warehouseId: string,
  page?: number,
  searchTag?: string,
  searchItemName?: string,
) => {
  const { data } = await axios.get<
    ApiResponse<PartnerTransferItem[], { paging: WarehouseSwapsPaging }>
  >(`/warehouse/v1/transfers/items`, {
    baseURL: getBaseUrl('inventoryApi'),
    params: {
      page,
      size: 100,
      warehouse_id: warehouseId,
      order: 'audited_at',
      tag_id: searchTag,
      item_name: searchItemName,
    },
  });

  return data;
};

export const postWarehouseAuditScan = async (
  warehouseId: string,
  tagId: string,
) => {
  const { data } = await axios.post<ApiResponse<PartnerTransferItem>>(
    `/warehouse/v1/warehouses/${warehouseId}/audits/scan`,
    {
      tag_id: tagId,
    },
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  return data.data;
};

export const postWarehouseAuditScanItem = async (
  warehouseId: string,
  legacyItemId: string,
) => {
  const { data } = await axios.post<ApiResponse<PartnerTransferItem>>(
    `/warehouse/v1/warehouses/${warehouseId}/audits/scan`,
    {
      legacy_item_id: legacyItemId,
    },
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  return data.data;
};

export const postWarehouseAuditReject = async (
  warehouseId: string,
  transferItemId: string,
  reasons: string[],
) => {
  const { data } = await axios.put<ApiResponse<PartnerTransferItem>>(
    `/warehouse/v1/warehouses/${warehouseId}/audits/reject`,
    {
      transfer_item_id: transferItemId,
      reasons,
    },
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  return data.data;
};

export const fetchTransferItemById = async (transferItemId: string) => {
  const { data } = await axios.get<ApiResponse<PartnerTransferItem>>(
    `/warehouse/v1/transfers/items/${transferItemId}`,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  return data.data;
};

export const putWarehouseAuditAccept = async (
  warehouseId: string,
  transferItemId: string,
  body: PostItemRequest,
) => {
  const { data } = await axios.put<ApiResponse<PartnerTransferItem>>(
    `/warehouse/v1/warehouses/${warehouseId}/audits/accept`,
    {
      transfer_item_id: transferItemId,
      reviewed_data: body,
    },
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  return data.data;
};
