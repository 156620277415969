import React from 'react';
import classNames from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/outline';
import LinkOrSpan from '../../LinkOrSpan';
import useCurrentQuoteQuery from '../../../hooks/query/useCurrentQuoteQuery';
import useMobile from '../../../hooks/useMobile';
import PartnerQuoteSummaryPanel from '../../Partner/Quote/PartnerQuoteSummaryPanel';
import WarningModalLink from '../../WarningModalLink';
import getQuoteListings from '../../../utils/getQuoteListings';

interface Props {
  children: React.ReactNode;
  step: 'productDetails' | 'customer' | 'payment';

  /**
   * Whether to warn the user when they try to exit the page
   */
  warnOnExit?: boolean;
}

const InStoreQuoteLayout: React.FC<Props> = ({
  children,
  step,
  warnOnExit,
}) => {
  const mobile = useMobile();
  const { data: quote } = useCurrentQuoteQuery();

  const differentStepCx = 'opacity-40';

  const linkToProductDetails = quote?.id
    ? mobile
      ? `/partner/quote/${quote?.id}/summary`
      : `/partner/quote/${quote?.id}/add`
    : undefined;

  const linkToCustomerInfo = quote
    ? `/partner/quote/${quote.id}/customer`
    : `/partner/quote/new/customer`;

  const linkToPayment =
    quote?.contact_id && getQuoteListings(quote).length > 0
      ? `/partner/quote/${quote.id}/payment`
      : undefined;

  const header = (
    <div className="sticky top-0 flex bg-white py-4 shadow-lg">
      {/* Container within shadow */}
      <div className="w-full pl-4">
        {/* Step indicator row */}
        <div
          className={classNames(
            mobile
              ? 'flex flex-col space-y-4 pr-4'
              : 'flex items-center space-x-4',
          )}
        >
          <div className="flex-1 text-2xl">Trade-in</div>

          <div className="grid grid-cols-3 gap-x-4">
            <WarningModalLink
              // Only warn the user if they have made progress in the flow
              disabled={!warnOnExit}
              to={linkToCustomerInfo}
              className={classNames(
                'border-b-4 border-current pb-1 text-sm font-bold uppercase tracking-wide',
              )}
            >
              1. Customer Info
            </WarningModalLink>

            <LinkOrSpan
              to={linkToProductDetails}
              className={classNames(
                'border-b-4 border-current pb-1 pr-4 text-sm font-bold uppercase tracking-wide',
                {
                  [differentStepCx]:
                    step !== 'productDetails' && step !== 'payment',
                },
              )}
            >
              2. Product Details
            </LinkOrSpan>

            <WarningModalLink
              // Only warn the user if they have made progress in the flow
              disabled={!warnOnExit}
              to={linkToPayment}
              className={classNames(
                'border-b-4 border-current pb-1 text-sm font-bold uppercase tracking-wide',
                {
                  [differentStepCx]: step !== 'payment',
                },
              )}
            >
              3. Payment
            </WarningModalLink>
          </div>
        </div>
      </div>

      <WarningModalLink
        // Only warn the user if they have made progress in the flow
        disabled={!warnOnExit}
        to="/partner/dashboard"
        className={classNames('px-4', mobile && 'absolute top-4 right-4')}
      >
        <div className="sr-only">Exit</div>
        <XMarkIcon className="h-8 w-8" strokeWidth={2.5} />
      </WarningModalLink>
    </div>
  );

  return (
    <>
      {header}

      <main className="flex pb-40">
        <div className="in-store-qs-container flex-1 px-4 pt-8">{children}</div>
        {!mobile && (
          <>
            <div className="fixed top-16 right-0 bottom-0 ml-4 border-l pt-8">
              <PartnerQuoteSummaryPanel />
            </div>

            <div className="ml-4 w-96" />
          </>
        )}
      </main>
    </>
  );
};

export default InStoreQuoteLayout;
