import React from 'react';
import CreateEventForm from '../../../components/EventForm/CreateEventForm';
import EventFormFields from '../../../components/EventForm/EventFormFields';
import PageLayout from '../../../components/Layout/PageLayout';
import PageHeader from '../../../components/Layout/PageHeader';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Form/Button';
import FormikSubmitButton from '../../../components/Form/FormikSubmitButton';

interface Props {}

const CreateEventPage: React.FC<Props> = () => (
  <PageLayout
    header={
      <div className="mx-auto max-w-md">
        <PageHeader>Create an Event</PageHeader>
      </div>
    }
  >
    <div className="mx-auto max-w-md">
      <CreateEventForm>
        <EventFormFields />

        <div className="mt-8 grid grid-cols-2 gap-4">
          <Link to="/" className="block flex-1">
            <Button variant="text" fluid>
              Cancel
            </Button>
          </Link>

          <FormikSubmitButton fluid>Create</FormikSubmitButton>
        </div>
      </CreateEventForm>
    </div>
  </PageLayout>
);

export default CreateEventPage;
