import React from 'react';
import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';

type ColorType =
  | 'primary'
  | 'green'
  | 'black'
  | 'blue'
  | 'secondary'
  | 'warning'
  | 'danger'
  | 'darkgrey'
  | 'grey';

type CustomButtonProps = {
  color?: ColorType;
  disabled?: boolean;
  className?: string;
  fluid?: boolean;
  size?: 'tiny' | 'small' | 'normal' | 'no-padding' | 'large';
  variant?: 'contained' | 'outlined' | 'text';
  ref?: React.Ref<HTMLButtonElement>;
};

function generateButtonClassNames({
  className,
  color = 'primary',
  disabled,
  fluid,
  size = 'normal',
  variant = 'contained',
}: CustomButtonProps) {
  const variantCx =
    variant === 'contained'
      ? [
          'border hover:shadow',
          {
            'bg-primary-900 border-transparent text-white hover:bg-primary-800':
              color === 'primary',
            'bg-green-500 border-transparent text-white hover:bg-green-600':
              color === 'green',
            'bg-blue-500 border-transparent hover:bg-blue-600 text-white':
              color === 'blue',
            'bg-red-500 border-transparent hover:bg-red-600 text-white':
              color === 'danger',
            'bg-yellow-500 border-transparent hover:bg-yellow-600 text-white':
              color === 'warning',
            'bg-darkgrey-500 border-transparent hover:bg-darkgrey-600 text-white':
              color === 'darkgrey' || color === 'secondary',
            'bg-white border-grey-500 hover:bg-grey-100 text-black':
              color === 'grey' || color === 'black',
          },
        ]
      : variant === 'outlined'
      ? [
          'border',
          {
            'border-pastel-green-500 hover:border-slate-green-500 text-turf-green-500':
              color === 'primary',
            'border-green-500 hover:bg-green-100 text-green-500':
              color === 'green',
            'border-blue-500 hover:bg-blue-100 text-blue-500': color === 'blue',
            'border-red-500 hover:bg-red-100 text-red-500': color === 'danger',
            'border-yellow-500 hover:bg-yellow-100 text-yellow-500':
              color === 'warning',
            'border-darkgrey-500 hover:bg-darkgrey-100 text-darkgrey-500':
              color === 'darkgrey' || color === 'secondary',
            'border-grey-600 hover:bg-grey-100 text-black':
              color === 'grey' || color === 'black' || !color,
          },
        ]
      : variant === 'text'
      ? ['text-black underline focus:outline-none']
      : null;

  return classNames(
    'appearance-none rounded font-semibold transition duration-300 focus:ring',
    {
      'px-2 py-1 text-xs': size === 'tiny',
      'px-4 py-1.5 text-sm': size === 'small',
      'px-4 py-1.5': size === 'normal',
      'px-6 py-3': size === 'large',
      'w-full flex-1': fluid,
      'text-sm': size === 'no-padding',
    },
    disabled
      ? 'cursor-not-allowed border border-grey-500 bg-grey-500 text-white'
      : variantCx,

    // variant === 'text' && 'border-0',
    className,
  );
}

export type ButtonProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'color'
> &
  CustomButtonProps & {
    as?: string | React.ComponentType;
  };

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children, className, color, disabled, fluid, size, variant, ...props },
    ref,
  ) => {
    const Element = props.as || 'button';

    return (
      // @ts-ignore
      <Element
        className={generateButtonClassNames({
          className,
          color,
          disabled,
          fluid,
          size,
          variant,
        })}
        type={props.type || 'button'}
        disabled={disabled}
        {...props}
        // @ts-ignore
        ref={ref}
      >
        {children}
      </Element>
    );
  },
);

export type ButtonLinkProps = LinkProps & CustomButtonProps;

export const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  (
    {
      children,
      className,
      color,
      disabled,
      fluid,
      size,
      variant,
      reloadDocument,
      replace,
      to,
      ...props
    },
    ref,
  ) => {
    if (disabled) {
      return (
        // @ts-ignore
        <Button
          {...props}
          ref={ref as any}
          disabled={disabled}
          className={className}
          color={color}
          fluid={fluid}
          size={size}
          variant={variant}
        >
          {children}
        </Button>
      );
    }

    return (
      <Link
        {...props}
        to={to}
        replace={replace}
        reloadDocument={reloadDocument}
        ref={ref}
        className={generateButtonClassNames({
          className: classNames(className, 'inline-block'),
          color,
          disabled,
          fluid,
          size,
          variant,
        })}
      >
        {children}
      </Link>
    );
  },
);
