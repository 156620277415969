import { Uuid } from '../api/intf/generic';
import Cookies from 'js-cookie';

export interface KnownCookies {
  access_token?: string;
  refresh_token?: string;
  sls_client_id?: Uuid;
  sls_user_type?: 'partner';
  test?: '1';

  slsti_warehouse_page_size?: string;
}

export function getCookie<T extends keyof KnownCookies>(
  name: T,
): KnownCookies[T] {
  return Cookies.get(name) as KnownCookies[T];
}

export function setCookie<T extends keyof KnownCookies>(
  name: T,
  value: NonNullable<KnownCookies[T]>,
  options?: Cookies.CookieAttributes,
): void {
  Cookies.set(name, value, options);
}

export function removeCookie<T extends keyof KnownCookies>(
  name: T,
  options?: Cookies.CookieAttributes,
): void {
  Cookies.remove(name, options);
}
