import classNames from 'classnames';
import React from 'react';

export interface MessageProps extends React.HTMLAttributes<HTMLDivElement> {
  error?: boolean;
  info?: boolean;
  success?: boolean;
  warning?: boolean;
}

const Message: React.FC<MessageProps> = ({
  children,
  className,
  error,
  info,
  warning,
  success,
}) => (
  <div
    className={classNames(
      'prose relative my-4 w-full max-w-none rounded border px-4 py-1.5 text-left',
      {
        'prose-red border-red-500 bg-red-200 text-red-700': error,
        'prose-blue border-blue-500 bg-blue-200 text-blue-700': info,
        'prose-yellow border-yellow-500 bg-yellow-200 text-yellow-700': warning,
        'prose-green border-green-500 bg-green-200 text-green-700': success,
      },
      className,
    )}
  >
    {children}
  </div>
);

export default Message;
