import React from 'react';
import Header from '../Header/Header';
import { Outlet } from 'react-router-dom';

const SimpleLayout: React.FC = () => (
  <>
    <Header />
    <Outlet />
  </>
);

export default SimpleLayout;
