import { TradeInModelVersion } from '../api/intf/item';
import { Quality } from '../constants/valueMatrix';

export function getTradeInValueForModelVersion(
  modelVersion?: TradeInModelVersion | null,
) {
  return (
    Number(
      modelVersion?.price_statistics?.trade_good ||
        modelVersion?.price_statistics?.trade_cost_good,
    ) || 0
  );
}

export function getListPriceForModelVersion(
  modelVersion?: TradeInModelVersion | null,
) {
  return Number(modelVersion?.price_statistics?.suggested_resale) || 0;
}

export function getCashOfferAmountForModelVersionAndQuality(
  quality: Quality,
  modelVersion: TradeInModelVersion,
) {
  return (
    Number(
      modelVersion.price_statistics?.trade_good ||
        modelVersion.price_statistics?.trade_cost_good,
    ) || 0
  );
}

export function getListPriceForModelVersionAndQuality(
  quality: Quality,
  modelVersion: TradeInModelVersion,
) {
  switch (quality) {
    case 'damagedParts':
    case 'good':
      return Number(modelVersion.price_statistics?.trade_list_good) || 0;

    case 'veryGood':
      return Number(modelVersion.price_statistics?.trade_list_very_good) || 0;

    case 'excellent':
      return Number(modelVersion.price_statistics?.trade_list_excellent) || 0;

    case 'newWithoutTags':
      return Number(modelVersion.price_statistics?.trade_list_new_no_tags) || 0;

    case 'newWithTags':
      return Number(modelVersion.price_statistics?.trade_list_new_w_tags) || 0;

    case 'unacceptable':
      return 0;
  }
}
