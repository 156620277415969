import React from 'react';
import { PartnerTransfer } from '../../api/partnerTransfers';
import { Link } from 'react-router-dom';
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import SimpleReactTable from '../Tables/SimpleReactTable';

interface Props {
  transfers: PartnerTransfer[];
}

const columns: ColumnDef<PartnerTransfer, any>[] = [
  {
    id: 'created_at',
    accessorFn: transfer => transfer.created_at,
    header: 'Created Date',
    cell: info => new Date(info.getValue()).toLocaleDateString(),
  },
  {
    id: 'transfer_id',
    header: 'Transfer ID',
    accessorFn: row => {
      const split = row.id?.split('-') || [];
      return split[split.length - 1];
    },
  },
  {
    accessorFn: transfer => transfer.shipments_count || 0,
    header: '# Boxes',
  },
  {
    accessorFn: transfer => transfer.items_count || 0,
    header: '# Items',
  },
  {
    id: 'actions',
    header: '',
    cell: info => (
      <Link
        to={`/partner/dashboard/transfers/${info.row.original.id}`}
        className="text-sm font-semibold text-green-500"
      >
        View
      </Link>
    ),
  },
];

const TransfersTable: React.FC<Props> = ({ transfers }) => {
  const table = useReactTable({
    columns,
    data: transfers,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: 'created_at',
          desc: true,
        },
      ],
    },
  });

  return <SimpleReactTable table={table} />;
};

export default TransfersTable;
