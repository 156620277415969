import React from 'react';
import WarehouseAuditLayout from '../../../components/Layout/Layouts/WarehouseAuditLayout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import useCurrentWarehouseItemId from '../../../hooks/warehouse/useCurrentWarehouseItemId';
import { useQuery, useQueryClient } from 'react-query';
import {
  fetchTransferItemById,
  putWarehouseAuditAccept,
} from '../../../api/warehouseAudit';
import LoadingIndicator from '../../../components/Form/LoadingIndicator';
import { z } from 'zod';
import FormikZodSchema from '../../../components/Form/FormikZodSchema';
import { fetchCategories, fetchCategoryById } from '../../../api/item';
import { formatFormikError, getErrorMessage } from '../../../api/ApiError';
import {
  Quality,
  QUALITY_DESCRIPTION,
  QUALITY_LABELS,
} from '../../../constants/valueMatrix';
import AuditForm from '../../../components/Partner/Audit/AuditForm';
import { TradeInModelVersionZ } from '../../../api/intf/item';
import Message from '../../../components/Form/Message';

export const warehouseAuditSchema = z.object({
  cashOfferAmount: z.coerce.number(),
  resaleAmount: z.coerce.number(),
  retailPrice: z.string().or(z.number()).nullish(),

  categoryId: z.coerce
    .number()
    .nullish()
    .refine(v => !!v, 'Category is required'),
  brandId: z
    .number()
    .nullish()
    .refine(v => !!v, 'Brand is required'),
  modelId: z
    .number()
    .nullish()
    .refine(v => !!v, 'Model is required'),
  details: z.record(z.coerce.number().nullish(), z.coerce.number().nullish()), // key is the detail type_id (legacy_id), value is the option legacy_id
  quality: z.enum(Object.keys(QUALITY_LABELS) as any),
  modelVersionUuid: z.string().uuid().nullish(),
  modelVersion: TradeInModelVersionZ.nullish(),
  name: z.string().nullish(),
});

interface Props {}

const WarehouseAuditItemDetailsPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const warehouseId = useCurrentWarehouseId();
  const itemId = useCurrentWarehouseItemId();
  const [searchParams] = useSearchParams();

  if (!itemId) {
    const transferId = searchParams.get('transfer_id');
    if (transferId) {
      navigate(`/warehouse/${warehouseId}/audit/transfers/${transferId}`);
    } else {
      navigate(`/warehouse/${warehouseId}/audit`);
    }
    return null;
  }

  const { data: transferItem, error: transferItemError } = useQuery(
    ['transfer-item', itemId],
    () => fetchTransferItemById(itemId!),
  );

  const categoryId =
    transferItem?.reviewed_data?.category_id ||
    transferItem?.original_data.category_id;
  const { data: fullCategory } = useQuery(
    ['full-category', transferItem?.original_data.category_id],
    () => fetchCategoryById(categoryId!),
    {
      enabled: !!categoryId,
    },
  );
  const { data: allCategories } = useQuery(['categories'], fetchCategories);

  let content: React.ReactNode;

  if (transferItem && fullCategory && allCategories) {
    content = (
      <FormikZodSchema
        schema={warehouseAuditSchema}
        initialValues={{
          details: (
            transferItem.reviewed_data?.details ||
            transferItem.original_data.details ||
            []
          ).reduce(
            (acc, detail) => ({
              ...acc,
              [detail.type_id]: detail.option_id,
            }),
            {},
          ),
          resaleAmount:
            transferItem.reviewed_data?.resale_amount ||
            transferItem.original_data.resale_amount,
          retailPrice:
            transferItem.reviewed_data?.retail_price ||
            transferItem.original_data.retail_price,
          cashOfferAmount:
            transferItem.reviewed_data?.cash_offer_amount ||
            transferItem.original_data.cash_offer_amount,
          categoryId:
            transferItem.reviewed_data?.category_id ||
            transferItem.original_data.category_id,
          brandId:
            transferItem.reviewed_data?.brand_id ||
            transferItem.original_data.brand_id,
          modelId:
            transferItem.reviewed_data?.model_id ||
            transferItem.original_data.model_id,
          modelVersionUuid:
            transferItem.reviewed_data?.model_version_id ||
            transferItem.original_data.model_version_id,
          quality:
            transferItem.reviewed_data?.quality ||
            transferItem.original_data.quality,
          name: (
            transferItem.reviewed_data?.name || transferItem.original_data.name
          )?.slice(0, 100),
        }}
        onSubmit={async (values, formikHelpers) => {
          try {
            await putWarehouseAuditAccept(warehouseId!, itemId!, {
              cash_offer_amount: values.cashOfferAmount,
              resale_amount: values.resaleAmount,
              retail_price: values.retailPrice!,
              category_id: values.categoryId,
              brand_id: values.brandId,
              model_id: values.modelId,
              model_version_id: values.modelVersionUuid,
              details: Object.entries(values.details)
                .filter(([typeId, optionId]) => !!typeId && !!optionId)
                .map(([typeId, optionId]) => ({
                  type_id: Number(typeId),
                  option_id: optionId!,
                })),
              credit_offer_amount: values.cashOfferAmount,
              quality: values.quality,
              description: QUALITY_DESCRIPTION[values.quality as Quality],
            });
            queryClient.invalidateQueries();

            const transferId = searchParams.get('transfer_id');
            if (transferId) {
              navigate(
                `/warehouse/${warehouseId}/audit/transfers/${transferId}`,
              );
            } else {
              navigate(`/warehouse/${warehouseId}/audit`);
            }
          } catch (e) {
            formikHelpers.setErrors(formatFormikError(e));
          }
        }}
      >
        <AuditForm
          allCategories={allCategories}
          fullCategory={fullCategory}
          transferItem={transferItem}
        />
      </FormikZodSchema>
    );
  } else if (transferItemError) {
    content = (
      <div>
        <Message error>
          Get transfer item error: {getErrorMessage(transferItemError)}
        </Message>
      </div>
    );
  } else {
    content = <LoadingIndicator size="large" />;
  }

  return (
    <WarehouseAuditLayout exitButton warnOnExit>
      {content}
    </WarehouseAuditLayout>
  );
};

export default WarehouseAuditItemDetailsPage;
