import React from 'react';
import { useSelect } from 'downshift';
import {
  BaseSelectButton,
  BaseSelectMenu,
  BaseSelectMenuItem,
  BaseSelectMenuItemsContainer,
} from './elements';
import FieldLabel from '../FieldLabel';
import { XMarkIcon } from '@heroicons/react/24/outline';

export interface SimpleSelectProps<T> {
  clearable?: boolean;
  error?: string;
  items?: T[];

  containerClassName?: string;

  label?: React.ReactNode;
  onChange: (value: T | null) => void;
  placeholder?: React.ReactNode;
  itemToString: (item: T | null) => string;
  renderItem?: (item: T, index: number) => React.ReactNode;
  value: T | null;
}

function SimpleSelect<T>({
  clearable,
  containerClassName,
  error,
  items,
  itemToString,
  label,
  placeholder,
  onChange,
  renderItem,
  value,
}: SimpleSelectProps<T>) {
  const selectedItem =
    typeof value === 'string' ? items?.find(i => i === value) : value;

  const {
    getMenuProps,
    getItemProps,
    getLabelProps,
    getToggleButtonProps,
    isOpen,
    highlightedIndex,
    reset,
  } = useSelect<T>({
    items: items ?? [],
    itemToString,
    selectedItem,
    onSelectedItemChange: changes => onChange(changes.selectedItem ?? null),
  });

  const handleReset: React.MouseEventHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    reset();
    onChange(null);
  };

  return (
    <div className={containerClassName}>
      {label && (
        <FieldLabel {...getLabelProps()} error={error}>
          {label}
        </FieldLabel>
      )}
      <div className="relative">
        <BaseSelectButton {...getToggleButtonProps()} error={error}>
          {selectedItem ? (
            renderItem?.(selectedItem, -1) ?? itemToString(selectedItem)
          ) : (
            <span className="text-slate-green-500">
              {placeholder ?? 'Select...'}
            </span>
          )}

          {clearable && (
            <>
              <div className="flex-1" />
              <button
                onClick={handleReset}
                className="-my-4 -ml-4 -mr-2 p-4"
                type="button"
                tabIndex={-1}
              >
                <XMarkIcon className="h-4 w-4 text-slate-green-500" />
              </button>
            </>
          )}
        </BaseSelectButton>

        <BaseSelectMenu isOpen={isOpen} {...getMenuProps()} error={error}>
          {isOpen && (
            <>
              {!items?.length && (
                <div className="mb-2 text-center text-slate-green-500">
                  No options...
                </div>
              )}

              <BaseSelectMenuItemsContainer>
                {items?.map((item, i) => {
                  const toString = itemToString(item);
                  return (
                    <BaseSelectMenuItem
                      highlighted={highlightedIndex === i}
                      selected={item === selectedItem}
                      key={toString}
                      {...getItemProps({
                        item,
                        index: i,
                      })}
                    >
                      {renderItem?.(item, i) ?? toString}
                    </BaseSelectMenuItem>
                  );
                })}
              </BaseSelectMenuItemsContainer>
            </>
          )}
        </BaseSelectMenu>
      </div>
    </div>
  );
}

export default SimpleSelect;
