import React from 'react';
import { Button } from '../Form/Button';
import { useEventCustomerQuoteQuery } from '../../hooks/routing/useEventCustomerQuoteQuery';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import {
  postCreatePartnerQuoteAndListing,
  putAddListingToQuote,
} from '../../api/partnerQuote';
import { fireAddedToCart } from '../../services/analytics/partner/quotes';
import { TradeInOptionMap } from '../../types/detailSelection';
import useCurrentQuoteQuery from '../../hooks/query/useCurrentQuoteQuery';
import Message from '../Form/Message';
import { getErrorMessage } from '../../api/ApiError';

export default function InStoreAddToCartButton({
  options,
}: {
  options: TradeInOptionMap;
}) {
  const { data: quote } = useCurrentQuoteQuery();
  const { quoteId } = useEventCustomerQuoteQuery();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    mutate: createQuote,
    error: createError,
    isLoading: isCreatingLoading,
  } = useMutation(() => postCreatePartnerQuoteAndListing(options), {
    onSuccess: data => {
      fireAddedToCart(options, data.quote.id);
      navigate(
        `/partner/quote/${data.quote.id}/summary?just_added=${data.listing.id}`,
      );
    },
  });

  const {
    mutate: addItemToQuote,
    error: updateError,
    isLoading: isUpdateLoading,
  } = useMutation(() => putAddListingToQuote(quote!.id, options), {
    onSuccess: data => {
      fireAddedToCart(options, quoteId);
      queryClient.invalidateQueries(['quotes', quote!.id]);
      navigate(`/partner/quote/${quote!.id}/summary?just_added=${data.id}`);
    },
  });

  const isPending = isCreatingLoading || isUpdateLoading;
  const handleAddToCart = () => {
    if (quote) {
      addItemToQuote();
    } else {
      createQuote();
    }
  };

  return (
    <>
      {!!(createError || updateError) && (
        <Message error>{getErrorMessage(createError || updateError)}</Message>
      )}

      <Button
        variant="contained"
        size="large"
        onClick={handleAddToCart}
        disabled={isPending}
      >
        Add to Cart
      </Button>
    </>
  );
}
