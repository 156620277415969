import { differenceInSeconds } from 'date-fns';
import { useRef, useState } from 'react';
import { ApiError } from '../../api/ApiError';
import { ScanHistory } from '../../types/spawnScanning';

interface PicklistScanHistory {
  createdAt: number;
  data: string;
  error?: ApiError;
  status: 'loading' | 'error' | 'success';
}

const THROTTLE_SECONDS = 3;

export default function useThrottledPicklistScanning(
  onScan: (data: string, scanHistory: ScanHistory) => void,
) {
  const scanHistory = useRef<PicklistScanHistory[]>([]);

  const [, setRerender] = useState<any>();

  function rerender() {
    setRerender(new Date());
  }

  async function onScanData(data: string) {
    if (!data.startsWith('https://')) {
      return;
    }

    // Look for the LAST scan history
    let found: PicklistScanHistory | undefined;
    for (let i = scanHistory.current.length - 1; i >= 0; i--) {
      if (scanHistory.current[i].data === data) {
        found = scanHistory.current[i];
        break;
      }
    }
    const now = new Date();
    let shouldProcess = false;
    if (!found) {
      shouldProcess = true;
    } else if (found.status !== 'loading') {
      if (
        differenceInSeconds(now, new Date(found.createdAt)) >= THROTTLE_SECONDS
      ) {
        shouldProcess = true;
      } else if (
        found.status === 'error' &&
        differenceInSeconds(now, new Date(found.createdAt)) >= THROTTLE_SECONDS
      ) {
        shouldProcess = true;
      }
    }
    if (shouldProcess) {
      const newLength = scanHistory.current.push({
        data: data,
        status: 'loading',
        createdAt: Number(new Date()),
      });
      rerender();
      const index = newLength - 1;

      await onScan(data, scanHistory.current[index]);
      rerender();
    }
  }

  return {
    scanHistory: scanHistory.current,
    onScanData,
  };
}
