import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
}

const Field: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
}) => <div className={classNames('w-full', className)}>{children}</div>;

export default Field;
