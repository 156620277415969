import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ListingItem } from '../../../api/intf/item';
import { deleteListingItemById } from '../../../api/item';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../api/ApiError';
import displayPrice from '../../../utils/displayPrice';
import LoadingIndicator from '../../Form/LoadingIndicator';

interface Props {
  listing: ListingItem;
}

const PartnerQuoteListingRow: React.FC<Props> = ({ listing }) => {
  const queryClient = useQueryClient();
  const {
    mutate: removeListing,
    isLoading,
    variables,
  } = useMutation(
    (listing: ListingItem) =>
      deleteListingItemById(listing.quote_id!, listing.id!),
    {
      onError: err => {
        toast.error(`Failed to remove listing: ${getErrorMessage(err)}`);
      },
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    },
  );

  return (
    <div className="flex space-x-6" key={listing.id}>
      {listing.images?.[0] ? (
        <img
          src={listing.images[0]}
          data-testid="model-version-image"
          alt=""
          width={64}
          height={64}
          className="h-16 w-16 rounded object-cover"
        />
      ) : (
        <div className="h-16 w-16" />
      )}

      <div className="flex-1">
        <div className="text-lg font-semibold">{listing.name}</div>

        <div className="flex space-x-8">
          <div className="text-slate-green-500">
            {displayPrice(listing.cash_offer_amount)}
          </div>

          <div className="flex items-center space-x-2">
            <button
              onClick={() => removeListing(listing)}
              className="font-semibold underline"
              disabled={isLoading && variables?.id === listing.id}
            >
              Delete
            </button>

            {isLoading && variables?.id === listing.id && <LoadingIndicator />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerQuoteListingRow;
