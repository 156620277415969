import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CreateCustomerForm from '../../../components/CustomerForm/CreateCustomerForm';
import CustomerFormFields from '../../../components/CustomerForm/CustomerFormFields';
import { Button } from '../../../components/Form/Button';
import PageHeader from '../../../components/Layout/PageHeader';
import PageSubheader from '../../../components/Layout/PageSubheader';
import FormikErrorMessage from '../../../components/Form/FormikErrorMessage';
import { Customer } from '../../../api/intf/customer';
import pluralize from '../../../utils/pluralize';
import PageLayout from '../../../components/Layout/PageLayout';
import CustomerCard from '../../../components/SearchCustomer/CustomerCard';
import SourceField from '../../../components/CustomerForm/SourceField';
import TradeInBreadcrumbLink from '../../../components/Layout/TradeInBreadcrumbLink';
import { useEventCustomerQuoteQuery } from '../../../hooks/routing/useEventCustomerQuoteQuery';

interface Props {}

const CreateCustomerPage: React.FC<Props> = () => {
  const [existingCustomers, setExistingCustomers] = useState<Customer[]>([]);
  const { eventId } = useEventCustomerQuoteQuery();

  return (
    <PageLayout
      header={
        <>
          <TradeInBreadcrumbLink to="home" />

          <PageHeader>Create customer account</PageHeader>
          <PageSubheader>Collect the customer&apos;s Photo ID</PageSubheader>
        </>
      }
    >
      <CreateCustomerForm setMatchedExistingCustomers={setExistingCustomers}>
        <div className="space-y-4">
          <div className="mb-4">
            <CustomerFormFields />
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <SourceField />
          </div>

          <FormikErrorMessage />

          <div className="flex justify-end space-x-4">
            <Link to={`/event/${eventId}`} className="block">
              <Button variant="text">Back</Button>
            </Link>
            <Button type="submit">Next</Button>
          </div>
        </div>
      </CreateCustomerForm>

      {existingCustomers.length > 0 && (
        <div className="mt-8">
          <div className="mb-4 text-xl font-semibold">
            {existingCustomers.length}
            {pluralize(
              existingCustomers.length,
              ' customer has ',
              ' customers have ',
            )}
            similar contact information
          </div>

          <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            {existingCustomers.map(customer => (
              <CustomerCard key={customer.id} customer={customer} />
            ))}
          </div>
        </div>
      )}
    </PageLayout>
  );
};

export default CreateCustomerPage;
