import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames';

interface Props extends LinkProps {}

const NavigationButton: React.FC<Props> = ({
  className,
  children,
  ...props
}) => {
  return (
    <Link
      {...props}
      className={classNames(
        'flex h-24 w-full items-center justify-center rounded border px-8 py-6 text-lg font-semibold',
        className,
      )}
    >
      {children}
    </Link>
  );
};

export default NavigationButton;
