import React from 'react';
import SimpleModal from '../Modal/SimpleModal';
import { ListingItem } from '../../api/intf/item';
import { Button } from '../Form/Button';
import { putPartnerItemTag } from '../../api/partnerTagging';
import { useQueryClient } from 'react-query';
import displayPrice from '../../utils/displayPrice';
import formatListingTag from '../../utils/formatListingTag';
import IsolatedTagForm from './IsolatedTagForm';
import {
  fireTaggedItem,
  fireTaggedItemError,
} from '../../services/analytics/partner/tagging';
import useRouteQuery from '../../hooks/useRouteQuery';
import { TagItemsPageQuery } from '../../pages/partnerInStore/dashboard/partnerTagItems';
import { getErrorMessage } from '../../api/ApiError';
import { differenceInDays } from 'date-fns';

interface Props {
  listing: ListingItem;
}

const PartnerTagItemRow: React.FC<Props> = ({ listing }) => {
  const queryClient = useQueryClient();
  const query = useRouteQuery<TagItemsPageQuery>();
  const [open, setOpen] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleSuccessfullySubmittedTag = async (tag: string) => {
    fireTaggedItem({
      tagId: tag,
      src: query.quote_id ? 'quote' : 'tag_items',
      quoteListingId: listing.id!,
      versionName: listing.name!,
    });
    await putPartnerItemTag(listing.item_id!, tag);
    setOpen(false);
    await queryClient.invalidateQueries();
  };

  const handleTagError = (error: Error, tag: string) => {
    fireTaggedItemError({
      tagId: tag,
      src: query.quote_id ? 'quote' : 'tag_items',
      errorMessage: getErrorMessage(error),
      quoteListingId: listing.id,
      versionName: listing.name,
    });
  };

  return (
    <>
      <div className="flex space-x-4">
        <div className="flex-shrink-0">
          {listing.images && listing.images.length > 0 ? (
            <img
              src={listing.images[0]}
              alt={listing.name}
              className="h-20 w-20 object-cover"
            />
          ) : (
            <div className="h-20 w-20 bg-gray-200"></div>
          )}
        </div>

        <div className="flex-1">
          <h2 className="text-lg font-semibold">{listing.name}</h2>
          <div className="mb-2">
            <span className="font-semibold text-slate-green-500">
              {displayPrice(listing.cash_offer_amount)}
            </span>
          </div>

          <div className="grid grid-cols-2 gap-x-4 gap-y-2 md:grid-cols-4">
            {listing.details?.map(detail => (
              <div key={detail.type_id}>
                <div className="text-xs font-semibold uppercase">
                  {detail.type}
                </div>
                <div className="text-sm font-semibold text-slate-green-500">
                  {detail.option}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="space-y-4">
          {listing.tag ? (
            <div>
              <div className="mb-1 text-sm font-semibold">QR Code</div>
              <div>
                {formatListingTag(listing.tag)}
                {'  '}
                <button
                  className="text-sm font-semibold text-green-500"
                  onClick={() => setOpen(true)}
                >
                  Edit
                </button>
              </div>
            </div>
          ) : (
            <Button onClick={() => setOpen(true)}>Enter QR Code</Button>
          )}

          <div>
            <div className="text-sm font-semibold">Purchased</div>
            <div className="text-sm text-slate-green-500">
              {differenceInDays(
                new Date(),
                new Date(listing.quote_completed_at!),
              )}{' '}
              days ago
            </div>
          </div>
        </div>
      </div>

      <SimpleModal
        open={open}
        onClose={() => setOpen(false)}
        title={listing.tag ? 'Edit QR Code' : 'Enter QR Code'}
        initialFocus={inputRef}
        className="w-full max-w-md"
      >
        <IsolatedTagForm
          ref={inputRef}
          initialTag={listing.tag}
          onCancel={() => setOpen(false)}
          onError={handleTagError}
          onSubmit={handleSuccessfullySubmittedTag}
        />
      </SimpleModal>
    </>
  );
};

export default PartnerTagItemRow;
