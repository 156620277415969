import { useQuery } from 'react-query';
import { fetchIamPartnerUser } from '../../api/partnerUsers';
import { useAuth } from '../../lib/auth/AuthContext';
import { useCookie } from 'react-use';
import { KnownCookies } from '../../lib/KnownCookies';

export default function useAuthedPartnerUser() {
  const { decodedJwt } = useAuth();
  const [userType] = useCookie('sls_user_type' as keyof KnownCookies);

  return useQuery(
    ['iam-partner', decodedJwt?.uuid],
    () => fetchIamPartnerUser(decodedJwt?.org_id!, decodedJwt?.uuid!),
    {
      enabled:
        userType === 'partner' && !!decodedJwt?.org_id && !!decodedJwt?.uuid,
    },
  );
}
