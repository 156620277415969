import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import displayPrice from '../../utils/displayPrice';
import QuoteSummaryItem from './QuoteSummaryItem';
import { Button } from '../../components/Form/Button';
import useCustomerById from '../../hooks/useCustomerById';
import pluralize from '../../utils/pluralize';
import { useEventCustomerQuoteQuery } from '../../hooks/routing/useEventCustomerQuoteQuery';
import QuotePrintModal from '../QuotePrintModal';
import useQuoteQuery from '../../hooks/query/useQuoteQuery';
import calculateMargin from '../../utils/calculateMargin';
import QuoteSummaryPanelBonusCode from './QuoteSummaryPanelBonusCode';
import getQuoteListings from '../../utils/getQuoteListings';

interface Props {
  quoteId: string;
  customerId: string;
}

const QuoteSummaryPanel: React.FC<Props> = ({ quoteId, customerId }) => {
  const navigate = useNavigate();
  const { eventId } = useEventCustomerQuoteQuery();
  const { data: quote } = useQuoteQuery(quoteId);
  const { data: customer } = useCustomerById(customerId);

  const [printModalOpen, setPrintModalOpen] = useState(false);
  const handleFinishQuote = () => {
    navigate(
      `/event/${eventId}/customer/${customerId}/quote/${quoteId}/summary`,
    );
  };

  const resaleEstimate = quote?.resale_estimate || 0;
  const cashOfferAmount = quote?.cash_offer_amount || 0;
  return (
    <div className="px-4 pt-4 pb-16">
      <div className="mb-2">Quote ID: {quote?.display_id}</div>

      <div className="mb-4">
        <div className="text-lg font-semibold">Customer Info</div>
        {customer && (
          <>
            <div>
              {customer.first_name} {customer.last_name}
            </div>
            <div>Email: {customer.email}</div>
            <div>Phone: {customer.phone}</div>
          </>
        )}

        {quote && <QuoteSummaryPanelBonusCode quote={quote} />}
      </div>

      <div className="flex items-center justify-between">
        <span className="text-lg font-semibold">Quote Summary</span>
        <span className="rounded-full bg-grey-200 px-1.5">
          {quote?.listing_count}{' '}
          {pluralize(quote?.listing_count, 'item', 'items')}
        </span>
      </div>

      <div className="mt-1">
        <div>
          <span className="font-semibold">Resale Subtotal: </span>
          {displayPrice(resaleEstimate)}
        </div>
        <div>
          <span className="font-semibold">Offer Subtotal: </span>
          {displayPrice(cashOfferAmount)} (
          {calculateMargin(cashOfferAmount, resaleEstimate)}% margin)
        </div>
      </div>

      <div className="mt-4 flex flex-col justify-end space-y-2">
        <Button onClick={handleFinishQuote}>View Summary</Button>
        <Button onClick={() => setPrintModalOpen(true)} variant="outlined">
          Print Labels
        </Button>
      </div>

      <div className="hidden md:block">
        {getQuoteListings(quote).map((ListingItem, index) => (
          <QuoteSummaryItem
            key={index}
            listingItem={ListingItem}
            quoteId={quoteId}
            customerId={customerId}
          />
        ))}
      </div>

      <QuotePrintModal
        eventId={eventId}
        quoteId={quoteId}
        onClose={() => setPrintModalOpen(false)}
        open={printModalOpen}
      />
    </div>
  );
};

export default QuoteSummaryPanel;
