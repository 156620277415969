import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchCustomerByQuery } from '../../../api/customer';
import FieldLabel from '../../../components/Form/FieldLabel';
import Input from '../../../components/Form/Input';
import Field from '../../../components/Form/Field';
import { useDebounce, useLocation } from 'react-use';
import qsParse from '../../../utils/qsParse';
import { useNavigate } from 'react-router-dom';
import CustomerCard from '../../../components/SearchCustomer/CustomerCard';
import CardContainer from '../../../components/CardContainer';
import PageLayout from '../../../components/Layout/PageLayout';
import Message from '../../../components/Form/Message';
import { getErrorMessage } from '../../../api/ApiError';
import LoadingIndicator from '../../../components/Form/LoadingIndicator';
import TradeInBreadcrumbLink from '../../../components/Layout/TradeInBreadcrumbLink';

interface Props {}

const SearchCustomerPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { search: urlSearch } = useLocation();
  const [search, setSearch] = useState(
    () => (qsParse(urlSearch)?.q as string) || '',
  );
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  useDebounce(
    () => {
      setDebouncedSearch(search);
    },
    500,
    [search],
  );

  const {
    data: customers,
    isSuccess,
    error,
    isLoading,
  } = useQuery(
    ['search-customers', debouncedSearch] as const,
    ({ queryKey }) => fetchCustomerByQuery(queryKey[1]),
    {
      keepPreviousData: true,
      retry: false,
      select: data => {
        if (debouncedSearch.length === 0) {
          return data.slice(0, 5);
        } else {
          return data;
        }
      },
      onSuccess: () => {
        if (urlSearch || debouncedSearch.length > 0) {
          navigate({ search: `q=${debouncedSearch}` });
        }
      },
    },
  );

  return (
    <PageLayout
      header={
        <>
          <TradeInBreadcrumbLink to="home" />
          <Field>
            <FieldLabel>Search by customer info</FieldLabel>
            <Input
              autoFocus
              onChange={e => setSearch(e.target.value)}
              value={search}
            />
          </Field>
        </>
      }
    >
      {isSuccess && customers?.length === 0 && (
        <div className="m-4 text-center text-lg italic">No customers found</div>
      )}

      {isLoading && <LoadingIndicator size="large" className="mx-auto" />}

      {!!error && (
        <Message>Failed to fetch customers: {getErrorMessage(error)}</Message>
      )}

      <CardContainer>
        {customers?.map(customer => (
          <CustomerCard key={customer.id} customer={customer} />
        ))}
      </CardContainer>
    </PageLayout>
  );
};

export default SearchCustomerPage;
