import { useQuery } from 'react-query';
import { fetchIamOrgConfig } from '../../api/orgs';
import useOrgId from '../useOrgId';

export default function useOrgConfigQuery() {
  const orgId = useOrgId();

  return useQuery(['org-config', orgId], () => fetchIamOrgConfig(orgId!), {
    enabled: !!orgId,
  });
}
