import React from 'react';
import { WarehouseSwapsPaging } from '../../../api/intf/warehouseSwap';
import { setCookie } from '../../../lib/KnownCookies';
import { useQueryClient } from 'react-query';

const SIZES = ['20', '50', '100'];

interface Props {
  paging: WarehouseSwapsPaging;
}

const WarehouseSwapsPageSizeChanger: React.FC<Props> = ({ paging }) => {
  const queryClient = useQueryClient();
  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = e => {
    setCookie('slsti_warehouse_page_size', e.target.value);
    queryClient.invalidateQueries(['warehouse-swaps'], { exact: false });
  };

  return (
    <select
      name="size"
      className="font-semibold"
      value={paging.size || SIZES[0]}
      onChange={handleChange}
    >
      {SIZES.map(size => (
        <option key={size} value={size}>
          {size}
        </option>
      ))}
    </select>
  );
};

export default WarehouseSwapsPageSizeChanger;
