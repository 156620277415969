import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { sideMenuLinkCx } from '../Layout/SideMenu/SideMenuLink';
import { BuildingStorefrontIcon } from '@heroicons/react/24/outline';
import { fetchPartnerStores, postChangeStore } from '../../api/partnerStores';
import { RadioGroup } from '@headlessui/react';
import SimpleModal from '../Modal/SimpleModal';
import { Button } from '../Form/Button';
import Radio from '../Form/Radio';
import Message from '../Form/Message';
import { getErrorMessage } from '../../api/ApiError';
import useOrgId from '../../hooks/useOrgId';
import { useAuthDispatch } from '../../lib/auth/AuthContext';
import { setCookie } from '../../lib/KnownCookies';
import useOrgQuery from '../../hooks/query/useOrgQuery';
import Field from '../Form/Field';
import FieldLabel from '../Form/FieldLabel';
import Input from '../Form/Input';

export const PartnerStoreChanger: React.FC = () => {
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedStore, setSelectedStore] = useState<string | null>(null);

  const dispatch = useAuthDispatch();
  const orgId = useOrgId();

  const { data: org } = useOrgQuery();

  const { data: stores, error: loadStoresError } = useQuery(
    ['stores', org?.parent_org?.id],
    () => fetchPartnerStores(org?.parent_org?.id),
    {
      staleTime: Infinity,
      enabled: !!org?.parent_org?.id,
    },
  );
  const {
    mutate: changeStore,
    isLoading: isSubmitting,
    error: changeStoreError,
  } = useMutation(
    () => {
      if (!selectedStore) {
        throw new Error('No store selected');
      }
      return postChangeStore(selectedStore);
    },
    {
      onSuccess: async data => {
        if (!data) {
          return;
        }

        setSearch('');
        dispatch({
          type: 'logged in',
          payload: {
            accessToken: data.access_token,
          },
        });

        setCookie('access_token', data.access_token);

        setTimeout(() => queryClient.invalidateQueries(), 200);
        setOpen(false);
      },
    },
  );

  const error = loadStoresError || changeStoreError;
  const filteredStores = stores?.filter(store =>
    store.org_name.toLowerCase().includes(search.toLowerCase()),
  );

  const currentStore = stores?.find(store => store.org_id == orgId);

  return (
    <>
      <button className={sideMenuLinkCx} onClick={() => setOpen(true)}>
        <BuildingStorefrontIcon className="h-6 w-6" />
        <span>Change Store</span>
      </button>

      <SimpleModal
        open={open}
        onClose={() => setOpen(false)}
        title="Change Store"
        className="w-full max-w-md"
      >
        <Field className="mb-4">
          <FieldLabel>Search</FieldLabel>
          <Input
            type="search"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Search for a store"
            disabled={isSubmitting}
          />
        </Field>

        {currentStore && (
          <div className="mb-4">
            Current Store: <strong>{currentStore.org_name}</strong>
          </div>
        )}

        <RadioGroup
          value={selectedStore}
          onChange={setSelectedStore}
          className="h-80 space-y-2 overflow-y-auto"
          disabled={isSubmitting}
        >
          {filteredStores?.map(store => (
            <RadioGroup.Option
              key={store.store_id}
              className="flex w-full cursor-default items-center gap-4 rounded border px-4 py-3 transition-colors duration-200 hover:bg-grey-200"
              value={store.org_id}
            >
              {({ checked }) => (
                <>
                  <Radio checked={checked} />
                  <div>
                    <div>{store.org_name}</div>

                    {store.org_id == orgId && (
                      <div className="mt-2 text-xs font-semibold uppercase">
                        current store
                      </div>
                    )}
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>

        {!!error && <Message error>{getErrorMessage(error)}</Message>}

        <div className="mt-6 flex gap-4">
          <Button fluid variant="outlined" onClick={() => setOpen(false)}>
            Close
          </Button>

          <Button
            fluid
            onClick={() => changeStore()}
            disabled={!selectedStore || isSubmitting}
          >
            Change Store
          </Button>
        </div>
      </SimpleModal>
    </>
  );
};
