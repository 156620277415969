import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Input from '../../../Form/Input';
import { Button } from '../../../Form/Button';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import FieldLabel from '../../../Form/FieldLabel';
import useDebounceValue from '../../../../hooks/useDebounceValue';

interface Props {
  label?: React.ReactNode;
}

const SearchBarUrlQuery: React.FC<Props> = ({ label }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(() => searchParams.get('q') || '');
  const debouncedSearch = useDebounceValue(search, 200);

  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    if (debouncedSearch) {
      newParams.set('q', debouncedSearch);
      newParams.delete('page');
    } else {
      newParams.delete('q');
    }

    setSearchParams(newParams);
  }, [debouncedSearch]);

  useEffect(() => {
    if (searchParams.get('q') !== debouncedSearch) {
      setSearch(searchParams.get('q') || '');
    }
  }, [searchParams]);

  return (
    <form
      onSubmit={e => {
        e.preventDefault();

        const newParams = new URLSearchParams(searchParams);
        newParams.set('q', search);
        newParams.delete('page');

        setSearchParams(newParams);
      }}
    >
      <FieldLabel htmlFor="search">{label || 'Search'}</FieldLabel>

      <div className="flex">
        <Input
          id="search"
          type="search"
          value={search}
          onChange={e => setSearch(e.target.value)}
          className="rounded-r-none"
          placeholder="Search customer or quote ID"
        />
        <Button className="rounded-l-none">
          <MagnifyingGlassIcon className="h-5 w-5" />
        </Button>
      </div>
    </form>
  );
};

export default SearchBarUrlQuery;
