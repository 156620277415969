import { format, parse } from 'date-fns';

export default function formattedEventDates(
  startDate?: string,
  endDate?: string,
) {
  const parsedStartDate = startDate
    ? parse(startDate, 'yyyy-MM-d', new Date())
    : null;

  const parsedEndDate = endDate
    ? parse(endDate, 'yyyy-MM-d', new Date())
    : null;

  const formattedStartDate = parsedStartDate
    ? format(parsedStartDate, 'MMMM do')
    : null;
  const formattedEndDate = parsedEndDate
    ? format(parsedEndDate, 'MMMM do, yyyy')
    : null;

  return {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  };
}
