import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  link: string;
  linkText: string;
  title: string;
  value: number;
}

const DashboardCell: React.FC<Props> = ({
  children,
  icon,
  link,
  linkText,
  title,
  value,
}) => (
  <section
    aria-label={title}
    className="flex flex-col rounded border p-4 shadow"
  >
    <div className="flex justify-between">
      <span className="text-xl">{title}</span>
      {icon}
    </div>

    {children}

    <div className="mb-4 flex-1" />

    <div className="flex items-end justify-between">
      <span className="text-6xl font-bold leading-none">{value}</span>

      <Link to={link} className="font-semibold text-green-500">
        {linkText}
      </Link>
    </div>
  </section>
);

export default DashboardCell;
