import useCurrentWarehouseId from './useCurrentWarehouseId';
import { useQuery } from 'react-query';
import {
  fetchWarehouseSwaps,
  WarehouseSwapFilteringOptions,
} from '../../api/warehouse';
import { useSearchParams } from 'react-router-dom';

export function warehouseSwapsQueryFn(
  warehouseId: string,
  searchParams: URLSearchParams,
) {
  const options: WarehouseSwapFilteringOptions = {
    scope: searchParams.get('scope') ?? 'all',
    keyword: searchParams.get('q') as string,
    filters: searchParams.has('filter')
      ? searchParams.getAll('filter')
      : undefined,
    page: searchParams.get('page') as string,
  };

  return {
    queryKey: ['warehouse-swaps', warehouseId, options],
    queryFn: () => fetchWarehouseSwaps(warehouseId, options),
    enabled: !!warehouseId,
  };
}

export default function useCurrentWarehouseSwapsQuery() {
  const warehouseId = useCurrentWarehouseId();
  const [query] = useSearchParams();

  return useQuery({
    ...warehouseSwapsQueryFn(warehouseId!, query),
    keepPreviousData: true,
  });
}
