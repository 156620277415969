import React from 'react';

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement>;

const NewTabLink: React.FC<Props> = ({ children, rel, ...props }) => (
  <a
    {...props}
    target="_blank"
    rel={`noopener noreferrer${rel ? ' ' + rel : ''}`}
  >
    {children}
  </a>
);

export default NewTabLink;
