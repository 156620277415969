import React from 'react';
import classNames from 'classnames';
import { Menu } from '@headlessui/react';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const SimpleMenuItemButton: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  ...props
}) => (
  <Menu.Item>
    {({ active }) => (
      <button
        className={classNames(
          active ? 'bg-grey-200' : '',
          'block w-full px-4 py-2 text-left text-sm',
          className,
        )}
        {...props}
      >
        {children}
      </button>
    )}
  </Menu.Item>
);

export default SimpleMenuItemButton;
