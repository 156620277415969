import React, { useEffect } from 'react';
import useRouteQuery from '../hooks/useRouteQuery';
import Loader from '../components/Form/Loader';
import { useNavigate } from 'react-router-dom';
import { setCookie } from '../lib/KnownCookies';
import { useAuthDispatch } from '../lib/auth/AuthContext';
import qsStringify from '../utils/qsStringify';

interface Props {}

interface Query {
  client_id: string;
  access_token: string;
  refresh_token: string;
}

const PartnerRedirectPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { access_token, client_id, refresh_token } = useRouteQuery<Query>();

  const dispatch = useAuthDispatch();

  useEffect(() => {
    if (access_token && client_id && refresh_token) {
      setCookie('sls_client_id', client_id);
      setCookie('access_token', access_token);
      setCookie('refresh_token', refresh_token);
      setCookie('sls_user_type', 'partner');

      dispatch({
        type: 'logged in',
        payload: {
          accessToken: access_token,
          refreshToken: refresh_token,
        },
      });

      navigate('/partner/dashboard');
    } else {
      navigate(
        '/partner-login' +
          qsStringify({
            contextMessage: 'Invalid redirect',
            contextMessageType: 'error',
          }),
      );
    }
  }, [access_token, client_id, refresh_token]);

  return (
    <div className="my-24">
      <Loader loading />
    </div>
  );
};

export default PartnerRedirectPage;
