import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { WarehouseSwapsPaging } from '../../../api/intf/warehouseSwap';

interface Props {
  paging?: WarehouseSwapsPaging;
}

const WarehouseNextPrevPaginator: React.FC<React.PropsWithChildren<Props>> = ({
  paging,
}) => {
  const location = useLocation();
  const [params] = useSearchParams();

  const currentPage = paging ? paging.page : 1;

  const { nextPageParams, prevPageParams } = useMemo(() => {
    let prevPageParams: URLSearchParams | null = null;
    if (paging && currentPage > 1) {
      const prevPage = currentPage - 1;
      prevPageParams = new URLSearchParams(params);

      if (prevPage <= 1) {
        prevPageParams.delete('page');
      } else {
        prevPageParams.set('page', String(prevPage));
      }
    }

    let nextPageParams: URLSearchParams | null = null;
    if (paging && paging.has_next_page) {
      nextPageParams = new URLSearchParams(params);
      nextPageParams.set('page', String(currentPage + 1));
    }

    return {
      nextPageParams,
      prevPageParams,
    };
  }, [paging, currentPage, params]);

  const prevIcon = <ChevronLeftIcon className="h-7 w-7" />;
  const nextIcon = <ChevronRightIcon className="h-7 w-7" />;

  return (
    <>
      {prevPageParams ? (
        <Link
          to={`${location.pathname}?${prevPageParams}`}
          className="rounded-full p-2"
        >
          {prevIcon}
        </Link>
      ) : (
        <span className="p-2 text-grey-500">{prevIcon}</span>
      )}

      <span className="text-sm font-semibold">{currentPage}</span>

      {nextPageParams ? (
        <Link
          to={`${location.pathname}?${nextPageParams}`}
          className="rounded-full p-2"
        >
          {nextIcon}
        </Link>
      ) : (
        <span className="p-2 text-grey-500">{nextIcon}</span>
      )}
    </>
  );
};

export default WarehouseNextPrevPaginator;
