import { useQuery } from 'react-query';
import { getButterConditionGuidancePages } from '../../api/buttercms';
import { useMemo } from 'react';

export default function useConditionGuidanceQuery(categorySlug?: string) {
  const query = useQuery(
    ['condition-guidance'],
    getButterConditionGuidancePages,
    {
      enabled: !!categorySlug,
      staleTime: Infinity,
    },
  );

  const guidance = useMemo(
    () =>
      query.data?.find(page =>
        page.fields.category_slugs.some(
          slug => slug.category_slug === categorySlug,
        ),
      )?.fields.conditions || null,
    [query.data, categorySlug],
  );

  return {
    guidance: guidance,
    isLoading: query.isLoading,
  };
}
