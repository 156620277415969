import React, { useState } from 'react';
import PageLayout from '../../../components/Layout/PageLayout';
import PageHeader from '../../../components/Layout/PageHeader';
import SettingsBreadcrumbLink from '../../../components/Settings/SettingsBreadcrumbLink';
import { useQuery } from 'react-query';
import { fetchBonusCodes } from '../../../api/bonusCodes';
import { Link } from 'react-router-dom';
import PageSubheader from '../../../components/Layout/PageSubheader';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import Field from '../../../components/Form/Field';
import FieldLabel from '../../../components/Form/FieldLabel';
import Input from '../../../components/Form/Input';
import { compareDesc } from 'date-fns';

interface Props {}

const BonusCodesManagementPage: React.FC<Props> = () => {
  const [search, setSearch] = useState('');
  const { data: bonusCodes } = useQuery(
    ['bonus-codes', 'all'],
    fetchBonusCodes,
  );

  const searchString = search.toLowerCase();

  const filteredBonusCodes = bonusCodes
    ?.filter(bonusCode => {
      return (
        bonusCode.code.toLowerCase().includes(searchString) ||
        bonusCode.organization_name?.toLowerCase().includes(searchString)
      );
    })
    .sort((a, b) =>
      compareDesc(new Date(a.created_at), new Date(b.created_at)),
    );

  return (
    <PageLayout
      header={
        <>
          <SettingsBreadcrumbLink />
          <PageHeader>Manage Bonus Codes</PageHeader>

          <PageSubheader>
            <Link
              to="/settings/bonus-codes/new"
              className="mt-2 inline-flex items-center underline"
            >
              <PlusCircleIcon className="mr-1 h-5 w-5" />
              <span>Create bonus code</span>
            </Link>
          </PageSubheader>
        </>
      }
    >
      <Field className="mb-8">
        <FieldLabel>Search by Code Name</FieldLabel>
        <Input
          type="search"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
      </Field>

      {filteredBonusCodes && filteredBonusCodes.length == 0 && (
        <p className="text-center text-lg font-semibold text-gray-500">
          No codes found
        </p>
      )}

      {filteredBonusCodes && filteredBonusCodes.length > 0 && (
        <table className="w-full text-center">
          <thead>
            <tr>
              <th className="px-2 py-2">Code</th>
              <th className="px-2 py-2">Created</th>
              <th className="px-2 py-2">Organization</th>
              <th className="px-2 py-2">Usage</th>
              <th className="px-2 py-2">State</th>
              <th className="px-2 py-2">{/* Actions */}</th>
            </tr>
          </thead>
          <tbody>
            {filteredBonusCodes.map(bonusCode => (
              <tr key={bonusCode.id}>
                <td className="px-2 py-2 text-left">{bonusCode.code}</td>
                <td className="px-2 py-2">
                  {new Date(bonusCode.created_at).toLocaleDateString()}
                </td>
                <td className="px-2 py-2">{bonusCode.organization_name}</td>
                <td className="px-2 py-2">{bonusCode.usage_count || 0}</td>
                <td className="px-2 py-2">{bonusCode.state}</td>
                <td className="px-2 py-2 ">
                  <Link
                    to={`/settings/bonus-codes/${bonusCode.id}`}
                    className="underline"
                  >
                    Edit
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </PageLayout>
  );
};

export default BonusCodesManagementPage;
