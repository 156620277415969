import React from 'react';
import { Button, ButtonProps } from './Button';
import { useFormikContext } from 'formik';

const FormikButton: React.FC<ButtonProps> = ({
  children,
  disabled,
  ...props
}) => {
  const formik = useFormikContext();
  return (
    <Button {...props} disabled={disabled || formik.isSubmitting}>
      {children}
    </Button>
  );
};

export default FormikButton;
