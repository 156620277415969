import React from 'react';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { IamPartner } from '../../../api/partnerUsers';
import partnerRoleToLabel from '../../../utils/partnerRoleToLabel';
import { Link } from 'react-router-dom';
import PartnerDeleteButton from './PartnerDeleteButton';
import SimpleReactTable from '../../Tables/SimpleReactTable';
import useOrgConfigQuery from '../../../hooks/query/useOrgConfigQuery';

interface Props {
  users: IamPartner[];
}

const PartnerUsersTable: React.FC<Props> = ({ users }) => {
  const { data: orgConfig } = useOrgConfigQuery();

  const table = useReactTable({
    columns: [
      orgConfig?.trade_in_experience === 'DEFAULT'
        ? {
            accessorFn: user => user.email,
            id: 'email',
            header: 'Email',
            enableSorting: true,
          }
        : {
            accessorFn: user => user.federated_id,
            id: 'federated_id',
            header: 'ID',
            enableSorting: true,
          },
      {
        accessorFn: user =>
          user.first_name && user.last_name
            ? `${user.last_name}, ${user.first_name}`
            : '',
        header: 'Name',
        enableSorting: true,
      },
      {
        accessorFn: user => user.role,
        header: 'Role',
        cell: info =>
          partnerRoleToLabel(info.getValue()) || (
            <i className="text-sm">No role</i>
          ),
      },
      {
        accessorFn: user => user.org_name,
        header: 'Organization',
        enableSorting: true,
      },
      {
        id: 'actions',
        header: () => <span className="sr-only">Actions</span>,
        enableSorting: false,
        cell: info => (
          <div className="flex space-x-4">
            <Link
              to={`./${info.row.original.uuid}`}
              className="font-semibold text-green-500"
            >
              Edit
            </Link>

            <PartnerDeleteButton user={info.row.original} />
          </div>
        ),
      },
    ],
    data: users,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return <SimpleReactTable table={table} />;
};

export default PartnerUsersTable;
