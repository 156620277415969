import React, { useMemo } from 'react';
import { useEventCustomerQuoteQuery } from '../../hooks/routing/useEventCustomerQuoteQuery';
import BackArrowLink from '../BackArrowLink';

interface Props {
  to: 'home';
}

const TradeInBreadcrumbLink: React.FC<Props> = ({ to }) => {
  const { quoteId, eventId, customerId } = useEventCustomerQuoteQuery();
  const { url, label } = useMemo<{ url: string; label: string }>(() => {
    switch (to) {
      case 'home':
        return { url: `/event/${eventId}`, label: 'Home' };
    }
  }, [to, eventId]);

  return <BackArrowLink to={url}>{label}</BackArrowLink>;
};

export default TradeInBreadcrumbLink;
