import axios from 'axios';
import { ApiResponse } from './intf/generic';
import { getBaseUrl } from './axiosInitialization';

export interface Partner {
  id: string;
  name: string;
  addresses?: PartnerAddress[];
}

export interface PartnerAddress {
  id: string;
  store_number?: string;
  address_1: string;
  address_2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

interface CreatePartnerBody {
  name: string;
}

type CreatePartnerLocationBody = Omit<PartnerAddress, 'id'>;

export const fetchPartners = async () => {
  const { data } = await axios.get<ApiResponse<Partner[]>>(
    '/trade-in/v1/partners',
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch partners error');
};

export const fetchPartnerById = async (id: string) => {
  const { data } = await axios.get<ApiResponse<Partner>>(
    `/trade-in/v1/partners/${id}`,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error(`Failed to fetch partner by ID "${id}"`);
};

export const postCreatePartner = async (body: CreatePartnerBody) => {
  const { data } = await axios.post<ApiResponse<Partner>>(
    `/trade-in/v1/partners`,
    body,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Critically failed trying to create a partner');
};

export const postCreatePartnerLocation = async (
  partnerId: string,
  body: CreatePartnerLocationBody,
) => {
  const { data } = await axios.post<ApiResponse<PartnerAddress>>(
    `/trade-in/v1/partners/${partnerId}/address`,
    body,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Critically failed trying to create a partner location');
};
