import React, { useState } from 'react';
import ConfirmationModal from '../../Modal/ConfirmationModal';
import { useMutation, useQueryClient } from 'react-query';
import { deleteIamPartnerUser, IamPartner } from '../../../api/partnerUsers';
import useOrgId from '../../../hooks/useOrgId';
import {
  fireClickedDeleteUser,
  fireDeletedUser,
} from '../../../services/analytics/partner/manageUsers';

interface Props {
  user: IamPartner;
}

const PartnerDeleteButton: React.FC<Props> = ({ user }) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const orgId = useOrgId();
  const { mutate: deleteUser } = useMutation(
    () => deleteIamPartnerUser(orgId!, user),
    {
      onSuccess: data => {
        queryClient.invalidateQueries();
        setOpen(false);
        fireDeletedUser(data);
      },
    },
  );

  const handleOpenModal = () => {
    setOpen(true);
    fireClickedDeleteUser();
  };

  return (
    <>
      <button onClick={handleOpenModal} className="font-semibold text-red-500">
        Delete
      </button>
      <ConfirmationModal
        handleOK={() => deleteUser()}
        okText="Yes"
        title="Delete user"
        description={`Are you sure you want to delete ${
          user.first_name || 'this'
        } ${user.last_name || 'user'}?`}
        okProps={{ color: 'danger' }}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
};

export default PartnerDeleteButton;
