import React, { useState } from 'react';
import classNames from 'classnames';
import { ScanHistory } from '../../../types/spawnScanning';
import ScannedItemInfoModal from './ScannedItemInfoModal';
import { ReceiveResponse } from '../../../api/spawn';

interface Props {
  scanHistory: ScanHistory[];
}

const ScanHistoryTable: React.FC<Props> = ({ scanHistory }) => {
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [itemInfo, setItemInfo] = useState<ReceiveResponse | undefined>(
    undefined,
  );

  if (scanHistory.length === 0) {
    return (
      <div>
        <p>No items scanned</p>
      </div>
    );
  }

  return (
    <>
      <table className="w-full">
        <thead>
          <tr>
            <th className="text-left">Time</th>
            <th className="text-left">Item ID</th>
            <th className="text-left">Status</th>
          </tr>
        </thead>
        <tbody>
          {scanHistory
            .slice()
            .reverse()
            .map(history => (
              <tr
                key={history.createdAt.toString() + history.data}
                onClick={() => {
                  setItemInfo(history.itemInfo);
                  setInfoModalOpen(true);
                }}
              >
                <td className="text-left">
                  {new Date(history.createdAt).toLocaleTimeString()}
                </td>
                <td className="text-left">
                  {history.data
                    .replace('https://sidelineswap.com/items/', '')
                    .replace('https://staging.sidelineswap.com/items/', '')}
                </td>
                <td
                  className={classNames(
                    'text-left',
                    history.status === 'success' &&
                      'font-semibold text-green-500',
                    history.status === 'error' && 'font-semibold text-red-500',
                    history.status === 'loading' &&
                      'font-semibold text-slate-green-500',
                  )}
                >
                  {history.status}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <ScannedItemInfoModal
        itemInfo={itemInfo}
        open={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
      />
    </>
  );
};

export default ScanHistoryTable;
