import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchTransferById } from '../../../api/partnerTransfers';
import PageLayout from '../../../components/Layout/PageLayout';
import TransferShipmentsTable from '../../../components/Partner/TransferShipmentsTable';
import { Button } from '../../../components/Form/Button';
import BackArrowLink from '../../../components/BackArrowLink';

interface Props {}

const PartnerDashboardTransferByIdSuccessCreated: React.FC<Props> = () => {
  const { transferId } = useParams<{ transferId: string }>();
  const { data: transfer, error: transferError } = useQuery(
    ['transfer', transferId],
    () => fetchTransferById(transferId!),
  );

  return (
    <PageLayout
      header={
        <>
          <BackArrowLink to="/partner/dashboard/transfers">
            Back to Shipments
          </BackArrowLink>
        </>
      }
    >
      <div className="mb-8">
        <h1 className="text-2xl font-semibold">Success!</h1>
        <p className="mb-4">
          Your shipping labels and packing slip have been created. Please review
          and print them below.
        </p>

        <div className="flex items-center space-x-4">
          <Link to={`/partner/dashboard/transfers/${transfer?.id}/print`}>
            <Button className="flex items-center space-x-3">
              <span>View Packing List</span>
            </Button>
          </Link>
        </div>
      </div>

      {transfer && (
        <TransferShipmentsTable
          transferShipments={transfer.shipments}
          transferId={transfer.id}
        />
      )}
    </PageLayout>
  );
};

export default PartnerDashboardTransferByIdSuccessCreated;
