import React from 'react';
import PageLayout from '../../../components/Layout/PageLayout';
import PageHeader from '../../../components/Layout/PageHeader';
import PartnerManageUserForm from '../../../components/Partner/ManageUsers/PartnerManageUserForm';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import FormikSubmitButton from '../../../components/Form/FormikSubmitButton';
import { fireCreatedUser } from '../../../services/analytics/partner/manageUsers';

interface Props {}

const PartnerAdminNewUserPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return (
    <PageLayout
      header={
        <>
          <PageHeader>New User</PageHeader>
        </>
      }
    >
      <PartnerManageUserForm
        onSuccessfulSubmit={async user => {
          await queryClient.invalidateQueries();
          navigate('/partner/manage-users');

          fireCreatedUser(user);
        }}
      >
        <FormikSubmitButton>Create</FormikSubmitButton>
      </PartnerManageUserForm>
    </PageLayout>
  );
};

export default PartnerAdminNewUserPage;
