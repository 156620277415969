import { useQuery, UseQueryOptions } from 'react-query';
import { fetchEventById, TradeInEvent } from '../../api/events';

export default function useEventQuery(
  eventId?: string,
  options?: UseQueryOptions<TradeInEvent, any, TradeInEvent, any>,
) {
  return useQuery(['event', eventId], () => fetchEventById(eventId!), {
    enabled: !!eventId,
  });
}
