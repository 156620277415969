import React from 'react';
import SimpleHeadlessCombobox from '../../Form/Select/SimpleHeadlessCombobox';
import AuditFieldDifference from './AuditFieldDifference';
import AuditModelVersionSelector from './AuditModelVersionSelector';
import Field from '../../Form/Field';
import FieldLabel from '../../Form/FieldLabel';
import displayPrice from '../../../utils/displayPrice';
import { Quality, QUALITY_LABELS } from '../../../constants/valueMatrix';
import FormikNumberField from '../../Form/FormikNumberField';
import FormikErrorMessage from '../../Form/FormikErrorMessage';
import FormikSubmitButton from '../../Form/FormikSubmitButton';
import { Form, useFormikContext } from 'formik';
import { warehouseAuditSchema } from '../../../pages/warehouse/audit/warehouseAuditItemDetailsPage';
import { z } from 'zod';
import { Category, FullCategory } from '../../../api/intf/item';
import { PartnerTransferItem } from '../../../api/partnerTransfers';
import { StringHeadlessSelect } from '../../Form/Select/StringHeadlessSelect';
import {
  getCashOfferAmountForModelVersionAndQuality,
  getListPriceForModelVersionAndQuality,
} from '../../../utils/modelVersionValues';
import FormikFieldError from '../../Form/FormikFieldError';
import { useSearchParams } from 'react-router-dom';

interface Props {
  allCategories: Category[];
  fullCategory: FullCategory;
  transferItem: PartnerTransferItem;
}

const AuditForm: React.FC<Props> = ({
  allCategories,
  fullCategory,
  transferItem,
}) => {
  const [searchParams] = useSearchParams();
  const { errors, values, setValues } =
    useFormikContext<z.input<typeof warehouseAuditSchema>>();

  const transferId = searchParams.get('transfer_id');

  return (
    <Form>
      <div className="grid grid-cols-2 gap-4">
        <SimpleHeadlessCombobox
          label="Category"
          items={allCategories || []}
          onChange={newCategory => {
            setValues({
              ...values,
              categoryId: newCategory?.legacy_id,
              brandId: undefined,
              modelId: undefined,
              modelVersionUuid: undefined,
              details: {},
            });
          }}
          value={allCategories?.find(
            category => category.legacy_id === values.categoryId,
          )}
          itemToString={item => item?.full_name || ''}
          filterItem={(item, inputValue) =>
            item.full_name.toLowerCase().includes(inputValue.toLowerCase())
          }
          minimumInputLength={3}
          renderItem={item => item?.full_name || ''}
        />
        <AuditFieldDifference
          formField="categoryId"
          originalValue={transferItem.original_data.category_id}
          format={id => allCategories?.find(c => c.legacy_id === id)?.full_name}
        />

        <SimpleHeadlessCombobox
          label="Brand"
          items={fullCategory?.brands || []}
          onChange={newBrand => {
            setValues({
              ...values,
              brandId: newBrand?.legacy_id,
              modelId: undefined,
            });
          }}
          value={fullCategory?.brands?.find(
            brand => brand.legacy_id === values.brandId,
          )}
          itemToString={item => item?.name || ''}
          filterItem={(item, inputValue) =>
            item.name.toLowerCase().includes(inputValue.toLowerCase())
          }
          renderItem={item => item?.name || ''}
        />
        <AuditFieldDifference
          formField="brandId"
          originalValue={transferItem.original_data.brand_id}
          format={id =>
            fullCategory?.brands?.find(c => c.legacy_id === id)?.name
          }
        />

        <SimpleHeadlessCombobox
          label="Model"
          items={fullCategory?.models || []}
          onChange={newModel => {
            setValues({
              ...values,
              modelId: newModel?.legacy_id,
            });
          }}
          value={fullCategory?.models?.find(
            model => model.legacy_id === values.modelId,
          )}
          itemToString={item => item?.name || ''}
          filterItem={(item, inputValue) =>
            item.name.toLowerCase().includes(inputValue.toLowerCase())
          }
          renderItem={item => item?.name || ''}
        />
        <AuditFieldDifference
          formField="modelId"
          originalValue={transferItem.original_data.model_id}
          format={id =>
            fullCategory?.models?.find(c => c.legacy_id === id)?.name
          }
        />

        <AuditModelVersionSelector
          item={transferItem}
          fullCategory={fullCategory}
        />

        {fullCategory?.details?.map(detailGroup => {
          const detailGroupId = detailGroup.legacy_id;
          const selectedDetailId = values.details[detailGroupId];
          const selectedDetail = detailGroup?.options?.find(
            d => d.legacy_id === selectedDetailId,
          );

          if (!detailGroup) {
            return null;
          }

          return (
            <React.Fragment key={detailGroupId}>
              <SimpleHeadlessCombobox
                label={detailGroup?.name}
                items={detailGroup.options || []}
                clearable={!detailGroup.required}
                className={
                  selectedDetail ? undefined : 'ring-4 ring-yellow-500/70'
                }
                onChange={newDetail => {
                  if (newDetail?.id) {
                    setValues({
                      ...values,
                      details: {
                        ...values.details,
                        [detailGroupId]: newDetail.legacy_id,
                      },
                    });
                  } else {
                    setValues({
                      ...values,
                      details: {
                        ...values.details,
                        [detailGroupId]: undefined,
                      },
                    });
                  }
                }}
                value={selectedDetail}
                itemToString={item => item?.name || ''}
                filterItem={(item, inputValue) =>
                  item.name.toLowerCase().includes(inputValue.toLowerCase())
                }
                renderItem={item => item?.name || ''}
              />
              <AuditFieldDifference
                // @ts-ignore
                formField={`details.${detailGroupId}`}
                schema={warehouseAuditSchema.shape.details.valueSchema}
                originalValue={
                  transferItem.original_data.details?.find(
                    d => d.type_id === detailGroupId,
                  )?.option_id
                }
                format={id =>
                  detailGroup?.options?.find(d => id === d.legacy_id)?.name
                }
              />
            </React.Fragment>
          );
        })}

        <Field>
          <FieldLabel>Cash Offer Amount</FieldLabel>
          {transferId ? (
            <FormikNumberField prefix="$" name="cashOfferAmount" />
          ) : (
            displayPrice(values.cashOfferAmount)
          )}
        </Field>
        <AuditFieldDifference
          formField="cashOfferAmount"
          originalValue={transferItem.original_data.cash_offer_amount}
          format={displayPrice}
        />

        <Field>
          <StringHeadlessSelect
            error={!!errors?.quality}
            label="Quality"
            items={Object.entries(QUALITY_LABELS).map(([value, label]) => ({
              value,
              label,
            }))}
            className={values.quality ? undefined : 'ring-4 ring-yellow-500/70'}
            onChange={newValue => {
              const newQuality = newValue as Quality | null;

              if (!newQuality) {
                setValues({
                  ...values,
                  quality: undefined,
                });
              } else if (!values.modelVersion) {
                setValues({
                  ...values,
                  quality: newQuality,
                });
              } else if (newQuality) {
                setValues({
                  ...values,
                  quality: newQuality,
                  cashOfferAmount: getCashOfferAmountForModelVersionAndQuality(
                    newQuality,
                    values.modelVersion,
                  ),
                  resaleAmount: getListPriceForModelVersionAndQuality(
                    newQuality,
                    values.modelVersion,
                  ),
                });
              }
            }}
            value={values.quality}
          />

          <FormikFieldError name="quality" />
        </Field>
        <AuditFieldDifference
          formField="quality"
          originalValue={transferItem.original_data.quality}
        />

        <FormikNumberField prefix="$" name="resaleAmount" label="List Price" />
        <AuditFieldDifference
          formField="resaleAmount"
          originalValue={transferItem.original_data.resale_amount}
          format={displayPrice}
        />

        <Field>
          <FieldLabel>Name</FieldLabel>
          {values.name}
        </Field>

        <AuditFieldDifference
          formField="name"
          originalValue={transferItem.original_data.name}
        />
      </div>

      <FormikErrorMessage showFieldErrors />

      <div className="flex justify-end space-x-4">
        <FormikSubmitButton>Accept</FormikSubmitButton>
      </div>
    </Form>
  );
};

export default AuditForm;
