import React, { useEffect, useState } from 'react';
import Input from '../../Form/Input';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useSearchParams } from 'react-router-dom';
import useDebounceValue from '../../../hooks/useDebounceValue';

interface Props {}

const WarehouseInboxSearch: React.FC<Props> = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState(() => searchParams.get('q') || '');
  const debouncedSearch = useDebounceValue(search, 200);

  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    if (debouncedSearch) {
      newParams.set('q', debouncedSearch);
      newParams.set('scope', 'ship_now');
      newParams.delete('page');
      newParams.delete('filter');
    } else {
      newParams.delete('q');
    }

    setSearchParams(newParams);
  }, [debouncedSearch]);

  useEffect(() => {
    if (searchParams.get('q') !== debouncedSearch) {
      setSearch(searchParams.get('q') || '');
    }
  }, [searchParams]);

  return (
    <div className="relative flex-1">
      <Input
        className="pl-10"
        name="q"
        placeholder="Search..."
        value={search}
        onChange={e => setSearch(e.target.value)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            e.preventDefault();
            setSearch(e.currentTarget.value);
          }
        }}
      />

      <MagnifyingGlassIcon className="absolute top-2 left-2 h-6 w-6 text-slate-green-500" />
    </div>
  );
};

export default WarehouseInboxSearch;
