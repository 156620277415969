import React from 'react';
import useCurrentWarehouseQuery from '../../../hooks/warehouse/useCurrentWarehouseQuery';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import PageHeader from '../../../components/Layout/PageHeader';
import PageSubheader from '../../../components/Layout/PageSubheader';
import PageLayout from '../../../components/Layout/PageLayout';
import { useQuery } from 'react-query';
import { fetchUnshippedOrders } from '../../../api/picklists';
import Loader from '../../../components/Form/Loader';
import PicklistTable from '../../../components/Warehouse/Picklists/PicklistTable';
import WarehouseBreadcrumbLink from '../../../components/Warehouse/WarehouseBreadcrumbLink';

interface Props {}

const WarehouseUnshippedOrdersPage: React.FC<Props> = () => {
  const { data: warehouse } = useCurrentWarehouseQuery();
  const warehouseId = useCurrentWarehouseId()!;

  const { data: unshippedOrders, isLoading } = useQuery(
    ['unshipped-orders', warehouseId],
    () => fetchUnshippedOrders(warehouseId),
  );

  return (
    <PageLayout
      header={
        <>
          <WarehouseBreadcrumbLink to="picklist" />
          <PageHeader>Warehouse - {warehouse?.facility}</PageHeader>
          <PageSubheader>Unshipped Orders</PageSubheader>
        </>
      }
    >
      <Loader loading={isLoading}>
        <PicklistTable
          picklistItems={unshippedOrders}
          warehouseId={warehouseId}
        />
      </Loader>
    </PageLayout>
  );
};

export default WarehouseUnshippedOrdersPage;
