export default function partnerRoleToLabel(role: string): string | null {
  switch (role) {
    case 'ROLE_TRADE_IN_DISTRICT_MANAGER':
      return 'District Manager';

    case 'ROLE_TRADE_IN_STORE_MANAGER':
      return 'Store Manager';

    case 'ROLE_TRADE_IN_STORE_EMPLOYEE':
      return 'Store Employee';

    default:
      return null;
  }
}
