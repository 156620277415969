import { ValueGuideTimeToSell } from '../api/intf/item';
import thousandsSeparator from './thousandsSeparator';

export default function formatTimeToSell(timeToSell: ValueGuideTimeToSell) {
  return `${
    thousandsSeparator(
      (timeToSell.dom_0_30 / timeToSell.total_items) * 100,
      1,
    ) || '--'
  }% / ${
    thousandsSeparator(
      (timeToSell.dom_30_90 / timeToSell.total_items) * 100,
      1,
    ) || '--'
  }% / ${
    thousandsSeparator((timeToSell.dom_90 / timeToSell.total_items) * 100, 1) ||
    '--'
  }%`;
}
