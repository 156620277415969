import axios from 'axios';
import { ApiResponse } from './intf/generic';
import { getBaseUrl } from './axiosInitialization';
import { Warehouse } from './intf/warehouse';
import {
  WarehouseSwap,
  WarehouseSwapsCount,
  WarehouseSwapsMeta,
} from './intf/warehouseSwap';
import { getCookie } from '../lib/KnownCookies';
import { RailsItem, RailsSwapDetail } from './intf/railsSwap';
import { ListingItem } from './intf/item';
import { ReceiveResponse } from './spawn';

export const fetchWarehouses = async () => {
  const { data } = await axios.get<ApiResponse<Warehouse[]>>(
    '/warehouse/v1/warehouses',
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data) {
    return data.data;
  }

  throw new Error('Could not fetch all warehouses');
};

export const fetchWarehouseById = async (warehouseId: string) => {
  const warehouses = await fetchWarehouses();

  const foundWarehouse = warehouses.find(
    warehouse => warehouse.id === warehouseId,
  );

  if (foundWarehouse) {
    return foundWarehouse;
  }

  throw new Error(`Could not find a warehouse with ID "${warehouseId}"`);
};

export const fetchWarehouseSwapCounts = async (warehouseId: string) => {
  const { data } = await axios.get<
    ApiResponse<{ counts: WarehouseSwapsCount[] }>
  >('/warehouse/v1/swaps/counts', {
    baseURL: getBaseUrl('inventoryApi'),
    params: {
      warehouse_id: warehouseId,
    },
  });

  if (data) {
    return data.data?.counts ?? [];
  }

  throw new Error('Could not fetch warehouse counts');
};

export interface WarehouseSwapFilteringOptions {
  scope?: string;
  keyword?: string;
  page?: string;
  filters?: string[];
  pageSize?: number;
}

export const fetchWarehouseSwaps = async (
  warehouseId: string,
  options: WarehouseSwapFilteringOptions = {
    scope: 'all',
    filters: [],
  },
) => {
  const pageSize =
    options.pageSize || getCookie('slsti_warehouse_page_size') || 20;

  const data = await axios.get<
    ApiResponse<WarehouseSwap[] | undefined, WarehouseSwapsMeta>
  >('/warehouse/v1/swaps', {
    baseURL: getBaseUrl('inventoryApi'),
    params: {
      q: options.keyword,
      page: options.page,
      scope: options.scope,
      warehouse_id: warehouseId,
      filters:
        options.filters && options.filters.length > 0
          ? options.filters.join(',')
          : undefined,
      size: pageSize,
    },
  });

  if (data) {
    return data.data;
  }

  throw new Error('Failed to get warehouse swaps');
};

export const putFireSwapAction = async (
  swapId: string | number, // legacy ID or new ID
  action: string,
  body?: any,
) => {
  const response = await axios.put<ApiResponse<RailsSwapDetail>>(
    `/warehouse/v1/swaps/${swapId}/actions/${action}`,
    body,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  return response.data?.data;
};

export const putPrintSwapLabel = async (swapId: string | number) => {
  return await putFireSwapAction(swapId, 'print_label');
};

export const fetchWarehouseItemById = async (id: string | number) => {
  const response = await axios.get<
    ApiResponse<{
      id: string;
      quote_listing: ListingItem;
      warehouse_item: ReceiveResponse;
    }>
  >(`/trade-in/v1/items/${id}`, {
    baseURL: getBaseUrl('inventoryApi'),
  });

  return response.data?.data;
};

export const fetchRailsItemById = async (id: string | number) => {
  const response = await axios.get<ApiResponse<RailsItem>>(`/v1/items/${id}`);

  return response.data?.data;
};

export const fetchWarehouseItemOrRailsItemById = async (
  id: string | number,
) => {
  try {
    const response = await axios.get<
      ApiResponse<{
        id: string;
        quote_listing: ListingItem;
        warehouse_item: ReceiveResponse;
      }>
    >(`/trade-in/v1/items/${id}`, {
      baseURL: getBaseUrl('inventoryApi'),
    });

    return response.data?.data;
  } catch (e) {
    return fetchRailsItemById(id);
  }
};
