import { ValueGuideMeta } from '../api/intf/item';
import { Demand, DEMAND_LABELS, Quality } from '../constants/valueMatrix';
import { determineDemand } from './costCalculations';
import { getSellThroughRate } from './getSellThroughRate';

export const SELL_THROUGH_RATE_HIGH = 70;

type ShouldBuy = Demand | 'do_not_buy' | 'insufficient_sales_data' | null;

export function doNotBuyLabel(shouldBuy: ShouldBuy) {
  if (shouldBuy === 'do_not_buy') {
    return 'Suggestion: Do Not Buy';
  } else if (shouldBuy === 'insufficient_sales_data') {
    return 'Insufficient Sales Data';
  } else if (shouldBuy) {
    return `Suggestion: ${DEMAND_LABELS[shouldBuy]}`;
  } else {
    return null;
  }
}

export default function determineShouldBuyItem(
  quality: Quality,
  valueGuidePrice?: number,
  valueGuide?: ValueGuideMeta,
): ShouldBuy {
  if (!valueGuide || !valueGuide.statistics || !valueGuide.recent_sales) {
    return 'insufficient_sales_data';
  }

  const avgResalePrice = valueGuidePrice || valueGuide.statistics?.median || 0;
  const demand = determineDemand(valueGuide);
  const str = getSellThroughRate(valueGuide);

  if (str >= SELL_THROUGH_RATE_HIGH) {
    return 'high';
  }

  if (avgResalePrice < 30) {
    return 'do_not_buy';
  }

  if (demand) {
    return demand;
  }

  return null;
}
