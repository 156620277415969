import React from 'react';
import { Field as FormikField, FieldProps, Form } from 'formik';
import Field from '../Form/Field';
import FieldLabel from '../Form/FieldLabel';
import TagInput from './TagInput';
import FormikFieldError from '../Form/FormikFieldError';
import FormikErrorMessage from '../Form/FormikErrorMessage';
import { Button } from '../Form/Button';
import FormikSubmitButton from '../Form/FormikSubmitButton';
import { z } from 'zod';
import { formatFormikError } from '../../api/ApiError';
import FormikZodSchema from '../Form/FormikZodSchema';

const schema = z.object({
  tag: z
    .string({ required_error: 'Required' })
    .regex(/\d{4}-?\d{4}/)
    .transform(v => v.replace(/\s|-/g, '')),
});

type FormValues = z.infer<typeof schema>;

interface Props {
  initialTag?: string;
  onCancel: () => void;
  onError?: (error: Error, tag: string) => void;
  onSubmit: (tag: string) => void;
}

const IsolatedTagForm = React.forwardRef<HTMLInputElement, Props>(
  ({ initialTag, onCancel, onError, onSubmit }, ref) => (
    <FormikZodSchema
      schema={schema}
      initialValues={{ tag: initialTag || '' }}
      onSubmit={async (values, formikHelpers) => {
        try {
          await onSubmit(values.tag);
        } catch (e) {
          onError?.(e as Error, values.tag);

          formikHelpers.setErrors(
            formatFormikError(e, {
              defaultMessage: 'Something went wrong...',
            }),
          );
        }
      }}
    >
      <Form className="space-y-4">
        <FormikField name="tag">
          {(props: FieldProps<'tag', FormValues>) => (
            <Field>
              <FieldLabel error={props.meta.error}>Tag</FieldLabel>
              <TagInput
                error={props.meta.error}
                ref={ref}
                {...props.field}
                disabled={props.form.isSubmitting}
              />
              <FormikFieldError name="tag" />
            </Field>
          )}
        </FormikField>

        <FormikErrorMessage />

        <div className="grid grid-cols-2 gap-4">
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
          <FormikSubmitButton fluid>Submit</FormikSubmitButton>
        </div>
      </Form>
    </FormikZodSchema>
  ),
);

export default IsolatedTagForm;
