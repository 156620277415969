import React from 'react';
import { Field, FieldProps as FormikFieldProps } from 'formik';
import NativeSelect from '../Select/NativeSelect';

export type FormikSelectFieldProps =
  React.SelectHTMLAttributes<HTMLSelectElement>;

const FormikSelectField: React.FC<FormikSelectFieldProps> = ({
  className,
  children,
  id,
  name,
  placeholder,
  ...props
}) => (
  <Field name={name}>
    {({ field, meta }: FormikFieldProps) => (
      <NativeSelect
        {...field}
        {...props}
        className={className}
        id={id || name}
        name={name}
        error={!!meta.error}
        placeholder={placeholder}
      >
        {children}
      </NativeSelect>
    )}
  </Field>
);

export default FormikSelectField;
