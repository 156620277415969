import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import useMobile from '../../hooks/useMobile';
import Input from '../Form/Input';
import { Button } from '../Form/Button';
import {
  TradeInDetailOption,
  TradeInOption,
  TradeInSelection,
} from '../../types/detailSelection';

interface RenderOptionProps {
  alwaysShowText?: boolean;
  disabled?: boolean;
  id: string;
  number: number;
  option: TradeInOption | TradeInDetailOption;
  getSubtitle?: (selection: TradeInSelection) => string | undefined;
  onSelect: (selection: TradeInSelection) => void;
  onSkip?: () => void;
  searchable?: boolean;
  skippable?: boolean;
  type?: 'brand';
}

const RenderOption: React.FC<RenderOptionProps> = ({
  alwaysShowText,
  disabled,
  id,
  number,
  option,
  onSelect,
  onSkip,
  searchable,
  skippable,
  getSubtitle,
  type,
}) => {
  const mobile = useMobile();
  const DISPLAYED_PER_PAGING = mobile ? 8 : 12;

  const [search, setSearch] = useState('');
  const [displayedMaximum, setDisplayedMaximum] =
    useState(DISPLAYED_PER_PAGING);

  const filteredOptions = useMemo<
    (TradeInSelection | { id: number; placeholder: boolean })[]
  >(() => {
    const sliced = option.values.slice(0, displayedMaximum);

    if (!searchable || search.length === 0) {
      if (type === 'brand') {
        // Split into one group with images and another without images in a for..i loop
        const withImages: TradeInSelection[] = [];
        const withoutImages: TradeInSelection[] = [];

        const hasAnyImages = option.values.some(value => value.image_url);

        if (hasAnyImages && DISPLAYED_PER_PAGING == displayedMaximum) {
          // not expanded yet, only show images
          for (let i = 0; i < option.values.length; i++) {
            if (option.values[i].image_url) {
              withImages.push(option.values[i]);
            }
          }
        } else {
          for (let i = 0; i < option.values.length; i++) {
            if (option.values[i].image_url) {
              withImages.push(option.values[i]);
            } else {
              withoutImages.push(option.values[i]);
            }
          }
        }

        return [...withImages, ...withoutImages].slice(0, displayedMaximum);
      } else {
        return sliced;
      }
    }

    const searchTerm = search.toLowerCase();
    const filtered = option.values.filter(value =>
      value.name.toLowerCase().includes(searchTerm),
    );

    const placeholders = Array.from(
      { length: (sliced.length - filtered.length) / 2 },
      (_, i) => ({
        id: i,
        placeholder: true,
      }),
    );

    return [...filtered, ...placeholders];
  }, [option.values, search, searchable, displayedMaximum]);

  function handleClickShowMore() {
    // If less than half of the options are shown, show the full page (+ 2 so non-images show up, see filteredOptions brand filtering)
    if (filteredOptions.length < DISPLAYED_PER_PAGING / 2) {
      setDisplayedMaximum(DISPLAYED_PER_PAGING + 2);
    } else {
      setDisplayedMaximum(prev => prev + DISPLAYED_PER_PAGING);
    }
  }

  useEffect(() => {
    setDisplayedMaximum(DISPLAYED_PER_PAGING);
    setSearch('');
  }, [option.values.length]);

  return (
    <div id={id}>
      <div className="mb-4 -mt-20 flex items-center space-x-4 pt-20 text-xl font-semibold">
        <span>
          {number}. {option.title}
        </span>
        {skippable && (
          <button
            onClick={onSkip}
            className="text-base font-semibold underline"
            type="button"
          >
            Skip
          </button>
        )}
      </div>

      {searchable && (
        <div className="mb-4">
          <Input
            autoFocus
            aria-label={`Search ${number}`}
            placeholder="Search..."
            type="search"
            value={search}
            onChange={e => setSearch(e.target.value)}
            // leftAdornment={<MagnifyingGlassIcon className="ml-2 h-6 w-6" />}
          />
        </div>
      )}

      <div
        className={classNames(
          'grid gap-4',
          mobile ? 'grid-cols-1' : 'grid-cols-2 md:grid-cols-4 xl:grid-cols-4',
        )}
      >
        {filteredOptions.map(selection => {
          if ('placeholder' in selection) {
            return <div key={selection.id} className="h-14"></div>;
          }

          const subtitle = getSubtitle?.(selection);

          return (
            <button
              key={selection.id}
              disabled={disabled}
              className={classNames(
                'rounded border font-semibold transition duration-200',
                {
                  'border-slate-green-500':
                    option.selected !== 'SKIP' &&
                    option.selected?.id === selection.id,
                  'border-grey-200':
                    option.selected !== 'SKIP' &&
                    option.selected?.id !== selection.id,
                  'cursor-not-allowed bg-grey-100 text-slate-green-500':
                    disabled,
                },
              )}
              onClick={() => onSelect(selection)}
            >
              {selection.image_url && (
                <span className="block p-4">
                  <img
                    className="block h-32 w-full object-contain"
                    src={selection.image_url}
                    alt={selection.name}
                  />
                </span>
              )}
              {alwaysShowText || !selection.image_url ? (
                <>
                  <span className="block p-4 text-center">
                    {selection.name}
                  </span>

                  {subtitle && (
                    <span className="-mt-3 block pb-2 text-center text-sm font-normal text-slate-green-500">
                      {subtitle}
                    </span>
                  )}
                </>
              ) : null}
            </button>
          );
        })}
      </div>

      {search.length == 0 && option.values.length > displayedMaximum && (
        <div className="mt-4 text-center">
          <Button onClick={handleClickShowMore}>Show More</Button>
        </div>
      )}
    </div>
  );
};

export default RenderOption;
