import React, { useRef } from 'react';
import SimpleModal, { ModalProps } from './Modal/SimpleModal';
import { Button } from './Form/Button';

interface PrintListingsFormProps {
  eventId: string;
  quoteId: string;
}

export const PrintListingsForm: React.FC<
  React.PropsWithChildren<PrintListingsFormProps>
> = ({ children, eventId, quoteId }) => (
  <form action="/print" target="_blank">
    <input type="hidden" name="type" value="listing" />
    <input type="hidden" name="quote_id" value={quoteId} />
    <input type="hidden" name="event_id" value={eventId} />
    {children}
  </form>
);

interface Props extends ModalProps {
  eventId: string;
  quoteId: string;
}

const ListingsPrintModal: React.FC<Props> = ({
  eventId,
  quoteId,
  onClose,
  ...props
}) => {
  const initialFocus = useRef<HTMLButtonElement>(null);
  return (
    <SimpleModal
      className="w-full max-w-md"
      onClose={onClose}
      {...props}
      title="Quote Submitted"
      description="Would you like to print a label for each listing?"
      initialFocus={initialFocus}
    >
      <PrintListingsForm eventId={eventId} quoteId={quoteId}>
        <div className="mt-6 flex space-x-2">
          <Button variant="outlined" color="grey" fluid onClick={onClose}>
            Cancel
          </Button>

          <Button type="submit" fluid ref={initialFocus}>
            Print Labels
          </Button>
        </div>
      </PrintListingsForm>
    </SimpleModal>
  );
};

export default ListingsPrintModal;
