import React from 'react';

interface Props {}

const BaseLabel: React.FC<React.PropsWithChildren<Props>> = ({ children }) => (
  <div
    className="print-label-container relative m-8 flex items-center border"
    style={{
      // If you change these, change them in global.css
      height: `${1 - 0.05 * 2}in`,
      width: `${2.125 - 0.1 * 2}in`,
      padding: '0.08in 0.08in',
    }}
  >
    <div className="flex h-full">{children}</div>
  </div>
);

export default BaseLabel;
