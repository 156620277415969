import React, { useRef } from 'react';
import SimpleModal, { ModalProps } from '../Modal/SimpleModal';
import {
  Partner,
  PartnerAddress,
  postCreatePartnerLocation,
} from '../../api/partners';
import { Button } from '../Form/Button';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import FormikField from '../Form/FormikField';
import FormikErrorMessage from '../Form/FormikErrorMessage';
import { formatFormikError } from '../../api/ApiError';
import { RailsRegion } from '../../api/regions';
import FormikSubregionSearchableSelector from '../Form/FormikSubregionSearchableSelector';

interface LocationFormValues
  extends Omit<PartnerAddress, 'id' | 'state' | 'country'> {
  state: RailsRegion;
}

const schema: yup.SchemaOf<LocationFormValues> = yup.object({
  address_1: yup.string().required('Required'),
  address_2: yup.string(),
  city: yup.string().required('Required'),
  state: yup.object().shape({}).required('Required'),
  zip: yup.string().required('Required'),
  store_number: yup.string(),
});

interface Props extends ModalProps {
  partner: Partner;
  onSuccess?: (address: PartnerAddress) => void;
}

const CreatePartnerLocationModal: React.FC<Props> = ({
  onSuccess,
  partner,
  onClose,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <SimpleModal
      {...props}
      onClose={onClose}
      title="Create Partner Location"
      description={`Creating a new location for ${partner.name}`}
      className="w-full max-w-lg"
      initialFocus={inputRef}
    >
      <Formik
        initialValues={schema.getDefault() as LocationFormValues}
        onSubmit={async (values, formikHelpers) => {
          try {
            const location = await postCreatePartnerLocation(partner.id, {
              ...values,
              state: values.state.id,
              country: 'US',
            });
            onSuccess?.(location);
          } catch (e) {
            formikHelpers.setErrors(formatFormikError(e));
          }
        }}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {formikProps => (
          <Form className="space-y-4">
            <FormikField
              label="Store Number"
              name="store_number"
              placeholder="Store number (optional)"
            />

            <FormikField
              label="Store Location"
              name="address_1"
              placeholder="Address 1"
            />
            <FormikField name="address_2" placeholder="Address 2 (optional)" />

            <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
              <FormikField label="City" name="city" placeholder="City" />
              <FormikSubregionSearchableSelector name="state" />

              <FormikField label="Zip" name="zip" placeholder="Zip" />
            </div>

            <FormikErrorMessage />

            <div className="flex space-x-2 pt-4">
              <Button fluid variant="text" onClick={onClose}>
                Cancel
              </Button>

              <Button fluid type="submit" disabled={formikProps.isSubmitting}>
                Create
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </SimpleModal>
  );
};

export default CreatePartnerLocationModal;
