import { CreateItemFormValues } from '../components/ItemForms/CreateItemForm';
import { ValueGuideMeta } from '../api/intf/item';
import { Demand, Quality, VALUE_MARGIN_MATRIX } from '../constants/valueMatrix';
import { getSellThroughRate } from './getSellThroughRate';

const GROSS_MARGIN_MINIMUM = 13;

export function determineDemand(
  valueGuideResponse: ValueGuideMeta,
): Demand | null {
  const str = getSellThroughRate(valueGuideResponse);

  if (str >= 70) {
    return 'high';
  } else if (str >= 40 && str < 70) {
    return 'medium';
  } else {
    return 'low';
  }
}

// How much we're giving the customer for their gear. Should be multiplied by
// margin
export function getTradeInValues(
  resaleValue: number,
  quality: Quality,
  demand: Demand,
  valueGuideResponse: ValueGuideMeta,
) {
  const medianEstimatedPrice = valueGuideResponse.statistics?.median;
  const p30EstimatedPrice = valueGuideResponse.statistics?.p30;
  const p70EstimatedPrice = valueGuideResponse.statistics?.p70;

  if (
    !demand ||
    medianEstimatedPrice == null ||
    p30EstimatedPrice == null ||
    p70EstimatedPrice == null
  ) {
    return null;
  }

  const foundBucket = VALUE_MARGIN_MATRIX.find(
    bucket => resaleValue >= bucket.min && resaleValue <= bucket.max,
  );

  if (!foundBucket) {
    return null;
  }

  const margin = foundBucket.margin;

  return {
    lowTradeInValue: p30EstimatedPrice * margin[demand][quality],
    midTradeInValue: medianEstimatedPrice * margin[demand][quality],
    highTradeInValue: p70EstimatedPrice * margin[demand][quality],

    lowTradeInValueExcellent: p30EstimatedPrice * margin[demand]['excellent'],
    midTradeInValueExcellent:
      medianEstimatedPrice * margin[demand]['excellent'],
    highTradeInValueExcellent: p70EstimatedPrice * margin[demand]['excellent'],

    lowTradeInValueVeryGood: p30EstimatedPrice * margin[demand]['veryGood'],
    midTradeInValueVeryGood: medianEstimatedPrice * margin[demand]['veryGood'],
    highTradeInValueVeryGood: p70EstimatedPrice * margin[demand]['veryGood'],

    lowTradeInValueGood: p30EstimatedPrice * margin[demand]['good'],
    midTradeInValueGood: medianEstimatedPrice * margin[demand]['good'],
    highTradeInValueGood: p70EstimatedPrice * margin[demand]['good'],

    lowTradeInValueUnacceptable:
      p30EstimatedPrice * margin[demand]['unacceptable'],
    midTradeInValueUnacceptable:
      medianEstimatedPrice * margin[demand]['unacceptable'],
    highTradeInValueUnacceptable:
      p70EstimatedPrice * margin[demand]['unacceptable'],
  };
}

interface ResaleValues {
  lowResaleValue: number | undefined | '--';
  midResaleValue: number | undefined | '--';
  highResaleValue: number | undefined;
  cashOffer: number | undefined;
  error: false | string;
}

// How much we're selling the item on SidelineSwap for
export function getResaleValues({
  valueGuideResponse,
  values,
}: {
  values: CreateItemFormValues;
  valueGuideResponse?: ValueGuideMeta | null;
}): ResaleValues {
  const quality = values.quality;
  const listPrice = Number(values.resalePrice || '0') || 0;
  const userEnteredCashOffer = Number(values.cashoffer || '0') || 0;
  const demand = values.demand;

  if (!valueGuideResponse || !valueGuideResponse.statistics) {
    return {
      lowResaleValue: valueGuideResponse?.statistics?.p30 || '--',
      midResaleValue: valueGuideResponse?.statistics?.median || '--',
      highResaleValue: valueGuideResponse?.statistics?.p70,
      cashOffer: undefined,
      error: false,
    };
  }

  let lowResaleValue: number | undefined;
  let midResaleValue: number;
  let highResaleValue: number | undefined;
  let cashOffer: number | undefined;

  if (
    !values.valueGuideInput == null ||
    valueGuideResponse.statistics.median === values.valueGuideInput
  ) {
    lowResaleValue = valueGuideResponse.statistics.p30;
    midResaleValue = valueGuideResponse.statistics.median;
    highResaleValue = valueGuideResponse.statistics.p70;
  } else {
    midResaleValue = values.valueGuideInput ?? 0;
  }

  const foundBucket = VALUE_MARGIN_MATRIX.find(
    bucket => midResaleValue >= bucket.min && midResaleValue <= bucket.max,
  );

  if (foundBucket && demand && quality) {
    const valueMatrix = foundBucket.value[demand][quality];
    const marginMatrix = foundBucket.margin[demand][quality];

    if (lowResaleValue) {
      lowResaleValue *= valueMatrix;
    }

    if (midResaleValue) {
      midResaleValue *= valueMatrix;
    }

    if (lowResaleValue) {
      lowResaleValue *= valueMatrix;
    }

    if (listPrice || midResaleValue) {
      cashOffer = (listPrice || midResaleValue) * marginMatrix;
    }
  } else {
    cashOffer = listPrice;
  }

  let error: ResaleValues['error'] = false;

  if (
    quality !== 'unacceptable' &&
    listPrice > 0 &&
    userEnteredCashOffer > 0 &&
    listPrice - userEnteredCashOffer < GROSS_MARGIN_MINIMUM
  ) {
    error = 'Margin is too low, please update your cash offer or list price.';
  }

  return {
    lowResaleValue,
    midResaleValue,
    highResaleValue,
    cashOffer,
    error,
  };
}
