import React from 'react';

const LogoIcon = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 700 700"
    {...props}
  >
    <path
      fill="currentColor"
      d="M515.9 18.6A26 26 0 00498.7 43c-.1 9.6 2.1 13.4 16.9 28.2l12.6 12.6-187.3.1c-115.6 0-189.1.4-191.9 1a61.9 61.9 0 00-47.6 40.1l-2.8 7.5-.4 75.9c-.2 65.3-.1 76.5 1.2 81 6.7 22.6 38.3 24.4 48.8 2.7l2.2-4.6.1-72c.1-39.6.4-72.9.8-74 1.8-5.3-9.5-5 190.9-5 110.7-.1 185.4.3 185.1.8-.3.5-5.3 5.8-11.2 11.9-12.6 12.9-16.1 18.4-16.5 25.9a26.1 26.1 0 0044.5 20.5c1.9-1.8 12.6-12.9 23.9-24.6l32.4-33.5c13.7-13.8 16.6-18.7 16.6-27.9 0-10.9-1.2-12.4-41.8-52.8-32.6-32.4-37.2-36.7-41.6-38.2-5.9-1.9-11.5-2-17.7 0z"
    />
    <path
      fill="currentColor"
      d="M278.9 208.3a39.6 39.6 0 00-24.9 22.5c-3 6.2-7.1 20.3-8.4 28.7a273 273 0 00-.1 64.5c4 24.5 16.1 41.7 33.6 47.4 8.3 2.7 18.2 3.6 56.2 5.1l11.7.5v-10c0-5.5.4-10 .8-10a4008.2 4008.2 0 0043.7 17.5c20.4 8.1 39.2 15.7 41.8 16.9l4.8 2.1-6.8 2.9-19.5 8.5-61.5 26.7-3.3 1.4v-11c0-6-.3-11.1-.7-11.1-2.1-.3-96-.4-97-.1-1.2.4-1.1 27 .2 33.2a58.9 58.9 0 0012.4 22.9c5.7 5.8 15 10.8 23.1 12.4 5.7 1.2 132.5 1.5 140 .4 12.8-2 23-6.6 29.8-13.6 5.4-5.5 6-6.2 8.9-11.6a119.2 119.2 0 007.9-23.5 238.8 238.8 0 00-1.2-79.3 64.6 64.6 0 00-15.9-30.5c-5.7-5-13.8-9-21.7-10.8-1.3-.3-15.7-.7-32-.9-22.8-.3-29.8-.1-30 .8l-.3 8.7-.4 9.2c-.1 1.3-8.3-1.6-42.3-15.4L282 294.4l-3.5-1.2 2.5-1a2898.8 2898.8 0 0134.5-15l33.8-14.6a219 219 0 0120.6-8.2c.4.3.6 5 .5 10.5 0 5.6.2 10.2.5 10.2l48.8.3 48.2.1-.4-13.5c-.2-7.5-.9-15.8-1.6-18.5a68.4 68.4 0 00-10.3-21.7 48 48 0 00-25.8-14.2c-2-.3-35.2-.8-73.9-1.1l-70.4-.5-6.6 2.3z"
    />
    <path
      fill="currentColor"
      d="M581.3 381.2a28.6 28.6 0 00-14.5 13.8c-2.1 4.4-2.1 5.6-2.2 77.2l-.1 72.8-2.8 2.7-2.7 2.8H373c-102.3.1-186-.1-186-.2 0-.2 1.9-2.3 4.2-4.7 21.6-21.8 24.1-25.5 24.1-35.6 0-7.5-1.7-12.1-6.2-17.3-5.9-6.6-12-9.2-21.8-9-4.7.1-11.8 3.3-16 7.2-1.5 1.5-12.5 12.7-24.3 25a4863.4 4863.4 0 01-33.2 34.3 122.3 122.3 0 00-13.5 15.7 25.6 25.6 0 00-1.8 11.6c0 6.9.4 8.6 2.7 12.5 3.6 6.2 73.2 75.2 78 77.4a27.5 27.5 0 0028.8-4.8 25.5 25.5 0 007.5-25.3c-1.5-6.4-3.7-9.3-17-22.7a163.8 163.8 0 01-10.5-11c0-.3 85.4-.7 189.8-.8l189.7-.3 6.5-2.2a64.8 64.8 0 0040.3-40.3c2.2-6.5 2.2-7.1 2.5-82.5.3-75.9.3-75.9-1.9-81.8-2.7-7.3-9-13.1-16.5-15.3a24 24 0 00-17.1.8z"
    />
  </svg>
);

export default LogoIcon;
