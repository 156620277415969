import axios from 'axios';

import { RedeemInfo } from './intf/redeem';
import { ApiResponse } from './intf/generic';
import { getBaseUrl } from './axiosInitialization';

export const putRedeem = async (redemptionCode: string) => {
  const { data } = await axios.put<ApiResponse<RedeemInfo>>(
    `/trade-in/v1/redeem/${redemptionCode}`,
    {},
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Put redeem error');
};

export const fetchRedeemStatus = async (redemptionCode: string) => {
  const { data } = await axios.get<ApiResponse<RedeemInfo>>(
    `/trade-in/v1/redeem/${redemptionCode}`,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Get redeem status error');
};
