import React from 'react';
import FormikSelectField, {
  FormikSelectFieldProps,
} from './FormikNativeSelect';
import Field from '../Field';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import { useField } from 'formik';

interface Props extends FormikSelectFieldProps {
  name: string;
  label: React.ReactNode;
}

const FormikNativeSelectField: React.FC<Props> = ({
  name,
  label,
  ...props
}) => {
  const [field, meta, helpers] = useField(name);

  return (
    <Field>
      <FieldLabel error={!!meta.error} htmlFor={name}>
        {label}
      </FieldLabel>

      <FormikSelectField name={name} {...props} />

      {meta.error && <FieldError id={name}>{meta.error}</FieldError>}
    </Field>
  );
};

export default FormikNativeSelectField;
