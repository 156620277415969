import React, { useEffect } from 'react';
import useRouteQuery from '../hooks/useRouteQuery';
import PageLayout from '../components/Layout/PageLayout';
import { useMutation } from 'react-query';
import { putSquareCode } from '../api/payoutIntegrations';
import Message from '../components/Form/Message';
import { getErrorMessage } from '../api/ApiError';
import LoadingIndicator from '../components/Form/LoadingIndicator';

type Query = {
  code?: string;
};

interface Props {}

const AuthSquareLandingPage: React.FC<Props> = () => {
  const route = useRouteQuery<Query>();

  const { mutate, error, isSuccess, isLoading } = useMutation(() => {
    if (!route.code) {
      throw new Error('No code provided');
    }

    return putSquareCode(route.code);
  });

  useEffect(() => {
    mutate();
  }, [route.code]);

  return (
    <PageLayout>
      {isLoading && <LoadingIndicator size="large" />}
      {!!error && <Message error>{getErrorMessage(error)}</Message>}

      {isSuccess && (
        <Message success>Successfully connected with Square</Message>
      )}
    </PageLayout>
  );
};

export default AuthSquareLandingPage;
