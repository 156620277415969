import useCurrentWarehouseId from './useCurrentWarehouseId';
import useCurrentPicklistId from './useCurrentPicklistId';
import { useQuery } from 'react-query';
import { fetchPicklistById } from '../../api/picklists';

export default function useCurrentPicklistQuery() {
  const warehouseId = useCurrentWarehouseId()!;
  const picklistId = useCurrentPicklistId()!;

  return useQuery(['picklist', warehouseId, picklistId], () =>
    fetchPicklistById(warehouseId, picklistId),
  );
}
