import React from 'react';
import { ListingItem } from '../../api/intf/item';
import displayPrice from '../../utils/displayPrice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { deleteListingItemById } from '../../api/item';
import qsStringify from '../../utils/qsStringify';
import { Uuid } from '../../api/intf/generic';
import { useEventCustomerQuoteQuery } from '../../hooks/routing/useEventCustomerQuoteQuery';
import toTitleCase from '../../utils/toTitleCase';
import { QUALITY_LABELS } from '../../constants/valueMatrix';
import {
  ClipboardIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';

interface Props {
  actionsDisabled: boolean;
  customerId: Uuid;
  listing: ListingItem;
  quoteId: Uuid;
}

const QuoteSummaryListingCard: React.FC<Props> = ({
  actionsDisabled,
  customerId,
  listing,
  quoteId,
}) => {
  const navigate = useNavigate();
  const { eventId } = useEventCustomerQuoteQuery();
  const isDeleted = !!listing.deleted_at;
  const notify = () => toast.success('Deleted quote item successfully!');

  const queryClient = useQueryClient();
  const { mutate: handleDelete } = useMutation(
    () => deleteListingItemById(quoteId, listing.id!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['quotes', quoteId]);
        notify();
      },
    },
  );

  const handleEditQuote = () => {
    const queryItem = qsStringify({
      mode: 'edit',
      categoryId: listing.category_id,
      listingId: listing.id,
    });
    navigate(
      `/event/${eventId}/customer/${customerId}/quote/${quoteId}/listing/edit${queryItem}`,
    );
  };

  const handleCopyQuote = () => {
    const query = {
      mode: 'copy',
      categoryId: listing.category_id,
      listingId: listing.id,
    };
    const queryItem = qsStringify(query);
    navigate(
      `/event/${eventId}/customer/${customerId}/quote/${quoteId}/listing/edit${queryItem}`,
    );
  };

  return (
    <div className="rounded border px-3 py-2 shadow">
      <div className="grid grid-cols-2 gap-2">
        <div className="font-semibold">{listing.name}</div>
        <div className="text-right">
          Item ID: {listing.legacy_id || <i>None</i>}
        </div>

        <div>
          Quality:{' '}
          {listing.quality ? QUALITY_LABELS[listing.quality] : <i>None</i>}
        </div>
        <div className="text-right">
          Demand: {listing.demand ? toTitleCase(listing.demand) : <i>None</i>}
        </div>

        <div>Quantity: {listing.quantity}</div>

        <div />

        <div>
          Cash Offer: <b>{displayPrice(listing.cash_offer_amount)}</b>
        </div>
      </div>

      {!actionsDisabled && (
        <div className="mt-4 flex space-x-8 text-sm text-slate-green-500">
          {!isDeleted && (
            <button
              className="flex items-center space-x-1 font-semibold underline"
              onClick={handleEditQuote}
            >
              <PencilIcon className="h-4 w-4" />
              <div>Edit</div>
            </button>
          )}

          <button
            className="flex items-center space-x-1 font-semibold underline"
            onClick={handleCopyQuote}
          >
            <ClipboardIcon className="h-4 w-4" />
            <div>Copy</div>
          </button>

          {!isDeleted && (
            <button
              className="flex items-center space-x-1 font-semibold underline"
              onClick={() => handleDelete()}
            >
              <TrashIcon className="h-4 w-4" />
              <div>Remove</div>
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default QuoteSummaryListingCard;
