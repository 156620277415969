import { Category } from '../api/intf/item';

export interface CategoryTree {
  id: string;
  legacy_id: number;
  name: string;
  slug: string;
  image_url?: string;
  children?: CategoryTree[];
}

function findCategory(
  categories: CategoryTree[],
  name: string,
): CategoryTree | undefined {
  for (const category of categories) {
    if (category.name === name) {
      return category;
    }
  }
}

export default function createCategoryTreeFromFlatList(
  categories: Category[],
): CategoryTree[] {
  const categoryTree: CategoryTree[] = [];

  for (const category of categories) {
    const categoryNames = category.full_name.split(' > ');

    let parentCategory = findCategory(categoryTree, categoryNames[0]);
    if (!parentCategory) {
      parentCategory = {
        id: category.id,
        legacy_id: category.legacy_id,
        name: categoryNames[0],
        image_url: category.image_url,
        slug: category.slug,
        children: [],
      };
      categoryTree.push(parentCategory);
    }

    if (categoryNames.length > 1) {
      let childCategory = findCategory(
        parentCategory.children || [],
        categoryNames[1],
      );
      if (!childCategory) {
        childCategory = {
          id: category.id,
          legacy_id: category.legacy_id,
          name: categoryNames[1],
          image_url: category.image_url,
          slug: category.slug,
          children: [],
        };
        parentCategory.children?.push(childCategory);
      }

      if (categoryNames.length > 2) {
        let grandChildCategory = findCategory(
          childCategory.children || [],
          categoryNames[2],
        );
        if (!grandChildCategory) {
          grandChildCategory = {
            id: category.id,
            legacy_id: category.legacy_id,
            name: categoryNames[2],
            image_url: category.image_url,
            slug: category.slug,
            children: [],
          };
          childCategory.children?.push(grandChildCategory);
        }

        if (categoryNames.length > 3) {
          let greatGrandChildCategory = findCategory(
            grandChildCategory.children || [],
            categoryNames[3],
          );
          if (!greatGrandChildCategory) {
            greatGrandChildCategory = {
              id: category.id,
              legacy_id: category.legacy_id,
              name: categoryNames[3],
              image_url: category.image_url,
              slug: category.slug,
              children: [],
            };
            grandChildCategory.children?.push(greatGrandChildCategory);
          }
        }
      }
    }
  }

  return categoryTree;
}
