import React from 'react';
import { putUpdateCustomer } from '../../api/customer';
import { formatFormikError } from '../../api/ApiError';
import { Customer } from '../../api/intf/customer';
import { Form, Formik } from 'formik';
import {
  customerFormValidation,
  CustomerFormValues,
} from './CreateCustomerForm';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';

interface Props {
  customer: Customer;
  onSuccessfulSubmit?: () => void;
}

const EditCustomerForm: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  customer,
  onSuccessfulSubmit,
}) => {
  const queryClient = useQueryClient();
  return (
    <Formik
      initialValues={
        {
          firstName: customer.first_name || '',
          lastName: customer.last_name || '',
          email: customer.email || '',
          phoneNumber: customer.phone || '',
        } as CustomerFormValues
      }
      onSubmit={async (values, { setErrors }) => {
        try {
          await putUpdateCustomer(
            customer.id,
            values.firstName,
            values.lastName,
            values.email,
            values.phoneNumber,
          );
          queryClient.invalidateQueries(['customers', customer.id]);
          toast('Customer updated', { type: 'success' });
          onSuccessfulSubmit?.();
        } catch (e) {
          setErrors(formatFormikError(e));
        }
      }}
      validationSchema={customerFormValidation}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default EditCustomerForm;
