import React from 'react';
import useMobile from '../../../hooks/useMobile';
import MobileHeaderAndDrawer from '../SideMenu/MobileHeaderAndDrawer';
import SideMenu from '../SideMenu/SideMenu';
import { Link, Outlet } from 'react-router-dom';
import SideMenuLink from '../SideMenu/SideMenuLink';
import {
  BookOpenIcon,
  ChartBarIcon,
  PhoneIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { Button } from '../../Form/Button';
import classNames from 'classnames';
import Footer from '../Footer';
import PartnerQuoteSummaryPanel from '../../Partner/Quote/PartnerQuoteSummaryPanel';
import { useEventCustomerQuoteQuery } from '../../../hooks/routing/useEventCustomerQuoteQuery';
import usePermissions from '../../../lib/auth/usePermissions';
import NewTabLink from '../../NewTabLink';
import useOrgQuery from '../../../hooks/query/useOrgQuery';
import getTrainingManualLinksForOrg from '../../../utils/getTrainingManualLinksForOrg';
import { PartnerStoreChanger } from '../../Partner/PartnerStoreChanger';
import getEnv from '../../../utils/getEnv';
import { fireVisitedStartQuote } from '../../../services/analytics/partner/quotes';
import ContactSupportLink from '../../Links/ContactSupportLink';

interface Props {}

const PartnerLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  // const data = useLoaderData() as LoaderData;
  const mobile = useMobile();
  const { quoteId } = useEventCustomerQuoteQuery();
  const permissions = usePermissions();
  const { data: org } = useOrgQuery();

  const hasTrainingManualsForParentOrg = org?.parent_org?.id
    ? getTrainingManualLinksForOrg(org.parent_org.id) != null
    : null;

  const canChangeStores =
    permissions.partnerChangeStores || getEnv() !== 'production';

  const menu = (
    <>
      <div className="flex-1 space-y-2 p-2 text-white">
        {permissions.partnerStartQuotes && (
          <div>
            <Link to="/partner/quote/new/customer">
              <Button
                fluid
                color="green"
                onClick={() => fireVisitedStartQuote()}
              >
                Start Trade-in
              </Button>
            </Link>
          </div>
        )}

        {permissions.partnerStartQuotes && (
          <SideMenuLink
            text="Dashboard"
            to="/partner/dashboard"
            icon={<ChartBarIcon className="h-6 w-6" />}
          />
        )}

        {permissions.partnerManageUsers && (
          <SideMenuLink
            text="Manage Users"
            to="/partner/manage-users"
            icon={<UsersIcon className="h-6 w-6" />}
          />
        )}

        {hasTrainingManualsForParentOrg && (
          <SideMenuLink
            text="Training Manual"
            to="/partner/training-manual"
            icon={<BookOpenIcon className="h-6 w-6" />}
          />
        )}

        <ContactSupportLink className="flex w-full items-center space-x-3 rounded px-2 py-2.5 text-white transition-colors duration-100 hover:bg-turf-green-900 md:py-1.5">
          <PhoneIcon className="h-6 w-6" />
          <span>Contact Support</span>
        </ContactSupportLink>
      </div>

      <div className="flex-1" />

      {/* Bottom content */}

      <div className="p-2">{canChangeStores && <PartnerStoreChanger />}</div>
    </>
  );

  return (
    <div>
      {mobile && (
        <MobileHeaderAndDrawer logoUrl="/partner/dashboard">
          {menu}
        </MobileHeaderAndDrawer>
      )}

      {!mobile && (
        <div className="fixed inset-y-0 flex w-80 flex-col bg-turf-green-500 shadow print:hidden">
          <SideMenu logoUrl="/partner/dashboard">{menu}</SideMenu>
        </div>
      )}

      <div className="flex h-full">
        {!mobile && <div className="w-80 flex-shrink-0 print:hidden" />}

        <div
          className={classNames('flex-1 pb-20', mobile ? 'w-screen' : 'w-full')}
        >
          <Outlet />
        </div>

        {quoteId && !mobile && (
          <div className="flex-shrink-0 print:hidden md:w-80" />
        )}
      </div>

      {quoteId && (
        <div
          className={classNames(
            'inset-y-0 right-0 bg-off-white-500 shadow print:hidden md:fixed md:w-80 md:pl-0',
            !mobile && 'pl-80',
          )}
        >
          <PartnerQuoteSummaryPanel />
        </div>
      )}

      <Footer />
    </div>
  );
};

export default PartnerLayout;
