import React, { useState } from 'react';
import { EventHistory } from '../../../api/spawn';
import FieldLabel from '../../Form/FieldLabel';

interface Props {
  event: EventHistory;
}

const CollapsibleEventRow: React.FC<Props> = ({ event }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <button
        type="button"
        className="flex justify-between font-bold"
        onClick={() => setExpanded(e => !e)}
      >
        <span>Event: {event.event}</span>
        <span>{expanded ? '-' : '+'}</span>
      </button>

      {expanded && (
        <div>
          {event.status && (
            <div>
              <FieldLabel>Status</FieldLabel>
              <div>{event.status}</div>
            </div>
          )}

          {event.received_at && (
            <div>
              <FieldLabel>Received</FieldLabel>
              <div>{new Date(event.received_at).toLocaleString()}</div>
            </div>
          )}

          {event.location && (
            <div>
              <FieldLabel>Location</FieldLabel>
              <div>{event.location}</div>
            </div>
          )}

          {event.last_scanned_at && (
            <div>
              <FieldLabel>Last Scanned</FieldLabel>
              <div>{new Date(event.last_scanned_at).toLocaleString()}</div>
            </div>
          )}

          <div>
            <FieldLabel>Status Detail</FieldLabel>
            <pre className="font-mono">
              {JSON.stringify(event.status_detail, null, 2)}
            </pre>
          </div>
        </div>
      )}
    </div>
  );
};

export default CollapsibleEventRow;
