import React from 'react';
import SimpleHeadlessSelect, {
  SimpleHeadlessSelectProps,
} from './SimpleHeadlessSelect';

export type StringHeadlessSelectProps = Omit<
  SimpleHeadlessSelectProps<{
    label: string;
    value: string;
  }>,
  'value' | 'onChange' | 'itemToString' | 'renderItem'
> & {
  value?: string;
  onChange: (value: string | null) => void;
};

export const StringHeadlessSelect: React.FC<StringHeadlessSelectProps> = ({
  items,
  value,
  onChange,
  clearable,
  ...props
}) => (
  <SimpleHeadlessSelect
    {...props}
    clearable={clearable}
    itemToString={item => item.label}
    items={items}
    value={value ? items.find(item => item.value === value) : null}
    onChange={item => onChange(item ? item.value : null)}
  />
);
