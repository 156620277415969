import React from 'react';
import SimpleModal, { ModalProps } from '../../Modal/SimpleModal';
import { ReceiveResponse } from '../../../api/spawn';
import FieldLabel from '../../Form/FieldLabel';
import CollapsibleEventRow from './CollapsibleEventRow';

interface Props extends ModalProps {
  itemInfo: ReceiveResponse | undefined;
}

const ScannedItemInfoModal: React.FC<Props> = ({ itemInfo, ...props }) => {
  return (
    <SimpleModal title="Item Info" {...props} className="w-full max-w-md">
      {itemInfo ? (
        <>
          <div className="space-y-4">
            <div>
              <FieldLabel>Item ID</FieldLabel>
              <div>{itemInfo.item_id}</div>
            </div>

            <div>
              <FieldLabel>Status</FieldLabel>
              <div>{itemInfo.status}</div>
            </div>

            <div>
              <FieldLabel>Received</FieldLabel>
              <div>
                {itemInfo.received_at
                  ? new Date(itemInfo.received_at).toLocaleString()
                  : 'None'}
              </div>
            </div>

            <div>
              <FieldLabel>Last Scanned</FieldLabel>
              <div>
                {itemInfo.last_scanned_at
                  ? new Date(itemInfo.last_scanned_at).toLocaleString()
                  : 'None'}
              </div>
            </div>

            <div>
              <FieldLabel>Status Detail</FieldLabel>
              <pre className="font-mono">
                {JSON.stringify(itemInfo.status_detail, null, 2) || 'None'}
              </pre>
            </div>

            <div>
              <FieldLabel>Events</FieldLabel>
              <div className="space-y-2">
                {itemInfo.events ? (
                  itemInfo.events.map((event, i) => (
                    <CollapsibleEventRow key={i} event={event} />
                  ))
                ) : (
                  <div>No previous events</div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="text-center font-semibold">No item info</div>
      )}
    </SimpleModal>
  );
};

export default ScannedItemInfoModal;
