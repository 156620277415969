import { EditListingItemDetailRequest, FullCategory } from '../api/intf/item';
import { SelectOption } from '../components/Form/FormikSelect/DetailSelect';
import { CreateItemFormValues } from '../components/ItemForms/CreateItemForm';

const notDetailItems: (keyof CreateItemFormValues)[] = [
  'category',
  'model',
  'brand',
  'quality',
  'cashoffer',
  'resalePrice',
  'estimatedTradeIn',
];

export default function getAdditionalItemDetails(
  values: CreateItemFormValues,
  categoryItem: FullCategory,
) {
  const details: EditListingItemDetailRequest[] = [];

  if (
    !categoryItem ||
    !categoryItem.details ||
    categoryItem.details.length === 0
  ) {
    return [];
  }

  const itemDetails = categoryItem.details;
  for (let i = 0; i < Object.keys(values).length; i += 1) {
    const value = Object.values(values)[i] as SelectOption | null;
    const key = Object.keys(values)[i] as keyof CreateItemFormValues;

    if (!value) {
      continue;
    }

    if (notDetailItems.includes(key)) {
      continue;
    }

    const detailItem = itemDetails.filter(item => item.name === key);
    details.push({
      type_id: detailItem[0]?.legacy_id || detailItem[0]?.id,
      option_id: parseInt(value.value, 10),
    });
  }

  return details;
}
