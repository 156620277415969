import React from 'react';
import PageLayout from '../../components/Layout/PageLayout';
import PageHeader from '../../components/Layout/PageHeader';
import useOrgQuery from '../../hooks/query/useOrgQuery';
import getTrainingManualLinksForOrg from '../../utils/getTrainingManualLinksForOrg';
import Loader from '../../components/Form/Loader';
import NewTabLink from '../../components/NewTabLink';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

interface Props {}

const TrainingManualPage: React.FC<Props> = () => {
  const { data: org, isLoading } = useOrgQuery();

  const trainingManuals = org?.parent_org?.id
    ? getTrainingManualLinksForOrg(org.parent_org.id)
    : null;

  return (
    <PageLayout
      header={
        <>
          <PageHeader>Training Manual</PageHeader>
        </>
      }
    >
      <Loader loading={isLoading}>
        <div className="space-y-8">
          {trainingManuals?.map(trainingManual => (
            <NewTabLink
              href={trainingManual.url}
              className="block rounded border px-6 py-4"
            >
              <span className="text-lg font-semibold">
                {trainingManual.title}{' '}
                <ArrowTopRightOnSquareIcon className="ml-2 mb-1 inline-block h-5 w-5 align-middle" />
              </span>
            </NewTabLink>
          ))}
        </div>
      </Loader>
    </PageLayout>
  );
};

export default TrainingManualPage;
