import React from 'react';
import Message from '../components/Form/Message';
import useRouteQuery from '../hooks/useRouteQuery';
import LoginForm from '../components/Login/LoginForm';
import LoginLayout from '../components/Layout/Layouts/LoginLayout';

export interface LoginPageQuery {
  contextMessage?: string;
  contextMessageType?: string;
  message?: string;
  messageType?: string;
  from?: string;
}

interface Props {}

const LoginPage: React.FC<Props> = () => {
  const query = useRouteQuery<LoginPageQuery>();

  return (
    <LoginLayout title="Log in to the SidelineSwap Trade-in Tool">
      {(query.contextMessage || query.message) && (
        <Message
          error={
            query.contextMessageType === 'error' ||
            query.messageType === 'error'
          }
          success={
            query.contextMessageType === 'success' ||
            query.messageType === 'success'
          }
          warning={
            !(query.contextMessageType || query.messageType) ||
            query.contextMessageType === 'warning' ||
            query.messageType === 'warning'
          }
        >
          {query.contextMessage || query.message}
        </Message>
      )}

      <LoginForm />
    </LoginLayout>
  );
};

export default LoginPage;
