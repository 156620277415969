import React from 'react';
import { useParams } from 'react-router-dom';
import PageLayout from '../../../components/Layout/PageLayout';
import PageHeader from '../../../components/Layout/PageHeader';
import { fetchBonusCodeById } from '../../../api/bonusCodes';
import { useQuery } from 'react-query';
import PageSubheader from '../../../components/Layout/PageSubheader';
import SettingsBreadcrumbLink from '../../../components/Settings/SettingsBreadcrumbLink';
import FormikSubmitButton from '../../../components/Form/FormikSubmitButton';
import BonusCodeForm from '../../../components/Settings/BonusCodeForm';

interface Props {}

const BonusCodeEditPage: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();

  const { data: bonusCode } = useQuery(
    ['bonus-codes', id],
    () => fetchBonusCodeById(id!),
    {
      enabled: !!id,
    },
  );

  return (
    <PageLayout
      header={
        <>
          <SettingsBreadcrumbLink page="bonusCodes" />
          <PageHeader>Editing Bonus Code</PageHeader>
          <PageSubheader>
            Organization Name: {bonusCode?.organization_name}
          </PageSubheader>
        </>
      }
    >
      {bonusCode && (
        <BonusCodeForm editingBonusCode={bonusCode}>
          <FormikSubmitButton>Update</FormikSubmitButton>
        </BonusCodeForm>
      )}
    </PageLayout>
  );
};

export default BonusCodeEditPage;
