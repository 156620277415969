const formatter =
  typeof Intl !== 'undefined' &&
  typeof Intl.NumberFormat !== 'undefined' &&
  new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 });

/**
 * Adds commas in the thousands, defaulting to using
 * toLocaleString (most browsers other than older safari).
 * Assumes positive whole numbers.
 * @param {number} value
 * @param {number|null} decimals
 * @returns {string}
 */
export default function thousandsSeparator(value = 0, decimals = 0) {
  if (value == null) return '';
  if (Number.isNaN(value)) return '';

  if (formatter) {
    return formatter.format(value);
  }

  value = Number(value);

  if (value < 1000) {
    return value.toFixed(decimals);
  }

  const [valueString, decimalValue] = value.toFixed(decimals).split('.');

  let newValue = valueString.toLocaleString();

  // Use regex as a fallback if toLocaleString() returned a non-digit-grouped number string
  // Used for Node specifically
  if (newValue === valueString) {
    newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  if (decimals) {
    newValue += `.${decimalValue}`;
  }

  return newValue;
}
