import { useEventCustomerQuoteQuery } from '../routing/useEventCustomerQuoteQuery';
import useQuoteQuery from './useQuoteQuery';
import { UseQueryOptions } from 'react-query';
import { Quote } from '../../api/intf/item';

export default function useCurrentQuoteQuery(
  options?: UseQueryOptions<Quote, any, Quote, any>,
) {
  const { quoteId } = useEventCustomerQuoteQuery();
  return useQuoteQuery(quoteId, options);
}
