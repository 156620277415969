import React from 'react';
import classNames from 'classnames';

export interface RadioProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: never;
}

const Radio: React.FC<RadioProps> = ({ className, ...props }) => (
  <input
    {...props}
    type="radio"
    className={classNames('form-radio rounded-full focus:ring-0', className)}
  />
);

export default Radio;
