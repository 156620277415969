import React from 'react';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { Link, LinkProps } from 'react-router-dom';

interface Props {
  children?: React.ReactNode;
  to: LinkProps['to'];
}

const BackArrowLink: React.FC<Props> = ({ children, to }) => {
  return (
    <div className="mb-4">
      <Link className="relative inline-flex items-center gap-2" to={to}>
        <div className="absolute -inset-4" />

        <ArrowLeftIcon className="h-4 w-4" />
        <span>{children}</span>
      </Link>
    </div>
  );
};

export default BackArrowLink;
