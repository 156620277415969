import classNames from 'classnames';
import React from 'react';

interface Props extends React.HTMLAttributes<HTMLElement> {}

const PageHeader: React.FC<Props> = ({ children, className, ...props }) => (
  <h1 className={classNames('text-2xl font-semibold', className)} {...props}>
    {children}
  </h1>
);

export default PageHeader;
