import React, { useRef, useState } from 'react';
import SimpleModal, { ModalProps } from './Modal/SimpleModal';
import NumberInput from './Form/NumberInput';
import Field from './Form/Field';
import FieldLabel from './Form/FieldLabel';
import { Button } from './Form/Button';

interface Props extends ModalProps {
  eventId: string;
  quoteId: string;
}

const QuotePrintModal: React.FC<Props> = ({
  eventId,
  quoteId,
  onClose,
  ...props
}) => {
  const [quantity, setQuantity] = useState<number | undefined>(1);
  const initialFocus = useRef<HTMLInputElement>(null);
  return (
    <SimpleModal
      className="w-full max-w-md"
      onClose={onClose}
      {...props}
      title="How many quote labels will you need?"
      description="If you have multiple bags or bulk items, select the appropriate quantity."
      initialFocus={initialFocus}
    >
      <form action="/print" target="_blank">
        <input type="hidden" name="type" value="quote" />
        <input type="hidden" name="quote_id" value={quoteId} />
        <input type="hidden" name="event_id" value={eventId} />

        <Field>
          <FieldLabel htmlFor="quantity">Quantity</FieldLabel>
          <NumberInput
            id="quantity"
            name="quantity"
            decimalScale={0}
            thousandSeparator={false}
            value={quantity}
            onValueChange={v => setQuantity(v.floatValue)}
            onFocus={e => e.target.select()}
            ref={initialFocus}
          />
        </Field>

        <div className="mt-6 flex space-x-2">
          <Button variant="outlined" color="grey" fluid onClick={onClose}>
            Cancel
          </Button>

          <Button type="submit" fluid disabled={!quantity || quantity < 1}>
            Print Labels
          </Button>
        </div>
      </form>
    </SimpleModal>
  );
};

export default QuotePrintModal;
