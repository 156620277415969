import axios from 'axios';
import { ApiResponse } from './intf/generic';
import { getBaseUrl } from './axiosInitialization';
import { getCookie } from '../lib/KnownCookies';

export interface PartnerStore {
  org_id: string;
  org_name: string;
  store_id: string;
}

export type PostCreatePartnerStoreBody = {
  store_number: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
};

export const fetchPartnerStores = async (parentOrgId?: string) => {
  const { data } = await axios.get<ApiResponse<PartnerStore[]>>(
    '/trade-in/v1/stores',
    {
      baseURL: getBaseUrl('inventoryApi'),
      params: {
        parent_org_id: parentOrgId,
      },
    },
  );

  return data.data;
};

export const postCreatePartnerStore = async (
  store: PostCreatePartnerStoreBody,
) => {
  const { data } = await axios.post<ApiResponse<PartnerStore>>(
    '/trade-in/v1/stores',
    store,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  return data.data;
};

interface ChangeStoreResponse {
  token_type: string;
  access_token: string;
  selected_org: string;
  default_org: string;
}

export const postChangeStore = async (orgId: string) => {
  const { data } = await axios.post<ApiResponse<ChangeStoreResponse>>(
    `/iam/auth/v1/trade_in/token/change_org`,
    {
      grant_type: 'refresh_token',
      token: getCookie('refresh_token'),
      org_id: orgId,
      client_id: getCookie('sls_client_id'),
    },
  );

  if (data.data?.access_token) {
    return data.data;
  }
};
