import React, { startTransition, useState } from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import { fetchWarehouseAuditItems } from '../../../api/warehouseAudit';
import { useQuery } from 'react-query';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import SimpleReactTable from '../../Tables/SimpleReactTable';
import useUserByIdQuery from '../../../hooks/query/useUserByIdQuery';
import displayPrice from '../../../utils/displayPrice';
import Message from '../../Form/Message';
import { getErrorMessage } from '../../../api/ApiError';
import LoadingIndicator from '../../Form/LoadingIndicator';
import { PartnerTransferItem } from '../../../api/partnerTransfers';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import formatListingTag from '../../../utils/formatListingTag';
import AuditNextPrevPaginator from './AuditNextPrevPaginator';
import Field from '../../Form/Field';
import FieldLabel from '../../Form/FieldLabel';
import Input from '../../Form/Input';
import useDebounceValue from '../../../hooks/useDebounceValue';
import NumberInput from '../../Form/NumberInput';
import { T } from 'vitest/dist/types-7cd96283';
import TagInput from '../../Partner/TagInput';
import { Button } from '../../Form/Button';

function rowToViewLinkDestination(row: Row<PartnerTransferItem>) {
  return `./${row.original.id}/condition`;
}

export const transferItemPriceVarianceColumn: ColumnDef<PartnerTransferItem> = {
  header: 'Price Variance',
  enableSorting: true,
  cell: ({ row }) => {
    const difference = row.original.price_variance;
    if (!difference) {
      return displayPrice(difference, false);
    }

    if (difference > 0) {
      return (
        <span className="text-green-500">
          + {displayPrice(difference, false)}
        </span>
      );
    }

    if (difference < 0) {
      return (
        <span className="text-red-500">
          - {displayPrice(difference, false, false)}
        </span>
      );
    }
  },
};

export const transferItemStatusColumn: ColumnDef<PartnerTransferItem> = {
  id: 'status',
  header: 'Status',
  accessorFn: row => row.status,
  enableSorting: true,
  sortingFn: 'text',
  cell: ({ row }) => {
    if (row.original.status === 'AUDIT_IN_PROGRESS') {
      return 'In Progress';
    }

    if (row.original.status === 'AUDIT_ACCEPTED') {
      return 'Accepted';
    }

    if (row.original.status === 'AUDIT_REJECTED') {
      return 'Rejected';
    }

    if (row.original.status === 'AUDIT_MODIFIED') {
      return 'Modified';
    }
  },
};

export const transferIdColumn: ColumnDef<PartnerTransferItem, any> = {
  id: 'transfer_id',
  header: 'Transfer ID',
  accessorFn: row => {
    const split = row.transfer_id?.split('-') || [];
    return split[split.length - 1];
  },
  cell: info => (
    <Link
      to={`/partner/dashboard/transfers/${info.row.original.transfer_id}`}
      className="underline"
    >
      {info.getValue()}
    </Link>
  ),
};

const columns: ColumnDef<PartnerTransferItem>[] = [
  transferIdColumn,
  {
    header: 'Item Name',
    accessorKey: 'name',
  },
  {
    header: 'Tag ID',
    accessorFn: row => {
      if (row.tag) {
        return formatListingTag(row.tag);
      } else {
        return row.original_data.legacy_item_id;
      }
    },
  },
  {
    header: 'Date',
    enableSorting: true,
    accessorFn: row => new Date(row.audited_at!),
    cell: ({ row }) => {
      const date = row.original.audited_at;
      if (!date) {
        return null;
      }

      return new Date(date).toLocaleDateString();
    },
  },
  {
    header: 'Details Changed',
    accessorFn: row => row.details_changed_count,
    enableSorting: true,
  },
  transferItemPriceVarianceColumn,
  transferItemStatusColumn,
  {
    id: 'actions',
    header: () => <span className="sr-only">Actions</span>,
    cell: ({ row }) => (
      <Link
        to={rowToViewLinkDestination(row)}
        className="font-semibold text-green-500"
      >
        View
      </Link>
    ),
  },
];

interface Props {}

const WarehouseAuditItemsTable: React.FC<Props> = () => {
  const navigate = useNavigate();
  const warehouseId = useCurrentWarehouseId();
  const [params] = useSearchParams();
  const pageNumber = Number(params.get('page')) || 1;

  const [searchTag, setSearchTag] = useState('');
  const [searchItemName, setSearchItemName] = useState('');

  const debouncedTag = useDebounceValue(searchTag, 200);
  const debouncedItemName = useDebounceValue(searchItemName, 200);

  const {
    data: response,
    error,
    isLoading,
    isSuccess,
  } = useQuery(
    [
      'warehouse-audit',
      warehouseId,
      pageNumber,
      debouncedTag,
      debouncedItemName,
    ],
    () =>
      fetchWarehouseAuditItems(
        warehouseId!,
        pageNumber,
        debouncedTag?.replace(/\D/g, ''),
        debouncedItemName,
      ),
    {
      enabled: !!warehouseId,
      keepPreviousData: true,
    },
  );

  const { data, meta } = response || {};

  const table = useReactTable({
    data: data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (error) {
    return (
      <div>
        <Message error>{getErrorMessage(error)}</Message>
      </div>
    );
  }

  if (isLoading && !response) {
    return (
      <div>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <>
      <div className="mb-8 flex gap-4">
        <div>
          <FieldLabel>Search Item Tag</FieldLabel>
          <NumberInput
            className="w-40"
            value={searchTag}
            onValueChange={value => setSearchTag(value.formattedValue)}
            allowNegative={false}
            decimalScale={0}
            thousandSeparator={false}
          />
        </div>

        <div>
          <FieldLabel>Search Item Name</FieldLabel>
          <Input
            type="search"
            className="w-80"
            value={searchItemName}
            onChange={e => setSearchItemName(e.target.value)}
          />
        </div>

        {!!(searchTag || searchItemName) && (
          <div>
            <FieldLabel>&nbsp;</FieldLabel>
            <Button
              variant="text"
              onClick={() => {
                setSearchTag('');
                setSearchItemName('');
              }}
            >
              Clear
            </Button>
          </div>
        )}
      </div>

      {isSuccess && data?.length === 0 ? (
        <div className="py-6 text-center text-lg font-semibold text-slate-green-500">
          No items
        </div>
      ) : (
        <>
          <SimpleReactTable table={table} />
          <div className="mt-8 flex justify-center">
            <AuditNextPrevPaginator paging={meta?.paging} />
          </div>
        </>
      )}
    </>
  );
};

export default WarehouseAuditItemsTable;
