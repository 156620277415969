import React from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';

interface Props {
  children: React.ReactNode | ((open: boolean) => React.ReactNode);

  // Flyout direction
  direction?: 'right' | 'left';

  // Trigger element
  trigger?: React.ReactNode | ((open: boolean) => React.ReactNode);

  menuTitle?: React.ReactNode;
}

const SimpleFlyoutMenu: React.FC<Props> = ({
  children,
  direction = 'left',
  menuTitle,
  trigger,
}) => {
  let renderedMenuTitle: React.ReactNode = null;

  if (typeof menuTitle === 'string') {
    renderedMenuTitle = (
      <div className="my-2 px-4 text-sm font-semibold text-slate-green-500">
        {menuTitle}
      </div>
    );
  } else if (menuTitle) {
    renderedMenuTitle = menuTitle;
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open }) => (
        <>
          {typeof trigger === 'function' ? trigger(open) : trigger}

          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={classNames(
                'absolute z-20 mt-2 w-56 rounded-md bg-white shadow-xl ring-1 ring-black ring-opacity-20 focus:outline-none',
                direction === 'right' && 'left-0 origin-top-left',
                direction === 'left' && 'right-0 origin-top-right',
              )}
            >
              <div className="py-1">
                {renderedMenuTitle}
                {typeof children === 'function' ? children(open) : children}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default SimpleFlyoutMenu;
