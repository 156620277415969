import React, { useState } from 'react';
import PrintBulkModal from './WarehouseSwapsPrintAllModal';
import useCurrentWarehouseSwapsQuery from '../../../hooks/warehouse/useCurrentWarehouseSwapsQuery';
import { PrinterIcon } from '@heroicons/react/24/outline';
import { useQuery } from 'react-query';
import {
  fetchWarehouseSwaps,
  WarehouseSwapFilteringOptions,
} from '../../../api/warehouse';
import { SHIP_NOW_SCOPE } from '../WarehouseLayoutShipNowSubfolders';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import SimpleFlyoutMenu from '../../Menu/SimpleFlyoutMenu';
import { Menu } from '@headlessui/react';
import SimpleMenuItemButton from '../../Menu/SimpleMenuItemButton';

interface Props {}

const WarehouseInboxPrintAction: React.FC<Props> = () => {
  const warehouseId = useCurrentWarehouseId();
  const { data: swaps, error, isLoading } = useCurrentWarehouseSwapsQuery();

  const newOrderOptions: WarehouseSwapFilteringOptions = {
    scope: SHIP_NOW_SCOPE,
    filters: ['new_orders'],
  };

  const { data: newOrderSwaps } = useQuery(
    ['warehouse-swaps', warehouseId, newOrderOptions],
    () => fetchWarehouseSwaps(warehouseId!, newOrderOptions),
    {
      keepPreviousData: true,
      enabled: !!warehouseId,
    },
  );

  const [printBulkModalOpen, setPrintBulkModalOpen] = useState(false);
  const [printNewOrdersModalOpen, setPrintNewOrdersModalOpen] = useState(false);

  return (
    <>
      <SimpleFlyoutMenu
        direction="right"
        trigger={() => (
          <Menu.Button title="Print all">
            <span className="sr-only">Print actions</span>
            <PrinterIcon className="h-6 w-6" aria-hidden="true" />
          </Menu.Button>
        )}
      >
        <SimpleMenuItemButton onClick={() => setPrintBulkModalOpen(true)}>
          Print All
        </SimpleMenuItemButton>

        <SimpleMenuItemButton onClick={() => setPrintNewOrdersModalOpen(true)}>
          Print New Orders ({newOrderSwaps?.data?.length})
        </SimpleMenuItemButton>
      </SimpleFlyoutMenu>

      <PrintBulkModal
        swaps={swaps?.data}
        open={printBulkModalOpen}
        onClose={() => setPrintBulkModalOpen(false)}
      />

      <PrintBulkModal
        newOrders
        swaps={newOrderSwaps?.data}
        open={printNewOrdersModalOpen}
        onClose={() => setPrintNewOrdersModalOpen(false)}
      />
    </>
  );
};

export default WarehouseInboxPrintAction;
