import React from 'react';

interface Props {}

const Kbd: React.FC<React.PropsWithChildren<Props>> = ({ children }) => {
  return (
    <kbd className="rounded-sm border bg-grey-200 px-1 py-0.5 text-turf-green-500">
      {children}
    </kbd>
  );
};

export default Kbd;
