import React, { useMemo } from 'react';
import { Form } from 'formik';
import FormikField from '../Form/FormikField';
import FormikNumberField from '../Form/FormikNumberField';
import { useQuery, useQueryClient } from 'react-query';
import { fetchAllPayoutMethods } from '../../api/paymentMethods';
import FieldLabel from '../Form/FieldLabel';
import FormikCheckboxWithLabel from '../Form/FormikCheckboxWithLabel';
import { z } from 'zod';
import FormikZodSchema from '../Form/FormikZodSchema';
import { isBefore } from 'date-fns';
import { BonusCode, CreateBonusCode } from '../../api/intf/bonusCodes';
import { formatFormikError } from '../../api/ApiError';
import { postCreateBonusCode, putUpdateBonusCode } from '../../api/bonusCodes';
import { useNavigate } from 'react-router-dom';
import FormikErrorMessage from '../Form/FormikErrorMessage';

const zodSchema = z
  .object({
    organizationName: z.string().min(1, 'Organization is required'),
    bonusAmount: z
      .number()
      .min(0, 'Bonus amount must be greater than 0%')
      .max(100, 'Bonus amount must be less than 100%'),
    startDate: z.string().min(1, 'Required'),
    endDate: z.optional(z.string()),
    paymentMethods: z.array(z.string().uuid('Invalid payment method ID')),
  })
  .refine(
    data => {
      return !(
        data.startDate &&
        data.endDate &&
        isBefore(new Date(data.endDate), new Date(data.startDate))
      );
    },
    {
      path: ['endDate'],
      message: 'Cannot end before it starts',
    },
  );

interface Props {
  editingBonusCode?: BonusCode;
}

const BonusCodeForm: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  editingBonusCode,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { data: payoutMethods } = useQuery(
    ['payout-methods', 'all'],
    fetchAllPayoutMethods,
  );

  return (
    <FormikZodSchema
      initialValues={useMemo(() => {
        if (editingBonusCode) {
          return {
            organizationName: editingBonusCode.organization_name || '',
            bonusAmount: editingBonusCode.bonus_amount
              ? editingBonusCode.bonus_amount * 100
              : 0,
            startDate: editingBonusCode.start_at
              ? new Date(editingBonusCode.start_at).toISOString().split('T')[0]
              : '',
            endDate: editingBonusCode.expires_at
              ? new Date(editingBonusCode.expires_at)
                  .toISOString()
                  .split('T')[0]
              : undefined,
            paymentMethods: editingBonusCode.payout_methods,
          };
        } else {
          return {
            organizationName: '',
            bonusAmount: '' as any,
            startDate: '',
            paymentMethods: [],
          };
        }
      }, [editingBonusCode])}
      schema={zodSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, formik) => {
        try {
          const body: CreateBonusCode = {
            organization_name: values.organizationName,
            bonus_amount: values.bonusAmount
              ? values.bonusAmount / 100
              : undefined,
            start_at: new Date(values.startDate).toISOString(),
            expires_at: values.endDate
              ? new Date(values.endDate).toISOString()
              : undefined,
            payout_methods: values.paymentMethods,
          };

          if (editingBonusCode) {
            await putUpdateBonusCode(editingBonusCode.id, body);
          } else {
            await postCreateBonusCode(body);
          }

          queryClient.invalidateQueries();
          navigate('/settings/bonus-codes');
        } catch (e) {
          formik.setErrors(
            formatFormikError(e, {
              errorFieldMap: {
                'create.command.bonus_amount': 'bonusAmount',
                'create.command.organization_name': 'organizationName',
                'create.command.start_at': 'startDate',
                'create.command.expires_at': 'endDate',
                'update.command.organization_name': 'organizationName',
                'update.command.bonus_amount': 'bonusAmount',
                'update.command.start_at': 'startDate',
                'update.command.expires_at': 'endDate',
              },
            }),
          );
        }
      }}
    >
      <Form className="space-y-4">
        <FormikField
          name="organizationName"
          required
          label="Organization"
          placeholder="(ex: Reos)"
        />
        <FormikNumberField
          name="bonusAmount"
          changeProperty="floatValue"
          required
          label="Bonus Amount"
          placeholder="(ex: 15%)"
          suffix="%"
        />
        <FormikField required name="startDate" label="Start Date" type="date" />
        <FormikField name="endDate" label="End Date" type="date" />

        <FormikErrorMessage />

        <div>
          <FieldLabel>Payment Methods</FieldLabel>
          <div className="space-y-2">
            {payoutMethods?.map(pm => (
              <FormikCheckboxWithLabel
                key={pm.id}
                name="paymentMethods"
                value={pm.id}
              >
                {pm.name}
              </FormikCheckboxWithLabel>
            ))}
          </div>
        </div>

        {children}
      </Form>
    </FormikZodSchema>
  );
};

export default BonusCodeForm;
