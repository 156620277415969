import React from 'react';
import { useField } from 'formik';
import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import FormikFieldError from './FormikFieldError';

interface Props {
  className?: string;
  name: string;
}

const FormikRadioGroup: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  name,
}) => {
  const [field, meta, helpers] = useField(name);

  return (
    <div
      className={classNames(
        meta.error && '-mx-2 rounded border border-red-500 p-2',
      )}
    >
      <RadioGroup
        className={className}
        value={field.value}
        onChange={helpers.setValue}
      >
        {children}
      </RadioGroup>

      <FormikFieldError className="pt-1" name={name} />
    </div>
  );
};

export default FormikRadioGroup;
