import axios from 'axios';
import { ApiResponse } from './intf/generic';
import { getBaseUrl } from './axiosInitialization';
import { Picklist, PicklistItem } from './intf/picklist';

export const fetchUnshippedOrdersCount = async (warehouseId: string) => {
  const { data } = await axios.get<ApiResponse<{ count: number }>>(
    `/warehouse/v1/warehouses/${warehouseId}/pick-list/count`,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch unshipped order count error');
};

export const postCreatePicklist = async (
  warehouseId: string,
  count: number,
) => {
  const { data } = await axios.post<ApiResponse<Picklist>>(
    `/warehouse/v1/warehouses/${warehouseId}/pick-list`,
    undefined,
    {
      baseURL: getBaseUrl('inventoryApi'),
      params: {
        pick_count: count,
      },
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Create pick list error');
};

export const fetchUnshippedOrders = async (warehouseId: string) => {
  const { data } = await axios.get<ApiResponse<PicklistItem[]>>(
    `/warehouse/v1/warehouses/${warehouseId}/unshipped-orders`,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch unshipped orders error');
};

export const fetchPicklistById = async (
  warehouseId: string,
  picklistId: string,
) => {
  const { data } = await axios.get<ApiResponse<Picklist>>(
    `/warehouse/v1/warehouses/${warehouseId}/pick-list/${picklistId}`,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    // `images` is an array of JSON-stringified image URLs, so fix it.
    for (let i = 0; i < data.data.items.length; i++) {
      try {
        const parsed = JSON.parse(data.data.items[i].images[0]);
        if (parsed) {
          data.data.items[i].images = parsed;
        }
      } catch (e) {}
    }
    return data.data;
  }

  throw new Error('Fetch pick list by id error');
};

export const putPickItem = (data: string) => {
  return axios.put(`/warehouse/v1/pick-item`, data, {
    baseURL: getBaseUrl('inventoryApi'),
  });
};

export const togglePickStatus = async (item: PicklistItem) => {
  if (item?.warehouse_order_state === 'PICKED') {
    await axios.put(`/warehouse/v1/item/${item.item_id}/unpick`, undefined, {
      baseURL: getBaseUrl('inventoryApi'),
    });
  } else {
    await axios.put(`/warehouse/v1/item/${item.item_id}/pick`, undefined, {
      baseURL: getBaseUrl('inventoryApi'),
    });
  }
};
