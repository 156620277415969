import React from 'react';
import { useQuery } from 'react-query';
import Message from '../../../components/Form/Message';
import { getErrorMessage } from '../../../api/ApiError';
import { fetchPartnerStats } from '../../../api/partnerDashboard';
import DashboardCell from '../../../components/Partner/Dashboard/DashboardCell';
import { CubeIcon, TagIcon, TruckIcon } from '@heroicons/react/24/outline';
import { ClockIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import DashboardRecentQuoteCard from '../../../components/Partner/Dashboard/DashboardRecentQuoteCard';
import FilteredTransfersItemsTable from '../../../components/Partner/FilteredTransfersItemsTable';
import { useAuth } from '../../../lib/auth/AuthContext';
import { fireVisitedDashboard } from '../../../services/analytics/partner/dashboard';
import { useMount } from 'react-use';
import LoadingIndicator from '../../../components/Form/LoadingIndicator';
import useMobile from '../../../hooks/useMobile';
import { Button } from '../../../components/Form/Button';
import { fireVisitedStartQuote } from '../../../services/analytics/partner/quotes';
import usePermissions from '../../../lib/auth/usePermissions';

interface Props {}

const PartnerDashboardPage: React.FC<Props> = () => {
  useMount(fireVisitedDashboard);
  const jwt = useAuth().decodedJwt;
  const { data: stats, error: statsError } = useQuery(
    ['stats'],
    fetchPartnerStats,
  );
  const mobile = useMobile();
  const permissions = usePermissions();

  const containerCx = 'container space-y-8 px-8 pt-8 pb-20';

  if (!jwt?.org_id) {
    return (
      <div className={containerCx}>
        <Message warning>
          Your account has not been approved to process trade-ins yet. Please
          contact your system administrator to get access.
        </Message>
      </div>
    );
  }

  if (statsError) {
    return (
      <div className={containerCx}>
        <Message error>
          Something went wrong: {getErrorMessage(statsError)}
        </Message>
      </div>
    );
  }

  if (!stats) {
    return (
      <div className={containerCx}>
        <LoadingIndicator size="large" />
      </div>
    );
  }

  return (
    <div className={containerCx}>
      {mobile && permissions.partnerStartQuotes && (
        <div>
          <Link to="/partner/quote/new/customer">
            <Button fluid color="green" onClick={() => fireVisitedStartQuote()}>
              Start Trade-in
            </Button>
          </Link>
        </div>
      )}

      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        <DashboardCell
          icon={<TagIcon className="h-6 w-6 stroke-2 text-slate-green-500" />}
          link="/partner/dashboard/tag"
          linkText="Tag Items"
          title="Items to Tag"
          value={stats.transfers.items_to_tag}
        />

        <DashboardCell
          icon={<CubeIcon className="h-6 w-6 stroke-2 text-slate-green-500" />}
          link="/partner/dashboard/transfers/new"
          linkText="Create Shipment"
          title="Items to Ship"
          value={stats.transfers.items_pending}
        >
          {stats.transfers.last_transfer_at && (
            <div className="mt-1 flex items-center space-x-1 text-yellow-600">
              <ClockIcon className="h-5 w-5 stroke-2" />
              <div className="text-sm">
                Last Shipment:{' '}
                {new Date(
                  stats.transfers.last_transfer_at,
                ).toLocaleDateString()}
              </div>
            </div>
          )}
        </DashboardCell>

        <DashboardCell
          icon={<TruckIcon className="h-6 w-6 stroke-2 text-slate-green-500" />}
          link="/partner/dashboard/transfers"
          linkText="View History"
          title="In Transit"
          value={stats.transfers.in_transit}
        />
      </div>

      {stats.event_stats?.recent_quotes && (
        <section aria-label="Recent Quotes">
          <div className="mb-4 flex items-center space-x-4">
            <div className="text-2xl">Recent Quotes</div>
            <Link
              to="/partner/dashboard/quotes"
              className="text-sm font-semibold text-green-500"
            >
              View All
            </Link>
          </div>

          {stats.event_stats.recent_quotes.length === 0 && (
            <div className="text-center text-lg font-semibold text-slate-green-500">
              No recent quotes
            </div>
          )}

          <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
            {stats.event_stats.recent_quotes.map(quote => (
              <DashboardRecentQuoteCard quote={quote} key={quote.id} />
            ))}
          </div>
        </section>
      )}

      <section aria-label="History">
        <div className="mb-4 flex items-center space-x-4">
          <div className="text-2xl">History</div>
          <Link
            to="/partner/dashboard/items"
            className="text-sm font-semibold text-green-500"
          >
            View All
          </Link>
        </div>

        <FilteredTransfersItemsTable />
      </section>
    </div>
  );
};

export default PartnerDashboardPage;
