import useCurrentWarehouseId from './useCurrentWarehouseId';
import { useQuery } from 'react-query';
import { fetchWarehouseSwapCounts } from '../../api/warehouse';

export default function useCurrentWarehouseSwapCountsQuery() {
  const warehouseId = useCurrentWarehouseId();
  return useQuery(
    ['warehouse-swap-counts', warehouseId],
    () => fetchWarehouseSwapCounts(warehouseId!),
    {
      enabled: !!warehouseId,
    },
  );
}
