import axios from 'axios';
import { ApiResponse } from './intf/generic';
import { getBaseUrl } from './axiosInitialization';

export interface IamTradeInOrg {
  id: string;
  name: string;
  prefix?: string;
  parent_id?: string;
  seller_user_id?: string;
}

export interface TradeInOrganization {
  id: string;
  name: string;
  credit_offer_markup: number;
}

interface TradeInOrganizationWithParent {
  org: IamTradeInOrg;
  parent_org?: IamTradeInOrg;
}

export type OrgConfig = 'DEFAULT' | 'DSG';

export interface IamOrganizationConfig {
  org_id: string;
  image_url?: string;
  trade_in_experience: OrgConfig;
}

export const fetchOrg = async (orgId: string) => {
  const { data } = await axios.get<ApiResponse<TradeInOrganization>>(
    `/trade-in/v1/orgs/${orgId}`,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data) {
    return data.data;
  }

  throw new Error('fetch organization error');
};

export const fetchIamOrg = async (orgId: string) => {
  const { data } = await axios.get<ApiResponse<TradeInOrganizationWithParent>>(
    `/iam/auth/v1/trade_in/orgs/${orgId}`,
  );

  if (data) {
    return data.data;
  }
};

export const fetchIamOrgConfig = async (orgId: string) => {
  const { data } = await axios.get<ApiResponse<IamOrganizationConfig>>(
    `/iam/v1/admin/orgs/${orgId}/config`,
  );

  if (data) {
    return data.data;
  }

  throw new Error('fetch organization error');
};
