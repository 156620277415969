import axios from 'axios';
import { ApiResponse } from './intf/generic';

export interface RailsRegion {
  id: string;
  name: string;
}

export const fetchRailsSubregions = async (region: string) => {
  const { data } = await axios.get<ApiResponse<RailsRegion[]>>(
    `/v1/regions/${region}`,
    {
      headers: {
        Authorization: null,
      },
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error(`Failed to fetch subregions for "${region}"`);
};
