import { Quote } from '../api/intf/item';

export default function getQuoteListings(quote?: Quote) {
  return (
    quote?.quote_listings?.filter(listing => listing.deleted_at == null) || []
  );
}

export function getDeletedQuoteListings(quote?: Quote) {
  return (
    quote?.quote_listings?.filter(listing => listing.deleted_at != null) || []
  );
}

export function hasQuoteListings(quote?: Quote) {
  return quote?.quote_listings?.length! > 0;
}
