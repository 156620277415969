import React from 'react';
import SimpleModal, { ModalProps } from '../Modal/SimpleModal';
import FormikZodSchema from '../Form/FormikZodSchema';
import { Form } from 'formik';
import { z } from 'zod';
import useQuoteQuery from '../../hooks/query/useQuoteQuery';
import useCustomerById from '../../hooks/useCustomerById';
import { formatFormikError } from '../../api/ApiError';
import { putUpdateCustomer } from '../../api/customer';
import FormikField from '../Form/FormikField';
import FormikSubmitButton from '../Form/FormikSubmitButton';
import { Button } from '../Form/Button';
import { toast } from 'react-toastify';

const schema = z.object({
  email: z
    .string({
      required_error: 'Required',
    })
    .email('Must be an email'),
});

interface Props extends ModalProps {
  onSuccessfulSubmit: () => void;
  quoteId?: string;
}

const ManageQuotePayoutsEditEmailModal: React.FC<Props> = ({
  onSuccessfulSubmit,
  quoteId,
  ...props
}) => {
  const { data: quote } = useQuoteQuery(quoteId);
  const { data: contact } = useCustomerById(quote?.contact_id);

  if (!contact) {
    return null;
  }

  const handleCancel = props.onClose;

  return (
    <SimpleModal {...props} title="Edit Email" className="w-full max-w-md">
      <FormikZodSchema
        initialValues={{
          email: contact.email || '',
        }}
        onSubmit={async (values, formikHelpers) => {
          try {
            await putUpdateCustomer(
              contact.id,
              contact.first_name,
              contact.last_name,
              values.email,
              contact.phone,
            );
            toast.success('Email updated');
            onSuccessfulSubmit();
          } catch (e) {
            formikHelpers.setErrors(formatFormikError(e));
          }
        }}
        schema={schema}
      >
        <Form className="space-y-6">
          <FormikField name="email" label="Email" type="email" />
          <div className="grid grid-cols-2 gap-2">
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>

            <FormikSubmitButton>Save</FormikSubmitButton>
          </div>
        </Form>
      </FormikZodSchema>
    </SimpleModal>
  );
};

export default ManageQuotePayoutsEditEmailModal;
