import React from 'react';
import classNames from 'classnames';
import CalculatorIcon from '../Icons/CalculatorIcon';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

const CalculatorCheckbox: React.FC<Props> = ({ checked, onChange }) => {
  return (
    <label>
      <input
        type="checkbox"
        className="hidden"
        onChange={onChange}
        checked={checked}
      />

      <span
        className={classNames(
          'cursor-pointer transition-colors duration-300',
          checked ? 'text-green-500' : 'text-grey-500',
        )}
      >
        <CalculatorIcon />
      </span>
    </label>
  );
};

export default CalculatorCheckbox;
