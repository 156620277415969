import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import Message, { MessageProps } from './Message';
import { ApiFormikError, FieldError } from '../../api/ApiError';

type Props = Omit<MessageProps, 'error' | 'warning' | 'info'> & {
  showFieldErrors?: string[] | boolean;
};

const FormikErrorMessage: React.FC<Props> = ({ showFieldErrors, ...props }) => {
  const context = useFormikContext();

  const errorChildren = useMemo(() => {
    let errors = context.errors;

    if (!errors) {
      return null;
    }

    // Catch case where you passed Formik the entire errors response payload, not just the errors array.
    if ('errors' in errors) {
      // @ts-ignore
      errors = errors.errors;
    }

    if ((errors as ApiFormikError)._message) {
      return <div>{(errors as ApiFormikError)._message}</div>;
    }

    // errors haven't been formatted by formatFormikError, or manually set
    if (!Array.isArray(errors)) {
      return null;
    }

    return (errors as FieldError[])
      .filter(e => {
        if (e.field && Array.isArray(showFieldErrors)) {
          return showFieldErrors.includes(e.field);
        } else if (showFieldErrors) {
          return true;
        } else {
          return !e.field;
        }
      })
      .map(e => (
        <div key={e.message || e.detail}>
          {showFieldErrors && `${e.field}: `}
          {e.message || e.detail}
        </div>
      ));
  }, [context.errors, showFieldErrors]);

  if (
    !errorChildren ||
    (Array.isArray(errorChildren) && errorChildren.length === 0)
  ) {
    return null;
  }

  return (
    <Message error {...props}>
      {errorChildren}
    </Message>
  );
};

export default FormikErrorMessage;
