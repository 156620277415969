import axios from 'axios';

import {
  CreateCustomerRequest,
  CreateQuoteRequest,
  Customer,
} from './intf/customer';
import { getBaseUrl } from './axiosInitialization';
import { ApiResponse } from './intf/generic';
import { Quote } from './intf/item';

export const postCustomer = async (
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  source?: string,
) => {
  const { data } = await axios.post<ApiResponse<Customer>>(
    '/iam/auth/v1/contacts',
    {
      email,
      source,
      first_name: firstName,
      last_name: lastName,
      phone: phoneNumber,
    } as CreateCustomerRequest,
  );

  return data.data;
};

export const putUpdateCustomer = async (
  customerId: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
) => {
  const { data } = await axios.put<ApiResponse<Customer>>(
    `/iam/auth/v1/contacts/${customerId}`,
    {
      email,
      first_name: firstName,
      last_name: lastName,
      phone: phoneNumber,
    } as CreateCustomerRequest,
  );

  return data.data;
};

export const fetchCustomerById = async (customerId: string) => {
  const { data } = await axios.get<ApiResponse<Customer>>(
    `/iam/auth/v1/contacts/${customerId}`,
  );

  if (data) {
    return data.data;
  }

  throw new Error('fetch customer error');
};

export const postQuote = async (
  contactId: string,
  eventId: string,
  type: string,
  submittedBy: string,
) => {
  const { data } = await axios.post<ApiResponse<Quote>>(
    '/trade-in/v1/quotes',
    {
      type,
      contact_id: contactId,
      trade_in_event_id: eventId,
      submitted_by: submittedBy,
    } as CreateQuoteRequest,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Create Quote error');
};

export const fetchCustomerByQuery = async (searchItem: string) => {
  const { data } = await axios.get<ApiResponse<Customer[]>>(
    '/iam/auth/v1/contacts',
    {
      params: {
        query: searchItem || undefined,
      },
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch customers by query error');
};

export const fetchRecentCustomer = async () => {
  const { data } = await axios.get<ApiResponse<Customer[]>>(
    '/iam/auth/v1/contacts',
  );

  if (data.data) {
    return data.data?.[0];
  }

  throw new Error('Fetch customers by query error');
};

export const fetchCustomerByEmail = async (searchItem: string) => {
  const { data } = await axios.get<ApiResponse<Customer[]>>(
    '/iam/auth/v1/contacts',
    {
      params: {
        email: searchItem,
      },
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch customers by email error');
};

export const fetchCustomerByPhone = async (searchItem: string) => {
  const { data } = await axios.get<ApiResponse<Customer[]>>(
    '/iam/auth/v1/contacts',
    {
      params: {
        phone: searchItem,
      },
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch customers by phone error');
};
