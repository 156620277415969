import React from 'react';
import classNames from 'classnames';
import { WarehouseSwap } from '../../../api/intf/warehouseSwap';
import NewTabLink from '../../NewTabLink';
import { MARKETPLACE_URL } from '../../../constants/marketplaceUrl';

interface Props {
  swap: WarehouseSwap;
}

const WarehouseSwapRow: React.FC<Props> = ({ swap }) => {
  const item = swap.items[0];

  return (
    <article className="rounded border px-4 py-3">
      <div className="flex space-x-2">
        <img
          src={item.primary_image.edge_url}
          alt=""
          className="h-12 w-12 flex-shrink-0 rounded object-cover"
        />

        <div className="flex-1 space-y-2">
          <section>
            <header
              className={classNames(
                'mb-2 block line-clamp-2',
                swap.unread && 'font-bold',
              )}
            >
              <span>@{swap.buyer.username}</span> | <span>{item.name}</span>
            </header>

            <ul className="space-y-0.5 text-sm">
              <li>Item ID: {item.legacy_id || <i>None</i>}</li>
              <li>Location ID: {item.location_id || <i>None</i>}</li>
              <li>Carrier: {swap.tracker?.name || <i>N/A</i>}</li>
            </ul>
          </section>
        </div>

        <div className="text-right">
          <span className="mb-4 block">{swap.label}</span>
          <span className="block text-sm">Sold by: {swap.seller.username}</span>
        </div>
      </div>

      <section className="mt-2 flex space-x-4 text-sm text-slate-green-500">
        <NewTabLink href={`${MARKETPLACE_URL}/swaps/${swap.legacy_id}`}>
          View Swap
        </NewTabLink>

        <NewTabLink href={`${MARKETPLACE_URL}/gear/${item.legacy_id}`}>
          View Item
        </NewTabLink>

        <NewTabLink href={`./print_bulk?swap_ids=${swap.id}`}>
          Print Label
        </NewTabLink>

        <NewTabLink
          href={`https://admin.sidelineswap.com/admin/swaps/${swap.id}`}
        >
          View in Admin
        </NewTabLink>

        {/*{swap.actions.find(a => a.slug === 'mark_as_read') && (
          <fetcher.Form action={`${swap.id}/mark_as_read`} method="post">
            <button type="submit">Mark as Read</button>
          </fetcher.Form>
        )}*/}

        {/*{swap.actions.find(a => a.slug === 'mark_as_unread') && (
          <fetcher.Form action={`${swap.id}/mark_as_unread`} method="post">
            <button type="submit">Mark as Unread</button>
          </fetcher.Form>
        )}*/}
      </section>

      {/*{errorMessage && <Message error>{errorMessage}</Message>}*/}
    </article>
  );
};

export default WarehouseSwapRow;
