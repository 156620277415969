import axios from 'axios';
import { getBaseUrl } from './axiosInitialization';
import { ApiResponse } from './intf/generic';
import { ListingItem } from './intf/item';

export const fetchPartnerItemsToTag = async () => {
  const { data } = await axios.get<ApiResponse<ListingItem[]>>(
    '/trade-in/v1/in-store/events/items-to-tag',
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );
  return data.data;
};

export const fetchPartnerItemsRecentlyTagged = async () => {
  const { data } = await axios.get<ApiResponse<ListingItem[]>>(
    '/trade-in/v1/in-store/events/items-recently-tagged',
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );
  return data.data;
};

export interface PartnerTagRequest {
  tag_id: string;
}

export const putPartnerItemTag = async (
  itemId: string,
  tag: string,
): Promise<void> => {
  await axios.put(
    `/warehouse/v1/items/${itemId}/tag`,
    {
      tag_id: tag,
    } as PartnerTagRequest,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );
};

export const getItemByTag = async (tag: string): Promise<ListingItem> => {
  const { data } = await axios.delete<ApiResponse<ListingItem>>(
    `/warehouse/v1/transfers/items`,
    {
      baseURL: getBaseUrl('inventoryApi'),
      params: {
        tag_id: tag,
      },
    },
  );
  return data.data;
};
