import React from 'react';
import { Quote } from '../../../api/intf/item';
import { useQuery } from 'react-query';
import { fetchCustomerById } from '../../../api/customer';
import { Link } from 'react-router-dom';
import { Button } from '../../Form/Button';
import classNames from 'classnames';

interface Props {
  quote: Quote;
}

const DashboardRecentQuoteCard: React.FC<Props> = ({ quote }) => {
  const { data: contact } = useQuery(
    ['contact', quote.contact_id],
    () => fetchCustomerById(quote.contact_id!),
    {
      enabled: !!quote.contact_id,
    },
  );

  const route =
    quote.status === 'COMPLETED'
      ? `/partner/dashboard/tag?quote_id=${quote.id}`
      : `/partner/quote/${quote.id}/summary`;

  return (
    <div className="space-y-2 rounded border p-4 shadow">
      <div className="font-semibold">
        {contact?.last_name && contact?.first_name ? (
          <span className="font-semibold">
            {contact.last_name}, {contact.first_name}
          </span>
        ) : (
          <i>No name</i>
        )}
      </div>

      <div className="text-slate-green-500">
        <div>Quote ID: {quote.display_id}</div>
        <div>Created: {new Date(quote.created_at).toLocaleDateString()}</div>
        <div>Items: {quote.listing_count}</div>
        <div>
          Status:{' '}
          <span
            className={classNames('text-sm font-semibold', {
              'text-green-500': quote.status === 'COMPLETED',
              'text-yellow-500': quote.status === 'PENDING',
              'text-red-500': quote.status === 'DELETED',
            })}
          >
            {quote.status}
          </span>
        </div>
      </div>

      <Link to={route} className="inline-block">
        <Button variant="outlined">View</Button>
      </Link>
    </div>
  );
};

export default DashboardRecentQuoteCard;
