import React, { useEffect, useState } from 'react';
import { QueryClient, useQuery, useQueryClient } from 'react-query';
import RenderOption from './RenderOption';
import { getDetailPathById } from '../../utils/getDetailPathById';
import {
  TradeInDetailOption,
  TradeInOptionMap,
  TradeInSelection,
  TradeInSkipToken,
} from '../../types/detailSelection';
import {
  fetchCategoriesAsTree,
  fetchCategoryById,
  fetchModels,
  fetchTradeInModelVersions,
} from '../../api/item';
import { CategoryTree } from '../../utils/createCategoryTreeFromFlatList';
import { TradeInModelVersion } from '../../api/intf/item';
import { useEventCustomerQuoteQuery } from '../../hooks/routing/useEventCustomerQuoteQuery';
import {
  fireSelectedCategory,
  fireSelectedDetail,
  fireSelectedVersion,
} from '../../services/analytics/partner/quotes';
import ModelVersionTile from './ModelVersionTile';
import useOrgQuery from '../../hooks/query/useOrgQuery';
import { getRenderOptionSubtitle } from '../../constants/inStoreQuoteFlow';

async function fetchDetailOptions(
  queryClient: QueryClient,
  categoryId: string,
): Promise<TradeInDetailOption[]> {
  const fullCategory = await queryClient.fetchQuery(
    ['details', categoryId],
    () => fetchCategoryById(categoryId),
    {
      staleTime: Infinity,
    },
  );

  return (
    fullCategory?.details
      ?.filter(
        detail =>
          detail.slug !== 'category' &&
          detail.slug !== 'brand' &&
          detail.trade_in_required,
      )
      .map(detail => ({
        title: detail.name,
        slug: detail.slug,
        detail: {
          name: detail.name,
          slug: detail.slug,
          // title_name: detail.title_name,
          // title_order: detail.title_order,
        },
        values:
          detail.options?.map(value => ({
            name: value.name,
            slug: value.slug,
            id: value.id,
            image_url: value.image_url || '',
            // title_name: value.title_name,
            // title_order: value.title_order,
          })) || [],
        selected: null,
      })) || []
  );
}

interface Props {
  children?: React.ReactNode;
  options: TradeInOptionMap;
  setOptions: React.Dispatch<React.SetStateAction<TradeInOptionMap>>;
}

const TradeInDetailSelectionFlow: React.FC<Props> = ({
  children,
  options,
  setOptions,
}) => {
  // Quote ID is only for analytics
  const { quoteId } = useEventCustomerQuoteQuery();
  const { data: orgResponse } = useOrgQuery();
  const queryClient = useQueryClient();
  const [loadingNextSelection, setLoadingNextSelection] = useState(false);
  const orgId = orgResponse?.org?.id;
  const parentOrgId = orgResponse?.parent_org?.id;

  // Show the available highest-level categories available.
  // This could be secondary categories or top-level.
  // It's top level categories when there are no forced allowed categories through the white label configuration.
  useQuery(['categories'], () => fetchCategoriesAsTree(orgId!, parentOrgId!), {
    staleTime: Infinity,
    enabled: !!parentOrgId && !!orgId,
    onSuccess: data => {
      if (!options.categories?.[0]?.selected) {
        setOptions({
          categories: [
            {
              title: 'Select a category',
              slug: 'category',
              values:
                data?.map(child => ({
                  name: child.name,
                  slug: child.slug,
                  id: child.id,
                  image_url: child.image_url || '',
                  // title_name: child.title_name,
                  // title_order: child.title_order,
                })) || [],
              selected: null,
            },
          ],
        });
      }
    },
  });

  useEffect(() => {
    // If mounted with data for categories, copy into the options state
    const data = queryClient.getQueryData<CategoryTree[]>(['categories']);
    if (data && !options.categories?.[0]?.selected) {
      setOptions({
        categories: [
          {
            title: 'Select a category',
            slug: 'category',
            values:
              data?.map(child => ({
                name: child.name,
                slug: child.slug,
                id: child.id,
                image_url: child.image_url || '',
                // title_name: child.title_name,
                // title_order: child.title_order,
              })) || [],
            selected: null,
          },
        ],
      });
    }
  }, []);

  async function makeCategorySelection(
    index: number,
    selection: TradeInSelection,
  ) {
    fireSelectedCategory(selection.name, quoteId);

    setLoadingNextSelection(true);
    setOptions(prev => ({
      categories: prev.categories.map((category, i) => {
        if (i === index) {
          return {
            ...category,
            selected: selection,
          };
        }
        return category;
      }),
    }));

    let firstCategoryId: string;
    if (options.categories[0].selected && index !== 0) {
      firstCategoryId = options.categories[0].selected.id;
    } else {
      firstCategoryId = selection.id;
    }

    // If there isn't a 0th index, then we've just selected the first category
    // const firstCategoryId = options.categories[0]?.selected?.id ?? selection.id;

    const categories = await queryClient.fetchQuery(
      ['categories'],
      () => fetchCategoriesAsTree(orgId!, parentOrgId!),
      {
        staleTime: Infinity,
      },
    );

    // Determine which category level we're on so we can filter the next level
    // of categories. Can't just use options.categories.length in case there is
    // only one allowed top-level category. In this case, the first category
    // will be selected, but the options.categories array will still be empty.

    const categoryPath =
      getDetailPathById<CategoryTree>(categories, selection.id) ?? categories;
    const categoryLevel = categoryPath.length;
    const justSelectedCategory = categoryPath[categoryLevel - 1];

    let filteredCategories = justSelectedCategory.children;

    if (filteredCategories?.length! > 0) {
      setOptions(prev => ({
        categories: [
          ...prev.categories.slice(0, index + 1),
          {
            title: `Select a type of ${selection.name}`,
            slug: selection.slug,
            values:
              filteredCategories?.map(child => ({
                name: child.name,
                slug: child.slug,
                id: child.id,
                image_url: child.image_url || '',
                // title_name: child.title_name,
                // title_order: child.title_order,
              })) || [],
            selected: null,
          },
        ],
      }));
    } else {
      // do brands
      const fullCategory = await queryClient.fetchQuery(
        ['details', selection.id],
        () => fetchCategoryById(selection.id),
        {
          staleTime: Infinity,
        },
      );

      const brands = fullCategory?.brands || [];

      setOptions(prev => ({
        // Slicing it like this removes any future category levels that were selected if the categories changed
        categories: prev.categories.slice(0, index + 1),
        brand: {
          title: 'Select a brand',
          slug: 'brand',
          values:
            brands.map(brand => ({
              name: brand.name,
              slug: brand.slug,
              id: brand.id,
              image_url: brand.image_url || '',
              title_name: brand.title_name,
              // title_order: brand.title_order,
            })) || [],
          selected: null,
        },
      }));
    }

    setLoadingNextSelection(false);
  }

  async function makeBrandSelection(selection: TradeInSelection) {
    // TODO: Error resolution flow here?
    if (!options.brand) {
      return;
    }

    fireSelectedDetail({
      quoteId,
      detailName: selection.name,
      detailType: 'Brand',
      hasImage: !!selection.image_url,
    });

    setLoadingNextSelection(true);
    setOptions({
      categories: options.categories,
      brand: {
        ...options.brand,
        selected: selection,
      },
    });

    const lastCategory =
      options.categories[options.categories.length - 1]?.selected;

    // TODO: Error resolution flow here?
    if (!lastCategory) {
      return;
    }

    const models = await queryClient.fetchQuery(
      ['models', lastCategory.id, selection.id],
      () => fetchModels(lastCategory.id, selection.id),
      {
        staleTime: Infinity,
      },
    );

    if (models?.length > 0) {
      setOptions(prev => ({
        categories: prev.categories,
        brand: prev.brand,
        model: {
          title: 'Select a model',
          slug: 'model',
          values:
            models
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(model => ({
                name: model.name,
                slug: model.slug,
                id: model.id,
                image_url: model.image_url || '',
                title_name: model.name,
                title_order: undefined,
              })) || [],
          selected: null,
        },
      }));
    } else {
      // No models, go straight to details
      const details = await fetchDetailOptions(queryClient, lastCategory.id);

      setOptions(prev => ({
        categories: prev.categories,
        brand: prev.brand,
        model: prev.model,
        details,
      }));
    }

    setLoadingNextSelection(false);
  }

  async function makeModelSelection(selection: TradeInSelection) {
    // TODO: Error resolution flow here?
    if (!options.model) {
      return;
    }

    fireSelectedDetail({
      quoteId,
      detailName: selection.name,
      detailType: 'Model',
      hasImage: !!selection.image_url,
    });

    setLoadingNextSelection(true);
    setOptions({
      categories: options.categories,
      brand: options.brand,
      model: {
        ...options.model,
        selected: selection,
      },
    });

    const lastCategory =
      options.categories[options.categories.length - 1]?.selected;

    // TODO: Error resolution flow here?
    if (!lastCategory) {
      return;
    }

    const details = await fetchDetailOptions(queryClient, lastCategory.id);

    setOptions(prev => ({
      categories: prev.categories,
      brand: prev.brand,
      model: prev.model,
      details,
    }));

    setLoadingNextSelection(false);
  }

  async function makeDetailSelection(
    index: number,
    selection: TradeInSelection | TradeInSkipToken,
  ) {
    // TODO: Error resolution flow here?
    if (!options.details) {
      return;
    }

    if (selection === 'SKIP') {
      fireSelectedDetail({
        quoteId,
        detailName: 'SKIP',
        detailType: options.details?.[index]?.detail?.name,
        hasImage: false,
      });
    } else {
      fireSelectedDetail({
        quoteId,
        detailName: selection.name,
        detailType: options.details?.[index]?.detail?.name,
        hasImage: !!selection.image_url,
      });
    }

    setLoadingNextSelection(true);

    let newOptions: TradeInOptionMap = {
      categories: options.categories,
      brand: options.brand,
      model: options.model,
      details: options.details.map((detail, i) => {
        if (i === index) {
          return {
            ...detail,
            selected: selection,
          };
        }

        // Deselect all future options
        if (i > index) {
          return {
            ...detail,
            selected: null,
          };
        }

        return detail;
      }),
    };

    // Finished selecting details, fetch value guide info and save into the item
    if (index === options.details.length - 1) {
      try {
        const detailUuids =
          newOptions.details?.reduce((acc, detail) => {
            if (detail.selected && detail.selected !== 'SKIP') {
              acc.push(detail.selected.id);
            }
            return acc;
          }, [] as string[]) || [];

        if (options.model?.selected?.id) {
          const modelVersions = await queryClient.fetchQuery(
            [
              'model-versions',
              options.model?.selected?.id,
              detailUuids,
            ] as const,
            ({ queryKey }) => {
              const [, modelId, uuids] = queryKey;
              return fetchTradeInModelVersions(modelId, uuids);
            },
            {
              staleTime: Infinity,
            },
          );

          if (modelVersions?.length > 0) {
            newOptions.modelVersions = {
              options: modelVersions,
              selected: null,
            };
          } /* else {
            const categoryId =
              options.categories[options.categories.length - 1].selected?.id;
            const finalValueOptions = {
              category: categoryId ? [categoryId] : undefined,
              brand: options.brand?.selected?.id
                ? [options.brand?.selected?.id]
                : undefined,
              model: options.model?.selected?.id
                ? [options.model?.selected?.id]
                : undefined,
              ...detailOptions,
            };

            newOptions.valueGuideAmount = await queryClient.fetchQuery(
              ['value-guide', finalValueOptions],
              () => fetchFinalValue(finalValueOptions),
              {
                staleTime: Infinity,
              },
            );
          }*/
        }
      } catch (e) {
        console.error('Failed to get a value guide calculation', e);
      }
    }

    setOptions(newOptions);
    setLoadingNextSelection(false);
  }

  async function makeModelVersionSelection(selection: TradeInModelVersion) {
    fireSelectedVersion(options, selection, quoteId);

    setOptions(prev => ({
      ...prev,
      valueGuideAmount: Number(selection.price_statistics?.price_retail) || 0,
      modelVersions: {
        options: prev.modelVersions?.options || [],
        selected: selection,
      },
    }));
  }

  useEffect(() => {
    // If the last option is not selected, scroll to it.
    // Only scroll if it's not the first option

    function scrollTo(id: string) {
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }

    const unselectedCategoryIndex = options.categories.findIndex(
      category => !category.selected,
    );

    // Only scroll to the _next_ unselected category (stops from scrolling as soon as the page loads)
    if (unselectedCategoryIndex >= 1) {
      scrollTo(`option-category-${unselectedCategoryIndex}`);
    } else if (options.brand && !options.brand.selected) {
      scrollTo('option-brand');
    } else if (options.model && !options.model.selected) {
      scrollTo('option-model');
    } else if (options.details) {
      const unselectedDetailIndex = options.details.findIndex(
        detail => !detail.selected,
      );

      if (unselectedDetailIndex >= 0) {
        scrollTo(`option-detail-${unselectedDetailIndex}`);
      } else if (options.modelVersions && !options.modelVersions.selected) {
        scrollTo('model-versions');
      } else {
        scrollTo('option-done');
      }
    }
  }, [options]);

  const selectedDetails = options.details?.filter(d => d.selected);
  const nextUnselectedDetail = options.details?.find(d => !d.selected);

  let complete: boolean = false;

  // If model version exist, it's complete when a model version is selected.
  // Otherwise, if selected details exist, it's complete when there is no next unselected detail.
  if (options.modelVersions) {
    complete = !!options.modelVersions.selected;
  } else if (selectedDetails) {
    complete = !nextUnselectedDetail;
  }

  return (
    <>
      <div className="space-y-8">
        {options.categories.map((option, index) => (
          <RenderOption
            key={option.slug + index}
            alwaysShowText
            option={option}
            number={index + 1}
            onSelect={selection => makeCategorySelection(index, selection)}
            id={`option-category-${index}`}
            disabled={loadingNextSelection}
          />
        ))}

        {options.brand && (
          <RenderOption
            option={options.brand}
            number={options.categories.length + 1}
            onSelect={selection => makeBrandSelection(selection)}
            id="option-brand"
            disabled={loadingNextSelection}
            searchable
            type="brand"
          />
        )}

        {options.model && (
          <RenderOption
            alwaysShowText
            option={options.model}
            number={options.categories.length + 2}
            onSelect={selection => makeModelSelection(selection)}
            id="option-model"
            disabled={loadingNextSelection}
            searchable
          />
        )}

        {selectedDetails?.map((option, index) => (
          <RenderOption
            key={option.slug + index}
            alwaysShowText
            option={option}
            number={options.categories.length + (options.model ? 3 : 2) + index}
            onSelect={selection => makeDetailSelection(index, selection)}
            onSkip={() => makeDetailSelection(index, 'SKIP')}
            id={`option-detail-${index}`}
            disabled={loadingNextSelection}
            skippable
            getSubtitle={selection =>
              getRenderOptionSubtitle(options, selection)
            }
          />
        ))}

        {nextUnselectedDetail && (
          <RenderOption
            option={nextUnselectedDetail}
            alwaysShowText
            number={
              options.categories.length +
              (options.model ? 3 : 2) +
              (selectedDetails?.length || 0)
            }
            onSelect={selection =>
              makeDetailSelection(selectedDetails?.length || 0, selection)
            }
            onSkip={() =>
              makeDetailSelection(selectedDetails?.length || 0, 'SKIP')
            }
            id={`option-detail-${selectedDetails?.length || 0}`}
            disabled={loadingNextSelection}
            skippable
            getSubtitle={selection =>
              getRenderOptionSubtitle(options, selection)
            }
          />
        )}

        {options.modelVersions && (
          <div id="model-versions">
            <div className="mb-4 -mt-10 pt-10 text-xl font-semibold">
              Select an Option
            </div>

            <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
              {options.modelVersions.options.map(modelVersion => (
                <ModelVersionTile
                  key={modelVersion.id}
                  modelVersion={modelVersion}
                  selected={
                    options.modelVersions?.selected?.id === modelVersion.id
                  }
                  onSelect={makeModelVersionSelection}
                />
              ))}
            </div>
          </div>
        )}

        {complete && children}
      </div>
    </>
  );
};

export default TradeInDetailSelectionFlow;
