import React, { useEffect } from 'react';
import Field from '../../Form/Field';
import FieldLabel from '../../Form/FieldLabel';
import Input from '../../Form/Input';
import { useQuery } from 'react-query';
import { fetchSearchTradeInModelVersions } from '../../../api/item';
import ModelVersionTile from '../../DetailSelectionFlow/ModelVersionTile';
import { TradeInModelVersion } from '../../../api/intf/item';
import useDebounceValue from '../../../hooks/useDebounceValue';
import { TradeInOptionMap } from '../../../types/detailSelection';

export default function PartnerCheckoutModelVersionSearch({
  children,
  options,
  search,
  setSearch,
  setOptions,
}: {
  children?: React.ReactNode;
  options: TradeInOptionMap;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setOptions: React.Dispatch<React.SetStateAction<TradeInOptionMap>>;
}) {
  const debouncedSearch = useDebounceValue(search, 500);

  const { data: searchResults } = useQuery(
    ['model-version-search', debouncedSearch],
    () => fetchSearchTradeInModelVersions(debouncedSearch),
    {
      enabled: debouncedSearch.length > 0,
      keepPreviousData: true,
      staleTime: Infinity,
    },
  );

  function handleModelVersionSelect(modelVersion: TradeInModelVersion) {
    // convert a trade-in model version to the option map
    setOptions({
      categories: [],
      valueGuideAmount:
        Number(modelVersion.price_statistics?.price_retail) || 0,
      modelVersions: {
        selected: modelVersion,
        options: [],
      },
    });
  }

  useEffect(() => {
    if (options.modelVersions?.selected?.id) {
      const element = document.getElementById('option-done');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [options.modelVersions?.selected?.id]);

  return (
    <div>
      <Field>
        <FieldLabel>Or search for a model number</FieldLabel>
        <Input
          type="search"
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder="Ex: WTLBBO517"
          className="text-xl"
        />
      </Field>

      {search.length > 0 && (
        <div id="model-versions" className="mt-6 mb-8">
          <div className="mb-4 -mt-10 pt-10 text-xl font-semibold">
            Select an Option
          </div>

          <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
            {searchResults?.map(modelVersion => (
              <ModelVersionTile
                key={modelVersion.id}
                modelVersion={modelVersion}
                selected={
                  options.modelVersions?.selected?.id === modelVersion.id
                }
                onSelect={handleModelVersionSelect}
              />
            ))}
          </div>
        </div>
      )}

      {options.modelVersions?.selected && search && children}
    </div>
  );
}
