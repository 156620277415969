import axios from 'axios';
import { ApiResponse } from './intf/generic';
import { PostItemRequest, Quote } from './intf/item';
import { getBaseUrl } from './axiosInitialization';
import { TradeInOptionMap } from '../types/detailSelection';
import { postListingItem, putUpdateQuote } from './item';
import { getTradeInValueForModelVersion } from '../utils/modelVersionValues';

export const postCreatePartnerQuote = async () => {
  const { data } = await axios.post<ApiResponse<Quote>>(
    '/trade-in/v1/in-store/quotes',
    {
      type: 'PARTNER',
    },
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (!data.data) {
    throw new Error('Error on creating quote');
  }

  return data.data;
};

export const fetchPartnerQuotes = async () => {
  const { data } = await axios.get<ApiResponse<Quote[]>>(
    '/trade-in/v1/in-store/quotes',
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (!data.data) {
    throw new Error('Error fetching quotes');
  }

  return data.data;
};

export const putUpdateContactOnQuote = async (
  quoteId: string,
  contactId: string,
) => {
  return await putUpdateQuote(quoteId, {
    contact_id: contactId,
  });
};

function tradeInOptionMapToRequestBody(options: TradeInOptionMap) {
  const body: PostItemRequest = {
    category_id:
      options.categories[options.categories.length - 1]?.selected?.id,
    model_id: options.model?.selected?.id,
    brand_id: options.brand?.selected?.id,
    name: options.modelVersions?.selected?.name,
    quantity: 1,
    model_version_id: options.modelVersions?.selected?.id,
    details: [],
    cash_offer_amount: getTradeInValueForModelVersion(
      options.modelVersions?.selected,
    ),
    resale_amount:
      Number(
        options.modelVersions?.selected?.price_statistics?.suggested_resale,
      ) || 0,
    retail_price:
      Number(options.modelVersions?.selected?.price_statistics?.price_retail) ||
      0,
    credit_offer_amount: getTradeInValueForModelVersion(
      options.modelVersions?.selected,
    ),
  };
  return body;
}

export const postCreatePartnerQuoteAndListing = async (
  options: TradeInOptionMap,
) => {
  const quote = await postCreatePartnerQuote();
  const body = tradeInOptionMapToRequestBody(options);

  const listing = await postListingItem(quote.id, body);

  return { quote, listing };
};

export const putAddListingToQuote = async (
  quoteId: string,
  options: TradeInOptionMap,
) => {
  const body = tradeInOptionMapToRequestBody(options);
  const listing = await postListingItem(quoteId, body);
  return listing;
};
