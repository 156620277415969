import { Form } from 'formik';
import React from 'react';
import { formatFormikError, getErrorMessage } from '../../api/ApiError';
import { useNavigate } from 'react-router-dom';
import { postCreateEvent } from '../../api/events';
import { useQuery } from 'react-query';
import { fetchAllPayoutMethods } from '../../api/paymentMethods';
import Message from '../Form/Message';
import { z } from 'zod';
import FormikZodSchema from '../Form/FormikZodSchema';

export const eventFormSchema = z
  .object({
    end_date: z.string().optional(),
    start_date: z.string(),
    partner: z.object({
      id: z.string(),
      name: z.string(),
      addresses: z.any(),
    }),
    location: z.object({
      id: z.string(),
    }),
    payout_methods: z.array(
      z
        .object({
          id: z.string(),
          name: z.string(),
          enabled: z.any().default(false),
          charities: z.array(z.string()).optional(),
          credit_offer_enabled: z.any(),
          credit_offer_amount: z.coerce.number().optional(),
          payout_type: z.any(),
        })
        .transform(data => ({
          id: data.id,
          name: data.name,
          enabled: data.enabled,
          charities: data.charities,
          credit_offer_amount: data.credit_offer_amount,
        })),
      {
        required_error: 'Required',
      },
    ),
    freight_boxes: z.coerce.number().optional(),
    staff_count: z.coerce.number().optional(),
    retro_notes: z.string().optional(),
    event_type: z.object({
      id: z.string(),
      description: z.string(),
    }),
  })
  .refine(
    data =>
      data.payout_methods.filter(payoutMethod => payoutMethod.enabled).length >
      0,
    'Must have at least 1 payout option selected',
  )
  .refine(data => {
    const donatePayoutMethod = data.payout_methods.find(
      payoutMethod =>
        payoutMethod.enabled &&
        payoutMethod.charities &&
        payoutMethod.charities.length === 0,
    );

    return !donatePayoutMethod;
  }, 'If Donate is a selected option, you must have at least 1 charity');

export type EventFormValues = z.input<typeof eventFormSchema>;

interface Props {}

const CreateEventForm: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { data: payoutMethods, error } = useQuery(
    ['payout-methods', 'all'],
    fetchAllPayoutMethods,
    {
      // Never invalidate because that would screw up the user's input.
      // We could solve this by deep merging the arrays without overwriting
      // the `enabled` flag but meh.
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: false,
    },
  );

  if (error) {
    return (
      <Message error>
        Failed to fetch payout methods: "{getErrorMessage(error)}"
      </Message>
    );
  }

  if (!payoutMethods) {
    return null;
  }

  const initialValues: EventFormValues = {
    // @ts-ignore
    event_type: '',
    start_date: '',
    payout_methods: payoutMethods,
  };

  return (
    <FormikZodSchema
      schema={eventFormSchema}
      initialValues={initialValues}
      onSubmit={async (values, formikHelpers) => {
        try {
          const event = await postCreateEvent({
            partner_id: values.partner.id,
            partner_address_id: values.location.id,
            start_date: values.start_date,
            end_date: values.end_date,
            event_type: values.event_type?.id!,
            payout_methods: values.payout_methods.map(payoutMethod => ({
              ...payoutMethod,
              credit_offer_amount:
                payoutMethod.credit_offer_amount &&
                payoutMethod.credit_offer_amount > 1
                  ? payoutMethod.credit_offer_amount / 100
                  : payoutMethod.credit_offer_amount,
            })),
            freight_boxes: values.freight_boxes,
            staff_count: values.staff_count,
            retro_notes: values.retro_notes,
          });
          navigate(`/event/${event.id}`);
        } catch (e) {
          formikHelpers.setErrors(formatFormikError(e));
        }
      }}
    >
      <Form>{children}</Form>
    </FormikZodSchema>
  );
};

export default CreateEventForm;
