import React, { useState } from 'react';
import { TradeInOptionMap } from '../../../types/detailSelection';
import TradeInDetailSelectionFlow from '../../../components/DetailSelectionFlow/TradeInDetailSelectionFlow';
import TradeInContinueToCondition from '../../../components/DetailSelectionFlow/TradeInContinueToCondition';
import useCurrentQuoteQuery from '../../../hooks/query/useCurrentQuoteQuery';
import InStoreQuoteLayout from '../../../components/Layout/Layouts/InStoreQuoteLayout';
import PartnerQuoteFlowBackButton from '../../../components/Partner/Quote/PartnerQuoteFlowBackButton';
import PartnerCheckoutModelVersionSearch from '../../../components/Partner/Quote/PartnerCheckoutModelVersionSearch';
import getQuoteListings from '../../../utils/getQuoteListings';
import TradeInConditionGuidance from '../../../components/DetailSelectionFlow/TradeInConditionGuidance';
import { ArrowLeftIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { Button } from '../../../components/Form/Button';

const SEARCH_ENABLED = true;

interface Props {}

const PartnerQuoteAddItemPage: React.FC<Props> = () => {
  const { data: quote } = useCurrentQuoteQuery();

  const [search, setSearch] = useState('');
  const [mode, setMode] = useState<'itemDetails' | 'condition'>('itemDetails');
  const [options, setOptions] = useState<TradeInOptionMap>({
    categories: [],
  });

  function handleStartOver() {
    setMode('itemDetails');
    setOptions({ categories: [] });
  }

  const warnOnExit = !!options.categories?.[0]?.selected;

  let content: React.ReactNode = null;

  if (mode === 'itemDetails') {
    content = (
      <>
        {!search && (
          <TradeInDetailSelectionFlow options={options} setOptions={setOptions}>
            <TradeInContinueToCondition
              options={options}
              onContinue={() => setMode('condition')}
            />
          </TradeInDetailSelectionFlow>
        )}

        {SEARCH_ENABLED && options.categories?.[0]?.selected == null && (
          <>
            <div className="mb-6" />

            <PartnerCheckoutModelVersionSearch
              search={search}
              setSearch={setSearch}
              options={options}
              setOptions={setOptions}
            >
              <TradeInContinueToCondition
                options={options}
                onContinue={() => setMode('condition')}
              />
            </PartnerCheckoutModelVersionSearch>
          </>
        )}
      </>
    );
  } else if (mode === 'condition') {
    content = (
      <TradeInConditionGuidance
        options={options}
        onStartOver={handleStartOver}
      />
    );
  }

  let backButton: React.ReactNode = null;

  if (mode === 'condition') {
    backButton = (
      <button
        type="button"
        className="-mt-4 mb-4 inline-flex items-center space-x-2"
        onClick={() => setMode('itemDetails')}
      >
        <ArrowLeftIcon className="h-4 w-4" />
        <span>Back to product details</span>
      </button>
    );
  } else if (getQuoteListings(quote).length > 0) {
    backButton = (
      <PartnerQuoteFlowBackButton to="summary" warnOnExit={warnOnExit} />
    );
  } else {
    backButton = (
      <PartnerQuoteFlowBackButton to="contact" warnOnExit={warnOnExit} />
    );
  }

  return (
    <InStoreQuoteLayout step="productDetails" warnOnExit={warnOnExit}>
      {backButton}

      {/*

        START OVER BUTTON
        
        <Button
          type="button"
          onClick={handleStartOver}
          disabled={
            options.categories?.[0]?.selected == null ||
            options.modelVersions?.selected == null
          }
          variant="text"
          className="mb-4 flex items-center gap-2"
        >
          <ArrowPathIcon className="h-4 w-4" />
          <span>Start over</span>
        </Button>*/}

      {content}
    </InStoreQuoteLayout>
  );
};

export default PartnerQuoteAddItemPage;
