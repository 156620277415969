import React from 'react';
import NumberInput, { NumberInputProps } from '../Form/NumberInput';

interface Props {}

const TagInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  (props, ref) => (
    <NumberInput
      aria-label="QR Code"
      autoFocus
      ref={ref}
      {...props}
      format="####-####"
      placeholder="____-____"
      mask="_"
    />
  ),
);

export default TagInput;
