import ButterClass, { Butter, ButterStatic } from 'buttercms';
import getEnv from '../utils/getEnv';

export const butterApi: ButterStatic = ButterClass(
  getEnv('REACT_APP_BUTTER_CMS_API_KEY')!,
) as any;

export type ButterInStoreConditionGuidancePage = {
  category_slugs: {
    category_slug: string;
  }[];
  conditions: {
    type: string;
    fields: {
      title: string;
      description: string;
      prompt: string;
      guidance: {
        caption: string;
        media: string;
      }[];
    };
  }[];
};

async function listButterPages<PageModel extends object>(pageType: string) {
  const response = await butterApi.page.list<PageModel>(pageType);
  return (
    (response as unknown as Butter.PageListResponse<
      PageModel,
      typeof pageType
    >) || null
  );
}

export const getButterConditionGuidancePages = async () => {
  try {
    const response = await listButterPages<ButterInStoreConditionGuidancePage>(
      'in-store_condition_guidance',
    );

    return response.data || null;
  } catch (e) {
    return null;
  }
};
