import React from 'react';
import { FieldConfig, useField } from 'formik';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format';
import Input from './Input';
import Field from './Field';
import FieldLabel from './FieldLabel';
import FieldError from './FieldError';
import FieldHint from './FieldHint';

export type FormikNumberFieldProps = Exclude<NumberFormatProps, 'name'> &
  Exclude<FieldConfig, 'value' | 'type'> & {
    changeProperty?: keyof NumberFormatValues;
    hint?: React.ReactNode;
    label?: React.ReactNode;
    required?: boolean;
  };

const FormikNumberField: React.FC<FormikNumberFieldProps> = ({
  hint,
  label,
  name,
  changeProperty = 'value',
  customInput,
  required,
  ...props
}) => {
  // Ignore onChange, we overwrite it with NumberFormat's onValueChange
  const [{ onChange, name: ignore, value, ...field }, meta, helpers] =
    useField(name);

  return (
    <Field>
      <FieldLabel htmlFor={name}>
        {typeof label === 'string' ? (
          <FieldLabel htmlFor={name} error={!!meta.error}>
            {label} {required && <span className="text-red-600">*</span>}
          </FieldLabel>
        ) : (
          label
        )}
      </FieldLabel>
      <NumberFormat
        name={name}
        id={name}
        error={meta.error}
        defaultChecked={meta.initialValue}
        defaultValue={meta.initialValue}
        customInput={customInput || Input}
        onValueChange={values => helpers.setValue(values[changeProperty])}
        inputMode="numeric"
        value={value ?? ''}
        {...field}
        {...props}
      />
      {meta.error && <FieldError id={name}>{meta.error}</FieldError>}
      {hint && <FieldHint>{hint}</FieldHint>}
    </Field>
  );
};

export default FormikNumberField;
