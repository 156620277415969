import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import EditItemForm from '../../../components/ItemForms/EditItemForm';
import { fetchCategories, fetchCategoryById } from '../../../api/item';
import useRouteQuery from '../../../hooks/useRouteQuery';
import { getCookie } from '../../../lib/KnownCookies';
import decodeJwt from '../../../utils/decodeJwt';
import ItemFormFields from '../../../components/ItemForms/ItemFormFields';
import { useEventCustomerQuoteQuery } from '../../../hooks/routing/useEventCustomerQuoteQuery';
import PageLayout from '../../../components/Layout/PageLayout';
import Message from '../../../components/Form/Message';
import { getErrorMessage } from '../../../api/ApiError';
import { fetchOrg } from '../../../api/orgs';
import ErrorBoundary from '../../../components/ErrorBoundary';

export interface EditItemPageQuery {
  mode: string;
  listingId: any;
  categoryId: any;
}

interface Props {}

const EditItemPage: React.FC<Props> = () => {
  const { quoteId, customerId } = useEventCustomerQuoteQuery();
  const accessToken = getCookie('access_token');
  const decodedToken = decodeJwt(accessToken);
  const orgId = decodedToken?.org_id || '';
  const query = useRouteQuery<EditItemPageQuery>();
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(
    query.categoryId,
  );
  const categoriesQuery = useQuery('fetchCategories', fetchCategories, {
    staleTime: 600000,
  });
  const categoryQuery = useQuery(
    ['fetchCategoryById', selectedCategoryId],
    () => fetchCategoryById(selectedCategoryId),
    {
      enabled: selectedCategoryId !== undefined,
      staleTime: 600000,
    },
  );
  const orgQuery = useQuery(['fetchOrg', orgId], () => fetchOrg(orgId), {
    staleTime: 600000,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageLayout
      header={
        <>
          <h1 className="text-3xl">
            {query.mode === 'copy' ? 'Add' : 'Update'} Item
          </h1>
        </>
      }
    >
      <ErrorBoundary
        fallback={<Message error>Ran into an error editing this item</Message>}
      >
        <EditItemForm
          categoryItem={categoryQuery.data}
          quoteId={quoteId}
          customerId={customerId}
          categoryId={selectedCategoryId}
          listingId={query.listingId}
          mode={query.mode}
          organization={orgQuery.data}
        >
          {!!categoriesQuery?.error && (
            <Message error>
              Failed to fetch categories:{' '}
              {getErrorMessage(categoriesQuery.error)}
            </Message>
          )}
          {!!categoryQuery?.error && (
            <Message error>
              Failed to fetch category ID <code>selectedCategoryId</code>:{' '}
              {getErrorMessage(categoryQuery.error)}
            </Message>
          )}

          <ItemFormFields
            categories={categoriesQuery.data}
            categoryItem={categoryQuery.data}
            setSelectedCategoryId={setSelectedCategoryId}
            organization={orgQuery.data}
          />
        </EditItemForm>
      </ErrorBoundary>
    </PageLayout>
  );
};

export default EditItemPage;
