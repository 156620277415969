import React, { useEffect, useState } from 'react';
import { TradeInOptionMap } from '../../types/detailSelection';
import { ButterInStoreConditionGuidancePage } from '../../api/buttercms';
import { Button } from '../Form/Button';
import Message from '../Form/Message';
import InStoreAddToCartButton from './InStoreAddToCartButton';
import useConditionGuidanceQuery from '../../hooks/query/useConditionGuidanceQuery';

type Answers = {
  answer: boolean | null;
}[];

export default function TradeInConditionGuidance({
  onStartOver,
  options,
}: {
  onStartOver: () => void;
  options: TradeInOptionMap;
}) {
  const [conditionResponses, setConditionResponses] = useState<null | Answers>(
    null,
  );

  const selectedCategorySlug =
    options.categories?.[options.categories.length - 1]?.selected?.slug ||
    options.modelVersions?.selected?.price_statistics?.category_slug ||
    undefined;

  const { guidance: conditions } =
    useConditionGuidanceQuery(selectedCategorySlug);

  useEffect(() => {
    if (conditions) {
      setConditionResponses(conditions.map(() => ({ answer: null })));
    }
  }, [conditions]);

  const respondedConditions = conditionResponses?.filter(
    response => response.answer !== null,
  );
  const nextConditionIndex = conditionResponses?.findIndex(
    response => response.answer === null,
  );
  const done = nextConditionIndex === -1;
  const anyAnsweredTrue = conditionResponses?.some(
    response => response.answer === true,
  );

  useEffect(() => {
    if (nextConditionIndex! >= 0) {
      document
        .getElementById(`condition-${nextConditionIndex}`)
        ?.scrollIntoView({ behavior: 'smooth' });
    } else {
      document
        .getElementById('condition-done')
        ?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [nextConditionIndex]);

  function handleResponseChange(index: number, answer: boolean) {
    const newResponses = conditionResponses!.map((response, i) => {
      if (i === index) {
        return { answer };
      }
      return response;
    });
    setConditionResponses(newResponses);
  }

  return (
    <div>
      {respondedConditions?.map((answer, i) => {
        const condition = conditions![i];
        return (
          <ConditionOption
            key={condition.fields.title}
            condition={condition}
            index={i}
            onChange={handleResponseChange}
            answer={answer}
          />
        );
      })}

      {!anyAnsweredTrue &&
        nextConditionIndex != null &&
        nextConditionIndex >= 0 && (
          <ConditionOption
            condition={conditions![nextConditionIndex]}
            index={nextConditionIndex}
            onChange={handleResponseChange}
            answer={{ answer: null }}
          />
        )}

      {(done || anyAnsweredTrue) && conditionResponses && (
        <Completion
          options={options}
          answers={conditionResponses}
          onStartOver={onStartOver}
        />
      )}
    </div>
  );
}

function ConditionOption({
  condition,
  index,
  onChange,
  answer,
}: {
  condition: ButterInStoreConditionGuidancePage['conditions'][number];
  index: number;
  onChange: (index: number, answer: boolean) => void;
  answer: { answer: boolean | null };
}) {
  return (
    <div id={`condition-${index}`} className="-mt-16 mb-16 pt-16">
      <div className="text-base font-semibold">
        {index + 1}. {condition.fields.title}
      </div>

      {!!condition.fields.description && (
        <div className="prose prose-sm mt-2">
          {condition.fields.description}
        </div>
      )}

      {condition.fields.guidance.length > 0 && (
        <div className="mt-4 flex gap-8 overflow-x-auto whitespace-nowrap">
          {condition.fields.guidance.map(guidance => (
            <div
              key={guidance.media + guidance.caption}
              className="flex-shrink-0"
            >
              <img
                src={guidance.media}
                loading="lazy"
                className="mb-2 rounded"
                style={{
                  width: '64vh',
                  height: '44vh',
                }}
              />

              <div className="pb-3 text-sm font-normal">{guidance.caption}</div>
            </div>
          ))}
        </div>
      )}

      <div className="mb-4 mt-4 text-lg font-semibold">
        {condition.fields.prompt}
      </div>

      <div className="inline-grid grid-cols-2 gap-4">
        <Button
          variant={answer.answer ? 'contained' : 'outlined'}
          size="large"
          onClick={() => onChange(index, true)}
        >
          Yes
        </Button>

        <Button
          variant={answer.answer == false ? 'contained' : 'outlined'}
          size="large"
          onClick={() => onChange(index, false)}
        >
          No
        </Button>
      </div>
    </div>
  );
}

function Completion({
  answers,
  onStartOver,
  options,
}: {
  answers: Answers;
  onStartOver: () => void;
  options: TradeInOptionMap;
}) {
  let content: React.ReactNode = null;

  const allAnsweredNo = answers.every(answer => answer.answer === false);

  if (!allAnsweredNo) {
    content = (
      <div>
        <Message error>
          Based on the conditions you provided, we do not recommend purchasing
          this item
        </Message>

        <Button variant="outlined" onClick={onStartOver}>
          Start Over
        </Button>
      </div>
    );
  } else {
    content = (
      <div>
        <InStoreAddToCartButton options={options} />
      </div>
    );
  }

  return (
    <div id="condition-done" className="mt-12 mb-80">
      {content}
    </div>
  );
}
