import React from 'react';
import PageLayout from '../../../components/Layout/PageLayout';
import PageHeader from '../../../components/Layout/PageHeader';
import FilteredTransfersItemsTable from '../../../components/Partner/FilteredTransfersItemsTable';
import { fireVisitedAllTransfers } from '../../../services/analytics/partner/dashboard';
import { useMount } from 'react-use';
import { UntaggedItemsWarning } from '../../../components/Partner/UntaggedItemsWarning';

interface Props {}

const PartnerDashboardFilterItemsPage: React.FC<Props> = () => {
  useMount(fireVisitedAllTransfers);

  return (
    <PageLayout
      header={
        <>
          <PageHeader>Manage Items</PageHeader>
        </>
      }
    >
      <section aria-label="History" className="mb-6 rounded border p-4">
        <div className="mb-4 flex items-center justify-between">
          <div className="text-lg">History</div>
          <div>
            <UntaggedItemsWarning />
          </div>
        </div>

        <FilteredTransfersItemsTable showFilters showPagination />
      </section>
    </PageLayout>
  );
};

export default PartnerDashboardFilterItemsPage;
