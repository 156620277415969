import * as amplitude from '@amplitude/analytics-browser';
import getEnv from '../../utils/getEnv';
import { DecodedJwt } from '../../api/intf/auth';
import { IamPartner } from '../../api/partnerUsers';
import { fetchIamOrg } from '../../api/orgs';

export const initializeAmplitude = () => {
  const key = getEnv('REACT_APP_AMPLITUDE_KEY');

  if (!key) {
    console.warn('No Amplitude key present, not initializing analytics');
    return;
  }

  amplitude.init(key);
};

export async function unidentifyAmplitude() {
  amplitude.reset();
}

export async function identifyAmplitude(token: DecodedJwt, user: IamPartner) {
  const identify = new amplitude.Identify();

  identify.set('user_id', user.uuid);

  if (token.org_id) {
    identify.set('child_org_id', token.org_id);
  }

  if (token.org_name) {
    identify.set('child_org_name', token.org_name);
  }

  if (user.email) {
    identify.set('email', user.email);
  }

  if (user.federated_id) {
    identify.set('employee_id', user.federated_id);
  }

  if (user.role) {
    identify.set('role', user.role);
  }

  if (token.org_id) {
    try {
      const response = await fetchIamOrg(token.org_id!);
      if (response?.parent_org) {
        identify.set('parent_org_id', response.parent_org.id);
        identify.set('parent_org_name', response.parent_org.name);
      }
    } catch (e) {}
  }

  if (getEnv() !== 'production') {
    console.log(
      'identifying with Amplitude',
      identify.getUserProperties()?.$set,
    );
  }

  amplitude.identify(identify);
}
