import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import './styles/global.css';
import { initializeAxiosClientSide } from './api/axiosInitialization';

async function main() {
  await initializeAxiosClientSide();

  // Only enable in dev
  if (window.location.port === '3001') {
    try {
      const { devWorker } = await import('./mock/dev-worker');
      // await devWorker.start();
    } catch (e) {
      console.error(e);
    }
  }

  const root = document.getElementById('root');
  if (!root) {
    throw new Error('No root element found');
  }

  // React 18 root:
  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}

main();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
