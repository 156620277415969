import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface Props extends LinkProps {
  icon?: React.ReactNode;
  text: string;
}

export const sideMenuLinkCx =
  'flex w-full items-center space-x-3 rounded px-2 py-3 text-white transition-colors duration-100 hover:bg-turf-green-900';

const SideMenuLink: React.FC<Props> = ({ icon, text, ...props }) => (
  <Link className={sideMenuLinkCx} {...props}>
    {icon}
    <span>{text}</span>
  </Link>
);

export default SideMenuLink;
