import React, { useState } from 'react';
import { PayoutMethod } from '../../api/intf/payoutMethods';
import { useMutation, useQueryClient } from 'react-query';
import Checkbox from '../Form/Checkbox';
import {
  putUpdateCreditOfferMarkup,
  togglePayoutMethod,
} from '../../api/paymentMethods';
import LoadingIndicator from '../Form/LoadingIndicator';
import { getErrorMessage } from '../../api/ApiError';
import Field from '../Form/Field';
import FieldLabel from '../Form/FieldLabel';
import NumberInput from '../Form/NumberInput';

interface Props {
  payoutMethod: PayoutMethod;
}

const PayoutMethodCheckbox: React.FC<Props> = ({ payoutMethod }) => {
  const queryClient = useQueryClient();
  const [bonusValue, setBonusValue] = useState<number | undefined>(
    payoutMethod.credit_offer_amount != null
      ? payoutMethod.credit_offer_amount * 100
      : undefined,
  );
  const {
    mutate: toggle,
    error: toggleError,
    isLoading: toggleIsLoading,
    isSuccess: toggleIsSuccess,
  } = useMutation(
    (enabled: boolean) => togglePayoutMethod(payoutMethod.id, enabled),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['payout-methods', 'all']);
      },
    },
  );

  const {
    mutate: updateBonusAmount,
    error: bonusAmountError,
    isLoading: bonusAmountIsLoading,
    isSuccess: bonusAmountIsSuccess,
  } = useMutation(
    (amount: number) => putUpdateCreditOfferMarkup(payoutMethod.id, amount),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('payout-methods', {
          exact: false,
        });
      },
    },
  );

  return (
    <div>
      <label className="flex items-center space-x-2">
        <Checkbox
          className="text-green-900"
          checked={payoutMethod.enabled}
          onChange={e => toggle(e.target.checked)}
        />

        <div>{payoutMethod.name}</div>

        {toggleIsLoading && <LoadingIndicator />}
        {toggleIsSuccess && <div className="text-xs text-green-500">Saved</div>}
        {!!toggleError && (
          <div className="text-xs text-red-500">
            {getErrorMessage(toggleError)}
          </div>
        )}
      </label>

      {(payoutMethod.credit_offer_enabled ||
        payoutMethod.credit_offer_amount != null) && (
        <Field>
          <FieldLabel htmlFor={`bonus-${payoutMethod.id}`}>
            Bonus Amount (optional)
          </FieldLabel>
          <div className="flex items-center space-x-2">
            <div className="w-32">
              <NumberInput
                id={`bonus-${payoutMethod.id}`}
                suffix="%"
                decimalScale={2}
                onValueChange={({ floatValue }) => setBonusValue(floatValue)}
                value={bonusValue}
                placeholder="(ex: 10%)"
                disabled={!payoutMethod.credit_offer_enabled}
                onBlur={() => {
                  if (bonusValue !== payoutMethod.credit_offer_amount! * 100) {
                    updateBonusAmount(bonusValue || 0);
                  }
                }}
              />
            </div>

            {bonusAmountIsLoading && <LoadingIndicator />}
            {bonusAmountIsSuccess && (
              <div className="text-xs text-green-500">Saved</div>
            )}
            {!!bonusAmountError && (
              <div className="text-xs text-red-500">
                {getErrorMessage(bonusAmountError)}
              </div>
            )}
          </div>
          <div className="mt-1 text-xs">
            Offer an incentive to be paid out in store credit
          </div>
        </Field>
      )}
    </div>
  );
};

export default PayoutMethodCheckbox;
