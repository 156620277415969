import React from 'react';
import PageLayout from '../components/Layout/PageLayout';
import NavigationButton from '../components/Layout/NavigationButton';
import usePermissions from '../lib/auth/usePermissions';

interface Props {}

const LoggedInLandingHomepage: React.FC<Props> = () => {
  const permissions = usePermissions();

  return (
    <PageLayout>
      <div className="mx-auto mt-8 max-w-sm space-y-8">
        {permissions.accessTradeInExperience && (
          <NavigationButton to="/event">Events</NavigationButton>
        )}

        {permissions.accessWarehouseExperience && (
          <NavigationButton to="/warehouse">Warehouse</NavigationButton>
        )}

        {permissions.accessPartnerExperience && (
          <NavigationButton to="/partner">Partner In-store</NavigationButton>
        )}
      </div>
    </PageLayout>
  );
};

export default LoggedInLandingHomepage;
