import { QueryClient } from 'react-query';
import { QuoteListingMetadata, ValueGuideMeta } from '../api/intf/item';
import formatTimeToSell from './formatTimeToSell';
import { getSellThroughRate } from './getSellThroughRate';
import determineShouldBuyItem, {
  doNotBuyLabel,
} from './determineShouldBuyItem';
import { Quality } from '../constants/valueMatrix';

export default function getQuoteListingMetadata(
  quality: Quality,
  queryClient: QueryClient,
) {
  const metadata: QuoteListingMetadata = {};

  const valueGuide = queryClient.getQueryData<ValueGuideMeta>('valueGuide', {
    active: true,
    exact: false,
    inactive: false,
  });

  if (valueGuide) {
    metadata.retail_value_guide = valueGuide.statistics?.retail_price;
    metadata.avg_resale_price = valueGuide.statistics?.median;
    metadata.recent_sales = valueGuide.recent_sales;
    metadata.sell_through_rate = getSellThroughRate(valueGuide);
    metadata.currently_available = valueGuide.available_items;

    if (valueGuide.time_to_sell) {
      metadata.time_to_sell = formatTimeToSell(valueGuide.time_to_sell);
    }

    const shouldBuy = doNotBuyLabel(
      determineShouldBuyItem(quality, undefined, valueGuide),
    );
    metadata.suggested_demand = shouldBuy || undefined;
  }

  return metadata;
}
