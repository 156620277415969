import React, { useMemo, useState } from 'react';
import { Button } from '../Form/Button';
import 'react-responsive-modal/styles.css';
import { useMutation, useQueryClient } from 'react-query';
import {
  PayoutMethodSubmission,
  postSubmitQuoteWithPayoutMethods,
} from '../../api/item';
import SimpleModal, { ModalProps } from '../Modal/SimpleModal';
import { getErrorMessage } from '../../api/ApiError';
import { PrintListingsForm } from '../ListingsPrintModal';
import { PayoutMethod } from '../../api/intf/payoutMethods';
import { useEventCustomerQuoteQuery } from '../../hooks/routing/useEventCustomerQuoteQuery';
import Field from '../Form/Field';
import FieldLabel from '../Form/FieldLabel';
import SimpleSelect from '../Form/Select/SimpleSelect';
import Message from '../Form/Message';
import classNames from 'classnames';
import displayPrice from '../../utils/displayPrice';
import useCustomerById from '../../hooks/useCustomerById';
import useCurrentEventQuery from '../../hooks/query/useCurrentEventQuery';
import CheckboxWithLabel from '../Form/CheckboxWithLabel';
import useCurrentQuoteQuery from '../../hooks/query/useCurrentQuoteQuery';

const DONATION_AMOUNTS = [
  {
    label: '10%',
    amount: 0.1,
  },
  {
    label: '25%',
    amount: 0.25,
  },
  {
    label: '50%',
    amount: 0.5,
  },
  {
    label: '100%',
    amount: 1,
  },
];

interface Props extends ModalProps {
  cashOfferAmount: number;
  eventId: string;
  quoteId: string;
}

const QuoteSubmitModal: React.FC<Props> = ({
  open,
  cashOfferAmount,
  eventId,
  quoteId,
  onClose,
}) => {
  const { customerId } = useEventCustomerQuoteQuery();
  const { data: customer } = useCustomerById(customerId);
  const queryClient = useQueryClient();
  const [selectedOption, setSelectedOption] = useState<null | PayoutMethod>(
    null,
  );
  const [donate, setDonate] = useState(false);
  const [donationCharity, setDonationCharity] = useState<null | string>(null);

  // In percents (0 - 1)
  const [donationAmount, setDonationAmount] = useState<null | number>(null);

  const { data: event } = useCurrentEventQuery();

  const payoutMethods = event?.payout_methods;

  const donationOption = payoutMethods?.find(
    p => p.charities && p.charities.length > 0,
  );

  // Put quote item by quote id
  const {
    mutate: submitQuote,
    isLoading: isSubmittingQuote,
    isSuccess,
    error,
  } = useMutation(
    () => {
      if (!selectedOption) {
        throw new Error('Must have a selection');
      }
      const finalOptions: PayoutMethodSubmission[] = [];

      // This is really ugly but if the donation amount is "100%", don't
      // send the selected option.
      if (donationAmount != 1) {
        finalOptions.push({
          payout_method_id: selectedOption?.id,
        });
      }

      if (donate) {
        if (!donationOption?.id) {
          throw new Error('Select a donation option');
        }

        if (!donationCharity) {
          throw new Error('Select a charity');
        }

        if (!donationAmount) {
          throw new Error('Must have a charitable percentage amount');
        }

        finalOptions.push({
          payout_method_id: donationOption?.id,
          charity: donationCharity,
          percentage: donationAmount,
        });
      }

      return postSubmitQuoteWithPayoutMethods(quoteId, finalOptions, customer);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['quotes', quoteId]);
      },
    },
  );

  const { data: quote } = useCurrentQuoteQuery();

  let bonusAmount: number | undefined;

  const itemization = useMemo(() => {
    const items: { label: string; amount: number; type?: 'total' }[] = [];

    let subtotal = cashOfferAmount;

    let bonusAmount: number | undefined = selectedOption?.credit_offer_amount;
    let bonusSource: undefined | 'bonusCode' | 'payoutMethod' = 'payoutMethod';

    if (
      quote?.bonus_code &&
      quote.bonus_code?.bonus_amount &&
      quote.bonus_code?.bonus_amount > 0 &&
      quote.bonus_code.payout_methods.includes(selectedOption?.id || '')
    ) {
      bonusAmount = quote.bonus_code.bonus_amount;
      bonusSource = 'bonusCode';
    }

    items.push({
      label: 'Cash Offer',
      amount: cashOfferAmount,
    });

    if (donate && donationAmount) {
      items.push({
        label: 'Donation',
        amount: -(cashOfferAmount * donationAmount),
      });

      subtotal = cashOfferAmount * (1 - donationAmount);

      items.push({
        label: 'Subtotal',
        amount: subtotal,
      });
    }

    if (bonusAmount != null) {
      items.push({
        label:
          bonusSource === 'bonusCode' ? 'Bonus Code' : 'Payout Method Bonus',
        amount: subtotal * bonusAmount,
      });

      subtotal += subtotal * bonusAmount;
    }

    items.push({
      label: "You'll receive",
      amount: subtotal,
      type: 'total',
    });

    return items;
  }, [
    cashOfferAmount,
    donate,
    donationAmount,
    quote?.bonus_code,
    selectedOption?.credit_offer_amount,
    selectedOption?.id,
  ]);

  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      className="w-full max-w-lg"
      title={isSuccess ? 'Quote Submitted' : 'Choose payout method'}
      description={
        isSuccess
          ? 'Would you like to print a label for each listing?'
          : undefined
      }
    >
      {isSuccess ? (
        <PrintListingsForm eventId={eventId} quoteId={quoteId}>
          <div className="mt-6 flex space-x-2">
            <Button variant="outlined" color="grey" fluid onClick={onClose}>
              Cancel
            </Button>

            <Button type="submit" fluid>
              Print Labels
            </Button>
          </div>
        </PrintListingsForm>
      ) : (
        <>
          <div className="mb-4 grid grid-cols-2 gap-4">
            {payoutMethods
              ?.filter(
                payoutMethod =>
                  payoutMethod.name !== 'Donate' && payoutMethod.enabled,
              )
              .map(payoutMethod => {
                let bonus = payoutMethod.credit_offer_amount;
                let bonusSource: 'bonusCode' | 'payoutMethod' = 'payoutMethod';

                if (
                  quote?.bonus_code?.payout_methods?.includes(
                    payoutMethod.id,
                  ) &&
                  quote?.bonus_code?.bonus_amount! > 0
                ) {
                  // If the bonus code is higher than the payout method's bonus, use the code's bonus
                  if (
                    bonus == null ||
                    quote?.bonus_code?.bonus_amount! > bonus
                  ) {
                    bonus = quote?.bonus_code?.bonus_amount!;
                    bonusSource = 'bonusCode';
                  }
                }

                return (
                  <button
                    key={payoutMethod.id}
                    type="button"
                    className={classNames(
                      'cursor-pointer rounded border px-4 py-3 text-left',
                      {
                        'bg-turf-green-500 text-white':
                          selectedOption?.id === payoutMethod.id,
                      },
                    )}
                    onClick={() => setSelectedOption(payoutMethod)}
                  >
                    <div className="font-semibold">{payoutMethod.name}</div>
                    <div className="text-sm">
                      {bonus != null &&
                        `+ ${bonus * 100}% (from ${
                          bonusSource === 'bonusCode'
                            ? 'bonus code'
                            : 'payout method'
                        })`}
                    </div>
                  </button>
                );
              })}
          </div>

          {/* Only show donation options if there are charities to select */}
          {!!donationOption && (
            <div className="mb-4">
              <CheckboxWithLabel
                checked={donate}
                onChange={e => {
                  setDonate(e.target.checked);
                  setDonationAmount(null);
                  setDonationCharity(null);
                }}
              >
                Donate a portion of your proceeds
              </CheckboxWithLabel>
            </div>
          )}

          {donate && (
            <div className="space-y-4">
              <Field>
                <FieldLabel>Select an Organization</FieldLabel>
                <SimpleSelect
                  items={donationOption?.charities}
                  onChange={d => setDonationCharity(d)}
                  itemToString={d => d ?? ''}
                  value={donationCharity}
                />
              </Field>

              <Field>
                <FieldLabel>Select an Amount</FieldLabel>
                <div className="flex space-x-4">
                  {DONATION_AMOUNTS.map(amount => (
                    <button
                      key={amount.label}
                      type="button"
                      className={classNames(
                        'rounded-full border px-3 py-1 text-sm font-semibold transition duration-200',
                        donationAmount === amount.amount
                          ? 'border-true-black bg-mint-green-500'
                          : '',
                      )}
                      onClick={() => setDonationAmount(amount.amount)}
                    >
                      {amount.label}
                    </button>
                  ))}
                </div>
              </Field>
            </div>
          )}

          <div className="flex justify-end">
            <div className="space-y-2">
              {itemization.map(item => (
                <div
                  key={item.label}
                  className="flex justify-end space-x-8 text-right"
                >
                  <div className={item.type === 'total' ? 'font-semibold' : ''}>
                    {item.label}
                  </div>
                  <div className={item.type === 'total' ? 'font-semibold' : ''}>
                    {displayPrice(item.amount)}
                  </div>
                </div>
              ))}
            </div>
          </div>

          {!!error && <Message error>{getErrorMessage(error)}</Message>}

          <div className="mb-12" />

          {cashOfferAmount > 500 && (
            <Message warning>
              This quote's offer amount is over $500. If the customer chooses a
              Gift Card, they will receive more than 1 email to claim the Gift
              Card.
            </Message>
          )}

          <div className="flex justify-end space-x-4">
            <Button variant="text" onClick={onClose} fluid>
              Back
            </Button>
            <Button
              fluid
              disabled={!selectedOption || isSubmittingQuote}
              onClick={() => submitQuote()}
            >
              Complete Quote
            </Button>
          </div>
        </>
      )}
    </SimpleModal>
  );
};

export default QuoteSubmitModal;
