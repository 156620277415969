import React from 'react';
import { warehouseAuditSchema } from '../../../pages/warehouse/audit/warehouseAuditItemDetailsPage';
import { z } from 'zod';
import { useField } from 'formik';

interface Props {
  format?: (value: any) => React.ReactNode;
  formField: keyof z.infer<typeof warehouseAuditSchema>;
  originalValue: any;
  schema?: z.Schema;
}

const AuditFieldDifference: React.FC<Props> = ({
  format,
  formField,
  originalValue,
  schema,
}) => {
  const [field] = useField(formField);
  const parsed =
    schema?.safeParse(field.value) ||
    warehouseAuditSchema.shape[formField]?.safeParse(field.value);

  if (
    // If there was an original value, but it was wiped out, show "Previously"
    (originalValue != null && field.value == null) ||
    (parsed.success &&
      // Make sure that there's an originalValue. We only want to show "Previously" if there was a value before
      originalValue &&
      parsed.data !== originalValue &&
      typeof parsed.data !== 'object')
  ) {
    return (
      <div className="mt-9 italic text-slate-green-500">
        Previously:{' '}
        {(format ? format(originalValue) : originalValue) ||
          'Data is now unavailable'}
      </div>
    );
  }

  return <div></div>;
};

export default AuditFieldDifference;
