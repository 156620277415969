import React, { useEffect, useState } from 'react';
import PageLayout from '../../../components/Layout/PageLayout';
import useCurrentWarehouseQuery from '../../../hooks/warehouse/useCurrentWarehouseQuery';
import PageHeader from '../../../components/Layout/PageHeader';
import PageSubheader from '../../../components/Layout/PageSubheader';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  fetchUnshippedOrdersCount,
  postCreatePicklist,
} from '../../../api/picklists';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import pluralize from '../../../utils/pluralize';
import { Button } from '../../../components/Form/Button';
import Field from '../../../components/Form/Field';
import FieldLabel from '../../../components/Form/FieldLabel';
import NumberInput from '../../../components/Form/NumberInput';
import Message from '../../../components/Form/Message';
import { getErrorMessage } from '../../../api/ApiError';
import { Link, useNavigate } from 'react-router-dom';
import FieldHint from '../../../components/Form/FieldHint';
import WarehouseBreadcrumbLink from '../../../components/Warehouse/WarehouseBreadcrumbLink';

interface Props {}

const WarehousePicklistHomepage: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const { data: warehouse } = useCurrentWarehouseQuery();
  const warehouseId = useCurrentWarehouseId()!;

  const navigate = useNavigate();

  const [selectedCount, setSelectedCount] = useState(0);

  const { data: countResponse } = useQuery(
    ['unshipped-order-count', warehouseId],
    () => fetchUnshippedOrdersCount(warehouseId),
  );
  const totalCount = countResponse?.count ?? 0;
  const pluralizedItems = pluralize(totalCount, 'item', 'items');

  const {
    mutate: createPicklist,
    error,
    isLoading,
  } = useMutation(() => postCreatePicklist(warehouseId, selectedCount), {
    onSuccess: picklist => {
      queryClient.invalidateQueries(['unshipped-order-count', warehouseId]);
      queryClient.setQueryData(
        ['picklist', warehouseId, picklist.id],
        picklist,
      );
      navigate(`/warehouse/${warehouseId}/picklist/${picklist.id}`);
    },
  });

  useEffect(() => {
    setSelectedCount(totalCount);
  }, [totalCount]);

  return (
    <PageLayout
      header={
        <>
          <WarehouseBreadcrumbLink to="home" />
          <PageHeader>Warehouse - {warehouse?.facility}</PageHeader>
          <PageSubheader>Create a Picklist</PageSubheader>
        </>
      }
    >
      <div className="mx-auto max-w-md">
        <Field className="mt-4 mb-8">
          <FieldLabel>How many items would you like to pick?</FieldLabel>
          <NumberInput
            autoFocus
            disabled={isLoading}
            value={selectedCount || ''}
            onValueChange={values => setSelectedCount(values.floatValue ?? 0)}
          />
          <FieldHint>
            {totalCount} total {pluralizedItems} to pick
          </FieldHint>
        </Field>

        {!!error && <Message error>{getErrorMessage(error)}</Message>}

        <Button
          fluid
          onClick={() => createPicklist()}
          disabled={selectedCount <= 0 || isLoading}
          className="mb-4"
          size="large"
        >
          Create Pick List ({selectedCount})
        </Button>

        <Link to={`/warehouse/${warehouseId}/unshipped_orders`}>
          <Button fluid variant="outlined" size="large">
            View All Unshipped Orders
          </Button>
        </Link>
      </div>
    </PageLayout>
  );
};

export default WarehousePicklistHomepage;
