import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Form/Button';
import QuoteSubmitModal from '../../../components/QuoteSubmitModal/QuoteSubmitModal';
import { deleteQuoteByQuoteId, putReminder } from '../../../api/item';
import displayPrice from '../../../utils/displayPrice';
import Message from '../../../components/Form/Message';
import QuoteSummaryListingCard from '../../../components/QuoteSummary/QuoteSummaryListingCard';
import CardContainer from '../../../components/CardContainer';
import { useEventCustomerQuoteQuery } from '../../../hooks/routing/useEventCustomerQuoteQuery';
import PageLayout from '../../../components/Layout/PageLayout';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal';
import { getErrorMessage } from '../../../api/ApiError';
import useMobile from '../../../hooks/useMobile';
import classNames from 'classnames';
import QuotePrintModal from '../../../components/QuotePrintModal';
import ListingsPrintModal from '../../../components/ListingsPrintModal';
import QuoteSummaryInfo from '../../../components/QuoteSummary/QuoteSummaryInfo';
import useCurrentQuoteQuery from '../../../hooks/query/useCurrentQuoteQuery';
import Issue5DollarGiftCardAction from '../../../components/QuoteSummary/Issue5DollarGiftCardAction';
import getQuoteListings, {
  getDeletedQuoteListings,
} from '../../../utils/getQuoteListings';

interface Props {}

const QuoteSummaryPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const mobile = useMobile();

  const { quoteId, eventId, customerId } = useEventCustomerQuoteQuery();

  const [submitModalOpen, setSubmitModalOpen] = useState<boolean>(false);
  const [isDeleteModal, setDeleteModal] = useState<boolean>(false);
  const [printModalOpen, setPrintModalOpen] = useState(false);
  const [printListingsModalOpen, setPrintListingsModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Fetch quote by quote id
  const quoteQuery = useCurrentQuoteQuery();

  const { mutate: sendReminder, isLoading: isSendingReminder } = useMutation(
    () => putReminder(quoteId),
    {
      onSuccess: () => {
        toast('Sent a reminder', { type: 'success' });
      },
      onError: (error: Error) => {
        toast(`Couldn't send a reminder: ${error?.message || 'no reason'}`, {
          type: 'error',
        });
      },
    },
  );

  // Delete quote by quote id
  const { mutate: deleteQuote } = useMutation(
    () => deleteQuoteByQuoteId(quoteId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['quotes', quoteId]);
        toast.success('Deleted the quote successfully!');
      },
      onError: (error: Error) => {
        toast.error(`Could not delete the quote: ${getErrorMessage(error)}`);
      },
    },
  );

  const handleAddItem = () => {
    navigate(
      `/event/${eventId}/customer/${customerId}/quote/${quoteId}/listing/search`,
    );
  };

  const handleExitQuote = () => {
    navigate(`/event/${eventId}`);
  };

  const actionsDisabled =
    !quoteQuery.data || quoteQuery.data.status === 'COMPLETED';

  const quoteListings = getQuoteListings(quoteQuery.data);
  const deletedQuoteListings = getDeletedQuoteListings(quoteQuery.data) || [];

  return (
    <PageLayout
      header={
        <>
          <div className="flex w-full flex-col md:flex-row">
            <QuoteSummaryInfo />

            {/* Action section */}
            <div className="flex-shrink-0">
              <div className="mt-6 grid w-full grid-cols-2 gap-4">
                <Button disabled={actionsDisabled} onClick={handleAddItem}>
                  Add an Item
                </Button>
                <Button
                  variant="outlined"
                  color="grey"
                  disabled={
                    // Can only void on completed quotes
                    !quoteQuery.data || quoteQuery.data.status !== 'COMPLETED'
                  }
                  onClick={() => setDeleteModal(true)}
                >
                  Void Quote
                </Button>

                <Button
                  variant="outlined"
                  color="grey"
                  onClick={() => setPrintModalOpen(true)}
                >
                  Print Quote Labels
                </Button>

                {quoteQuery?.data && (
                  <Issue5DollarGiftCardAction quote={quoteQuery.data} />
                )}

                <form action="/print" target="_blank" className="w-full">
                  <input type="hidden" name="type" value="listing" />
                  <input type="hidden" name="quote_id" value={quoteId} />
                  <input type="hidden" name="event_id" value={eventId} />
                  <Button fluid color="grey" variant="outlined" type="submit">
                    Print Listing Labels
                  </Button>
                </form>

                <Button
                  variant="text"
                  onClick={handleExitQuote}
                  className="block md:hidden"
                >
                  Exit Quote
                </Button>
              </div>
            </div>
          </div>
        </>
      }
    >
      {!!quoteQuery?.error && (
        <Message error>
          <p>Couldn't get quote: {getErrorMessage(quoteQuery.error)}</p>
          <Button
            size="small"
            onClick={() => quoteQuery.refetch()}
            disabled={quoteQuery.isLoading}
          >
            {quoteQuery.isLoading ? 'Retrying...' : 'Retry'}
          </Button>
        </Message>
      )}

      {/* Quote Info table section */}
      {quoteListings.length > 0 ? (
        <CardContainer>
          {quoteListings.map(listing => (
            <QuoteSummaryListingCard
              key={listing.id}
              customerId={customerId}
              quoteId={quoteId}
              listing={listing}
              actionsDisabled={actionsDisabled}
            />
          ))}
        </CardContainer>
      ) : (
        quoteQuery.isSuccess && (
          <div className="mt-4 text-center text-lg">No available listings</div>
        )
      )}

      {/* Deleted listings */}
      {deletedQuoteListings.length > 0 && (
        <div className="mt-8">
          <div className="mb-4 text-lg font-semibold text-slate-green-500">
            Deleted Listings
          </div>
          <CardContainer>
            {deletedQuoteListings.map(listing => (
              <QuoteSummaryListingCard
                key={listing.id}
                customerId={customerId}
                quoteId={quoteId}
                listing={listing}
                actionsDisabled={actionsDisabled}
              />
            ))}
          </CardContainer>
        </div>
      )}

      {/* Footer section */}
      <div
        className={classNames(
          'fixed bottom-0 right-0 bg-off-white-500',
          mobile ? 'left-0' : 'left-80',
        )}
      >
        <div className="container grid grid-cols-2 gap-2 px-4 py-2 md:flex md:items-center md:space-x-4">
          {getQuoteListings(quoteQuery.data).length > 0 ? (
            <div className="text-lg font-semibold">
              Item Count: {quoteQuery?.data?.listing_count}
            </div>
          ) : (
            <div />
          )}

          <div className="hidden flex-1 md:block" />

          <div>
            <div>Offer Value</div>
            <div className="text-xl font-bold">
              {displayPrice(quoteQuery.data?.cash_offer_amount || 0)}
              {quoteQuery.data &&
                quoteQuery.data.credit_offer_amount > 0 &&
                ` - ${displayPrice(quoteQuery.data?.credit_offer_amount)}`}
            </div>
          </div>

          <Button
            variant="text"
            onClick={handleExitQuote}
            className="hidden md:block"
          >
            Exit Quote
          </Button>

          <Button
            variant="outlined"
            disabled={actionsDisabled}
            onClick={() => setSubmitModalOpen(true)}
          >
            Submit Quote
          </Button>

          <Button onClick={() => sendReminder()} disabled={isSendingReminder}>
            {isSendingReminder ? 'Sending' : 'Send'} Reminder
          </Button>
        </div>
      </div>

      <QuoteSubmitModal
        cashOfferAmount={quoteQuery.data?.cash_offer_amount || 0}
        eventId={eventId}
        open={submitModalOpen}
        quoteId={quoteId}
        onClose={() => setSubmitModalOpen(false)}
      />

      <ConfirmationModal
        open={isDeleteModal}
        onClose={() => setDeleteModal(false)}
        title="Are you sure you want to void this quote?"
        okText="Void"
        okProps={{
          color: 'danger',
        }}
        cancelText="No"
        handleOK={deleteQuote}
        handleCancel={() => setDeleteModal(false)}
      />

      <QuotePrintModal
        eventId={eventId}
        quoteId={quoteId}
        onClose={() => setPrintModalOpen(false)}
        open={printModalOpen}
      />

      <ListingsPrintModal
        eventId={eventId}
        quoteId={quoteId}
        onClose={() => setPrintListingsModalOpen(false)}
        open={printListingsModalOpen}
      />
    </PageLayout>
  );
};

export default QuoteSummaryPage;
