import React, { useRef, useState } from 'react';
import PageLayout from '../../../components/Layout/PageLayout';
import useCurrentWarehouseQuery from '../../../hooks/warehouse/useCurrentWarehouseQuery';
import { differenceInSeconds } from 'date-fns';
import ScanHistoryTable from '../../../components/Warehouse/Spawn/ScanHistoryTable';
import { postWarehouseScanReceive, ReceiveError } from '../../../api/spawn';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import { ScanHistory } from '../../../types/spawnScanning';
import WarehouseBreadcrumbLink from '../../../components/Warehouse/WarehouseBreadcrumbLink';
import { ApiError } from '../../../api/ApiError';
import Scanner from '../../../components/Scanner/Scanner';
import getEnv from '../../../utils/getEnv';
import Field from '../../../components/Form/Field';
import Input from '../../../components/Form/Input';
import FieldLabel from '../../../components/Form/FieldLabel';
import { Button } from '../../../components/Form/Button';

const THROTTLE_SECONDS = 3;

interface Props {}

const ACTIONS = [
  {
    action: 'publish',
    label: 'Published',
  },
  {
    action: 'repair',
    label: 'Repair',
  },
  {
    action: 'salvage',
    label: 'Salvage',
  },
  {
    action: 'donate',
    label: 'Donated',
  },
  {
    action: 'discard',
    label: 'Discarded',
  },
];

const WarehouseReceivePage: React.FC<Props> = () => {
  const warehouseId = useCurrentWarehouseId();
  const { data: warehouse } = useCurrentWarehouseQuery();
  const [, setRerender] = useState<any>();
  const [action, setAction] = useState(ACTIONS[0].action);
  const scanHistory = useRef<ScanHistory[]>([]);

  function rerender() {
    setRerender(new Date());
  }

  async function onScanData(data: string) {
    // Look for the LAST scan history
    let found: ScanHistory | undefined;
    for (let i = scanHistory.current.length - 1; i >= 0; i--) {
      if (scanHistory.current[i].data === data) {
        found = scanHistory.current[i];
        break;
      }
    }

    const now = new Date();

    let shouldProcess = false;
    if (!found) {
      shouldProcess = true;
    } else if (found.status !== 'loading') {
      if (
        differenceInSeconds(now, new Date(found.createdAt)) >= THROTTLE_SECONDS
      ) {
        shouldProcess = true;
      } else if (
        found.status === 'error' &&
        differenceInSeconds(now, new Date(found.createdAt)) >= THROTTLE_SECONDS
      ) {
        shouldProcess = true;
      }
    }

    if (shouldProcess) {
      window.navigator?.vibrate?.(25);

      const newLength = scanHistory.current.push({
        action,
        data,
        status: 'loading',
        createdAt: Number(new Date()),
      });
      rerender();
      const index = newLength - 1;

      try {
        const response = await postWarehouseScanReceive(
          warehouseId!,
          action,
          data,
        );
        scanHistory.current[index].itemInfo = response;
        scanHistory.current[index].status = 'success';
        rerender();
      } catch (e) {
        const receiveError = e as ReceiveError;
        const apiError = e as ApiError;
        if (receiveError.errors.data) {
          scanHistory.current[index].itemInfo = receiveError.errors.data;
        } else if (Array.isArray(apiError.errors)) {
          scanHistory.current[index].error = apiError;
        }

        scanHistory.current[index].status = 'error';
        rerender();
      }
    }
  }

  return (
    <PageLayout
      header={
        <>
          <WarehouseBreadcrumbLink to="home" />

          <div>Receiving inventory into {warehouse?.facility}</div>
        </>
      }
    >
      <Scanner onScan={onScanData} mode="qr" />

      {getEnv() != 'production' && (
        <form
          onSubmit={e => {
            e.preventDefault();
            onScanData(e.currentTarget.scanData.value);
            e.currentTarget.scanData.value = '';
          }}
        >
          <Field>
            <FieldLabel>Manually Enter an Item ID</FieldLabel>
            <div className="flex max-w-sm gap-4">
              <Input placeholder="Scan data" name="scanData" />

              <Button type="submit">Submit</Button>
            </div>
          </Field>
        </form>
      )}

      <div className="my-4 flex items-center space-x-2">
        <span>Scan items to be Received and</span>
        <select
          value={action}
          onChange={e => setAction(e.target.value)}
          className="flex-1 rounded border px-2 py-1.5"
        >
          {ACTIONS.map(a => (
            <option key={a.action} value={a.action}>
              {a.label}
            </option>
          ))}
        </select>
      </div>

      <ScanHistoryTable scanHistory={scanHistory.current} />
    </PageLayout>
  );
};

export default WarehouseReceivePage;
