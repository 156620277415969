import React from 'react';
import useRouteQuery from '../../../hooks/useRouteQuery';
import { IamPartnerUserMeta } from '../../../api/partnerUsers';
import { generatePageNumbers } from '../../../utils/pageNumbers';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Button } from '../../Form/Button';
import { useLocation } from 'react-router';
import qsStringify from '../../../utils/qsStringify';
import LinkOrSpan from '../../LinkOrSpan';

interface Props {
  meta: IamPartnerUserMeta;
}

const PartnerManageUsersPagination: React.FC<Props> = ({ meta }) => {
  const location = useLocation();
  const { page: urlPage, ...query } = useRouteQuery();
  const currentPage = Number(urlPage) || 1;
  const pageNumbers = generatePageNumbers(currentPage, meta.total_pages);

  const hasPrevPage = currentPage > 1;
  const hasNextPage = currentPage < meta.total_pages;

  function generateUrl(page: number | string) {
    if (page == 1) {
      return location.pathname + qsStringify(query);
    }

    return (
      location.pathname +
      qsStringify({
        // in this order
        page,
        ...query,
      })
    );
  }

  return (
    <div className="flex items-center space-x-2">
      <LinkOrSpan to={hasPrevPage ? generateUrl(currentPage - 1) : undefined}>
        <Button size="tiny" variant="outlined" disabled={!hasPrevPage}>
          Prev
        </Button>
      </LinkOrSpan>

      {pageNumbers.map((page, i) => {
        if (page === '...') {
          return <EllipsisHorizontalIcon key={i} className="h-4 w-4" />;
        }

        return (
          <Link to={generateUrl(page)} key={i}>
            <Button
              size="tiny"
              variant={page === currentPage ? 'contained' : 'outlined'}
            >
              {page}
            </Button>
          </Link>
        );
      })}

      <LinkOrSpan to={hasNextPage ? generateUrl(currentPage + 1) : undefined}>
        <Button size="tiny" variant="outlined" disabled={!hasNextPage}>
          Next
        </Button>
      </LinkOrSpan>
    </div>
  );
};

export default PartnerManageUsersPagination;
