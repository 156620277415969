import React, { useState } from 'react';
import useCustomerById from '../../hooks/useCustomerById';
import { useEventCustomerQuoteQuery } from '../../hooks/routing/useEventCustomerQuoteQuery';
import displayPrice from '../../utils/displayPrice';
import ManageQuotePayoutsModal from './ManageQuotePayoutsModal';
import useCurrentQuoteQuery from '../../hooks/query/useCurrentQuoteQuery';
import QuoteSummaryPanelBonusCode from './QuoteSummaryPanelBonusCode';

interface Props {}

const QuoteSummaryInfo: React.FC<Props> = () => {
  const { customerId } = useEventCustomerQuoteQuery();
  const [managePayoutsModalOpen, setManagePayoutsModalOpen] = useState(false);

  const { data: customerInfo } = useCustomerById(customerId);
  const { data: quote } = useCurrentQuoteQuery();

  const donatePayout = quote?.payouts?.find(
    payout => payout?.payment_method?.payout_type === 'DONATION',
  );
  const nonDonatePayout = quote?.payouts?.find(
    payout => payout?.payment_method?.payout_type !== 'DONATION',
  );

  return (
    <div className="flex-1 space-y-1.5">
      <div className="text-3xl leading-tight">
        Quote ID: {quote?.display_id}
      </div>
      <div>
        Customer name: {customerInfo?.first_name} {customerInfo?.last_name}
      </div>
      <div>Phone: {customerInfo?.phone}</div>
      <div>Email: {customerInfo?.email}</div>
      <div>Status: {quote?.status}</div>
      {quote?.payouts && (
        <dl className="inline-grid grid-cols-2 gap-x-4 gap-y-1.5">
          <div>
            <dt>Payout Method</dt>:{' '}
            <dd>{nonDonatePayout?.payment_method?.name ?? <i>None</i>}</dd>
          </div>

          {donatePayout ? (
            <div>
              <dt>Charity</dt>
              {': '}
              <dd>
                {(donatePayout?.charity ||
                  donatePayout?.payment_method?.name) ?? <i>None</i>}
              </dd>
            </div>
          ) : (
            <div />
          )}

          <div>
            <dt>Payout Amount</dt>
            {': '}
            <dd>
              {displayPrice(nonDonatePayout?.payout_amount)}{' '}
              <button
                type="button"
                onClick={() => setManagePayoutsModalOpen(true)}
                className="font-semibold underline"
              >
                Manage
              </button>
            </dd>
          </div>

          {donatePayout ? (
            <div>
              <dt>Donation Amount</dt>
              {': '}
              <dd>{displayPrice(donatePayout?.payout_amount)}</dd>
            </div>
          ) : (
            <div />
          )}
        </dl>
      )}
      {quote?.redemption_code && quote?.redemption_status && (
        <div>
          Redemption Code: {quote?.redemption_code} ({quote?.redemption_status})
        </div>
      )}

      {quote && (
        <div className="max-w-xs">
          <QuoteSummaryPanelBonusCode quote={quote} />
        </div>
      )}

      <ManageQuotePayoutsModal
        allowFullContactEdit
        onClose={() => setManagePayoutsModalOpen(false)}
        open={managePayoutsModalOpen}
        quoteId={quote?.id}
      />
    </div>
  );
};

export default QuoteSummaryInfo;
