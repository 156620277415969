import { PartnerDashboard } from './intf/partner';
import { ApiResponse } from './intf/generic';
import axios from 'axios';
import { getBaseUrl } from './axiosInitialization';

export const fetchPartnerStats = async () => {
  const { data } = await axios.get<ApiResponse<PartnerDashboard>>(
    '/trade-in/v1/in-store/events/stats',
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Failed to fetch partner stats');
};
