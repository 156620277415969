import React from 'react';
import { Link } from 'react-router-dom';
import FormikButton from '../Form/FormikButton';
import { useEventCustomerQuoteQuery } from '../../hooks/routing/useEventCustomerQuoteQuery';
import useMobile from '../../hooks/useMobile';
import useRouteQuery from '../../hooks/useRouteQuery';
import { EditItemPageQuery } from '../../pages/tradeIn/item/editItemPage';
import FormikCheckbox from '../Form/FormikCheckbox';

interface Props {
  disabled?: boolean;
}

const ItemFormSubmitButtons: React.FC<Props> = ({ disabled }) => {
  const { quoteId, eventId, customerId } = useEventCustomerQuoteQuery();
  const mobile = useMobile();
  const query = useRouteQuery<EditItemPageQuery>();

  const createAnotherCheckbox = (
    <label className="flex items-center space-x-1.5">
      <FormikCheckbox name="createAnother" />
      <div>Create another</div>
    </label>
  );

  if (query.mode !== 'copy') {
    return (
      <div className="mt-4 border-t-2">
        <div className="my-5 flex justify-end space-x-4">
          {!mobile && createAnotherCheckbox}

          <Link
            to={`/event/${eventId}/customer/${customerId}/quote/${quoteId}/listing/search`}
            className="block"
          >
            <FormikButton variant="outlined" color="grey">
              Back
            </FormikButton>
          </Link>
          <FormikButton type="submit" disabled={disabled}>
            {query.mode === 'edit' ? 'Update Listing' : 'Add to quote'}
          </FormikButton>
        </div>

        {mobile && (
          <div className="mt-4 flex justify-end">{createAnotherCheckbox}</div>
        )}
      </div>
    );
  }

  return (
    <div className="mt-4 border-t-2">
      <div className="mx-10 my-5 flex justify-end">
        <Link
          to={`/event/${eventId}/customer/${customerId}/quote/${quoteId}/listing/search`}
          className="mr-8 block"
        >
          <FormikButton variant="outlined" color="grey">
            Back
          </FormikButton>
        </Link>
        <FormikButton type="submit" disabled={disabled}>
          {query.mode === 'copy' ? 'Add' : 'Update'} Item
        </FormikButton>
      </div>
    </div>
  );
};

export default ItemFormSubmitButtons;
