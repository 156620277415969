import React from 'react';
import Checkbox, { CheckboxProps } from './Checkbox';
import classNames from 'classnames';

export interface CheckboxWithLabelProps
  extends Omit<CheckboxProps, 'children'> {
  checkboxClassName?: string;
  children: React.ReactNode;
}

const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = ({
  checkboxClassName,
  children,
  className,
  ...props
}) => {
  return (
    <label className={classNames('flex items-center space-x-2', className)}>
      <Checkbox {...props} className={checkboxClassName} />
      <span>{children}</span>
    </label>
  );
};

export default CheckboxWithLabel;
