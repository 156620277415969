import React from 'react';
import { useFormikContext } from 'formik';
import { Button, ButtonProps } from './Button';

interface Props extends Omit<ButtonProps, 'type'> {}

const FormikSubmitButton: React.FC<Props> = ({
  children,
  disabled,
  ...props
}) => {
  const { isSubmitting } = useFormikContext();

  return (
    <Button {...props} type="submit" disabled={isSubmitting || disabled}>
      {children}
    </Button>
  );
};

export default FormikSubmitButton;
