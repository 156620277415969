import React, { useMemo, useState } from 'react';
import { Combobox } from '@headlessui/react';
import { BaseSelectButton, BaseSelectMenuItem } from './elements';
import FieldLabel from '../FieldLabel';
import Input from '../Input';
import classNames from 'classnames';

export interface SimpleHeadlessComboboxProps<T> {
  className?: string;
  disabled?: boolean;
  error?: string | boolean;
  filterItem: (item: T, inputValue: string) => boolean;
  id?: string;
  items: T[];
  itemToString?: (item?: T) => string;
  label?: React.ReactNode;
  minimumInputLength?: number;
  placeholder?: React.ReactNode;
  renderItem: (item: T) => React.ReactNode;
  value?: T | null;
  clearable?: boolean;
  onChange: (value: T | null) => void;
}

export default function SimpleHeadlessCombobox<T>({
  clearable,
  className,
  disabled,
  error,
  filterItem,
  id,
  itemToString,
  minimumInputLength,
  label,
  items,
  placeholder,
  renderItem,
  value,
  onChange,
}: SimpleHeadlessComboboxProps<T>) {
  const [inputValue, setInputValue] = useState('');
  const filteredItems = useMemo(() => {
    if (!inputValue) {
      return items;
    }

    return minimumInputLength != null && inputValue.length < minimumInputLength
      ? []
      : items.filter(item => filterItem(item, inputValue));
  }, [items, filterItem, inputValue, minimumInputLength]);

  return (
    <Combobox value={value ?? null} onChange={onChange}>
      <div className="relative">
        {label && (
          <Combobox.Label as={FieldLabel} error={error}>
            {label}
          </Combobox.Label>
        )}
        <Combobox.Button
          as={BaseSelectButton}
          // I don't know why the disabled prop isn't being processed by their types
          // @ts-ignore
          disabled={disabled}
          error={!!error}
          className={classNames('cursor-default', className)}
          onClear={clearable ? () => onChange(null) : undefined}
        >
          {value ? (
            renderItem(value)
          ) : (
            <i className="text-slate-green-500">{placeholder || 'Select...'}</i>
          )}
        </Combobox.Button>

        <Combobox.Options
          as="div"
          className="absolute top-full z-10 -mt-1.5 w-full rounded-b rounded-bl border-b border-l border-r bg-white py-2 shadow-lg focus:outline-none"
        >
          <div className="mx-4 my-2 flex">
            <Combobox.Input
              as={Input}
              displayValue={itemToString}
              onFocus={e => e.currentTarget.select()}
              onChange={e => setInputValue(e.target.value)}
              value={inputValue}
              type="search"
              placeholder="Search..."
            />
          </div>

          <ul className="max-h-80 overflow-y-auto">
            {filteredItems.length == 0 &&
              ((minimumInputLength != null &&
                inputValue.length >= minimumInputLength) ||
                !minimumInputLength) && (
                <li className="m-4 text-center italic text-slate-green-500">
                  No options found
                </li>
              )}

            {minimumInputLength != null &&
              inputValue.length < minimumInputLength && (
                <li className="m-4 text-center italic text-slate-green-500">
                  Enter {minimumInputLength} or more characters to search
                </li>
              )}

            {filteredItems.map(item => (
              <Combobox.Option
                key={itemToString?.(item)}
                value={item}
                as={React.Fragment}
              >
                {({ selected, active }) => (
                  <BaseSelectMenuItem selected={selected} highlighted={active}>
                    {renderItem(item)}
                  </BaseSelectMenuItem>
                )}
              </Combobox.Option>
            ))}
          </ul>
        </Combobox.Options>
      </div>
    </Combobox>
  );
}
