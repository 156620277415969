import React from 'react';
import PageLayout from '../../../components/Layout/PageLayout';
import PageHeader from '../../../components/Layout/PageHeader';
import SettingsBreadcrumbLink from '../../../components/Settings/SettingsBreadcrumbLink';
import BonusCodeForm from '../../../components/Settings/BonusCodeForm';
import FormikSubmitButton from '../../../components/Form/FormikSubmitButton';

interface Props {}

const NewBonusCodePage: React.FC<Props> = () => {
  return (
    <PageLayout
      header={
        <>
          <SettingsBreadcrumbLink page="bonusCodes" />
          <PageHeader>Create Bonus Code</PageHeader>
        </>
      }
    >
      <BonusCodeForm>
        <FormikSubmitButton>Create</FormikSubmitButton>
      </BonusCodeForm>
    </PageLayout>
  );
};

export default NewBonusCodePage;
