import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { WarehouseSwapsPaging } from '../../../api/intf/warehouseSwap';
import { ButtonLink } from '../../Form/Button';

interface Props {
  paging?: WarehouseSwapsPaging;
}

const AuditNextPrevPaginator: React.FC<React.PropsWithChildren<Props>> = ({
  paging,
}) => {
  const location = useLocation();
  const [params] = useSearchParams();

  const currentPage = paging ? paging.page : 1;

  const { nextPageParams, prevPageParams } = useMemo(() => {
    let prevPageParams: URLSearchParams | null = null;
    if (paging && currentPage > 1) {
      const prevPage = currentPage - 1;
      prevPageParams = new URLSearchParams(params);

      if (prevPage <= 1) {
        prevPageParams.delete('page');
      } else {
        prevPageParams.set('page', String(prevPage));
      }
    }

    let nextPageParams: URLSearchParams | null = null;
    if (paging && paging.has_next_page) {
      nextPageParams = new URLSearchParams(params);
      nextPageParams.set('page', String(currentPage + 1));
    }

    return {
      nextPageParams,
      prevPageParams,
    };
  }, [paging, currentPage, params]);

  return (
    <div className="flex items-center gap-4">
      <ButtonLink
        to={`${location.pathname}?${prevPageParams}`}
        size="tiny"
        disabled={!prevPageParams}
      >
        Prev
      </ButtonLink>

      <span className="text-sm font-semibold">{currentPage}</span>

      <ButtonLink
        to={`${location.pathname}?${nextPageParams}`}
        size="tiny"
        disabled={!nextPageParams}
      >
        Next
      </ButtonLink>
    </div>
  );
};

export default AuditNextPrevPaginator;
