import React, { Fragment, useState } from 'react';
import HeaderUserSection from '../Header/HeaderUserSection';
import SideMenu, { SideMenuProps } from './SideMenu';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Header from '../Header/Header';

interface Props extends Omit<SideMenuProps, 'onClickLink'> {}

const MobileHeaderAndDrawer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const headerNavBar = (
    <Header
      className="print:hidden md:hidden"
      {...props}
      left={
        <button onClick={() => setDrawerOpen(true)} type="button">
          <Bars3Icon className="h-6 w-6 text-white" />
        </button>
      }
      right={<HeaderUserSection />}
    />
  );

  const sideMenu = (
    <SideMenu {...props} onClickLink={() => setDrawerOpen(false)}>
      {children}
    </SideMenu>
  );

  return (
    <>
      <Transition.Root show={drawerOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex md:hidden"
          onClose={setDrawerOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-true-black bg-opacity-70" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-full max-w-xs flex-1 flex-col bg-turf-green-500">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:ring focus:ring-inset"
                    onClick={() => setDrawerOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XMarkIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>

              {sideMenu}
            </div>
          </Transition.Child>

          <div className="w-14 flex-shrink-0" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {headerNavBar}
    </>
  );
};

export default MobileHeaderAndDrawer;
