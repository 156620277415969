import React from 'react';
import PageHeader from '../../components/Layout/PageHeader';
import PageLayout from '../../components/Layout/PageLayout';
import Message from '../../components/Form/Message';

interface Props {}

const ResetPasswordSentPage: React.FC<Props> = () => {
  return (
    <PageLayout
      header={
        <>
          <PageHeader>Reset Password</PageHeader>
        </>
      }
    >
      <Message success>
        Check your email for a link to reset your password. If it doesn&apos;t
        appear within a few minutes, check your spam folder.
      </Message>
    </PageLayout>
  );
};

export default ResetPasswordSentPage;
