import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FullLogo from '../../Icons/FullLogo';
import Profile from '../../../img/profile.png';
import { useAuth, useAuthDispatch } from '../../../lib/auth/AuthContext';
import { removeCookie } from '../../../lib/KnownCookies';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import useOrgConfigQuery from '../../../hooks/query/useOrgConfigQuery';
import useAuthedPartnerUser from '../../../hooks/query/useAuthedPartnerUser';

export interface SideMenuProps {
  logoUrl?: string;
  onClickLink?: () => void;
}

const SideMenu: React.FC<React.PropsWithChildren<SideMenuProps>> = ({
  children,
  logoUrl,
  onClickLink,
}) => {
  const { organizationName, decodedJwt } = useAuth();
  const { data: orgConfig } = useOrgConfigQuery();
  const { data: iamPartnerUser } = useAuthedPartnerUser();

  const authDispatch = useAuthDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const pathname = useLocation().pathname;

  const handleLogout = () => {
    // @ts-ignore
    authDispatch({ type: 'logged out' });

    if (pathname.startsWith('/partner')) {
      navigate('/partner-login', { replace: true });
    } else {
      navigate('/login', { replace: true });
    }

    removeCookie('access_token');
    removeCookie('refresh_token');
    // removeCookie('sls_client_id');
    removeCookie('sls_user_type');
    queryClient.removeQueries();
  };

  return (
    <>
      <div className="h-14 bg-turf-green-900 px-4 py-2 text-white print:hidden">
        <Link to={logoUrl ?? '/'} onClick={onClickLink}>
          <FullLogo />
        </Link>
      </div>

      {children}

      <div className="border-t border-slate-green-500 px-4 py-2 print:hidden">
        <div className="flex items-center space-x-4">
          <img
            src={orgConfig?.image_url || Profile}
            alt="Profile"
            className="h-10 w-10"
          />

          <div className="flex-1 truncate">
            <div className="truncate text-base font-semibold text-white">
              {iamPartnerUser?.first_name && iamPartnerUser?.last_name
                ? `${iamPartnerUser.last_name}, ${iamPartnerUser.first_name}`
                : decodedJwt?.sub}
            </div>
            <div className="flex justify-between">
              <div
                className="mr-2 truncate text-sm text-pastel-green-500"
                style={{
                  direction: 'rtl',
                }}
              >
                {organizationName}
              </div>

              <button
                type="button"
                className="text-sm text-pastel-green-500 underline"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
