import React from 'react';
import { useQuery } from 'react-query';
import { fetchValueGuidePlatforms } from '../../api/item';
import ValueGuideDataTableColumn from './ValueGuideDataTableColumn';

interface Props {
  valueGuideParameters: Record<string, any>;
}

const ValueGuideDataTable: React.FC<Props> = ({ valueGuideParameters }) => {
  const { data: availablePlatforms } = useQuery(
    'platforms',
    fetchValueGuidePlatforms,
  );

  return (
    <div className="grid grid-flow-col grid-rows-6 items-center">
      <div />

      <div className="mr-2 text-center font-semibold">Avg. Resale Price</div>
      <div className="mr-2 text-center font-semibold">Recent Sales</div>
      <div className="mr-2 text-center">
        <div className="font-semibold">Time to Sell</div>
        <div>(&lt;30 / 30-90 / 90+)</div>
      </div>
      <div className="mr-2 text-center font-semibold">Recent Listings</div>
      <div className="mr-2 text-center font-semibold">Currently Available</div>

      {availablePlatforms?.map(platform => (
        <ValueGuideDataTableColumn
          key={platform}
          platform={platform}
          valueGuideParameters={valueGuideParameters}
        />
      ))}
    </div>
  );
};

export default ValueGuideDataTable;
