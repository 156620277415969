import axios from 'axios';

import {
  Category,
  FullCategory,
  Item,
  ListingItem,
  PostItemRequest,
  PostItemResponse,
  PostSourceRequest,
  Quote,
  Source,
  TradeInModel,
  TradeInModelVersion,
  ValueGuideMeta,
} from './intf/item';
import { ApiResponse } from './intf/generic';
import { getBaseUrl } from './axiosInitialization';
import { PayoutMethod } from './intf/payoutMethods';
import { Customer } from './intf/customer';
import createCategoryTreeFromFlatList from '../utils/createCategoryTreeFromFlatList';
import { ALLOWED_CATEGORIES_MAP_RE } from '../constants/inStoreQuoteFlow';

export const fetchPredictiveSearch = async (searchItem: string) => {
  const { data } = await axios.get<ApiResponse<Item[]>>(
    '/v1/trade_in/predictive_search',
    {
      params: {
        q: searchItem,
        filter_type: ['listable', 'relatable_category', 'canonical_model'],
      },
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch Items error');
};

export const fetchCategories = async () => {
  const { data } = await axios.get<ApiResponse<Category[]>>(
    '/trade-in/v1/categories',
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch categories error');
};

export const fetchCategoriesAsTree = async (
  orgId: string,
  parentOrgId: string,
) => {
  const { data } = await axios.get<ApiResponse<Category[]>>(
    '/trade-in/v1/categories',
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  let categories = data.data;

  if (!categories) {
    throw new Error('Fetch categories error');
  }

  const re =
    ALLOWED_CATEGORIES_MAP_RE[orgId] ||
    ALLOWED_CATEGORIES_MAP_RE[parentOrgId] ||
    ALLOWED_CATEGORIES_MAP_RE.DEFAULT;
  categories = categories.filter(category => re.test(category.full_name));

  return createCategoryTreeFromFlatList(categories);
};

export const fetchTradeInModelVersions = async (
  modelId: string,
  details: string[],
  itemId?: string,
) => {
  const { data } = await axios.get<ApiResponse<TradeInModelVersion[]>>(
    `/trade-in/v1/in-store/models/${modelId}/versions`,
    {
      params: {
        details: details.join(','),
        item_id: itemId,
      },
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (!data.data) {
    throw new Error('Error on model versions');
  }

  return data.data;
};

export const fetchSearchTradeInModelVersions = async (searchTerm: string) => {
  const { data } = await axios.get<ApiResponse<TradeInModelVersion[]>>(
    `/trade-in/v1/in-store/models/versions/search`,
    {
      params: {
        q: searchTerm,
      },
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (!data.data) {
    throw new Error('Error on model versions');
  }

  return data.data;
};

export const fetchCategoryById = async (id: number | string) => {
  if (!id || (Number(id) && Number(id) < 0)) {
    return;
  }

  const { data } = await axios.get<ApiResponse<FullCategory>>(
    `/trade-in/v1/categories/${id}`,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch category by id error');
};

export const fetchModels = async (categoryId: string, brandId: string) => {
  const { data } = await axios.get<ApiResponse<TradeInModel[]>>(
    `/trade-in/v1/in-store/models`,
    {
      baseURL: getBaseUrl('inventoryApi'),
      params: {
        category_id: categoryId,
        brand_id: brandId,
      },
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch category by id error');
};

export const postListingItem = async (
  quoteId: string,
  requestBody: PostItemRequest,
) => {
  const { data } = await axios.post<ApiResponse<PostItemResponse>>(
    `/trade-in/v1/quotes/${quoteId}/listing`,
    requestBody,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Create Item error');
};

export const putListingItem = async (
  quoteId: string,
  listingId: string,
  requestBody: PostItemRequest,
) => {
  const { data } = await axios.put<ApiResponse<PostItemResponse>>(
    `/trade-in/v1/quotes/${quoteId}/listing/${listingId}`,
    requestBody,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Create Item error');
};

export const fetchRecentQuotes = async (eventId?: string) => {
  const { data } = await axios.get<ApiResponse<Quote[]>>(
    '/trade-in/v1/quotes',
    {
      baseURL: getBaseUrl('inventoryApi'),
      params: {
        trade_in_event_id: eventId,
      },
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch quote items error');
};

export const fetchQuoteById = async (id: string, eventId?: string) => {
  const { data } = await axios.get<ApiResponse<Quote>>(
    `/trade-in/v1/quotes/${id}`,
    {
      baseURL: getBaseUrl('inventoryApi'),
      params: {
        trade_in_event_id: eventId,
      },
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch quote items error');
};

export const fetchQuotesByCustomer = async (
  customerId: string,
  eventId?: string,
) => {
  const { data } = await axios.get<ApiResponse<Quote[]>>(
    '/trade-in/v1/quotes/search',
    {
      baseURL: getBaseUrl('inventoryApi'),
      params: {
        customer_id: customerId,
        trade_in_event_id: eventId,
      },
    },
  );

  // Doesn't always return a top level data property...
  return data?.data ?? [];
};

export const fetchQuoteByCustomer = async (
  customerIdArr: string[],
  eventId?: string,
) => {
  let quotesArr = [];
  for (let customerId of customerIdArr) {
    const { data } = await axios.get<ApiResponse<Quote[]>>(
      '/trade-in/v1/quotes/search',
      {
        baseURL: getBaseUrl('inventoryApi'),
        params: {
          customer_id: customerId,
          trade_in_event_id: eventId,
        },
      },
    );

    if (data.data) {
      const quoteItem = {
        customerId,
        quotes: data.data,
      };

      quotesArr.push(quoteItem);
    }
  }

  return quotesArr;
};

export const fetchQuoteListingById = async (
  quoteId: string,
  listingId: string,
) => {
  const { data } = await axios.get<ApiResponse<ListingItem>>(
    `/trade-in/v1/quotes/${quoteId}/listing/${listingId}`,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch quote listing item by id error');
};

export const fetchSource = async () => {
  const { data } = await axios.get<ApiResponse<Source[]>>(
    '/trade-in/v1/sources',
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch source items error');
};

export const putUpdateQuote = async (
  quoteId: string,
  quote: Partial<Quote>,
) => {
  const { data } = await axios.put<ApiResponse<PostItemResponse>>(
    `/trade-in/v1/quotes/${quoteId}`,
    {
      ...quote,
    },
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Update source error');
};

export const postSource = async (source: PostSourceRequest) => {
  const { data } = await axios.post<ApiResponse<PostItemResponse>>(
    '/trade-in/v1/sources',
    source,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Create source error');
};

export const fetchValueGuide = async (
  queryObject: Record<string, any>,
  platform?: string,
): Promise<ValueGuideMeta> => {
  const { data } = await axios.get<
    ApiResponse<any[], { value_guide: ValueGuideMeta }>
  >('/v2/value_guide', {
    params: {
      ...queryObject,
      platform,
      context: 'trade_in',
    },
  });

  if (data?.meta?.value_guide) {
    return data.meta.value_guide;
  }

  throw new Error('Failed to fetch value guide');
};

export const fetchValueGuidePlatforms = async () => {
  const { data } = await axios.get<ApiResponse<string[]>>(
    '/v2/value_guide/platforms',
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Failed to fetch value guide');
};

export const fetchRetailValueGuide = async (
  queryObject: Record<string, any>,
): Promise<number | '--'> => {
  const { data } = await axios.get<
    ApiResponse<any[], { value_guide: ValueGuideMeta }>
  >('/v1/retail_value_guide', {
    params: {
      ...queryObject,
    },
  });

  if (data?.meta) {
    return data.meta.value_guide?.statistics?.median
      ? data.meta.value_guide.statistics.median * 0.4
      : '--';
  }

  throw new Error('Failed to fetch value guide');
};

export interface RecentlySoldItem {
  item_id: number;
  name: string;
  images: string[];
  details: {
    id: number;
    type: string;
    slug: string;
    name: string;
  }[];
  list_price: string;
  sold_price: string;
  sold_at: string; // Date
  published_at: string; // Date
  platform: 'sidelineswap';
  time_to_sell: number;
}

export const fetchRecentlySoldItems = async (
  queryObject: Record<string, any>,
  platform?: string,
): Promise<RecentlySoldItem[]> => {
  const { data } = await axios.get<ApiResponse<RecentlySoldItem[]>>(
    '/v2/trade_in/recently_sold_item',
    {
      params: {
        ...queryObject,
        platform,
      },
    },
  );

  if (data?.data) {
    return data.data;
  }

  throw new Error('Failed to fetch value guide');
};

export const fetchPayoutMethods = async () => {
  const { data } = await axios.get<ApiResponse<PayoutMethod[]>>(
    '/trade-in/v1/payout_methods',
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data) {
    return data.data;
  }

  throw new Error('Could not fetch payout methods');
};

export interface PayoutMethodSubmission {
  payout_method_id: string;
  charity?: string;
  percentage?: number;
}

// It's actually a put, but probably shouldn't be, so just name it post
export const postSubmitQuoteWithPayoutMethods = async (
  quoteId: string,
  payouts: PayoutMethodSubmission[],
  customer?: Customer,
) => {
  const { data } = await axios.put<ApiResponse<Quote>>(
    `/trade-in/v1/quotes/${quoteId}/complete`,
    {
      payouts,
      contact_email: customer?.email,
      contact_phone_number: customer?.phone,
    },
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data) {
    return data.data;
  }

  throw new Error('Put quote error');
};

export const deleteListingItemById = async (
  quoteId: string,
  listingId: string,
) => {
  await axios.delete<ApiResponse>(
    `/trade-in/v1/quotes/${quoteId}/listing/${listingId}`,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );
};

export const deleteQuoteByQuoteId = async (quoteId: string) => {
  await axios.delete<ApiResponse>(`/trade-in/v1/quotes/${quoteId}`, {
    baseURL: getBaseUrl('inventoryApi'),
  });
};

export const putReminder = async (quoteId: string) => {
  await axios.put<ApiResponse>(
    `/trade-in/v1/quotes/${quoteId}/pickup`,
    {},
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );
};
