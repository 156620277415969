import React from 'react';
import useCurrentQuoteQuery from '../../../hooks/query/useCurrentQuoteQuery';
import { useEventCustomerQuoteQuery } from '../../../hooks/routing/useEventCustomerQuoteQuery';
import PartnerQuoteListingRow from './PartnerQuoteListingRow';
import getQuoteListings from '../../../utils/getQuoteListings';
import displayPrice from '../../../utils/displayPrice';

interface Props {}

const PartnerQuoteSummaryPanel: React.FC<Props> = () => {
  const { quoteId } = useEventCustomerQuoteQuery();
  const { data: quote, error } = useCurrentQuoteQuery();

  const listings = getQuoteListings(quote);
  const subtotal = listings?.reduce(
    (acc, listing) => acc + listing.cash_offer_amount,
    0,
  );

  return (
    <div className="flex h-full w-80 flex-col space-y-4 px-4 md:w-96">
      <div className="pb-4 text-xl font-semibold">Trade-in Cart</div>

      {(!quoteId || (listings && listings?.length === 0)) && (
        <>
          <div className="flex-1" />
          <div className="self-center justify-self-center text-center">
            <div className="mb-1 text-2xl">Your cart is empty.</div>
            <div className="text-base text-slate-green-500">
              Add a trade-in item to get started.
            </div>
          </div>
          <div className="flex-1 pb-32" />
        </>
      )}

      {listings?.map(listing => (
        <PartnerQuoteListingRow key={listing.id} listing={listing} />
      ))}

      {listings?.length! > 0 && (
        <div className="mt-6">
          Subtotal:{' '}
          <span className="font-semibold">{displayPrice(subtotal)}</span>
        </div>
      )}
    </div>
  );
};

export default PartnerQuoteSummaryPanel;
