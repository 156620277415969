import Axios, { AxiosError, AxiosInstance } from 'axios';
import qsStringify from '../utils/qsStringify';
import { ApiError } from './ApiError';
import { getCookie, setCookie } from '../lib/KnownCookies';
import { v4 as uuidv4 } from 'uuid';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { postNewAccessToken } from './auth';

function baseRejectedInterceptor(error: AxiosError) {
  throw ApiError.fromAxiosError(error);
}

const configuredStringify = (params: any) =>
  qsStringify(params, { addQueryPrefix: false });

export function getBaseUrl(type?: 'inventoryApi') {
  if (type === 'inventoryApi') {
    return import.meta.env.REACT_APP_INVENTORY_API_URL;
  }

  return import.meta.env.REACT_APP_API_URL;
}

async function refreshLogic(error: AxiosError) {
  const refreshToken = getCookie('refresh_token');

  if (refreshToken) {
    await postNewAccessToken(refreshToken);
    return Promise.resolve();
  }
}

export async function initializeAxiosClientSide(axios: AxiosInstance = Axios) {
  let clientId = getCookie('sls_client_id');
  if (!clientId) {
    const uuid = uuidv4();
    setCookie('sls_client_id', uuid);
    clientId = uuid;
  }

  axios.defaults.baseURL = import.meta.env.REACT_APP_API_URL;
  if (axios.defaults.paramsSerializer) {
    axios.defaults.paramsSerializer.serialize = configuredStringify;
  }

  axios.defaults.headers['x-session-id'] = clientId;

  createAuthRefreshInterceptor(axios, refreshLogic);

  axios.interceptors.request.use(config => {
    if (!config.headers['x-sls-client-type']) {
      if (getCookie('sls_user_type') === 'partner') {
        config.headers['x-sls-client-type'] = 'IN_STORE';
      } else {
        config.headers['x-sls-client-type'] = 'TRADE_IN';
      }
    }

    if (config.headers.Authorization === null) {
      delete config.headers.Authorization;
    } else {
      const accessToken = getCookie('access_token');
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }

    return config;
  });

  axios.interceptors.response.use(undefined, baseRejectedInterceptor);

  return axios;
}
