import React from 'react';
import PageLayout from '../../components/Layout/PageLayout';
import PageHeader from '../../components/Layout/PageHeader';
import { z } from 'zod';
import FormikZodSchema from '../../components/Form/FormikZodSchema';
import { Form } from 'formik';
import FormikField from '../../components/Form/FormikField';
import FormikSubmitButton from '../../components/Form/FormikSubmitButton';
import { ButtonLink } from '../../components/Form/Button';
import { postResetPassword, postResetPasswordEmail } from '../../api/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthDispatch } from '../../lib/auth/AuthContext';
import { formatFormikError } from '../../api/ApiError';
import FormikErrorMessage from '../../components/Form/FormikErrorMessage';
import FormikPasswordField from '../../components/Form/FormikPasswordField';

const emailFormSchema = z.object({
  email: z.string().email('Invalid email address'),
});

const resetPasswordSchema = z.object({
  token: z.string(),
  password: z.string(),
});

interface Props {}

const ResetPasswordPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const authDispatch = useAuthDispatch();

  return (
    <PageLayout
      header={
        <>
          <PageHeader>Reset Password</PageHeader>
        </>
      }
    >
      {token ? (
        <FormikZodSchema
          onSubmit={async (values, formikHelpers) => {
            try {
              const { access_token, refresh_token } = await postResetPassword(
                values.token,
                values.password,
              );
              authDispatch({
                type: 'logged in',
                payload: {
                  accessToken: access_token,
                  refreshToken: refresh_token,
                },
              });

              navigate('/partner');
            } catch (e) {
              formikHelpers.setErrors(
                formatFormikError(e, {
                  errorFieldMap: {
                    new_password: 'password',
                  },
                }),
              );
            }
          }}
          schema={resetPasswordSchema}
          initialValues={{ token, password: '' }}
        >
          <Form className="mx-auto max-w-md space-y-4">
            <FormikPasswordField name="password" label="New Password" />

            <FormikErrorMessage />

            <div className="flex justify-end gap-4">
              <FormikSubmitButton>Reset Password</FormikSubmitButton>
            </div>
          </Form>
        </FormikZodSchema>
      ) : (
        <FormikZodSchema
          onSubmit={async values => {
            await postResetPasswordEmail(values.email);
            navigate('/reset-password/sent');
          }}
          schema={emailFormSchema}
          initialValues={{ email: '' }}
        >
          <Form className="mx-auto max-w-md space-y-4">
            <FormikField name="email" label="Email" type="email" />

            <FormikErrorMessage />

            <div className="flex justify-end gap-4">
              <ButtonLink to="/partner-login" variant="outlined">
                Back
              </ButtonLink>
              <FormikSubmitButton>Send Reset Link</FormikSubmitButton>
            </div>
          </Form>
        </FormikZodSchema>
      )}
    </PageLayout>
  );
};

export default ResetPasswordPage;
