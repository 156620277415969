import React from 'react';
import * as Sentry from '@sentry/browser';

interface Props {
  fallback?: React.ReactNode;
}

interface State {
  error?: Error;
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<
  React.PropsWithChildren<Props>,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return {
      error,
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    Sentry.captureException(error);
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    } else {
      return this.props.children;
    }
  }
}
