import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Item } from '../../../api/intf/item';
import qsStringify from '../../../utils/qsStringify';
import { useQuery } from 'react-query';
import { fetchPredictiveSearch } from '../../../api/item';
import { useEventCustomerQuoteQuery } from '../../../hooks/routing/useEventCustomerQuoteQuery';
import PageLayout from '../../../components/Layout/PageLayout';
import Message from '../../../components/Form/Message';
import { getErrorMessage } from '../../../api/ApiError';
import QuotePrintModal from '../../../components/QuotePrintModal';
import LoadingIndicator from '../../../components/Form/LoadingIndicator';
import ItemSearchDropdown from '../../../components/ItemForms/ItemSearchDropdown';
import PageHeader from '../../../components/Layout/PageHeader';
import { Button } from '../../../components/Form/Button';

interface Props {}

const SearchItemPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [params] = useSearchParams();
  const { quoteId, eventId, customerId } = useEventCustomerQuoteQuery();
  const [showPrintModal, setShowPrintModal] = useState(
    params.get('show_print') === '1',
  );

  const {
    data: items,
    error,
    isLoading,
  } = useQuery(['search-items', search], () => fetchPredictiveSearch(search), {
    enabled: search.length > 1,
    staleTime: 300000,
    keepPreviousData: true,
  });

  const handleStartFromScratch = () => {
    navigate(
      `/event/${eventId}/customer/${customerId}/quote/${quoteId}/listing/create`,
    );
  };

  const handleAddItem = (item: Item) => {
    const stringItem = qsStringify(item.query);
    navigate(
      `/event/${eventId}/customer/${customerId}/quote/${quoteId}/listing/create${stringItem}`,
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageLayout header={<PageHeader>Search for an item</PageHeader>}>
      <div className="flex items-end space-x-2">
        <ItemSearchDropdown onSelect={handleAddItem} />
        <Button className="whitespace-nowrap" onClick={handleStartFromScratch}>
          Start From Scratch
        </Button>
      </div>

      <div className="space-y-4">
        {!!error && (
          <Message error>
            Failed to search for items: {getErrorMessage(error)}
          </Message>
        )}

        {isLoading && <LoadingIndicator size="large" className="mx-auto" />}

        {items?.map(item => (
          <div key={item.id} className="flex items-center space-x-4">
            {item.thumb_url ? (
              <img src={item.thumb_url} className="h-14 w-14 rounded-md" />
            ) : (
              <div className="h-14 w-14" />
            )}
            <div>
              <div>
                {item.name}{' '}
                {item.category_name && (
                  <span>
                    {' in '}
                    <span className="font-semibold">{item.category_name}</span>
                  </span>
                )}
              </div>

              <div className="flex justify-start">
                <button
                  className="pr-4 font-semibold underline"
                  onClick={() => handleAddItem(item)}
                >
                  Add Item
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <QuotePrintModal
        eventId={eventId}
        quoteId={quoteId}
        onClose={() => setShowPrintModal(false)}
        open={showPrintModal}
      />
    </PageLayout>
  );
};

export default SearchItemPage;
