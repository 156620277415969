import React, { useMemo } from 'react';
import {
  fetchTransferHistoryItems,
  FetchTransferHistoryItemsParams,
  PartnerTransferItem,
} from '../../api/partnerTransfers';
import { useSearchParams } from 'react-router-dom';
import {
  ColumnDef,
  ColumnFilter,
  ColumnSort,
  FilterFn,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import SimpleReactTable from '../Tables/SimpleReactTable';
import AuditNextPrevPaginator from '../Warehouse/Audit/AuditNextPrevPaginator';
import usePartnerUserName from '../../hooks/query/usePartnerUserName';
import {
  transferIdColumn,
  transferItemPriceVarianceColumn,
  transferItemStatusColumn,
} from '../Warehouse/Audit/WarehouseAuditItemsTable';
import { isAfter, isBefore, minutesToMilliseconds, set } from 'date-fns';
import Input from '../Form/Input';
import FieldLabel from '../Form/FieldLabel';
import { useQuery } from 'react-query';
import Message from '../Form/Message';
import { getErrorMessage } from '../../api/ApiError';
import { XMarkIcon } from '@heroicons/react/24/outline';

const ITEMS_PER_PAGE = 20;

interface TransfersItemsFilters {
  page?: string;
  start_date?: string;
  end_date?: string;
  created_by?: string;
  sort?: string;
  direction?: string;
}

interface DateFilterValue {
  start_date?: Date;
  end_date?: Date;
}

function searchParamsToBackendParams(
  params: URLSearchParams,
): FetchTransferHistoryItemsParams {
  return {
    size: String(ITEMS_PER_PAGE),
    page: params.get('page') || '1',
    sort: params.get('sort') as any,
    order: params.get('direction') as any,
  };
}

function searchParamsToColumnFilters(params: URLSearchParams): ColumnFilter[] {
  const filterStartDate = params.get('start_date');
  const filterEndDate = params.get('end_date');

  let filters: ColumnFilter[] = [];

  if (filterStartDate || filterEndDate) {
    const dateFilter: DateFilterValue = {
      start_date: filterStartDate
        ? set(new Date(filterStartDate), {
            hours: 0,
            minutes: 0,
            seconds: 0,
          })
        : undefined,
      end_date: filterEndDate
        ? set(new Date(filterEndDate), {
            hours: 23,
            minutes: 59,
            seconds: 59,
          })
        : undefined,
    };
    filters.push({
      id: 'created',
      value: dateFilter,
    });
  }

  const filterCreatedBy = params.get('created_by');
  if (filterCreatedBy) {
    filters.push({
      id: 'created_by',
      value: filterCreatedBy,
    });
  }

  return filters;
}

function searchParamsToSorting(params: URLSearchParams): ColumnSort[] {
  const sort = params.get('sort');
  const direction = params.get('direction');

  if (sort && direction) {
    return [
      {
        id: sort,
        desc: direction === 'desc',
      },
    ];
  }

  return [{ id: 'created', desc: true }];
}

const dateFilterFn: FilterFn<PartnerTransferItem> = (
  row,
  columnId,
  filterValue?: DateFilterValue,
) => {
  const createdAt = row.getValue(columnId) as Date;

  if (filterValue?.start_date && filterValue?.end_date) {
    return (
      isAfter(createdAt, filterValue.start_date) &&
      isBefore(createdAt, filterValue.end_date)
    );
  } else if (filterValue?.start_date) {
    return isAfter(createdAt, filterValue.start_date);
  } else if (filterValue?.end_date) {
    return isBefore(createdAt, filterValue.end_date);
  } else {
    return true;
  }
};

const columns: ColumnDef<PartnerTransferItem, any>[] = [
  {
    id: 'created',
    accessorFn: item => new Date(item.created_at),
    header: 'Created',
    enableSorting: true,
    sortDescFirst: false,
    cell: info => info.getValue().toLocaleDateString(),
    sortingFn: 'datetime',
    filterFn: dateFilterFn,
  } as ColumnDef<PartnerTransferItem, Date>,
  {
    id: 'name',
    accessorFn: item => item.name,
    header: 'Name',
    cell: row => (
      <span className="inline-block w-52 whitespace-pre-line">
        {row.getValue()}
      </span>
    ),
    enableSorting: true,
  } as ColumnDef<PartnerTransferItem, PartnerTransferItem['name']>,
  {
    id: 'created_by',
    header: 'Teammate',
    accessorFn: item => item.created_by,
    cell: ({ row }) => {
      const [params, setParams] = useSearchParams();
      const partnerName = usePartnerUserName(row.original.created_by);

      return (
        <button
          className="underline"
          type="button"
          onClick={() => {
            const newParams = new URLSearchParams(params);
            newParams.set('created_by', row.original.created_by);
            newParams.delete('page');
            setParams(newParams);
          }}
        >
          {partnerName}
        </button>
      );
    },
    filterFn: 'equalsString',
  } as ColumnDef<PartnerTransferItem, PartnerTransferItem['created_by']>,
  transferIdColumn,
  {
    id: 'audited',
    enableSorting: true,
    accessorFn: item => (item.audited_at ? new Date(item.audited_at) : null),
    sortingFn: 'datetime',
    header: 'Reviewed',
    cell: info => {
      if (info.getValue() == null) {
        return '';
      }

      return info.getValue()?.toLocaleDateString();
    },
    filterFn: dateFilterFn,
  } as ColumnDef<PartnerTransferItem, Date | null>,
  transferItemStatusColumn,
  transferItemPriceVarianceColumn,
];

interface Props {
  showFilters?: boolean;
  showPagination?: boolean;
}

const FilteredTransfersItemsTable: React.FC<Props> = ({
  showFilters,
  showPagination,
}) => {
  const [params, setParams] = useSearchParams();

  const page = Number(params.get('page') || 1);

  const backendParams = useMemo(
    () => searchParamsToBackendParams(params),
    [params],
  );
  const { data: itemsResponse, error: transferItemsError } = useQuery(
    ['transfer-items', backendParams],
    () => fetchTransferHistoryItems(backendParams),
    {
      keepPreviousData: true,
      staleTime: minutesToMilliseconds(5),
    },
  );

  const transferItems = itemsResponse?.data || [];

  const filteredPartnerName = usePartnerUserName(params.get('created_by'));

  function handleChangeParam(key: keyof TransfersItemsFilters, value: any) {
    const newParams = new URLSearchParams(params);
    newParams.delete('page');

    if (!value) {
      newParams.delete(key);
    } else {
      newParams.set(key, value);
    }

    setParams(newParams);
  }

  function handleChangeSort(sort: SortingState) {
    const newParams = new URLSearchParams(params);
    newParams.delete('page');

    if (sort.length === 0) {
      newParams.delete('sort');
      newParams.delete('direction');
    } else {
      newParams.set('sort', sort[0].id);
      newParams.set('direction', sort[0].desc ? 'desc' : 'asc');
    }

    setParams(newParams);
  }

  const sorting = useMemo(() => searchParamsToSorting(params), [params]);
  const columnFilters = useMemo(
    () => searchParamsToColumnFilters(params),
    [params],
  );

  const table = useReactTable<PartnerTransferItem>({
    columns,
    enableSorting: true,
    manualSorting: true,
    data: transferItems,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      columnFilters,
      pagination: {
        pageSize: ITEMS_PER_PAGE,
        pageIndex: page - 1,
      },
    },
    onSortingChange: updater => {
      if (typeof updater !== 'function') {
        handleChangeSort(updater);
        return;
      } else {
        const newSort = updater(sorting);
        handleChangeSort(newSort);
      }
    },
  });

  return (
    <div>
      {!!transferItemsError && (
        <Message error>
          Something went wrong: {getErrorMessage(transferItemsError)}
        </Message>
      )}

      {showFilters && (
        <div className="mb-8 flex gap-4">
          <div>
            <FieldLabel htmlFor="filter-start-date">
              Created Start Date
            </FieldLabel>
            <Input
              type="date"
              id="filter-start-date"
              max={params.get('end_date') || undefined}
              value={params.get('start_date') || ''}
              onChange={e => handleChangeParam('start_date', e.target.value)}
            />
          </div>

          <div>
            <FieldLabel htmlFor="filter-start-date">
              Created End Date
            </FieldLabel>
            <Input
              type="date"
              id="filter-end-date"
              min={params.get('start_date') || undefined}
              value={params.get('end_date') || ''}
              onChange={e => handleChangeParam('end_date', e.target.value)}
            />
          </div>

          {params.get('created_by') && (
            <div>
              <FieldLabel htmlFor="filter-created-by">Teammate</FieldLabel>
              <div className="mt-3 flex items-center gap-2">
                <div>{filteredPartnerName}</div>
                <button
                  type="button"
                  onClick={() => handleChangeParam('created_by', null)}
                >
                  <XMarkIcon className="h-6 w-6 stroke-2 text-red-500" />
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      <SimpleReactTable table={table} />

      {transferItems?.length === 0 && (
        <div className="mx-auto my-8 font-semibold text-slate-green-500">
          No results
        </div>
      )}

      {showPagination && (
        <div className="mt-4 flex justify-center">
          <AuditNextPrevPaginator paging={itemsResponse?.meta?.paging} />
        </div>
      )}
    </div>
  );
};

export default FilteredTransfersItemsTable;
