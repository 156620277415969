import { useQuery, UseQueryOptions } from 'react-query';
import { fetchCustomerById } from '../api/customer';
import { IAMUser } from '../api/intf/auth';

export default function useCustomerById(
  customerId?: string | null,
  options?: UseQueryOptions<IAMUser, any, IAMUser, any>,
) {
  return useQuery(
    ['customers', customerId],
    () => fetchCustomerById(customerId!),
    {
      enabled: !!customerId,
      staleTime: 30000,
    },
  );
}
