import React, { useState } from 'react';
import PageHeader from '../../../components/Layout/PageHeader';
import PageLayout from '../../../components/Layout/PageLayout';
import useOrgId from '../../../hooks/useOrgId';
import { fetchIamPartnerUsers } from '../../../api/partnerUsers';
import Message from '../../../components/Form/Message';
import FieldLabel from '../../../components/Form/FieldLabel';
import Input from '../../../components/Form/Input';
import Field from '../../../components/Form/Field';
import { Link, useNavigate } from 'react-router-dom';
import { useDebounce, useMount } from 'react-use';
import { useQuery } from 'react-query';
import { Button } from '../../../components/Form/Button';
import { PlusIcon } from '@heroicons/react/24/outline';
import PartnerUsersTable from '../../../components/Partner/ManageUsers/PartnerUsersTable';
import useRouteQuery from '../../../hooks/useRouteQuery';
import PartnerManageUsersPagination from '../../../components/Partner/ManageUsers/PartnerManageUsersPagination';
import {
  fireSearchedUser,
  fireVisitedManageUsers,
} from '../../../services/analytics/partner/manageUsers';

interface Props {}

const PartnerAdminManageUsersPage: React.FC<Props> = () => {
  useMount(fireVisitedManageUsers);

  const orgId = useOrgId();
  const navigate = useNavigate();
  const { page: urlPage, q: urlQ } = useRouteQuery();
  const [search, setSearch] = useState(() => (urlQ as string) || '');
  const [debouncedSearch, setDebouncedSearch] = useState(search);

  useDebounce(
    () => {
      if (search !== debouncedSearch) {
        setDebouncedSearch(search);
      }
    },
    250,
    [search],
  );

  const { data, error } = useQuery(
    ['search-users', debouncedSearch, urlPage] as const,
    ({ queryKey }) =>
      fetchIamPartnerUsers(orgId!, {
        page: queryKey[2],
        q: queryKey[1] || undefined,
      }),
    {
      keepPreviousData: true,
      enabled: !!orgId,
      onSuccess: () => {
        if (debouncedSearch) {
          fireSearchedUser(debouncedSearch);
        }
      },
    },
  );

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    setSearch(e.target.value);
    navigate(`?q=${e.target.value}`, {
      replace: true,
    });
  };

  const users = data?.data.trade_in_users || [];

  return (
    <PageLayout
      header={
        <>
          <PageHeader>Manage Users</PageHeader>
        </>
      }
    >
      <Field>
        <FieldLabel htmlFor="search-users">Search by customer info</FieldLabel>
        <Input
          id="search-users"
          placeholder="Search for users"
          type="search"
          autoFocus
          onChange={handleChange}
          value={search}
        />
      </Field>

      <Link to="./new" className="my-6 inline-block">
        <Button className="flex items-center space-x-2">
          <PlusIcon className="h-4 w-4" />
          <span>Add User</span>
        </Button>
      </Link>

      {!!error && <Message error>Failed to load users</Message>}
      {users.length === 0 && (
        <div className="my-8 text-center text-lg text-slate-green-500">
          No users found
        </div>
      )}

      {users.length > 0 && <PartnerUsersTable users={users} />}

      {data?.meta && (
        <div className="mt-6 flex justify-center">
          <PartnerManageUsersPagination meta={data?.meta} />
        </div>
      )}
    </PageLayout>
  );
};

export default PartnerAdminManageUsersPage;
