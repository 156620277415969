import { CreateItemFormValues } from '../components/ItemForms/CreateItemForm';
import { FullCategory } from '../api/intf/item';

export default function prepareValuesForValueGuide(
  values: CreateItemFormValues,
  fullCategory?: FullCategory,
  enabledFields?: Record<string, boolean>,
) {
  let queryObject: any = {};

  if (!values) return {};

  const keys = Object.keys(values);
  const objectValues = Object.values(values);
  for (let i = 0; i < keys.length; i++) {
    const value = objectValues[i];
    const key = keys[i];

    if (key === 'grade' || key === 'cashoffer' || key === 'resalePrice') {
      continue;
    }

    if (value?.value && enabledFields?.[key]) {
      queryObject[key] = [value.value];
    }
  }

  return queryObject;
}
