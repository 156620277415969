import React from 'react';
import classNames from 'classnames';

interface Props extends React.LabelHTMLAttributes<HTMLLabelElement> {
  error?: any;
}

const FieldLabel: React.FC<Props> = ({
  children,
  className,
  error,
  ...props
}) => (
  <label
    className={classNames(
      'mb-1 block font-semibold',
      className,
      error && 'text-red-500',
    )}
    {...props}
  >
    {children}
  </label>
);

export default FieldLabel;
