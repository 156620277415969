import React, { useMemo } from 'react';
import useCurrentWarehouseId from '../../hooks/warehouse/useCurrentWarehouseId';
import useCurrentPicklistId from '../../hooks/warehouse/useCurrentPicklistId';
import BackArrowLink from '../BackArrowLink';

interface Props {
  to: 'home' | 'picklist' | 'picklistById';
}

const WarehouseBreadcrumbLink: React.FC<Props> = ({ to }) => {
  const warehouseId = useCurrentWarehouseId();
  const picklistId = useCurrentPicklistId();

  const { url, label } = useMemo<{ url: string; label: string }>(() => {
    switch (to) {
      case 'home':
        return { url: `/warehouse/${warehouseId}`, label: 'Warehouse Home' };

      case 'picklist':
        return {
          url: `/warehouse/${warehouseId}/pick`,
          label: 'Pick Lists',
        };

      case 'picklistById':
        return {
          url: `/warehouse/${warehouseId}/picklist/${picklistId}`,
          label: 'Pick List',
        };
    }
  }, [to, warehouseId, picklistId]);

  return <BackArrowLink to={url}>{label}</BackArrowLink>;
};

export default WarehouseBreadcrumbLink;
