import React from 'react';
import PageHeader from '../../../components/Layout/PageHeader';
import PageLayout from '../../../components/Layout/PageLayout';
import NavigationButton from '../../../components/Layout/NavigationButton';

// These links intentionally do not start with a "/"
// so we can do relative routing
const links = [
  {
    to: 'customer/create',
    text: 'Create a Customer',
  },
  {
    to: 'customer/search',
    text: 'Search Customer',
  },
  // {
  //   to: '/',
  //   text: 'Start with Quote',
  // },
  {
    to: 'quote/search',
    text: 'Search by Quote ID',
  },
];

interface Props {}

const EventHomepage: React.FC<Props> = () => {
  return (
    <PageLayout
      header={
        <>
          <PageHeader>Welcome</PageHeader>
        </>
      }
    >
      <div className="mx-auto mt-8 max-w-sm space-y-8">
        {links.map(link => {
          return (
            <NavigationButton key={link.to} to={link.to}>
              {link.text}
            </NavigationButton>
          );
        })}
      </div>
    </PageLayout>
  );
};

export default EventHomepage;
