import jwtDecode from 'jwt-decode';
import { DecodedJwt } from '../api/intf/auth';

export default function decodeJwt(accessToken?: string) {
  if (accessToken) {
    try {
      return jwtDecode<DecodedJwt>(accessToken);
    } catch (e) {
      return null;
    }
  }
  return null;
}
