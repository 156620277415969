import React from 'react';
import { PicklistItem } from '../../../api/intf/picklist';

interface Props {
  item: PicklistItem;
}

const PicklistItemDetails: React.FC<Props> = ({ item }) => {
  return (
    <div>
      <div className="mb-4 grid grid-cols-3 gap-x-4 gap-y-1">
        <div className="font-semibold">Location</div>
        <div className="font-semibold">Item ID</div>
        <div className="font-semibold">Status</div>

        <div>{item.location}</div>
        <div>{item.legacy_item_id}</div>
        <div>
          {item.warehouse_order_state === 'RECEIVED' ? (
            'Unpicked'
          ) : (
            <span className="font-semibold text-green-500">Picked</span>
          )}
        </div>
      </div>

      <div className="mb-1 font-semibold">Status</div>
      <div className="mb-4">{item.swap_state}</div>

      <div className="mb-1 font-semibold">Name</div>
      <div className="mb-4">{item.name}</div>

      <div className="mb-6">
        {item.images?.[0] ? (
          <img
            src={item.images?.[0]}
            className="rounded object-cover"
            height={400}
          />
        ) : (
          <div className="text-center text-lg">No image</div>
        )}
      </div>
    </div>
  );
};

export default PicklistItemDetails;
