import React from 'react';
import NewTabLink from '../NewTabLink';

export default function ContactSupportLink({
  children,
  ...props
}: Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>) {
  // const { data } = useCurrentPartnerUserQuery();

  return (
    <NewTabLink
      href="https://help.sidelineswap.com/hc/en-us/requests/new?ticket_form_id=8594259314076"
      {...props}
    >
      {children}
    </NewTabLink>
  );
}
