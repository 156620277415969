import React, { useState } from 'react';
import displayPrice from '../../../utils/displayPrice';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Button } from '../../Form/Button';
import { useMutation, useQueryClient } from 'react-query';
import { postResendPayoutNotification } from '../../../api/payouts';
import ConfirmationModal from '../../Modal/ConfirmationModal';
import { deleteQuoteByQuoteId } from '../../../api/item';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../api/ApiError';
import useQuoteQuery from '../../../hooks/query/useQuoteQuery';
import useCustomerById from '../../../hooks/useCustomerById';
import useRouteQuery from '../../../hooks/useRouteQuery';
import { TagItemsPageQuery } from '../../../pages/partnerInStore/dashboard/partnerTagItems';
import ManageQuotePayoutsEditEmailModal from '../../QuoteSummary/ManageQuotePayoutsEditEmailModal';
import {
  fireClickedEditContact,
  fireClickedManagePayout,
  fireClickedResendNotification,
  fireUpdatedContactEmail,
} from '../../../services/analytics/partner/tagging';
import { fireVoidedQuote } from '../../../services/analytics/partner/quotes';
import isQuoteVoidable from '../../../utils/isQuoteVoidable';

interface Props {}

const PartnerQuoteManagePayout: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const query = useRouteQuery<TagItemsPageQuery>();
  const [showManagePayout, setShowManagePayout] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [editEmailModalOpen, setEditEmailModalOpen] = useState(false);
  const quoteId = query.quote_id;

  const { data: quote } = useQuoteQuery(quoteId);
  const { data: contact } = useCustomerById(quote?.contact_id);

  const refetchAndInvalidateQuote = () => {
    queryClient.invalidateQueries({
      exact: false,
    });
  };

  const { mutate: resendNotification, isLoading: isResendingNotification } =
    useMutation((sms: boolean) => postResendPayoutNotification(quoteId!, sms), {
      onMutate: () => {
        fireClickedResendNotification(quoteId!);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['quotes', quoteId]);
        toast.success('Sent another gift card email');
      },
      onError: err => {
        toast.error(`Error resending notification: ${getErrorMessage(err)}`);
      },
    });

  const isVoidable = isQuoteVoidable(quote);
  const { mutate: deleteQuote } = useMutation(
    () => {
      if (!isQuoteVoidable(quote)) {
        throw new Error('Quote is not voidable');
      }
      return deleteQuoteByQuoteId(quoteId!);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['quotes', quoteId]);
        toast.success('Quote voided');
        setDeleteModal(false);
      },
      onError: (error: Error) => {
        toast.error(`Could not void the quote: ${getErrorMessage(error)}`);
        setDeleteModal(false);
      },
    },
  );

  function handleClickToggleSection() {
    fireClickedManagePayout(quoteId!);
    setShowManagePayout(!showManagePayout);
  }

  function handleClickVoidQuote() {
    fireVoidedQuote(quoteId!, quote?.cash_offer_amount!);
    setDeleteModal(true);
  }

  function handleClickEditEmail() {
    fireClickedEditContact(quoteId!);
    setEditEmailModalOpen(true);
  }

  function handleSuccessfulEditEmail() {
    fireUpdatedContactEmail(quoteId!);
    setEditEmailModalOpen(false);
    refetchAndInvalidateQuote();
  }

  function handleClickResendNotification() {
    resendNotification(false);
  }

  return (
    <section
      aria-label="Gift Card"
      className="my-8 space-y-4 rounded border px-4 py-3"
    >
      <div className="flex justify-between">
        <h2 className="text-lg font-semibold">
          Success! A gift card for {displayPrice(quote?.cash_offer_amount)} was
          sent to: <b>{contact?.email}</b>
        </h2>
        <button
          type="button"
          onClick={handleClickToggleSection}
          className="inline-flex items-center space-x-2 text-sm font-semibold text-green-500"
        >
          <span>Manage Payout</span>
          {showManagePayout ? (
            <ChevronUpIcon className="h-4 w-4" />
          ) : (
            <ChevronDownIcon className="h-4 w-4" />
          )}
        </button>
      </div>

      {showManagePayout && (
        <div className="prose">
          <ul>
            <li>
              Gift cards may take up to five minutes to arrive in your email
              inbox.
            </li>
            <li>
              Be sure to check your spam folder if you don't see it initially.
            </li>
            <li>Gift cards can be used immediately at any store or online.</li>
            <li>
              The value can be split over multiple purchases, and they don't
              expire.
            </li>
          </ul>

          <div className="mt-4 flex space-x-2">
            <Button
              onClick={handleClickResendNotification}
              disabled={isResendingNotification}
            >
              Resend Notification
            </Button>
            <Button variant="outlined" onClick={handleClickEditEmail}>
              Edit Email
            </Button>
          </div>

          {isVoidable && (
            <p>
              If the quote was completed in error, you can void the quote and
              cancel the gift card that was issued.{' '}
              <div className="mt-2">
                <Button
                  variant="outlined"
                  color="danger"
                  onClick={handleClickVoidQuote}
                >
                  Void Quote
                </Button>
              </div>
            </p>
          )}
        </div>
      )}

      <ConfirmationModal
        open={isDeleteModal}
        onClose={() => setDeleteModal(false)}
        title="Are you sure you want to void this quote?"
        okText="Void"
        okProps={{
          color: 'danger',
        }}
        cancelText="No"
        handleOK={deleteQuote}
        handleCancel={() => setDeleteModal(false)}
      />

      <ManageQuotePayoutsEditEmailModal
        onSuccessfulSubmit={handleSuccessfulEditEmail}
        open={editEmailModalOpen}
        onClose={() => setEditEmailModalOpen(false)}
        quoteId={quoteId}
      />
    </section>
  );
};

export default PartnerQuoteManagePayout;
