import React from 'react';
import { Button } from '../../components/Form/Button';
import selectEnv from '../../utils/selectEnv';
import DSG from '../../img/dsg-logo.png';

interface Props {}

const URL = selectEnv(
  'https://b2bsp.sidelineswap.com',
  'https://dsgtest.sidelineswap.com',
);

const DSGAuthButton: React.FC<Props> = () => {
  return (
    <a href={URL}>
      <Button
        variant="outlined"
        fluid
        className="flex h-14 items-center space-x-4"
      >
        <img src={DSG} alt="DSG" className="h-10" />
        <span>Log in with DICK&apos;S</span>
      </Button>
    </a>
  );
};

export default DSGAuthButton;
