import React from 'react';
import { Listbox } from '@headlessui/react';
import {
  BaseSelectButton,
  BaseSelectMenu,
  BaseSelectMenuItem,
} from './elements';
import FieldLabel from '../FieldLabel';
import classNames from 'classnames';

export interface SimpleHeadlessSelectProps<T> {
  className?: string;
  error?: string | boolean;
  disabled?: boolean;
  id?: string;
  items: T[];
  itemToString: (item: T, index: number) => string | number;
  label?: React.ReactNode;
  placeholder?: React.ReactNode;
  renderItem?: (item: T) => React.ReactNode;
  value?: T | null;
  clearable?: boolean;
  onChange: (value: T | null) => void;
}

export default function SimpleHeadlessSelect<T>({
  className,
  clearable,
  error,
  disabled,
  id,
  itemToString,
  items,
  label,
  placeholder,
  renderItem,
  value,
  onChange,
}: SimpleHeadlessSelectProps<T>) {
  return (
    <Listbox value={value} onChange={onChange} disabled={disabled}>
      <div className="relative">
        {label && (
          <Listbox.Label as={FieldLabel} error={error}>
            {label}
          </Listbox.Label>
        )}

        <Listbox.Button
          as={BaseSelectButton}
          className={classNames('cursor-default', className)}
          // I don't know why the disabled prop isn't being processed by their types
          // @ts-ignore
          disabled={disabled}
          id={id}
          error={error}
          onClear={clearable ? () => onChange(null) : undefined}
        >
          {value ? (
            renderItem?.(value) ?? itemToString(value, -1)
          ) : (
            <span className="text-slate-green-500">
              {placeholder ?? 'Select an option'}
            </span>
          )}
        </Listbox.Button>

        <Listbox.Options as={React.Fragment}>
          {({ open }) => (
            <BaseSelectMenu isOpen={open} error={error}>
              {items.map((item, i) => {
                const toString = itemToString?.(item, i);

                return (
                  <Listbox.Option
                    key={toString}
                    value={item}
                    as={React.Fragment}
                  >
                    {({ selected, active }) => (
                      <BaseSelectMenuItem
                        selected={selected}
                        highlighted={active}
                      >
                        {renderItem?.(item) || toString}
                      </BaseSelectMenuItem>
                    )}
                  </Listbox.Option>
                );
              })}
            </BaseSelectMenu>
          )}
        </Listbox.Options>
      </div>
    </Listbox>
  );
}
