import React from 'react';
import classNames from 'classnames';

interface Props extends React.BaseHTMLAttributes<HTMLDivElement> {}

const FieldError: React.FC<Props> = ({ children, className, id, ...props }) => (
  <div
    className={classNames('mt-1 text-sm text-red-500', className)}
    id={id ? `${id}-error` : undefined}
    {...props}
  >
    {children}
  </div>
);

export default FieldError;
