import React, { useState } from 'react';
import { z } from 'zod';
import FormikZodSchema from '../../Form/FormikZodSchema';
import { Form } from 'formik';
import FormikField from '../../Form/FormikField';
import FormikPhoneNumberField from '../../Form/FormikPhoneNumberField';
import { postCustomer } from '../../../api/customer';
import {
  postCreatePartnerQuote,
  putUpdateContactOnQuote,
} from '../../../api/partnerQuote';
import { ApiError, formatFormikError } from '../../../api/ApiError';
import { CreateCustomerErrorMeta, Customer } from '../../../api/intf/customer';
import pluralize from '../../../utils/pluralize';
import formatPhoneNumber from '../../../utils/formatPhoneNumber';
import { Button } from '../../Form/Button';
import getEnv from '../../../utils/getEnv';
import { fireAddedContact } from '../../../services/analytics/partner/quotes';
import { Quote } from '../../../api/intf/item';
import { useNavigate } from 'react-router-dom';

const schema = z.object({
  firstName: z.string().min(1, 'Required'),
  lastName: z.string().min(1, 'Required'),
  email: z.string().email('Must be an email').min(1, 'Required'),
  phone: z
    .any({ invalid_type_error: 'Required' })
    .transform(v => Number(v))
    .refine(v => String(v).length == 10, 'Required'),
});

interface Props {
  children?: React.ReactNode;
  existingContact?: Customer;
  quote?: Quote;
  onSuccessfulSubmit?: (quote: Quote) => void;
}

const PartnerContactForm: React.FC<Props> = ({
  children,
  existingContact,
  onSuccessfulSubmit,
  quote,
}) => {
  const navigate = useNavigate();
  const [matchedExistingCustomers, setMatchedExistingCustomers] = useState<
    Customer[] | undefined
  >();

  async function handleClickExistingCustomer(customer: Customer) {
    if (!quote) {
      return;
    }

    try {
      await putUpdateContactOnQuote(quote.id, customer.id);
      fireAddedContact({
        quoteId: quote.id,
        contactId: customer.id,
        contactPhone: String(customer.phone),
        contactEmail: customer.email,
        contactFirstName: customer.first_name,
        contactLastName: customer.last_name,
        isNew: false,
      });
      setMatchedExistingCustomers(undefined);
      onSuccessfulSubmit?.(quote);
    } catch (e) {}
  }

  return (
    <FormikZodSchema
      initialValues={{
        firstName: existingContact?.first_name || '',
        lastName: existingContact?.last_name || '',
        email: existingContact?.email || '',
        phone: Number(existingContact?.phone) || '',
      }}
      onSubmit={async (values, formikHelpers) => {
        let actualQuote: Quote;
        try {
          if (quote) {
            actualQuote = quote;
          } else {
            actualQuote = await postCreatePartnerQuote();
            navigate(`/partner/quote/${actualQuote.id}/customer`);
          }
        } catch (e) {
          formikHelpers.setErrors(formatFormikError(e));
          return;
        }

        try {
          setMatchedExistingCustomers(undefined);
          const createCustomerResponse = await postCustomer(
            values.firstName,
            values.lastName,
            values.email,
            String(values.phone),
          );

          await putUpdateContactOnQuote(
            actualQuote.id,
            createCustomerResponse.id,
          );

          fireAddedContact({
            quoteId: actualQuote.id,
            contactId: createCustomerResponse.id,
            contactPhone: String(values.phone),
            contactEmail: values.email,
            contactFirstName: values.firstName,
            contactLastName: values.lastName,
            isNew: true,
          });

          onSuccessfulSubmit?.(actualQuote);
        } catch (e) {
          const matchedContacts = (e as ApiError<CreateCustomerErrorMeta>)?.meta
            ?.matched_contacts;

          if (matchedContacts && matchedContacts.length > 0) {
            setMatchedExistingCustomers(matchedContacts);
          } else {
            formikHelpers.setErrors(formatFormikError(e));
          }
        }
      }}
      schema={schema}
    >
      {formik => (
        <Form className="mt-4 space-y-4">
          {getEnv() !== 'production' && (
            <Button
              color="blue"
              onClick={() => {
                formik.setValues({
                  firstName: 'Test',
                  lastName: 'User',
                  email: 'test@sidelineswap.com',
                  phone: 1234567890,
                });
              }}
            >
              Prefill with test data
            </Button>
          )}

          <FormikField name="firstName" label="First Name" />
          <FormikField name="lastName" label="Last Name" />
          <FormikField name="email" label="Email" type="email" />
          <FormikPhoneNumberField name="phone" label="Phone Number" />

          {matchedExistingCustomers && matchedExistingCustomers.length > 0 && (
            <div className="mt-8">
              <div className="mb-2 text-xl font-semibold">
                {matchedExistingCustomers.length}
                {pluralize(
                  matchedExistingCustomers.length,
                  ' customer has ',
                  ' customers have ',
                )}
                similar contact information
              </div>

              <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
                {matchedExistingCustomers.map(customer => (
                  <button
                    key={customer.id}
                    type="button"
                    className="rounded border px-4 py-2.5 text-left"
                    onClick={() => handleClickExistingCustomer(customer)}
                  >
                    <span className="block font-semibold">
                      {customer.first_name} {customer.last_name}
                    </span>
                    <span className="block">{customer.email}</span>
                    <span className="block">
                      {formatPhoneNumber(customer.phone)}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          )}

          {children}
        </Form>
      )}
    </FormikZodSchema>
  );
};

export default PartnerContactForm;
