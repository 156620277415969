import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from '../lib/auth/AuthContext';
import EventHomepage from './tradeIn/event';
import CreateCustomerPage from './tradeIn/customer/create';
import SearchCustomerPage from './tradeIn/customer/search';
import CreateItemPage from './tradeIn/item/createItemPage';
import EditItemPage from './tradeIn/item/editItemPage';
import SearchItemPage from './tradeIn/item/searchItemPage';
import QuoteSummaryPage from './tradeIn/quote/quoteSummary';
import Redemption from './tradeIn/redemption/redemption';
import LoginPage from './login';
import qsStringify from '../utils/qsStringify';
import ErrorBoundary from '../components/ErrorBoundary';
import TradeInLayout from '../components/Layout/Layouts/TradeInLayout';
import EditCustomerPage from './tradeIn/customer/edit';
import SearchQuoteByIdPage from './tradeIn/quote/search';
import SettingsPage from './tradeIn/settings/settingsPage';
import EventSelectionPage from './tradeIn/event/select';
import CreateEventPage from './tradeIn/event/create';
import { useLocation } from 'react-router';
import Page404 from './404';
import PrintTestPage from './print';
import EventEditPage from './tradeIn/event/edit';
import EventStatsPage from './tradeIn/event/stats';
import WarehouseLayout from '../components/Layout/Layouts/WarehouseLayout';
import WarehouseSelectionPage from './warehouse/warehouseSelectionPage';
import WarehouseHomepage from './warehouse/warehouseHomepage';
import WarehouseSwapsPage from './warehouse/warehouseSwapsPage';
import WarehouseSwapsPrintBulkPage from './warehouse/warehouseSwapsPrintBulk';
import WarehouseReceivePage from './warehouse/spawn/warehouseReceive';
import WarehouseLocationPage from './warehouse/spawn/warehouseLocation';
import UserFacingCheckInPage from './tradeIn/selfCheckIn/checkIn';
import SelfContactFlowPage from './tradeIn/selfCheckIn/selfContactFlowPage';
import WarehousePicklistHomepage from './warehouse/picklists/warehousePicklistHomepage';
import WarehouseUnshippedOrdersPage from './warehouse/picklists/warehouseUnshippedOrders';
import WarehouseViewPicklistPage from './warehouse/picklists/warehouseViewPicklist';
import WarehousePicklistScan from './warehouse/picklists/warehousePicklistScan';
import LoggedInLandingHomepage from './home';
import SimpleLayout from '../components/Layout/Layouts/SimpleLayout';
import WarehousePicklistViewItem from './warehouse/picklists/warehousePicklistViewItem';
import PaymentSettingsPage from './tradeIn/settings/paymentSettingsPage';
import BonusCodesManagementPage from './tradeIn/settings/bonusCodesManagementPage';
import BonusCodeEditPage from './tradeIn/settings/bonusCodeEditPage';
import NewBonusCodePage from './tradeIn/settings/newBonusCodePage';
import PartnerLayout from '../components/Layout/Layouts/PartnerLayout';
import PartnerQuoteSummaryPage from './partnerInStore/quote/partnerQuoteSummary';
import PartnerAdminManageUsersPage from './partnerInStore/admin/partnerAdminManageUsers';
import PartnerLoginPage from './partnerLogin';
import PartnerAdminNewUserPage from './partnerInStore/admin/partnerAdminNewUser';
import PartnerAdminEditUserPage from './partnerInStore/admin/partnerAdminEditUser';
import PartnerDashboardPage from './partnerInStore/dashboard/partnerDashboard';
import PartnerTagItemsPage from './partnerInStore/dashboard/partnerTagItems';
import PartnerDashboardFilterItemsPage from './partnerInStore/dashboard/partnerDashboardItems';
import PartnerDashboardCreateTransferPage from './partnerInStore/dashboard/partnerDashboardTransfersNew';
import PartnerDashboardTransferById from './partnerInStore/dashboard/partnerDashboardTransferById';
import PartnerRedirectPage from './partnerRedirect';
import PartnerQuoteAddItemPage from './partnerInStore/quote/quoteAddItem';
import PartnerDashboardTransferByIdSuccessCreated from './partnerInStore/dashboard/partnerDashboardTransferByIdSuccessCreated';
import PartnerQuoteCustomerPage from './partnerInStore/quote/partnerQuoteCustomer';
import PartnerQuotePaymentPage from './partnerInStore/quote/partnerQuotePayment';
import PartnerDashboardQuotesPage from './partnerInStore/dashboard/partnerDashboardQuotes';
import TrainingManualPage from './partnerInStore/trainingManualPage';
import PartnerDashboardTransferPrintablePackingList from './partnerInStore/dashboard/partnerDashboardTransferPrintablePackingSlip';
import WarehouseAuditMainPage from './warehouse/audit/warehouseAuditMainPage';
import WarehouseAuditScanPage from './warehouse/audit/warehouseAuditScanPage';
import WarehouseAuditItemConditionPage from './warehouse/audit/warehouseAuditItemConditionPage';
import WarehouseAuditItemDetailsPage from './warehouse/audit/warehouseAuditItemDetailsPage';
import { getErrorMessage } from '../api/ApiError';
import AuthSquareLandingPage from './authSquareLandingPage';
import ResetPasswordPage from './resetPassword/resetPassword';
import ResetPasswordSentPage from './resetPassword/resetPasswordSent';
import PartnerDashboardTransfers from './partnerInStore/dashboard/partnerDashboardTransfers';
import WarehouseReprintLabelsPage from './warehouse/warehouseReprintLabelsPage';
import WarehouseAuditTransferPage from './warehouse/audit/transfers/warehouseAuditTransferPage';

interface BaseQuery {
  contextMessage?: string;
  contextMessageType?: 'success' | 'error' | 'warning';
}

export interface EditCustomerPageQuery extends BaseQuery {
  customerId: string;
}

export interface EventCustomerQuoteQuery extends BaseQuery {
  customerId: string;
  eventId: string;
  quoteId: string;
}

const RouterComponent: React.FC = () => (
  <ErrorBoundary
    fallback={
      <TradeInLayout>
        <div className="text-center text-red-700">
          <div className="mb-4 text-xl font-semibold">
            Something went wrong...
          </div>
        </div>
      </TradeInLayout>
    }
  >
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/partner-login" element={<PartnerLoginPage />} />

      <Route element={<SimpleLayout />}>
        <Route
          path="/"
          element={
            <AuthRequired>
              <LoggedInLandingHomepage />
            </AuthRequired>
          }
        />

        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route
          path="/reset-password/sent"
          element={<ResetPasswordSentPage />}
        />

        <Route path="/auth/square" element={<AuthSquareLandingPage />} />
      </Route>

      <Route element={<TradeInLayout />}>
        <Route
          path="/event"
          element={
            <AuthRequired>
              <EventSelectionPage />
            </AuthRequired>
          }
        />

        <Route
          path="/event/create"
          element={
            <AuthRequired>
              <CreateEventPage />
            </AuthRequired>
          }
        />

        <Route
          path="/event/:eventId"
          element={
            <AuthRequired>
              <EventHomepage />
            </AuthRequired>
          }
        />

        <Route
          path="/event/:eventId/edit"
          element={
            <AuthRequired>
              <EventEditPage />
            </AuthRequired>
          }
        />

        <Route
          path="/event/:eventId/stats"
          element={
            <AuthRequired>
              <EventStatsPage />
            </AuthRequired>
          }
        />

        <Route
          path="/event/:eventId/customer/create"
          element={
            <AuthRequired contextMessage="Login to create a customer">
              <CreateCustomerPage />
            </AuthRequired>
          }
        />
        <Route
          path="/event/:eventId/customer/search"
          element={
            <AuthRequired>
              <SearchCustomerPage />
            </AuthRequired>
          }
        />

        <Route
          path="/event/:eventId/customer/:customerId/edit"
          element={
            <AuthRequired contextMessage="Edit Customer">
              <EditCustomerPage />
            </AuthRequired>
          }
        />

        <Route
          path="/event/:eventId/customer/:customerId/quote/:quoteId/listing/search"
          element={
            <AuthRequired contextMessage="Search Item">
              <SearchItemPage />
            </AuthRequired>
          }
        />
        <Route
          path="/event/:eventId/customer/:customerId/quote/:quoteId/listing/create"
          element={
            <AuthRequired>
              <CreateItemPage />
            </AuthRequired>
          }
        />
        <Route
          path="/event/:eventId/customer/:customerId/quote/:quoteId/listing/edit"
          element={
            <AuthRequired>
              <EditItemPage />
            </AuthRequired>
          }
        />

        <Route
          path="/event/:eventId/customer/:customerId/quote/:quoteId/summary"
          element={
            <AuthRequired>
              <QuoteSummaryPage />
            </AuthRequired>
          }
        />

        <Route
          path="/event/:eventId/quote/search"
          element={
            <AuthRequired>
              <SearchQuoteByIdPage />
            </AuthRequired>
          }
        />

        <Route
          path="/settings"
          element={
            <AuthRequired>
              <SettingsPage />
            </AuthRequired>
          }
        />

        <Route
          path="/settings/payment"
          element={
            <AuthRequired>
              <PaymentSettingsPage />
            </AuthRequired>
          }
        />

        <Route
          path="/settings/bonus-codes"
          element={
            <AuthRequired>
              <BonusCodesManagementPage />
            </AuthRequired>
          }
        />

        <Route
          path="/settings/bonus-codes/:id"
          element={
            <AuthRequired>
              <BonusCodeEditPage />
            </AuthRequired>
          }
        />

        <Route
          path="/settings/bonus-codes/new"
          element={
            <AuthRequired>
              <NewBonusCodePage />
            </AuthRequired>
          }
        />

        <Route
          path="*"
          element={
            <AuthRequired>
              <Page404 />
            </AuthRequired>
          }
        />

        <Route
          path="/event/*"
          element={
            <AuthRequired>
              <Page404 />
            </AuthRequired>
          }
        />
      </Route>

      <Route element={<WarehouseLayout />}>
        <Route
          path="/warehouse"
          element={
            <AuthRequired>
              <WarehouseSelectionPage />
            </AuthRequired>
          }
        />

        <Route
          path="/warehouse/:warehouseId"
          element={
            <AuthRequired>
              <WarehouseHomepage />
            </AuthRequired>
          }
        />

        <Route
          path="/warehouse/reprint"
          element={
            <AuthRequired>
              <WarehouseReprintLabelsPage />
            </AuthRequired>
          }
        />

        <Route
          path="/warehouse/:warehouseId/swaps"
          element={
            <AuthRequired>
              <WarehouseSwapsPage />
            </AuthRequired>
          }
        />

        <Route
          path="/warehouse/:warehouseId/receive"
          element={
            <AuthRequired>
              <WarehouseReceivePage />
            </AuthRequired>
          }
        />

        <Route
          path="/warehouse/:warehouseId/location"
          element={<WarehouseLocationPage />}
        />

        <Route
          path="/warehouse/:warehouseId/pick"
          element={
            <AuthRequired>
              <WarehousePicklistHomepage />
            </AuthRequired>
          }
        />
        <Route
          path="/warehouse/:warehouseId/unshipped_orders"
          element={
            <AuthRequired>
              <WarehouseUnshippedOrdersPage />
            </AuthRequired>
          }
        />

        <Route
          path="/warehouse/:warehouseId/picklist/:picklistId"
          element={
            <AuthRequired>
              <WarehouseViewPicklistPage />
            </AuthRequired>
          }
        />
        <Route
          path="/warehouse/:warehouseId/picklist/:picklistId/item/:itemId"
          element={
            <AuthRequired>
              <WarehousePicklistViewItem />
            </AuthRequired>
          }
        />
        <Route
          path="/warehouse/:warehouseId/picklist/:picklistId/scan"
          element={
            <AuthRequired>
              <WarehousePicklistScan />
            </AuthRequired>
          }
        />

        <Route path="/warehouse/*" element={<Page404 />} />
      </Route>

      <Route>
        <Route
          path="/warehouse/:warehouseId/audit"
          element={
            <AuthRequired>
              <WarehouseAuditMainPage />
            </AuthRequired>
          }
        />

        <Route
          path="/warehouse/:warehouseId/audit/scan"
          element={
            <AuthRequired>
              <WarehouseAuditScanPage />
            </AuthRequired>
          }
        />

        <Route
          path="/warehouse/:warehouseId/audit/:itemId/condition"
          element={
            <AuthRequired>
              <WarehouseAuditItemConditionPage />
            </AuthRequired>
          }
        />

        <Route
          path="/warehouse/:warehouseId/audit/:itemId/details"
          element={
            <AuthRequired>
              <WarehouseAuditItemDetailsPage />
            </AuthRequired>
          }
        />

        <Route
          path="/warehouse/:warehouseId/audit/transfers/:transferId"
          element={
            <AuthRequired>
              <WarehouseAuditTransferPage />
            </AuthRequired>
          }
        />
      </Route>

      <Route>
        <Route
          path="/partner/quote/:quoteId/add"
          element={
            <AuthRequired>
              <PartnerQuoteAddItemPage />
            </AuthRequired>
          }
        />

        <Route
          path="/partner/quote/:quoteId/summary"
          element={
            <AuthRequired>
              <PartnerQuoteSummaryPage />
            </AuthRequired>
          }
        />

        <Route
          path="/partner/quote/new/customer"
          element={
            <AuthRequired>
              <PartnerQuoteCustomerPage />
            </AuthRequired>
          }
        />

        <Route
          path="/partner/quote/:quoteId/customer"
          element={
            <AuthRequired>
              <PartnerQuoteCustomerPage />
            </AuthRequired>
          }
        />

        <Route
          path="/partner/quote/:quoteId/payment"
          element={
            <AuthRequired>
              <PartnerQuotePaymentPage />
            </AuthRequired>
          }
        />
      </Route>

      <Route path="/partner" element={<PartnerLayout />}>
        <Route
          path="/partner"
          element={
            <AuthRequired>
              <Navigate to="/partner/dashboard" replace />
            </AuthRequired>
          }
        />

        <Route
          path="/partner/manage-users"
          element={
            <AuthRequired>
              <PartnerAdminManageUsersPage />
            </AuthRequired>
          }
        />

        <Route
          path="/partner/manage-users/new"
          element={
            <AuthRequired>
              <PartnerAdminNewUserPage />
            </AuthRequired>
          }
        />

        <Route
          path="/partner/manage-users/:userId"
          element={
            <AuthRequired>
              <PartnerAdminEditUserPage />
            </AuthRequired>
          }
        />

        <Route
          path="/partner/training-manual"
          element={
            <AuthRequired>
              <TrainingManualPage />
            </AuthRequired>
          }
        />

        <Route
          path="/partner/dashboard"
          element={
            <AuthRequired>
              <PartnerDashboardPage />
            </AuthRequired>
          }
        />

        <Route
          path="/partner/dashboard/tag"
          element={
            <AuthRequired>
              <PartnerTagItemsPage />
            </AuthRequired>
          }
        />

        <Route
          path="/partner/dashboard/quotes"
          element={
            <AuthRequired>
              <PartnerDashboardQuotesPage />
            </AuthRequired>
          }
        />

        <Route
          path="/partner/dashboard/items"
          element={
            <AuthRequired>
              <PartnerDashboardFilterItemsPage />
            </AuthRequired>
          }
        />

        <Route
          path="/partner/dashboard/transfers"
          element={
            <AuthRequired>
              <PartnerDashboardTransfers />
            </AuthRequired>
          }
        />

        <Route
          path="/partner/dashboard/transfers/new"
          element={
            <AuthRequired>
              <PartnerDashboardCreateTransferPage />
            </AuthRequired>
          }
        />

        <Route
          path="/partner/dashboard/transfers/:transferId"
          element={
            <AuthRequired>
              <PartnerDashboardTransferById />
            </AuthRequired>
          }
        />

        <Route
          path="/partner/dashboard/transfers/:transferId/created"
          element={
            <AuthRequired>
              <PartnerDashboardTransferByIdSuccessCreated />
            </AuthRequired>
          }
        />

        <Route
          path="/partner/dashboard/transfers/:transferId/print"
          element={
            <AuthRequired>
              <PartnerDashboardTransferPrintablePackingList />
            </AuthRequired>
          }
        />

        <Route path="/partner/*" element={<Page404 />} />
      </Route>

      <Route
        path="/warehouse/:warehouseId/print_bulk"
        element={
          <AuthRequired>
            <WarehouseSwapsPrintBulkPage />
          </AuthRequired>
        }
      />

      <Route
        path="/print"
        element={
          <AuthRequired>
            <PrintTestPage />
          </AuthRequired>
        }
      />

      <Route path="/redeem/:redemptionCode" element={<Redemption />} />

      <Route path="/check-in" element={<UserFacingCheckInPage />} />
      <Route path="/check-in/:eventId" element={<SelfContactFlowPage />} />

      <Route path="/sp-redirect" element={<PartnerRedirectPage />} />

      <Route path="*" element={<Page404 />} />
    </Routes>
  </ErrorBoundary>
);

interface PrivateRouteProps extends BaseQuery {}

const AuthRequired: React.FC<React.PropsWithChildren<PrivateRouteProps>> = ({
  children,
  contextMessage,
  contextMessageType,
}: any) => {
  const auth = useAuth();
  const location = useLocation();

  if (auth.loggedIn) {
    return children;
  }

  const isPartner = window.location.origin.includes('partners');

  return (
    <Navigate
      replace
      to={{
        pathname: isPartner ? '/partner-login' : '/login',
        search: qsStringify({
          contextMessage,
          contextMessageType,
          from: encodeURIComponent(
            isPartner
              ? '/partner/dashboard'
              : location.pathname + location.search,
          ),
        }),
      }}
    />
  );
};

export default RouterComponent;
