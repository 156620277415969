import React, { CSSProperties, Dispatch, SetStateAction } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { XMarkIcon } from '@heroicons/react/24/outline';

export interface ModalProps {
  open: boolean;
  onClose: () => void;

  className?: any;
  style?: CSSProperties;
  title?: React.ReactNode;
  description?: React.ReactNode;
  initialFocus?: React.MutableRefObject<any>;
}

const SimpleModal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  children,
  className,
  initialFocus,
  onClose,
  open,
  title,
  description,
  style,
}) => (
  <Transition.Root show={open} as={React.Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-40 overflow-y-auto"
      onClose={onClose}
      initialFocus={initialFocus}
    >
      <div
        className={classNames(
          'flex min-h-screen items-start justify-center px-4 py-20 text-center',
          // If we want to center in the panel
          // !mobile && 'pl-80',
        )}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-true-black bg-opacity-60 transition-opacity" />
        </Transition.Child>

        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300 origin-top"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200 origin-top"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          {/* Body */}
          <div
            className={classNames(
              'inline-block transform rounded-lg bg-white pt-4 text-left shadow-xl transition-all sm:my-8',
              className,
            )}
            style={style}
          >
            <div className="flex w-full items-start space-x-4 px-6">
              <div className="flex-1">
                {!!title && (
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-semibold text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                )}

                {!!description && (
                  <Dialog.Description className="mt-2 text-sm text-gray-500">
                    {description}
                  </Dialog.Description>
                )}
              </div>

              {/* Close button */}
              <button
                type="button"
                className="whitespace-nowrap rounded-md text-slate-green-500 hover:text-turf-green-500 focus:ring"
                onClick={onClose}
              >
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            <div className="mb-6 mt-4 px-6">{children}</div>
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);

export default SimpleModal;
