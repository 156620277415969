import React from 'react';
import NumberFormat, { NumberFormatPropsBase } from 'react-number-format';
import Input, { InputProps } from './Input';

export type NumberInputProps = Omit<
  InputProps,
  'defaultValue' | 'value' | 'type'
> &
  NumberFormatPropsBase;

const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  ({ name, ...props }, ref) => (
    <NumberFormat
      name={name}
      customInput={Input}
      inputMode="numeric"
      getInputRef={ref}
      {...props}
    />
  ),
);

export default NumberInput;
