import { getBaseUrl } from './axiosInitialization';
import axios from 'axios';
import { ApiResponse } from './intf/generic';
import { IApiError } from './ApiError';

export interface ReceiveError extends Omit<IApiError, 'errors'> {
  errors: {
    data: ReceiveResponse | null;
  };
}

export interface ReceiveResponse {
  id: string;
  item_id: string;
  location?: string;
  location_id?: string;
  status: string;
  status_detail: { message: string }[];
  received_at: string; // Date
  last_scanned_at: string; // Date
  events?: EventHistory[];
}

export interface EventHistory {
  event: string;
  location?: string;
  status: string;
  status_detail?: { message: string }[];
  received_at?: string; // Date
  last_scanned_at: string; // Date
}

export const postWarehouseScanReceive = async (
  warehouseId: string,
  action: string,
  scanData: string,
) => {
  const { data } = await axios.post<ApiResponse<ReceiveResponse>>(
    '/warehouse/v1/item/receive',
    {
      action,
      warehouse_id: warehouseId,
      scan_data: scanData,
    },
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data) {
    return data.data;
  }
};

export const postWarehouseScanUpdateLocation = async (
  warehouseId: string,
  location: string,
  scanData: string,
) => {
  const { data } = await axios.put<ApiResponse<ReceiveResponse>>(
    '/warehouse/v1/item/location',
    {
      location,
      warehouse_id: warehouseId,
      scan_data: scanData,
    },
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data) {
    return data.data;
  }

  throw new Error('Could not update location on item');
};
