import React from 'react';
import { TradeInModelVersion } from '../../api/intf/item';
import classNames from 'classnames';

interface Props {
  modelVersion: TradeInModelVersion;
  onSelect: (modelVersion: TradeInModelVersion) => void;
  selected?: boolean;
}

const ModelVersionTile: React.FC<Props> = ({
  modelVersion,
  onSelect,
  selected,
}) => {
  return (
    <button
      key={modelVersion.id}
      type="button"
      className={classNames(
        'rounded border-2 border-transparent px-3 py-2 hover:border-grey-500',
        selected ? 'border-green-500' : 'border-transparent',
      )}
      onClick={() => onSelect(modelVersion)}
    >
      <img src={modelVersion.primary_image?.small_url} className="mb-4" />
      <div className="font-semibold">{modelVersion.name}</div>
    </button>
  );
};

export default ModelVersionTile;
