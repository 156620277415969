export default function formatPhoneNumber(phoneNumber?: string): string {
  if (!phoneNumber) {
    return '';
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6,
  )}-${phoneNumber.slice(6, 10)}`;
}
