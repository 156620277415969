import React, { useState } from 'react';
import LinkOrSpan from './LinkOrSpan';
import { LinkProps, useNavigate } from 'react-router-dom';
import ConfirmationModal from './Modal/ConfirmationModal';

type Props = (LinkProps | React.HTMLAttributes<HTMLSpanElement>) & {
  // Turns off the warning modal, but keeps the link enabled
  disabled?: boolean;

  // Change the copy in the modal
  warningText?: string;
};

const WarningModalLink: React.FC<Props> = ({
  children,
  disabled,
  ...props
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const usingModal = 'to' in props && !!props.to && !disabled;

  return (
    <>
      <LinkOrSpan
        {...props}
        onClick={(e: any) => {
          if (usingModal) {
            e.preventDefault();
            setOpen(true);
          } else {
            props.onClick?.(e);
          }
        }}
      >
        {children}
      </LinkOrSpan>

      {usingModal && (
        <ConfirmationModal
          title="Warning"
          description={
            props.warningText ||
            'Are you sure you want to leave? All changes will be lost.'
          }
          okProps={{
            color: 'danger',
          }}
          okText="Leave"
          handleOK={() => navigate(props.to)}
          open={open}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default WarningModalLink;
