import React, { useEffect } from 'react';
import { useAuth } from '../lib/auth/AuthContext';
import useAuthedPartnerUser from '../hooks/query/useAuthedPartnerUser';
import {
  identifyAmplitude,
  unidentifyAmplitude,
} from '../services/analytics/amplitude';

interface Props {}

const AnalyticsInitializer: React.FC<Props> = () => {
  const auth = useAuth();
  const { data: user } = useAuthedPartnerUser();

  useEffect(() => {
    if (auth.loggedIn) {
      if (user && auth.decodedJwt) {
        identifyAmplitude(auth.decodedJwt, user);
      }
    } else {
      unidentifyAmplitude();
    }
  }, [auth.loggedIn, user?.uuid]);

  return null;
};

export default AnalyticsInitializer;
