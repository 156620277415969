import * as Sentry from '@sentry/browser';
import getEnv from '../../utils/getEnv';

export default function initializeSentry() {
  if (getEnv() === 'development') {
    // Don't initialize Sentry in development
    return;
  }

  Sentry.init({
    environment: getEnv(),
    dsn: getEnv('REACT_APP_SENTRY_DSN'),
    tracePropagationTargets: ['localhost', getEnv('REACT_APP_API_URL')],
    tracesSampleRate: 1.0,
  });
}
