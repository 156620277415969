import usePartnerUserQuery from './usePartnerUserQuery';

export default function usePartnerUserName(
  id?: string | null,
  differentOrgId?: string,
) {
  const { data: user, isLoading } = usePartnerUserQuery(id, differentOrgId);

  if (!id) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  if (!user || !user.first_name) {
    return 'Unknown';
  }

  return `${user.first_name} ${user.last_name || ''}`;
}
