import React from 'react';
import PageLayout from '../../../components/Layout/PageLayout';
import PageHeader from '../../../components/Layout/PageHeader';
import PageSubheader from '../../../components/Layout/PageSubheader';
import PayoutMethodCheckbox from '../../../components/Settings/PayoutMethodCheckbox';
import { useQuery } from 'react-query';
import { fetchAllPayoutMethods } from '../../../api/paymentMethods';
import SettingsBreadcrumbLink from '../../../components/Settings/SettingsBreadcrumbLink';

interface Props {}

const PaymentSettingsPage: React.FC<Props> = () => {
  const { data: payoutMethods } = useQuery(
    ['payout-methods', 'all'],
    fetchAllPayoutMethods,
  );

  return (
    <PageLayout
      header={
        <>
          <SettingsBreadcrumbLink />
          <PageHeader>Default Payment Options</PageHeader>
          <PageSubheader>
            Select payout methods you want to offer customers. If you're trying
            to change this event's payout methods, go to the event edit screen.
          </PageSubheader>
        </>
      }
    >
      <div className="space-y-2">
        {payoutMethods?.map(payoutMethod => (
          <PayoutMethodCheckbox
            key={payoutMethod.id}
            payoutMethod={payoutMethod}
          />
        ))}
      </div>
    </PageLayout>
  );
};

export default PaymentSettingsPage;
