import React from 'react';
import BaseLabel from './BaseLabel';
import ItemListingQrCode from './ItemListingQrCode';

interface Props {
  listing: { legacy_id?: number | string; name?: string };
}

const ItemListingQrLabel: React.FC<Props> = ({ listing }) => {
  return (
    <BaseLabel>
      {/* Item info column */}
      <div
        className="flex-1 overflow-hidden"
        style={{
          width: '1.425in',
          marginRight: '0.125in',
        }}
      >
        {/* ID and price row */}
        <div className="flex justify-between">
          <div
            className="print-label-title font-bold"
            style={{
              marginRight: '0.125in',
            }}
          >
            {listing.legacy_id || <i>No ID</i>}
          </div>
          <div className="print-label-title font-bold" />
        </div>

        <div className="break-all text-2xs leading-tight line-clamp-4">
          {listing.name ? listing.name : <i>No name</i>}
        </div>
      </div>

      {/*  QR Code*/}
      <ItemListingQrCode listing={listing} />
    </BaseLabel>
  );
};

export default ItemListingQrLabel;
