import React, { useState } from 'react';
import WarehouseBreadcrumbLink from '../../../components/Warehouse/WarehouseBreadcrumbLink';
import PageHeader from '../../../components/Layout/PageHeader';
import PageSubheader from '../../../components/Layout/PageSubheader';
import useCurrentWarehouseQuery from '../../../hooks/warehouse/useCurrentWarehouseQuery';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import PageLayout from '../../../components/Layout/PageLayout';
import useCurrentPicklistQuery from '../../../hooks/warehouse/useCurrentPicklistQuery';
import { Picklist } from '../../../api/intf/picklist';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import useThrottledPicklistScanning from '../../../hooks/warehouse/useThrottledPicklistScanning';
import { ApiError } from '../../../api/ApiError';
import getFirstPickableItemIndex from '../../../utils/getFirstPickableItem';
import useCurrentPicklistId from '../../../hooks/warehouse/useCurrentPicklistId';
import { putPickItem, togglePickStatus } from '../../../api/picklists';
import { Button } from '../../../components/Form/Button';
import PicklistItemDetails from '../../../components/Warehouse/Picklists/PicklistItemDetails';
import Scanner from '../../../components/Scanner/Scanner';

interface Props {}

const WarehousePicklistScan: React.FC<Props> = () => {
  const { data: warehouse } = useCurrentWarehouseQuery();
  const warehouseId = useCurrentWarehouseId()!;
  const picklistId = useCurrentPicklistId()!;
  const { itemId } = useParams<{ itemId?: string }>();

  const navigation = useNavigate();
  const queryClient = useQueryClient();

  const [error, setError] = useState<null | string>(null);
  const [expectedItemIndex, setExpectedItemIndex] = useState(() => {
    const found = queryClient.getQueryData<Picklist>([
      'picklist',
      warehouseId,
      picklistId,
    ]);
    return getFirstPickableItemIndex(found?.items);
  });

  const { data: picklist, isLoading, refetch } = useCurrentPicklistQuery();
  const { mutate: pickToggle } = useMutation(togglePickStatus, {
    onSuccess: () => refetch(),
  });

  const expectedItem =
    expectedItemIndex != null ? picklist?.items?.[expectedItemIndex] : null;

  // useEffect(() => {
  //   if (expectedItemIndex != null && picklist) {
  //     navigation(
  //       `/warehouse/${warehouseId}/picklist/${picklistId}/item/${expectedItemIndex}`,
  //     );
  //   }
  // }, [expectedItemIndex, picklist?.items.length]);

  const { onScanData } = useThrottledPicklistScanning(async (data, history) => {
    setError(null);

    const split = data.split('/');
    const itemId = split[split.length - 1];

    if (itemId != String(expectedItem?.legacy_item_id)) {
      setError(`The item you scanned (${error}) is not the expected item`);
      return;
    }

    try {
      await putPickItem(data);
      refetch();
      history.status = 'success';
    } catch (e) {
      refetch();
      history.error = e as ApiError;
      history.status = 'error';
    }
  });

  function handleSkip() {
    setError(null);

    if (expectedItemIndex == null) {
      return setExpectedItemIndex(
        getFirstPickableItemIndex(picklist?.items) || 0,
      );
    }

    if (expectedItemIndex + 1 >= picklist?.items.length!) {
      setExpectedItemIndex(getFirstPickableItemIndex(picklist?.items) || 0);
    } else {
      setExpectedItemIndex(expectedItemIndex + 1);
    }
  }

  function returnToList() {
    navigation(`/warehouse/${warehouseId}/picklist/${picklistId}`);
  }

  return (
    <PageLayout
      header={
        <>
          <WarehouseBreadcrumbLink to="picklistById" />
          <PageHeader>Warehouse - {warehouse?.facility}</PageHeader>
          <PageSubheader>Pick List Item</PageSubheader>
        </>
      }
    >
      <Scanner onScan={onScanData} mode="qr" />

      <div className="mt-4">
        {expectedItem ? (
          <PicklistItemDetails item={expectedItem} />
        ) : (
          <div className="text-center text-2xl font-semibold text-slate-green-500">
            No more pickable items
          </div>
        )}

        <div className="mt-4">
          <Button fluid size="large" onClick={handleSkip} className="mb-4">
            Next
          </Button>

          <Button
            fluid
            size="large"
            disabled={!expectedItem}
            onClick={() => {
              if (expectedItem) {
                pickToggle(expectedItem);
              }
            }}
            className="mb-4"
            variant="outlined"
          >
            Mark as{' '}
            {expectedItem?.warehouse_order_state === 'PICKED'
              ? 'Unpicked'
              : 'Picked'}
          </Button>

          <Button fluid size="large" onClick={returnToList} variant="outlined">
            Return to List
          </Button>
        </div>
      </div>
    </PageLayout>
  );
};

export default WarehousePicklistScan;
