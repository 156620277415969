import React from 'react';
import FormikField from '../Form/FormikField';
import FormikPhoneNumberField from '../Form/FormikPhoneNumberField';

const CustomerFormFields: React.FC = () => (
  <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
    <FormikField autoFocus name="firstName" label="First Name" />
    <FormikField name="lastName" label="Last Name" />

    <FormikField
      name="email"
      type="email"
      label="Email"
      placeholder="test@example.com"
    />

    <FormikPhoneNumberField name="phoneNumber" label="Phone Number" />
  </div>
);

export default CustomerFormFields;
