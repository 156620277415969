import React, { Dispatch, useContext, useReducer } from 'react';
import { getCookie } from '../KnownCookies';
import decodeJwt from '../../utils/decodeJwt';
import { DecodedJwt } from '../../api/intf/auth';

// TODO: Move to generic type file?
type Action<T extends string, P = undefined> = {
  type: T;
  payload: P;
};

interface AuthState {
  loggedIn?: boolean;
  accessToken?: string;
  refreshToken?: string;
  organizationName?: string;
  decodedJwt?: DecodedJwt | null;
}

type AuthAction =
  | Action<'logged in', { accessToken: string; refreshToken?: string }>
  | Action<'logged out'>;

function authReducer(state: AuthState, action: AuthAction): AuthState {
  if (action.type === 'logged in') {
    const decodedJwt = decodeJwt(action.payload.accessToken);

    return {
      ...state,
      decodedJwt,
      loggedIn: true,
      accessToken: action.payload.accessToken ?? state.accessToken,
      refreshToken: action.payload.refreshToken ?? state.refreshToken,
      organizationName: decodedJwt?.org_name,
    };
  }

  if (action.type === 'logged out') {
    return {
      loggedIn: false,
    };
  }

  return state;
}

interface IAuthContext {
  state: AuthState;
  dispatch: Dispatch<AuthAction>;
}

export const AuthContext = React.createContext<IAuthContext>({
  state: {},
  dispatch: () => {
    throw new Error(
      'Race condition. Auth Context dispatch called either outside a provider or too early',
    );
  },
});

export const AuthProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(authReducer, {}, initialState => {
    const accessToken = getCookie('access_token');

    if (accessToken) {
      return authReducer(initialState, {
        type: 'logged in',
        payload: {
          accessToken,
          refreshToken: getCookie('refresh_token'),
        },
      });
    }

    return initialState;
  });

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (context == null) {
    throw new Error('useAuth used outside an AuthContext.Provider');
  }

  return context.state;
}

export function useAuthDispatch() {
  const context = useContext(AuthContext);
  if (context == null) {
    throw new Error('useAuthDispatch used outside an AuthContext.Provider');
  }

  return context.dispatch;
}
