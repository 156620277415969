import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { formatFormikError } from '../api/ApiError';
import { postPartnerAuthLogin } from '../api/auth';
import { Button } from '../components/Form/Button';
import FormikErrorMessage from '../components/Form/FormikErrorMessage';
import FormikField from '../components/Form/FormikField';
import Message from '../components/Form/Message';
import useRouteQuery from '../hooks/useRouteQuery';
import { useAuth, useAuthDispatch } from '../lib/auth/AuthContext';
import LoginLayout from '../components/Layout/Layouts/LoginLayout';
import DSGAuthButton from '../lib/auth/DSGAuthButton';
import FormikSubmitButton from '../components/Form/FormikSubmitButton';
import FormikPasswordField from '../components/Form/FormikPasswordField';
import ContactSupportLink from '../components/Links/ContactSupportLink';

interface LoginPageQuery {
  contextMessage?: string;
  contextMessageType?: string;
  message?: string;
  messageType?: string;
  from?: string;
}

interface LoginFormValues {
  email: string;
  password: string;
}

const schema: yup.SchemaOf<LoginFormValues> = yup.object({
  email: yup.string().email('Must be an email').required('Required'),
  password: yup.string().required('Required'),
});

interface Props {}

const PartnerLoginPage: React.FC<Props> = () => {
  const [showingEmailPasswordForm, setShowingEmailPasswordForm] =
    useState(false);
  const auth = useAuth();
  const authDispatch = useAuthDispatch();
  const navigate = useNavigate();
  const query = useRouteQuery<LoginPageQuery>();

  function goToDestination() {
    navigate(query.from || '/partner');
  }

  useEffect(() => {
    if (auth.loggedIn) {
      goToDestination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.from, auth.loggedIn]);

  const urlMessage = (query.contextMessage || query.message) && (
    <Message
      error={
        query.contextMessageType === 'error' || query.messageType === 'error'
      }
      success={
        query.contextMessageType === 'success' ||
        query.messageType === 'success'
      }
      warning={
        !(query.contextMessageType || query.messageType) ||
        query.contextMessageType === 'warning' ||
        query.messageType === 'warning'
      }
    >
      {query.contextMessage || query.message}
    </Message>
  );

  const contactSupport = (
    <div className="mt-4 text-center">
      <ContactSupportLink className="text-sm underline">
        Contact Support
      </ContactSupportLink>
    </div>
  );

  if (!showingEmailPasswordForm) {
    return (
      <LoginLayout title="Log in to SidelineSwap for Partners">
        <div className="space-y-4">
          <DSGAuthButton />

          <div className="text-center text-sm font-semibold text-slate-green-500">
            OR
          </div>

          <Button
            fluid
            className="h-14"
            variant="outlined"
            onClick={() => setShowingEmailPasswordForm(true)}
          >
            Login with Email + Password
          </Button>
        </div>

        {contactSupport}
      </LoginLayout>
    );
  }

  return (
    <LoginLayout title="Log in to SidelineSwap for Partners">
      {urlMessage}
      <Formik
        initialValues={
          {
            email: '',
            password: '',
          } as LoginFormValues
        }
        onSubmit={async (values, { setErrors }) => {
          try {
            const { access_token, refresh_token } = await postPartnerAuthLogin(
              values.email,
              values.password,
            );
            authDispatch({
              type: 'logged in',
              payload: {
                accessToken: access_token,
                refreshToken: refresh_token,
              },
            });

            goToDestination();
          } catch (e) {
            setErrors(formatFormikError(e));
          }
        }}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <Form className="mx-auto max-w-sm">
          <div className="space-y-4">
            <FormikField label="Email" name="email" type="email" autoFocus />
            <FormikPasswordField label="Password" name="password" />

            <div className="my-4 flex justify-end">
              <Link
                to="/reset-password"
                className="text-sm font-semibold underline"
              >
                Reset Password
              </Link>
            </div>

            <FormikErrorMessage />

            <FormikSubmitButton fluid>Log in</FormikSubmitButton>
          </div>

          <div className="mt-4">
            <DSGAuthButton />
          </div>
        </Form>
      </Formik>

      {contactSupport}
    </LoginLayout>
  );
};

export default PartnerLoginPage;
