import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchPredictiveSearch } from '../../api/item';
import { useCombobox } from 'downshift';
import Input from '../Form/Input';
import FieldLabel from '../Form/FieldLabel';
import {
  BaseSelectMenu,
  BaseSelectMenuItem,
  BaseSelectMenuItemsContainer,
} from '../Form/Select/elements';
import { Item } from '../../api/intf/item';
import LoadingIndicator from '../Form/LoadingIndicator';
import Message from '../Form/Message';
import { getErrorMessage } from '../../api/ApiError';

interface Props {
  onSelect: (item: Item) => void;
}

const ItemSearchDropdown: React.FC<Props> = ({ onSelect }) => {
  const [inputValue, setInputValue] = useState('');

  const {
    data: items,
    error,
    isLoading,
    isPreviousData,
    isFetching,
  } = useQuery(
    ['search-items', inputValue],
    () => fetchPredictiveSearch(inputValue),
    {
      enabled: inputValue.length > 1,
      staleTime: 300000,
      keepPreviousData: true,
    },
  );

  const {
    getComboboxProps,
    getInputProps,
    getLabelProps,
    isOpen,
    getMenuProps,
    getItemProps,
    highlightedIndex,
    openMenu,
  } = useCombobox({
    defaultHighlightedIndex: 0,
    inputValue,
    items: items ?? [],
    itemToString: item => item?.name ?? '',
    onSelectedItemChange: changes => {
      if (changes.selectedItem) {
        onSelect(changes.selectedItem);
      }
    },
  });

  function open() {
    if (inputValue.length > 1) {
      openMenu();
    }
  }

  return (
    <>
      <div className="relative flex-1" {...getComboboxProps()}>
        <FieldLabel {...getLabelProps()}>Search for an item...</FieldLabel>
        <Input
          autoFocus
          {...getInputProps()}
          onFocus={e => {
            e.target.select();
            open();
          }}
          onChange={e => {
            setInputValue(e.target.value);
            open();
          }}
          value={inputValue}
        />

        <BaseSelectMenu isOpen={isOpen} {...getMenuProps()}>
          {isOpen && (
            <>
              <BaseSelectMenuItemsContainer>
                {isLoading && (
                  <LoadingIndicator size="large" className="mx-auto" />
                )}

                {isFetching && !isLoading && (
                  <LoadingIndicator className="absolute top-0 left-0 m-2" />
                )}

                {!!error && (
                  <Message error>
                    Failed to search for items: {getErrorMessage(error)}
                  </Message>
                )}

                {!isLoading && inputValue && !items?.length && (
                  <li className="mb-2 text-center text-slate-green-500">
                    No results...
                  </li>
                )}

                {!isLoading && !inputValue && !items?.length && (
                  <li className="mb-2 text-center text-slate-green-500">
                    No options...
                  </li>
                )}

                {items?.map((item, i) => (
                  <BaseSelectMenuItem
                    highlighted={highlightedIndex === i}
                    key={item.id}
                    {...getItemProps({
                      item,
                      index: i,
                    })}
                  >
                    <div key={item.id} className="flex items-center space-x-4">
                      {item.thumb_url ? (
                        <img
                          src={item.thumb_url}
                          className="h-14 w-14 rounded-md"
                        />
                      ) : (
                        <div className="h-14 w-14" />
                      )}
                      <div>
                        {item.name}{' '}
                        {item.category_name && (
                          <span>
                            {' in '}
                            <span className="font-semibold">
                              {item.category_name}
                            </span>
                          </span>
                        )}
                      </div>
                    </div>
                  </BaseSelectMenuItem>
                ))}
              </BaseSelectMenuItemsContainer>
            </>
          )}
        </BaseSelectMenu>
      </div>

      {!!error && (
        <Message error>
          Failed to search for items: {getErrorMessage(error)}
        </Message>
      )}
    </>
  );
};

export default ItemSearchDropdown;
