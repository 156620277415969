import { AxiosError } from 'axios';

export interface FieldError {
  field?: string;
  detail?: string;
  message: string;
  codes?: any[];
  code?: number | string;
}

export interface IApiError {
  message: string;
  endpoint?: string;
  params?: Record<string, any>;
  status?: number | string;
  errors?: FieldError[];
  meta?: Record<string, any>;
}

export class ApiError<TMeta extends Record<string, any> | undefined = undefined>
  extends Error
  implements IApiError
{
  endpoint?: string | undefined;
  params?: Record<string, any> | undefined;
  status?: string | number;
  errors?: FieldError[] | undefined;
  meta?: TMeta;
  data?: any;

  constructor(message?: string) {
    super(message);
    this.name = 'ApiError';
  }

  static fromResponse(response: Response, bodyText?: string) {
    let endpoint = response.url;

    const err = new ApiError(
      `status="${response.status}" endpoint="${endpoint}" responseBody="${bodyText}"`,
    );
    err.endpoint = endpoint;
    if (bodyText) {
      try {
        const parsedBody = JSON.parse(bodyText);
        err.errors = parsedBody.errors;
        err.meta = parsedBody.meta;
      } catch (e) {}
    }
    err.status = response.status;
    return err;
  }

  static fromApiError(other: ApiError) {
    const err = new ApiError(other.message);
    err.errors = other.errors;
    err.endpoint = other.endpoint;
    err.status = other.status;
    err.meta = other.meta;

    return err;
  }

  static fromAxiosError(error: AxiosError<any>) {
    const err = new ApiError(error.message);

    if (Array.isArray(error.response?.data?.errors)) {
      err.errors = error.response?.data?.errors;
    } else {
      err.errors = [{ message: getErrorMessage(error.response?.data) }];
    }

    err.endpoint = error.config?.url;
    err.status = error.code;
    // @ts-ignore
    err.meta = error.response?.data?.meta;
    err.data = error.response?.data;
    return err;
  }
}

export class AuthRefreshFailure extends ApiError {
  constructor(message?: string) {
    super(message);
    this.name = 'AuthRefreshError';
  }
}

export function getErrorMessage(error?: unknown | string | ApiError | Error) {
  const err = error as string | ApiError | Error;
  if (err == null) {
    return 'Something went wrong...';
  }

  if (typeof err == 'string') {
    return err;
  }

  let message: string;
  if ('errors' in err && Array.isArray(err.errors) && err.errors.length > 0) {
    const found = err.errors.find(e => !!e.message || !!e.detail);
    const foundMessage = found ? found.message || found.detail : undefined;
    if (foundMessage) {
      message = foundMessage;
    } else {
      const foundCode = err.errors.find(e => !!e.code)?.code;
      message = `Code: ${foundCode}`;
    }
  } else {
    message =
      // @ts-ignore
      (typeof err.error === 'string' ? err.error : undefined) ||
      // @ts-ignore
      err._embedded?.errors?.[0]?.message ||
      // @ts-ignore
      err.errors?._embedded?.errors?.[0]?.message ||
      // @ts-ignore
      err?.response?.data?.error ||
      // @ts-ignore
      err?.data?.error ||
      // @ts-ignore
      err?.data?.data?.error ||
      // @ts-ignore
      err?.data?.error?.message ||
      // @ts-ignore
      err?.error ||
      err.message ||
      // @ts-ignore
      err['detail'];
  }

  return message ?? 'Something went wrong...';
}

export type ApiErrorKeyMap = string;
// | ((key: string, value: string) => Record<string, string>);

/**
 * If you build support for a function "errorFieldMap", include this documentation:
 *
 * You can also provide a function as a value to perform some transformation
 * on the key or value. In this case, it returns a key-value pair in place of
 * the original key.
 */

export type ApiFormikError = Record<string, any>;

interface FormikErrorFormatOptions<TFormValues extends Record<string, any>> {
  defaultMessage?: string;

  /**
   * Provide a key-value pair to override keys.
   * The object's key is the original value, the value is the desired key.
   */
  errorFieldMap?: Record<string, keyof TFormValues>;
}

export function formatFormikError<TFormValues extends Record<string, any> = {}>(
  error: ApiError | unknown,
  options?: FormikErrorFormatOptions<TFormValues>,
): ApiFormikError {
  const realError = error as ApiError;
  const errors: ApiFormikError = {};
  realError?.errors?.forEach(err => {
    const message = err.message || err.detail;

    if (err.field) {
      // There's a possibility (apparently) that the API can come back with
      // an object notation error object.
      if (typeof message === 'string') {
        const keyMapElement = options?.errorFieldMap?.[err.field];
        if (keyMapElement) {
          if (keyMapElement === '_message') {
            // @ts-ignore
            errors[keyMapElement] = `${err.field}: ${message}`;
          } else {
            // @ts-ignore
            errors[keyMapElement] = message;
          }
        } else {
          errors[err.field] = message;
        }
      }
    } else {
      errors._message = message;
    }
  });

  if (Object.keys(errors).length === 0) {
    errors._message =
      // Handle backend's marvelous error consistency
      // @ts-ignore
      error?._embedded?.errors?.[0]?.message ||
      // @ts-ignore
      error?.message ||
      options?.defaultMessage ||
      getErrorMessage(realError) ||
      'Something went wrong...';
  }

  return errors;
}
