import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Button } from '../../../components/Form/Button';
import { fetchRedeemStatus, putRedeem } from '../../../api/redeem';
import displayPrice from '../../../utils/displayPrice';
import { useParams } from 'react-router-dom';
import LogoTagline from '../../../components/Icons/LogoTagline';
import Message from '../../../components/Form/Message';
import LogoIcon from '../../../components/Icons/LogoIcon';
import classNames from 'classnames';

const Redemption: React.FC = () => {
  const queryClient = useQueryClient();
  const [isChecked, setChecked] = useState<boolean>(false);
  const { redemptionCode } = useParams<{ redemptionCode: string }>();

  const { data: redemptionStatus, error } = useQuery(
    ['redemptionStatus', redemptionCode],
    () => fetchRedeemStatus(redemptionCode!),
  );

  const { mutate: confirmRedemption, isSuccess: justRedeemed } = useMutation(
    () => putRedeem(redemptionCode!),
    {
      onSuccess: data => {
        queryClient.setQueryData(['redemptionStatus', redemptionCode], data);
      },
    },
  );

  const handleCheckChange: React.ChangeEventHandler<
    HTMLInputElement
  > = event => {
    setChecked(!isChecked);
  };

  const handleUpdateRedeem = () => {
    confirmRedemption();
  };

  const spinningIcon = (
    <div
      className={classNames('mt-6 flex justify-center', {
        'text-red-500': redemptionStatus?.status === 'REDEEMED',
      })}
    >
      <LogoIcon className="redemption-spin h-12 w-12" />
    </div>
  );

  return (
    <>
      <div className="flex h-16 items-center justify-center shadow-lg">
        <LogoTagline />
      </div>

      {justRedeemed && (
        <div className="mx-4">
          <Message success className="mx-4">
            Code redeemed!
          </Message>
        </div>
      )}

      {!!(error || redemptionStatus?.status === 'DELETED') && (
        <div className="mx-4">
          <Message error>
            This quote has been voided and the redemption code is no longer
            valid.
          </Message>
        </div>
      )}

      {redemptionStatus && (
        <div className="mx-auto w-full md:max-w-lg lg:max-w-xl">
          {redemptionStatus.status === 'ACTIVE' ? (
            <div className="flex flex-col px-4 py-10 text-center">
              <span className="text-base italic text-gray-500">
                Present this screen to a store associate to receive you store
                credit
              </span>

              <span className="mt-7 text-xl">
                This code is <b>Valid</b>
              </span>

              {spinningIcon}

              <span className="mt-6 text-xl">
                Payment Method: <b>{redemptionStatus.payment_method || ''}</b>
              </span>

              <span className="mt-4 text-xl">
                Store credit: <b>{displayPrice(redemptionStatus.amount)}</b>
              </span>

              <span className="mt-4 text-xl">
                Redemption code: <b>{redemptionStatus.redemption_code || ''}</b>
              </span>

              <label className="mx-auto mt-12 flex max-w-xs justify-center space-x-4">
                <div className="mt-2">
                  <input
                    type="checkbox"
                    id="check"
                    checked={isChecked}
                    onChange={handleCheckChange}
                  />
                </div>
                <span className="text-left text-lg">
                  Click to confirm that you are using a one-time store credit.
                  This should only be redeemed by a store associate. Once your
                  credit is redeemed it will no longer be valid.
                </span>
              </label>

              <div className="flex justify-center">
                <Button
                  className="mt-10 w-32"
                  size="small"
                  disabled={!isChecked}
                  onClick={handleUpdateRedeem}
                >
                  Redeem
                </Button>
              </div>
            </div>
          ) : (
            <div className="px-4 py-10 text-center">
              <span className="mt-7 text-xl">
                This code is <b className="text-red-500">Redeemed</b>
              </span>

              {spinningIcon}

              {redemptionStatus.status !== 'DELETED' && (
                <div className="mt-10 text-xl">
                  Your credit was redeemed on{' '}
                  {new Date(redemptionStatus.redeemed_at).toLocaleString()}.
                  Please refer to the information you received from the store to
                  use your credit.
                </div>
              )}

              <div className="mt-10 text-xl">
                Store credit: <b>{displayPrice(redemptionStatus.amount)}</b>
              </div>

              {redemptionStatus.status !== 'DELETED' && (
                <div className="mt-4 text-xl">
                  Redemption code:{' '}
                  <b>{redemptionStatus.redemption_code || ''}</b>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Redemption;
