import { getCookie, setCookie } from '../lib/KnownCookies';
import decodeJwt from '../utils/decodeJwt';
import {
  ChallengedTokenAuthorization,
  IAMLoginMeta,
  IAMLoginRequest,
  IAMTokenRefreshRequest,
  IAMUser,
  TokenAuthorization,
} from './intf/auth';
import axios from 'axios';
import { ApiResponse } from './intf/generic';

export const MOCK_ACCESS_TOKEN = 'MOCK_ACCESS_TOKEN';
export const MOCK_REFRESH_TOKEN = 'MOCK_REFRESH_TOKEN';

export const postAuthLogin = async (username: string, password: string) => {
  const { data } = await axios.post<
    | ApiResponse<TokenAuthorization>
    | ApiResponse<ChallengedTokenAuthorization, IAMLoginMeta>
  >('/iam/v1/auth/token', {
    grant_type: 'client_credentials',
    username_or_email: username,
    password: password,
    test_2FA: false,
    client_id: getCookie('sls_client_id'),
    access_token_ttl: 1200,
  } as IAMLoginRequest);

  if ('access_token' in data.data) {
    setCookie('access_token', data.data.access_token);
    setCookie('refresh_token', data.data.refresh_token);

    return data.data;
  }

  throw new Error('Unhandled challenge');
};

export const postPartnerAuthLogin = async (email: string, password: string) => {
  const { data } = await axios.post<
    | ApiResponse<TokenAuthorization>
    | ApiResponse<ChallengedTokenAuthorization, IAMLoginMeta>
  >(
    '/iam/auth/v1/trade_in/token',
    {
      grant_type: 'CLIENT_CREDENTIALS',
      email: email,
      password: password,
      client_id: getCookie('sls_client_id'),
      access_token_ttl: 1200,
    },
    {
      headers: {
        'x-sls-client-type': 'IN_STORE',
      },
    },
  );

  if ('access_token' in data.data) {
    setCookie('access_token', data.data.access_token);
    setCookie('refresh_token', data.data.refresh_token);
    setCookie('sls_user_type', 'partner');

    return data.data;
  }

  throw new Error('Unhandled challenge');
};

export const fetchCurrentUser = async () => {
  const accessToken = getCookie('access_token');
  const decodedToken = decodeJwt(accessToken);

  if (
    decodedToken?.uuid ||
    ((import.meta.env.NODE_ENV === 'development' ||
      import.meta.env.NODE_ENV === 'test') &&
      accessToken === MOCK_ACCESS_TOKEN)
  ) {
    const { data } = await axios.get<ApiResponse<IAMUser>>(
      `/iam/v1/auth/user/${decodedToken ? decodedToken.uuid : accessToken}`,
    );

    return data.data;
  }

  throw new Error('fetchCurrentUser called without an access_token cookie');
};

export const postNewAccessToken = async (refreshToken: string) => {
  try {
    if (getCookie('sls_user_type') === 'partner') {
      const { data } = await axios.post<ApiResponse<TokenAuthorization>>(
        '/iam/auth/v1/trade_in/token',
        {
          grant_type: 'REFRESH_TOKEN',
          token: refreshToken,
          client_id: getCookie('sls_client_id'),
        },
        {
          headers: {
            Authorization: null,
          },
        },
      );

      setCookie('access_token', data.data.access_token);
      setCookie('refresh_token', data.data.refresh_token);
    } else {
      const { data } = await axios.post<ApiResponse<TokenAuthorization>>(
        '/iam/v1/auth/token',
        {
          grant_type: 'refresh_token',
          token: refreshToken,
          client_id: getCookie('sls_client_id'),
        } as IAMTokenRefreshRequest,
        {
          headers: {
            Authorization: null,
          },
        },
      );

      setCookie('access_token', data.data.access_token);
      setCookie('refresh_token', data.data.refresh_token);
    }
  } catch (err) {}
};

export const fetchUserById = async (uuid: string) => {
  const { data } = await axios.get<ApiResponse<IAMUser>>(
    `/iam/v1/auth/user/${uuid}`,
  );

  return data.data;
};

export const postResetPasswordEmail = async (email: string) => {
  try {
    await axios.post<ApiResponse<null>>(
      '/iam/auth/v1/trade_in/password_reset',
      {
        email,
      },
      {
        headers: {
          'x-sls-client-type': 'IN_STORE',
        },
      },
    );
  } catch (e) {}
};

export const postResetPassword = async (token: string, password: string) => {
  const response = await axios.post<
    ApiResponse<{}, { authorization: TokenAuthorization }>
  >(
    '/iam/auth/v1/trade_in/password',
    {
      new_password: password,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'x-sls-client-type': 'IN_STORE',
      },
    },
  );

  const authorization = response.data.meta?.authorization;

  if (authorization) {
    setCookie('access_token', authorization.access_token);
    setCookie('refresh_token', authorization.refresh_token);
    setCookie('sls_user_type', 'partner');
    return authorization;
  }

  throw new Error('Failed to reset password');
};
