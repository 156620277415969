import React, { useEffect } from 'react';
import PageLayout from '../../../components/Layout/PageLayout';
import PageHeader from '../../../components/Layout/PageHeader';
import { z } from 'zod';
import { Form, useField } from 'formik';
import FormikZodSchema from '../../../components/Form/FormikZodSchema';
import { useQuery, useQueryClient } from 'react-query';
import {
  fetchItemsToTransfer,
  postCreateTransfer,
} from '../../../api/partnerTransfers';
import Loader from '../../../components/Form/Loader';
import Message from '../../../components/Form/Message';
import { formatFormikError, getErrorMessage } from '../../../api/ApiError';
import FormikNumberField from '../../../components/Form/FormikNumberField';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Checkbox from '../../../components/Form/Checkbox';
import SimpleReactTable from '../../../components/Tables/SimpleReactTable';
import FormikSubmitButton from '../../../components/Form/FormikSubmitButton';
import FormikErrorMessage from '../../../components/Form/FormikErrorMessage';
import FormikFieldError from '../../../components/Form/FormikFieldError';
import pluralize from '../../../utils/pluralize';
import { useNavigate } from 'react-router-dom';
import formatListingTag from '../../../utils/formatListingTag';
import usePartnerUserName from '../../../hooks/query/usePartnerUserName';
import PageSubheader from '../../../components/Layout/PageSubheader';
import TransferAddMissingItems from '../../../components/Partner/TransferAddMissingItems';
import {
  fireAddedMissingItem,
  fireCreatedTransfer,
  fireVisitedCreateTransfer,
} from '../../../services/analytics/partner/transfers';
import BackArrowLink from '../../../components/BackArrowLink';
import { differenceInDays } from 'date-fns';
import { UntaggedItemsWarning } from '../../../components/Partner/UntaggedItemsWarning';

const schema = z.object({
  additionalItems: z.array(
    z.object({
      item_id: z.string(),
    }),
  ),
  boxes: z.coerce.number({
    required_error: 'Please enter a number of boxes',
  }),
  selectedItems: z.array(z.string()).min(1, 'Please select at least one item'),
});

export type CreateTransferFormValues = z.infer<typeof schema>;

const PartnerDashboardCreateTransferPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    data: itemsToTransfer,
    error,
    isLoading,
    refetch,
  } = useQuery(['items-to-transfer'], fetchItemsToTransfer);

  useEffect(() => {
    if (itemsToTransfer) {
      fireVisitedCreateTransfer(itemsToTransfer.length);
    }
  }, [itemsToTransfer]);

  const handleSuccessfullyAddedMissingItem = (tag: string) => {
    fireAddedMissingItem(tag);
    refetch();
  };

  const table = useReactTable({
    data: itemsToTransfer ?? [],
    columns: [
      {
        id: 'selectAll',
        enableSorting: false,
        size: 24,
        header: () => {
          const [field, meta, helpers] =
            useField<CreateTransferFormValues['selectedItems']>(
              'selectedItems',
            );

          return (
            <Checkbox
              aria-label="Select All"
              checked={field.value.length === itemsToTransfer?.length}
              onChange={e => {
                if (e.target.checked) {
                  helpers.setValue(
                    itemsToTransfer?.map(item => item.item_id) ?? [],
                  );
                } else {
                  helpers.setValue([]);
                }
              }}
            />
          );
        },
        footer: 'Total',
        cell: ({ row }) => {
          const [field, meta, helpers] =
            useField<CreateTransferFormValues['selectedItems']>(
              'selectedItems',
            );

          return (
            <Checkbox
              aria-label={row.original.name}
              checked={field.value.includes(row.original.item_id)}
              onChange={e => {
                if (e.target.checked) {
                  helpers.setValue([...field.value, row.original.item_id]);
                } else {
                  helpers.setValue(
                    field.value.filter(id => id !== row.original.item_id),
                  );
                }
              }}
            />
          );
        },
      },
      {
        accessorFn: row => row.tag,
        cell: row => formatListingTag(row.getValue()) ?? 'No Tag',
        header: 'Tag',
        enableSorting: false,
      },
      {
        id: 'quote_completed_at',
        header: 'Purchased',
        accessorFn: row => new Date(row.quote_completed_at),
        cell: row => `${differenceInDays(new Date(), row.getValue())} days ago`,
      },
      {
        accessorFn: row => row.name,
        header: 'Name',
        footer: () => {
          const [field] =
            useField<CreateTransferFormValues['selectedItems']>(
              'selectedItems',
            );

          return (
            <span>
              {field.value.length}{' '}
              {pluralize(field.value.length, 'item', 'items')}
            </span>
          );
        },
      },
      {
        accessorFn: row => row.quoted_by,
        cell: row => usePartnerUserName(row.getValue()),
        header: 'Quoted By',
      },
    ],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: 'quote_completed_at',
          desc: false,
        },
      ],
    },
  });

  let content: React.ReactNode;

  if (isLoading || itemsToTransfer == null) {
    content = (
      <Loader loading={isLoading}>
        {!!error && <Message error>{getErrorMessage(error)}</Message>}
      </Loader>
    );
  } else if (itemsToTransfer.length === 0) {
    content = <p>There are no items to ship</p>;
  } else {
    content = (
      <FormikZodSchema
        schema={schema}
        initialValues={{
          additionalItems: [],
          boxes: 0,
          selectedItems: [],
        }}
        onSubmit={async (values, formikHelpers) => {
          try {
            const transfer = await postCreateTransfer(
              values.boxes,
              values.selectedItems,
            );
            fireCreatedTransfer(
              values.boxes,
              values.selectedItems.length,
              transfer.id,
            );
            queryClient.invalidateQueries();
            navigate(`/partner/dashboard/transfers/${transfer.id}/created`);
          } catch (e) {
            formikHelpers.setErrors(formatFormikError(e));
          }
        }}
      >
        {({ values }) => (
          <Form className="space-y-4">
            <div className="flex justify-between">
              <FormikNumberField
                className="w-40"
                name="boxes"
                label="How many boxes do you need?"
              />

              <UntaggedItemsWarning />
            </div>

            <SimpleReactTable table={table} />
            <FormikFieldError name="selectedItems" />

            <TransferAddMissingItems
              onSuccessfullyAdded={handleSuccessfullyAddedMissingItem}
            />

            <FormikErrorMessage />

            <FormikSubmitButton
              disabled={values.selectedItems.length === 0 || values.boxes <= 0}
            >
              Create Labels
            </FormikSubmitButton>
          </Form>
        )}
      </FormikZodSchema>
    );
  }

  return (
    <PageLayout
      header={
        <>
          <BackArrowLink to="/partner/dashboard/transfers">
            Back to Shipments
          </BackArrowLink>

          <PageHeader>Create a Shipment</PageHeader>

          <PageSubheader className="max-w-sm">
            Before you start, collect all the products you plan to add to this
            shipment. You&apos;ll receive one shipping label for each box.
          </PageSubheader>
        </>
      }
    >
      {content}
    </PageLayout>
  );
};

export default PartnerDashboardCreateTransferPage;
