import { Form } from 'formik';
import React from 'react';
import { formatFormikError, getErrorMessage } from '../../api/ApiError';
import { useNavigate } from 'react-router-dom';
import { putUpdateEvent } from '../../api/events';
import { useQuery, useQueryClient } from 'react-query';
import { fetchAllPayoutMethods } from '../../api/paymentMethods';
import { eventFormSchema, EventFormValues } from './CreateEventForm';
import { useEventCustomerQuoteQuery } from '../../hooks/routing/useEventCustomerQuoteQuery';
import Message from '../Form/Message';
import useCurrentEventQuery from '../../hooks/query/useCurrentEventQuery';
import FormikZodSchema from '../Form/FormikZodSchema';
import { EVENT_TYPES } from './EventFormFields';

interface Props {}

const EditEventForm: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { eventId } = useEventCustomerQuoteQuery();
  const queryClient = useQueryClient();
  const { data: event, error: eventError } = useCurrentEventQuery();

  const { data: payoutMethods, error: payoutMethodsError } = useQuery(
    ['payout-methods', 'all'],
    fetchAllPayoutMethods,
    {
      // Never invalidate because that would screw up the user's input.
      // We could solve this by deep merging the arrays without overwriting
      // the `enabled` flag but meh.
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  );

  if (eventError) {
    return (
      <Message error>
        Failed to load event: {getErrorMessage(eventError)}
      </Message>
    );
  }

  if (payoutMethodsError) {
    return (
      <Message error>
        Failed to fetch payout methods: {getErrorMessage(payoutMethodsError)}
      </Message>
    );
  }

  if (!payoutMethods) {
    return null;
  }

  if (!event) {
    return null;
  }

  const initialValues: EventFormValues = {
    ...event,
    // @ts-ignore
    event_type: event.event_type
      ? EVENT_TYPES.find(eventType => eventType.id === event.event_type)
      : undefined,
    payout_methods:
      event.payout_methods?.map(payoutMethod => ({
        ...payoutMethod,
        credit_offer_amount:
          payoutMethod.credit_offer_amount != null
            ? payoutMethod.credit_offer_amount * 100
            : undefined,
      })) || [],
    partner: event.partner,
    location: event.partner_address,
  };

  return (
    <FormikZodSchema
      schema={eventFormSchema}
      initialValues={initialValues}
      onSubmit={async (values, formikHelpers) => {
        try {
          const event = await putUpdateEvent(eventId, {
            partner_id: values.partner.id,
            partner_address_id: values.location.id,
            start_date: values.start_date,
            end_date: values.end_date,
            event_type: values.event_type.id,
            payout_methods: values.payout_methods.map(payoutMethod => ({
              ...payoutMethod,
              credit_offer_amount:
                payoutMethod.credit_offer_amount &&
                payoutMethod.credit_offer_amount > 1
                  ? payoutMethod.credit_offer_amount / 100
                  : payoutMethod.credit_offer_amount,
            })),
            freight_boxes: values.freight_boxes,
            staff_count: values.staff_count,
            retro_notes: values.retro_notes,
          });
          queryClient.invalidateQueries();
          navigate(`/event/${event.id}`);
        } catch (e) {
          formikHelpers.setErrors(formatFormikError(e));
        }
      }}
    >
      <Form>{children}</Form>
    </FormikZodSchema>
  );
};

export default EditEventForm;
