import React from 'react';
import { useLocation } from 'react-router';
import useMobile from '../../../hooks/useMobile';
import MobileHeaderAndDrawer from '../SideMenu/MobileHeaderAndDrawer';
import SideMenu from '../SideMenu/SideMenu';
import { Outlet, useSearchParams } from 'react-router-dom';
import SideMenuLink from '../SideMenu/SideMenuLink';
import {
  ArrowRightOnRectangleIcon,
  CalendarIcon,
  CubeIcon,
  HomeIcon,
  InboxStackIcon,
  PrinterIcon,
} from '@heroicons/react/24/outline';
import useCurrentWarehouseSwapCountsQuery from '../../../hooks/warehouse/useCurrentWarehouseSwapCountsQuery';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';

interface Props {}

const WarehouseLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  // const data = useLoaderData() as LoaderData;
  const mobile = useMobile();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const currentScope =
    location.pathname.includes('/swaps') &&
    (searchParams.get('scope') || 'all');

  const { data: swapCounts, error } = useCurrentWarehouseSwapCountsQuery();

  const warehouseId = useCurrentWarehouseId();

  const menu = (
    <>
      <div className="flex-1 space-y-2 p-2 text-white">
        <SideMenuLink
          text="Warehouse Home"
          to={warehouseId ? `/warehouse/${warehouseId}` : '/warehouse'}
          icon={<HomeIcon className="h-6 w-6" />}
        />

        {warehouseId && (
          <>
            <SideMenuLink
              to={`/warehouse/${warehouseId}/swaps?scope=ship_now&filter=new_orders`}
              text="New Orders"
              icon={<InboxStackIcon className="h-6 w-6" />}
            />

            <SideMenuLink
              to={`/warehouse/${warehouseId}/swaps?scope=ship_now&filter=ready_to_ship`}
              text="Ready to Ship"
              icon={<CubeIcon className="h-6 w-6" />}
            />
          </>
        )}

        <SideMenuLink
          text="Change Warehouse"
          to="/warehouse"
          icon={<CalendarIcon className="h-6 w-6" />}
        />

        <SideMenuLink
          to={`/warehouse/reprint`}
          text="Reprint Labels"
          icon={<PrinterIcon className="h-6 w-6" />}
        />
      </div>

      <div className="flex-1" />

      <div className="p-2">
        <SideMenuLink
          text="Change Mode"
          to="/"
          icon={<ArrowRightOnRectangleIcon className="h-6 w-6" />}
        />
      </div>
    </>
  );

  return (
    <div>
      {mobile && <MobileHeaderAndDrawer>{menu}</MobileHeaderAndDrawer>}

      {!mobile && (
        <div className="fixed inset-y-0 flex w-80 flex-col bg-turf-green-500 shadow">
          <SideMenu>{menu}</SideMenu>
        </div>
      )}

      <div className="flex h-full">
        {!mobile && <div className="w-80" />}

        <div className="flex-1 pb-20">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default WarehouseLayout;
