import React from 'react';
import classNames from 'classnames';

type AdditionalProps = {
  error?: boolean;
};

type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  as?: any;
} & AdditionalProps;

const NativeSelect: React.FC<SelectProps> = ({
  children,
  error,
  disabled,
  placeholder,
  value = '',
  className,
  ...props
}) => (
  <select
    {...props}
    value={value}
    className={classNames(
      'h-10 w-full rounded border border-grey-500 px-4 text-base text-turf-green-500 outline-none transition duration-200 focus:outline-none focus:ring',
      {
        'border-red-500 bg-white ring-red-500 ring-opacity-30': error,
        'bg-white focus:border-green-500': !error,
        'cursor-not-allowed bg-off-white-500 text-slate-green-500': disabled,
      },
      className,
    )}
    disabled={disabled}
  >
    {placeholder && (
      <option value="" disabled>
        {placeholder}
      </option>
    )}
    {children}
  </select>
);

export default NativeSelect;
