import React from 'react';
import WarehouseBreadcrumbLink from '../../../components/Warehouse/WarehouseBreadcrumbLink';
import PageHeader from '../../../components/Layout/PageHeader';
import PageSubheader from '../../../components/Layout/PageSubheader';
import useCurrentWarehouseQuery from '../../../hooks/warehouse/useCurrentWarehouseQuery';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import PageLayout from '../../../components/Layout/PageLayout';
import useCurrentPicklistId from '../../../hooks/warehouse/useCurrentPicklistId';
import useCurrentPicklistQuery from '../../../hooks/warehouse/useCurrentPicklistQuery';
import PicklistTable from '../../../components/Warehouse/Picklists/PicklistTable';
import { Button } from '../../../components/Form/Button';
import { useNavigate } from 'react-router-dom';

interface Props {}

const WarehouseViewPicklistPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { data: warehouse } = useCurrentWarehouseQuery();
  const warehouseId = useCurrentWarehouseId()!;
  const picklistId = useCurrentPicklistId()!;

  const { data: picklist } = useCurrentPicklistQuery();

  return (
    <PageLayout
      header={
        <>
          <WarehouseBreadcrumbLink to="picklist" />
          <PageHeader>Warehouse - {warehouse?.facility}</PageHeader>
          <PageSubheader>Pick List</PageSubheader>
        </>
      }
    >
      <PicklistTable
        picklistId={picklistId}
        warehouseId={warehouseId}
        picklistItems={picklist?.items ?? []}
      />

      <div className="mt-8 space-y-4">
        <Button
          fluid
          size="large"
          onClick={() =>
            navigate(`/warehouse/${warehouseId}/picklist/${picklistId}/scan`)
          }
        >
          Enter Pick Mode
        </Button>

        <Button
          fluid
          size="large"
          variant="outlined"
          onClick={() => navigate(`/warehouse/${warehouseId}/pick`)}
        >
          Finish
        </Button>
      </div>
    </PageLayout>
  );
};

export default WarehouseViewPicklistPage;
