import React, { useEffect, useState } from 'react';
import InStoreQuoteLayout from '../../../components/Layout/Layouts/InStoreQuoteLayout';
import useEventQuery from '../../../hooks/query/useEventQuery';
import { Link, useNavigate } from 'react-router-dom';
import useCurrentQuoteQuery from '../../../hooks/query/useCurrentQuoteQuery';
import useCustomerById from '../../../hooks/useCustomerById';
import { useMutation, useQueryClient } from 'react-query';
import { postSubmitQuoteWithPayoutMethods } from '../../../api/item';
import qsStringify from '../../../utils/qsStringify';
import classNames from 'classnames';
import Message from '../../../components/Form/Message';
import { getErrorMessage } from '../../../api/ApiError';
import { Button } from '../../../components/Form/Button';
import PartnerQuoteFlowBackButton from '../../../components/Partner/Quote/PartnerQuoteFlowBackButton';
import { PayoutMethod } from '../../../api/intf/payoutMethods';
import {
  fireCompletedQuote,
  fireSelectedPayoutMethod,
} from '../../../services/analytics/partner/quotes';
import useOrgId from '../../../hooks/useOrgId';
import { useEventCustomerQuoteQuery } from '../../../hooks/routing/useEventCustomerQuoteQuery';
import DSGImage from '../../../img/dsg-logo.png';
import getQuoteListings from '../../../utils/getQuoteListings';

function getPayoutMethodImage(payoutMethod: PayoutMethod) {
  if (/DSG|DICK'S/.test(payoutMethod.name)) {
    return DSGImage;
  }
}

interface Props {}

const PartnerQuotePaymentPage: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const orgId = useOrgId();
  const { quoteId } = useEventCustomerQuoteQuery();
  const [selectedPayoutMethodId, setSelectedPayoutMethodId] = useState<
    undefined | string
  >();

  function handleSelectPayoutMethod(payoutMethod: PayoutMethod) {
    fireSelectedPayoutMethod(payoutMethod.name, quoteId);
    setSelectedPayoutMethodId(payoutMethod.id);
  }

  const { data: quote } = useCurrentQuoteQuery();
  const { data: event, isLoading: isLoadingEvent } = useEventQuery(
    quote?.trade_in_event_id,
  );
  const { data: contact } = useCustomerById(quote?.contact_id);

  const availablePayoutMethods =
    event?.payout_methods?.filter(pm => pm.enabled) || [];

  const {
    mutate: submitPartnerQuote,
    isLoading: isSubmitting,
    error,
  } = useMutation(
    () => {
      if (!quote || !selectedPayoutMethodId || !contact) {
        throw new Error('Failed to submit quote');
      }

      return postSubmitQuoteWithPayoutMethods(
        quote.id,
        [
          {
            payout_method_id: selectedPayoutMethodId,
          },
        ],
        contact,
      );
    },
    {
      onSuccess: data => {
        fireCompletedQuote({
          quoteId: data.id,
          payoutMethod:
            availablePayoutMethods?.find(
              method => method.id === selectedPayoutMethodId,
            )?.name || 'Unknown',
          quote_items: getQuoteListings(data).length,
          payoutAmount: data.cash_offer_amount || 0,
          storeId: orgId!,
          processedBy: data.contact_id!,
        });

        queryClient.invalidateQueries();
        navigate(
          '/partner/dashboard/tag' +
            qsStringify({
              from_quote: '1',
              quote_id: quote?.id,
            }),
        );
      },
    },
  );

  useEffect(() => {
    // Auto select if there's only one payout method available, if you haven't made a selection yet
    if (!selectedPayoutMethodId && availablePayoutMethods?.length! == 1) {
      handleSelectPayoutMethod(availablePayoutMethods[0]);
    }
  }, [selectedPayoutMethodId, availablePayoutMethods]);

  return (
    <InStoreQuoteLayout step="payment">
      <PartnerQuoteFlowBackButton to="summary" />

      <div className="mb-4 text-2xl">
        Gift card will be sent to <b>{contact?.email}</b>{' '}
        <Link
          to={`/partner/quote/${quoteId}/customer`}
          className="text-base underline"
        >
          Edit
        </Link>
      </div>

      <header className="mb-2 text-lg font-semibold">Payment Method</header>

      {event?.payout_methods && availablePayoutMethods.length == 0 && (
        <Message>No available payout methods</Message>
      )}

      {event?.payout_methods && availablePayoutMethods.length > 0 && (
        <div className="mb-8 grid grid-cols-2 gap-4 md:grid-cols-4">
          {availablePayoutMethods?.map(method => {
            const image = getPayoutMethodImage(method);

            return (
              <button
                key={method.id}
                className={classNames(
                  'flex items-center space-x-4 rounded border-2 px-4 py-2.5 text-left',
                  selectedPayoutMethodId === method.id
                    ? 'border-green-500'
                    : 'border-grey-300',
                )}
                onClick={() => handleSelectPayoutMethod(method)}
              >
                {!!image && <img src={image} alt="" className="w-16" />}
                <div className="flex-1">
                  <div className="text-lg font-semibold">{method.name}</div>
                </div>
                <div className="flex-shrink-0">
                  {/*<img alt="" src={method} width={100} height={100} />*/}
                </div>
              </button>
            );
          })}
        </div>
      )}

      {!!error && <Message error>{getErrorMessage(error)}</Message>}

      <Button
        disabled={!quote?.contact_id || !selectedPayoutMethodId || isSubmitting}
        onClick={() => submitPartnerQuote()}
      >
        Complete Trade-in
      </Button>
    </InStoreQuoteLayout>
  );
};

export default PartnerQuotePaymentPage;
