import React, { useState } from 'react';
import FieldLabel from '../Form/FieldLabel';
import generateItemTitle from '../../utils/generateItemTitle';
import { useFormikContext } from 'formik';
import { CreateItemFormValues, MAX_ITEM_TITLE } from './CreateItemForm';
import { FullCategory } from '../../api/intf/item';
import FormikField from '../Form/FormikField';

interface Props {
  categoryItem: FullCategory | undefined;
}

const EditableItemTitle: React.FC<Props> = ({ categoryItem }) => {
  const { values, setFieldValue } = useFormikContext<CreateItemFormValues>();
  const [editing, setEditing] = useState(false);

  if (editing) {
    return (
      <div className="mt-4 text-sm">
        <FormikField
          label="Item Title"
          name="title"
          maxLength={MAX_ITEM_TITLE}
        />
      </div>
    );
  }

  return (
    <div className="mt-4 text-sm">
      <FieldLabel>Item Title</FieldLabel>
      {generateItemTitle(values, categoryItem!)}
      <button
        type="button"
        className="ml-2 inline-block text-slate-green-500 underline"
        onClick={() => {
          setFieldValue('title', generateItemTitle(values, categoryItem!));
          setEditing(true);
        }}
      >
        Edit
      </button>
    </div>
  );
};

export default EditableItemTitle;
