import React, { useState } from 'react';
import { useQueries, useQuery, useQueryClient } from 'react-query';
import {
  fetchQuoteById,
  fetchQuotesByCustomer,
  fetchRecentQuotes,
} from '../../../api/item';
import FieldLabel from '../../../components/Form/FieldLabel';
import Input from '../../../components/Form/Input';
import Field from '../../../components/Form/Field';
import QuoteCard from '../../../components/SearchQuote/QuoteCard';
import CardContainer from '../../../components/CardContainer';
import PageLayout from '../../../components/Layout/PageLayout';
import Message from '../../../components/Form/Message';
import { getErrorMessage } from '../../../api/ApiError';
import LoadingIndicator from '../../../components/Form/LoadingIndicator';
import { useEventCustomerQuoteQuery } from '../../../hooks/routing/useEventCustomerQuoteQuery';
import TradeInBreadcrumbLink from '../../../components/Layout/TradeInBreadcrumbLink';
import { useSearchParams } from 'react-router-dom';
import { fetchCustomerByQuery } from '../../../api/customer';
import { compareDesc } from 'date-fns';
import { Quote } from '../../../api/intf/item';
import SearchBarUrlQuery from '../../../components/Partner/Dashboard/ViewAllQuotes/SearchBarUrlQuery';

interface Props {}

const SearchQuoteByIdPage: React.FC<Props> = () => {
  const { eventId } = useEventCustomerQuoteQuery();
  const queryClient = useQueryClient();

  const [params] = useSearchParams();
  const search = params.get('q') || '';

  const {
    data: recentQuotes,
    isLoading: isLoadingRecentQuotes,
    error: recentQuotesError,
  } = useQuery(['quotes', eventId], () => fetchRecentQuotes(eventId));
  const {
    data: searchedCustomers,
    error: searchedCustomersError,
    isLoading: isLoadingSearchedQuote,
  } = useQuery(
    ['customer-quotes', search, eventId],
    () => fetchCustomerByQuery(search),
    {
      enabled: !!search,
      onSuccess: customers => {
        for (const customer of customers) {
          queryClient.setQueryData(['contact', customer.id], customer);
        }
      },
    },
  );

  const quotesByCustomers = useQueries(
    searchedCustomers?.map(customer => ({
      queryKey: ['quotes-by-contact', customer.id, eventId],
      queryFn: () => fetchQuotesByCustomer(customer.id, eventId),
      enabled: !!customer.id,
    })) || [],
  );

  const quotes = search
    ? (quotesByCustomers
        ?.map(q => q.data)
        .flat()
        .filter(q => q != null)
        ?.sort((a, b) => {
          return compareDesc(new Date(a!.created_at), new Date(b!.created_at));
        }) as Quote[])
    : recentQuotes;

  const isLoading =
    isLoadingRecentQuotes ||
    isLoadingSearchedQuote ||
    quotesByCustomers?.some(q => q.isLoading);

  return (
    <PageLayout
      header={
        <>
          <TradeInBreadcrumbLink to="home" />

          <div className="max-w-sm">
            <SearchBarUrlQuery label="Search by customer name or quote ID" />
          </div>
        </>
      }
    >
      {!!recentQuotesError && (
        <Message error>
          Failed to load recent quotes: {getErrorMessage(recentQuotesError)}
        </Message>
      )}

      {!!searchedCustomersError && (
        <Message error>
          Failed to load quotes by that search:{' '}
          {getErrorMessage(searchedCustomersError)}
        </Message>
      )}

      {!!isLoading && <LoadingIndicator size="large" className="mx-auto" />}

      {!isLoading && quotes && quotes.length === 0 && (
        <div className="text-center text-lg font-semibold text-slate-green-500">
          {search ? `No quotes matching "${search}"` : 'No recent quotes'}
        </div>
      )}

      <CardContainer>
        {quotes?.map(quote => (
          <QuoteCard key={quote.id} quote={quote} />
        ))}
      </CardContainer>
    </PageLayout>
  );
};

export default SearchQuoteByIdPage;
