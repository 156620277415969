import React, { useMemo, useState } from 'react';
import PageHeader from '../components/Layout/PageHeader';
import { useQuery } from 'react-query';
import { fetchQuoteById } from '../api/item';
import Message from '../components/Form/Message';
import { getErrorMessage } from '../api/ApiError';
import PageSubheader from '../components/Layout/PageSubheader';
import { useSearchParams } from 'react-router-dom';
import ItemListingQrLabel from '../components/Printing/ItemListingQrLabel';
import QuoteLabel from '../components/Printing/QuoteLabel';
import pluralize from '../utils/pluralize';
import LoadingIndicator from '../components/Form/LoadingIndicator';
import { fetchEventById } from '../api/events';
import getQuoteListings from '../utils/getQuoteListings';

const PrintTestPage: React.FC = () => {
  const [params] = useSearchParams();

  const quoteId = params.get('quote_id');
  const eventId = params.get('event_id');
  const listingId = params.get('listing_id');
  const listingName = params.get('listing_name');
  const quantity = Number(params.get('quantity'));
  const type = params.get('type') as 'listing' | 'quote';
  const [refetchQuote, setRefetchQuote] = useState(type === 'listing');

  const {
    data: quote,
    error,
    isSuccess,
    isLoading: isQuoteLoading,
  } = useQuery(
    ['quotes', quoteId],
    () => {
      if (!quoteId) {
        throw new Error('missing quote_id in url');
      }

      return fetchQuoteById(quoteId);
    },
    {
      refetchInterval: refetchQuote ? 3000 : false,
      enabled: !!quoteId,
      onSuccess: data => {
        const listings = getQuoteListings(data);
        if (listings.length === 0) {
          setRefetchQuote(true);
          return;
        }

        // If there are any filteredListing without legacy_ids
        if (
          listings.find(
            listing => listing.quality !== 'unacceptable' && !listing.legacy_id,
          )
        ) {
          setRefetchQuote(true);
        } else {
          // Stop refetching when every item has a legacy_id
          setRefetchQuote(false);
        }
      },
    },
  );

  const { data: event, error: eventError } = useQuery(
    ['events', eventId],
    () => {
      if (!eventId) {
        throw new Error('missing event_id in url');
      }

      return fetchEventById(eventId);
    },
    {
      enabled: !!eventId,
      staleTime: 30000,
    },
  );

  if (!type || (type !== 'quote' && type !== 'listing')) {
    throw new Error(
      'No ?type provided, valid options are "listing" or "quote"',
    );
  }

  if (type === 'quote' && !quantity) {
    throw new Error('Asked for quote labels but did not provide a quantity');
  }

  const range = useMemo(() => {
    const r: number[] = [];
    if (type === 'quote') {
      for (let i = 0; i < quantity; i++) {
        r.push(i);
      }
    }

    return r;
  }, [quantity, type]);

  const filteredListings = getQuoteListings(quote).filter(
    listing => listing.quality !== 'unacceptable',
  );

  const listingsWithLegacyId = filteredListings.filter(
    listing => listing.legacy_id,
  ).length;

  return (
    <div>
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: `
            @page {
              size: 2.125in 1in;
            
              /* Y, X */
              margin: 0.05in 0.1in;
            }`,
        }}
      />
      {!!(error || eventError) && (
        <Message error>
          Failed to fetch quote for print preview:{' '}
          {getErrorMessage(error || eventError)}
        </Message>
      )}

      <PageHeader className="print-hidden mx-8 mt-12">Print</PageHeader>
      <PageSubheader className="print-hidden m-8">
        Make sure your printer is set to <code>30336</code> label sizes
      </PageSubheader>

      {isQuoteLoading && <LoadingIndicator size="large" className="mx-auto" />}

      {type === 'listing' && (
        <div>
          {listingsWithLegacyId != null &&
            listingsWithLegacyId !== filteredListings.length && (
              <div className="print-hidden m-8 flex space-x-2 italic">
                <LoadingIndicator size="small" />
                <div>
                  Waiting for {filteredListings.length - listingsWithLegacyId}{' '}
                  {pluralize(
                    filteredListings.length - listingsWithLegacyId,
                    'listing',
                    'listings',
                  )}{' '}
                  to get an ID (polling every 3 seconds)...
                </div>
              </div>
            )}

          {filteredListings?.map((listing, i) => (
            <>
              <ItemListingQrLabel listing={listing} key={listing.id} />
              {i < filteredListings.length - 1 && (
                <div className="break-after-page" />
              )}
            </>
          ))}

          {listingId && listingName && (
            <ItemListingQrLabel
              listing={{
                legacy_id: Number(listingId),
                name: listingName,
              }}
            />
          )}

          {isSuccess && !filteredListings && (
            <div className="m-8 text-lg italic">No listings in this quote</div>
          )}
        </div>
      )}

      {type === 'quote' && event && quote && (
        <div>
          {range.map(i => (
            <>
              <QuoteLabel key={i} quoteId={quote.display_id} event={event} />
              {i < range.length - 1 && <div className="break-after-page" />}
            </>
          ))}
        </div>
      )}
    </div>
  );
};

export default PrintTestPage;
