import { fireAnalytics } from '../fireAnalytics';
import { IamPartner } from '../../../api/partnerUsers';

export const fireVisitedManageUsers = () => {
  fireAnalytics('Visited Manage Users');
};

export const fireSearchedUser = (searchTerm: string) => {
  fireAnalytics('Searched User', { q: searchTerm });
};

export const fireClickedDeleteUser = () => {
  fireAnalytics('Clicked Delete User');
};

export const fireDeletedUser = (user?: IamPartner) => {
  if (!user) {
    return console.error('No user provided to delete user');
  }

  fireAnalytics('Deleted User', {
    user_id: user?.uuid,
    email: user?.email,
    first_name: user?.first_name,
    last_name: user?.last_name,
    role: user?.role,
  });
};

export const fireVisitedEditUser = () => {
  fireAnalytics('Visited Edit User');
};

export const fireEditedUser = (user?: IamPartner) => {
  if (!user) {
    return console.error('No user provided to edit user');
  }

  fireAnalytics('Edited User', {
    user_id: user?.uuid,
    email: user?.email,
    first_name: user?.first_name,
    last_name: user?.last_name,
    role: user?.role,
  });
};

export const fireCreatedUser = (user?: IamPartner) => {
  if (!user) {
    return console.error('No user provided to create user');
  }

  fireAnalytics('Created User', {
    user_id: user?.uuid,
    email: user?.email,
    first_name: user?.first_name,
    last_name: user?.last_name,
    role: user?.role,
  });
};
