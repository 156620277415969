import React, { useEffect, useRef, useState } from 'react';
import BarcodeDetector from 'barcode-detector';
import Webcam from 'react-webcam';
import {
  BrowserMultiFormatOneDReader,
  BrowserQRCodeReader,
} from '@zxing/browser';
import { DetectedBarcode } from '../../types/barcode-detector/basic-types';
import useScanProcessor from '../../hooks/useScanProcessor';
import { DecodeHintType } from '@zxing/library';
import useMobile from '../../hooks/useMobile';
import CheckboxWithLabel from '../Form/CheckboxWithLabel';

interface Props {
  mode: 'qr' | 'barcode';
  onScan: (data: string) => void;
}

const Scanner: React.FC<Props> = ({ mode, onScan }) => {
  const codeReader = useRef<
    BarcodeDetector | BrowserQRCodeReader | BrowserMultiFormatOneDReader
  >(null);
  const webcamRef = useRef<Webcam>(null);
  const [enabled, setEnabled] = useState(false);
  const mobile = useMobile();

  const { onScanData } = useScanProcessor(onScan);

  // Default mirrored to false on mobile
  const [mirrored, setMirrored] = useState(!mobile);

  useEffect(() => {
    if (window.BarcodeDetector) {
      codeReader.current = new window.BarcodeDetector({
        formats: mode === 'qr' ? ['qr_code'] : ['code_128'],
      });
    } else {
      const hints = new Map();
      hints.set(DecodeHintType.TRY_HARDER, true);

      codeReader.current =
        mode === 'barcode'
          ? new BrowserMultiFormatOneDReader(hints)
          : new BrowserQRCodeReader(hints);
    }
  }, [mode]);

  useEffect(() => {
    async function handleScan() {
      if (!enabled || !codeReader.current || !webcamRef.current?.video) {
        return;
      }

      if (codeReader.current instanceof BarcodeDetector) {
        try {
          const result: DetectedBarcode[] = await codeReader.current.detect(
            webcamRef.current.video,
          );
          if (result[0]) {
            await onScanData(result[0].rawValue);
          }
        } catch (e) {}
      } else if (
        codeReader.current instanceof BrowserQRCodeReader ||
        codeReader.current instanceof BrowserMultiFormatOneDReader
      ) {
        try {
          const screenshot = webcamRef.current.getScreenshot();

          if (screenshot) {
            const result = await codeReader.current.decodeFromImageUrl(
              screenshot,
            );
            await onScanData(result.getText());
          }
        } catch (e) {}
      }
    }

    const interval = setInterval(handleScan, 120);
    return () => clearInterval(interval);
  }, [onScan, mode, enabled]);

  return (
    <div className="relative">
      <Webcam
        width="100%"
        className="mx-auto"
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          facingMode: 'environment',
          autoGainControl: false,
          // aspectRatio: 350 / 270,
        }}
        mirrored={mirrored}
        imageSmoothing={false}
        screenshotQuality={1}
        audio={false}
        onUserMedia={() => {
          setEnabled(true);
        }}
      />

      <div className="absolute top-2 left-2 rounded bg-white/50 px-2 py-1">
        <CheckboxWithLabel
          checked={mirrored}
          onChange={e => setMirrored(e.target.checked)}
        >
          Mirror
        </CheckboxWithLabel>
      </div>
    </div>
  );
};

export default Scanner;
