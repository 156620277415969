import React from 'react';
import { Form, Formik, FormikConfig } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { postCustomer, postQuote } from '../../api/customer';
import { ApiError, formatFormikError } from '../../api/ApiError';
import { CreateCustomerErrorMeta, Customer } from '../../api/intf/customer';
import { useEventCustomerQuoteQuery } from '../../hooks/routing/useEventCustomerQuoteQuery';

interface Props extends React.FormHTMLAttributes<HTMLFormElement> {
  setMatchedExistingCustomers: (customers: Customer[]) => void;
}

export interface CustomerFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  source?: string;
}

export const customerFormValidation: yup.SchemaOf<CustomerFormValues> =
  yup.object({
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    email: yup.string().email('Must be an email').required('Required'),
    phoneNumber: yup.string().required('Required').min(10),
    source: yup.string(),
  });

const customerInitialValue: CustomerFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  source: '',
};

export const TYPE = 'IN_STORE';
export const SUBMITTED_BY = 'Sales Associate';

const CreateCustomerForm: React.FC<Props> = ({
  children,
  setMatchedExistingCustomers,
  ...props
}) => {
  const navigate = useNavigate();
  const { eventId } = useEventCustomerQuoteQuery();

  const handleSubmit: FormikConfig<CustomerFormValues>['onSubmit'] = async (
    values: CustomerFormValues,
    { setErrors },
  ) => {
    try {
      setMatchedExistingCustomers([]);
      const { id: customerId } = await postCustomer(
        values.firstName,
        values.lastName,
        values.email,
        values.phoneNumber,
        values.source ?? '',
      );
      const { id: quoteId } = await postQuote(
        customerId,
        eventId,
        TYPE,
        SUBMITTED_BY,
      );
      navigate(
        `/event/${eventId}/customer/${customerId}/quote/${quoteId}/listing/search?show_print=1`,
      );
    } catch (e) {
      const matchedContacts = (e as ApiError<CreateCustomerErrorMeta>)?.meta
        ?.matched_contacts;

      if (matchedContacts && matchedContacts.length > 0) {
        setMatchedExistingCustomers(matchedContacts);
      } else {
        setErrors(formatFormikError(e));
      }
    }
  };

  // const handleContinueCustomer = async () => {
  //   const { id: quoteId } = await postQuote(
  //     existingCustomerId,
  //     SOURCE_ID,
  //     TYPE,
  //     SUBMITTED_BY,
  //   );
  //   navigate(
  //     `/customer/${existingCustomerId}/quote/${quoteId}/listing/search`,
  //   );
  // };

  return (
    <>
      <Formik
        initialValues={customerInitialValue}
        onSubmit={handleSubmit}
        validationSchema={customerFormValidation}
        validateOnBlur={false}
        validateOnChange={false}
      >
        <Form {...props}>{children}</Form>
      </Formik>
    </>
  );
};

export default CreateCustomerForm;
