import React, { useRef, useState } from 'react';
import SimpleModal, { ModalProps } from '../Modal/SimpleModal';
import FieldLabel from '../Form/FieldLabel';
import Field from '../Form/Field';
import Input from '../Form/Input';
import { Button } from '../Form/Button';
import { useMutation } from 'react-query';
import { Partner, postCreatePartner } from '../../api/partners';

interface Props extends ModalProps {
  onSuccess?: (partner: Partner) => void;
}

const CreatePartnerModal: React.FC<Props> = ({
  onClose,
  onSuccess,
  ...props
}) => {
  const [name, setName] = useState('');
  const { mutate: createEvent, isLoading } = useMutation(postCreatePartner, {
    onSuccess: data => {
      onSuccess?.(data);
    },
  });
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <SimpleModal
      {...props}
      onClose={onClose}
      title="Create Partner"
      className="w-full max-w-lg"
      initialFocus={inputRef}
    >
      <div className="space-y-4">
        <Field>
          <FieldLabel>Partner Name</FieldLabel>
          <Input
            autoFocus
            value={name}
            onChange={e => setName(e.target.value)}
            disabled={isLoading}
            ref={inputRef}
          />
        </Field>

        <div className="flex space-x-2">
          <Button fluid variant="text" onClick={onClose}>
            Cancel
          </Button>

          <Button
            fluid
            disabled={name.length < 3 || isLoading}
            onClick={() => createEvent({ name })}
          >
            Create
          </Button>
        </div>
      </div>
    </SimpleModal>
  );
};

export default CreatePartnerModal;
