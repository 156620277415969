import React from 'react';
import PageLayout from '../components/Layout/PageLayout';

const Page404: React.FC = () => {
  return (
    <PageLayout>
      <h1 className="mt-20 text-center text-8xl font-bold tracking-widest">
        404
      </h1>
      <p className="mt-2 text-center">Page not found</p>
    </PageLayout>
  );
};

export default Page404;
