import React from 'react';
import { Field as BaseFormikField, FieldProps, useField } from 'formik';
import FieldError from './FieldError';
import Field from './Field';
import { InputProps, PasswordInput } from './Input';
import FieldLabel from './FieldLabel';
import FieldHint from './FieldHint';

interface Props extends Exclude<InputProps, 'value'> {
  autoFocus?: boolean;
  label?: React.ReactNode;
  hint?: React.ReactNode;
  name: string;
  // placeholder?: string;
  required?: boolean;
}

const FormikPasswordField = React.forwardRef<HTMLInputElement, Props>(
  ({ children, hint, label, name, required, ...props }, ref) => {
    const [field, meta] = useField(name);

    return (
      <Field>
        {typeof label === 'string' ? (
          <FieldLabel htmlFor={name} error={!!meta.error}>
            {label} {required && <span className="text-red-600">*</span>}
          </FieldLabel>
        ) : (
          label
        )}
        <BaseFormikField name={name}>
          {({ field, meta }: FieldProps) => (
            <PasswordInput
              {...field}
              {...props}
              ref={ref}
              error={!!meta.error}
              id={name}
            />
          )}
        </BaseFormikField>
        {hint && <FieldHint>{hint}</FieldHint>}
        {meta.error && <FieldError id={name}>{meta.error}</FieldError>}
      </Field>
    );
  },
);

export default FormikPasswordField;
