import React from 'react';
import { Field, FieldConfig, useField } from 'formik';
import TextArea from './TextArea';

type Props = Omit<FieldConfig, 'value' | 'onChange'> &
  React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const FormikTextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  ({ children, name, ...props }, ref) => {
    const [field, meta, helpers] = useField(name);

    return (
      <Field
        as={TextArea}
        {...props}
        {...field}
        error={meta.error}
        name={name}
        innerRef={ref}
      >
        {children}
      </Field>
    );
  },
);

export default FormikTextArea;
