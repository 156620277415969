import { useQuery, UseQueryOptions } from 'react-query';
import { fetchIamPartnerUser, IamPartner } from '../../api/partnerUsers';
import useOrgId from '../useOrgId';
import { useCookie } from 'react-use';
import decodeJwt from '../../utils/decodeJwt';

export default function usePartnerUserQuery(
  id?: string | null,
  differentOrgId?: string,
  options?: UseQueryOptions<IamPartner, any, IamPartner, any>,
) {
  const tokenOrgId = useOrgId();

  const orgId = differentOrgId || tokenOrgId;

  return useQuery(['iam-partner', id], () => fetchIamPartnerUser(orgId!, id!), {
    ...options,
    enabled: !!id && !!orgId,
  });
}

export function useCurrentPartnerUserQuery() {
  const [accessToken] = useCookie('access_token');
  const decodedAccessToken = decodeJwt(accessToken || '');

  return usePartnerUserQuery(decodedAccessToken?.uuid);
}
