import { clsx } from 'clsx';
import React, { ComponentProps } from 'react';
import { Button } from './Button';

type Props = Omit<ComponentProps<typeof Button>, 'variant'> & {
  selected?: boolean;
  ref?: React.Ref<HTMLButtonElement>;
};

export const Tab = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, className, selected, ...props }, ref) => (
    <Button
      className={clsx(
        selected
          ? 'whitespace-no-wrap border-b-4 border-primary-500'
          : 'whitespace-no-wrap border-b-4 border-transparent hover:border-grey-500',
        className,
        'rounded-none',
      )}
      {...props}
      style={{
        ...props.style,
        textDecoration: 'none',
      }}
      ref={ref}
      variant="text"
    >
      {children}
    </Button>
  ),
);
