import React from 'react';
import classNames from 'classnames';
import { RecentlySoldItem } from '../../../api/item';
import calculateDom from '../../../utils/calculateDom';
import displayPrice from '../../../utils/displayPrice';
import Checkbox from '../../Form/Checkbox';
import generateFastlyImageUrl from '../../../utils/generateFastlyImage';
import { MagnifyingGlassMinusIcon } from '@heroicons/react/24/outline';

interface Props {
  highlighted?: boolean;
  id?: string;
  item: RecentlySoldItem;
  onChange?: (checked: boolean) => void;
  onZoom?: React.MouseEventHandler;
  selected?: boolean;
}

const RecentlySoldModalItemCard: React.FC<Props> = ({
  highlighted,
  id,
  item,
  onChange,
  onZoom,
  selected,
}) => {
  const dom = calculateDom(item);

  return (
    <article
      className={classNames(
        'rounded border shadow transition duration-100',
        highlighted ? 'ring ring-green-500 ring-opacity-90' : 'border',
      )}
      id={id}
    >
      <label className="aspect-w-4 aspect-h-3 block rounded-t">
        <img
          src={generateFastlyImageUrl(item.images?.[0], {
            height: 400,
          })}
          height={400}
          className="w-full rounded-t object-contain"
          loading="lazy"
        />
        <div className="absolute flex p-2">
          <div>
            <div className="flex h-8 w-8 items-center justify-center rounded bg-white bg-opacity-50">
              <Checkbox
                checked={selected}
                onChange={e => onChange?.(e.target.checked)}
                className="text-turf-green-500"
              />
            </div>
          </div>

          <div className="flex-1" />

          {onZoom && (
            <div>
              <button
                type="button"
                className="flex h-8 w-8 items-center justify-center rounded bg-white bg-opacity-50"
                onClick={onZoom}
              >
                <MagnifyingGlassMinusIcon className="h-4 w-4" />
              </button>
            </div>
          )}
        </div>
      </label>

      <section className="mt-0.5 px-2.5 py-1.5">
        <div className="mb-2 text-sm font-semibold line-clamp-3">
          {item.name}
        </div>

        <div className="grid grid-cols-2 items-center gap-1 text-xs text-slate-green-500">
          <div className="mb-1 text-base font-bold text-black">
            Sale Price: {displayPrice(item.sold_price)}
          </div>

          <div className="mb-1 text-right">
            List Price: {displayPrice(item.list_price)}
          </div>

          <div>
            Days on Market:{' '}
            <span
              className={classNames(
                'inline-block rounded-sm px-1.5 py-0.5 font-semibold text-white',
                {
                  'bg-green-500': dom <= 30,
                  'bg-yellow-600': dom > 30 && dom <= 90,
                  'bg-red-500': dom > 90,
                },
              )}
            >
              {dom}
            </span>
          </div>

          <div className="text-right">
            Sold On: <b>{item.platform}</b>
          </div>

          <div>
            Date Sold: <b>{new Date(item.sold_at).toLocaleDateString()}</b>
          </div>

          <div />

          <div>
            Date Listed:{' '}
            <b>{new Date(item.published_at).toLocaleDateString()}</b>
          </div>

          <div />

          <div>
            Days Since Price Drop: <b>TODO</b>
          </div>
        </div>
      </section>
    </article>
  );
};

export default RecentlySoldModalItemCard;
