import React from 'react';
import { Menu } from '@headlessui/react';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { FunnelIcon } from '@heroicons/react/24/outline';
import SimpleFlyoutMenu from '../../Menu/SimpleFlyoutMenu';
import SimpleMenuItemCheckbox from '../../Menu/SimpleMenuItemCheckbox';
import { WarehouseSwapsFilter } from '../../../api/intf/warehouseSwap';

interface Props {
  filters?: WarehouseSwapsFilter[];
}

const SwapsInboxFiltersFlyoutMenu: React.FC<Props> = ({ filters }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentScope = searchParams.get('scope') || 'all';

  const activeFilters = searchParams.getAll('filter');
  const hasFilters = filters && filters.length > 0;

  return (
    <SimpleFlyoutMenu
      direction="right"
      menuTitle="Quick Filters"
      trigger={
        <Menu.Button
          disabled={!hasFilters}
          className="relative rounded-full py-1 px-1 disabled:text-slate-green-500"
          title="Quick Filters"
        >
          <span className="sr-only">Quick Filters</span>
          <FunnelIcon
            className={classNames('h-6 w-6', {
              'text-grey-200': !hasFilters,
            })}
            aria-hidden="true"
          />
          {activeFilters.length > 0 && (
            <span className="absolute top-0 right-0 -mt-1 -mr-1 rounded-full bg-green-500 px-1.5 py-px text-xs font-semibold leading-tight text-white">
              {activeFilters.length}
            </span>
          )}
        </Menu.Button>
      }
    >
      {filters?.map(filter => {
        const isActive = activeFilters.includes(filter.slug);

        return (
          <SimpleMenuItemCheckbox
            key={filter.slug}
            name="filter"
            value={filter.slug}
            checked={isActive}
            onChange={e => {
              if (e.target.checked) {
                setSearchParams({
                  scope: currentScope,
                  filter: [...activeFilters, filter.slug],
                });
              } else {
                setSearchParams({
                  scope: currentScope,
                  filter: activeFilters.filter(f => f !== filter.slug),
                });
              }
            }}
          >
            {filter.label} ({filter.count})
          </SimpleMenuItemCheckbox>
        );
      })}
    </SimpleFlyoutMenu>
  );
};

export default SwapsInboxFiltersFlyoutMenu;
