import { fireAnalytics } from '../fireAnalytics';

export const fireVisitedDashboard = () => {
  fireAnalytics('Visited Dashboard');
};

export const fireVisitedAllQuotes = () => {
  fireAnalytics('Visited All Quotes');
};

export const fireVisitedAllTransfers = () => {
  fireAnalytics('Visited All Transfers');
};
