import qs from 'qs';

export default function qsParse<Q extends Record<string, any>>(
  query?: string,
  options: qs.IParseOptions = {},
): Q {
  if (!query) {
    return {} as Q;
  }

  return qs.parse(query, {
    parseArrays: true,
    allowDots: true,
    comma: true,
    ignoreQueryPrefix: true,
    ...options,
  }) as Q;
}
