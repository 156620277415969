import React from 'react';
import { PartnerTransferItem } from '../../api/partnerTransfers';
import {
  ColumnDef,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import SimpleReactTable from '../Tables/SimpleReactTable';
import usePartnerUserName from '../../hooks/query/usePartnerUserName';
import {
  transferItemPriceVarianceColumn,
  transferItemStatusColumn,
} from '../Warehouse/Audit/WarehouseAuditItemsTable';

interface Props {
  transferItems: PartnerTransferItem[];
}

const columns: ColumnDef<PartnerTransferItem, any>[] = [
  {
    id: 'created_at',
    accessorFn: item => new Date(item.created_at),
    header: 'Created',
    cell: info => info.getValue()?.toLocaleDateString(),
  },
  {
    header: 'Processed By',
    cell: ({ row }) => {
      return usePartnerUserName(row.original.created_by);
    },
  },
  transferItemStatusColumn,
  {
    header: 'Name',
    cell: item => (
      <span className="inline-block w-52 whitespace-pre-line">
        {item.row.original.name}
      </span>
    ),
  },
  {
    accessorFn: row => row.details_changed_count || 0,
    header: 'Details Changed',
    id: 'details_changed',
  },
  transferItemPriceVarianceColumn,
];

const TransferItemsTable: React.FC<Props> = ({ transferItems }) => {
  const table = useReactTable({
    columns,
    data: transferItems,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: 'created_at',
          desc: true,
        },
      ],
    },
  });

  return <SimpleReactTable table={table} />;
};

export default TransferItemsTable;
