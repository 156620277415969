import React, { useMemo, useState } from 'react';
import { useCombobox } from 'downshift';
import {
  BaseSelectButton,
  BaseSelectMenu,
  BaseSelectMenuItem,
  BaseSelectMenuItemsContainer,
} from './elements';
import FieldLabel from '../FieldLabel';
import { IdBasedObject } from '../../../types/generic';
import Input from '../Input';
import { XMarkIcon } from '@heroicons/react/24/outline';

export interface SearchableSelectProps<T extends IdBasedObject> {
  clearable?: boolean;
  error?: string;
  items?: T[];

  label?: React.ReactNode;
  onChange: (value: T | null) => void;
  value: string | T | null | undefined;
  inputPlaceholder?: string;
  placeholder?: React.ReactNode;
  filterItem: (input: string, item: T, index: number) => boolean;
  renderItem?: (item: T, index: number) => React.ReactNode;
  itemToString: (item: T | null) => string;

  postItemsContent?: React.ReactNode;
}

function SearchableSelect<T extends IdBasedObject>({
  clearable,
  error,
  filterItem,
  items,
  itemToString,
  label,
  inputPlaceholder,
  placeholder,
  onChange,
  postItemsContent,
  renderItem,
  value,
}: SearchableSelectProps<T>) {
  const [inputValue, setInputValue] = useState('');

  const selectedItem =
    typeof value === 'string' ? items?.find(i => i.id === value) : value;

  const filteredItems = useMemo<T[]>(() => {
    if (inputValue === '') {
      return items ?? [];
    }

    return (
      items?.filter((item, index) => filterItem(inputValue, item, index)) ?? []
    );
  }, [filterItem, inputValue, items]);

  const {
    getLabelProps,
    getComboboxProps,
    getMenuProps,
    getInputProps,
    getItemProps,
    getToggleButtonProps,
    isOpen,
    highlightedIndex,
    setHighlightedIndex,
    reset,
  } = useCombobox<T>({
    inputValue,
    itemToString,
    items: filteredItems,
    selectedItem,
    onIsOpenChange: () => setInputValue(''),
    onSelectedItemChange: changes => onChange(changes.selectedItem ?? null),
  });

  const handleReset: React.MouseEventHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    reset();
    onChange(null);
    setInputValue('');
  };

  let actuallyRenderItem = renderItem || itemToString;

  return (
    <div>
      {label && (
        <FieldLabel {...getLabelProps()} error={error}>
          {label}
        </FieldLabel>
      )}
      <div className="relative" {...getComboboxProps()}>
        <BaseSelectButton {...getToggleButtonProps()} error={error}>
          {selectedItem ? (
            actuallyRenderItem(selectedItem, -1)
          ) : (
            <span className="text-slate-green-500">
              {placeholder ?? 'Select...'}
            </span>
          )}

          {clearable && (
            <>
              <div className="flex-1" />
              <button
                onClick={handleReset}
                className="-my-4 -ml-4 -mr-2 p-4"
                type="button"
                tabIndex={-1}
              >
                <XMarkIcon className="h-4 w-4 text-slate-green-500" />
              </button>
            </>
          )}
        </BaseSelectButton>

        <BaseSelectMenu isOpen={isOpen} error={error}>
          {isOpen && (
            <>
              <div className="flex px-4 pb-4">
                <Input
                  autoFocus
                  className="flex-1"
                  placeholder={
                    inputPlaceholder ||
                    (typeof label === 'string'
                      ? `Search ${label}`
                      : 'Search...')
                  }
                  type="search"
                  {...getInputProps({
                    onFocus: e => e.target.select(),
                  })}
                  value={inputValue}
                  onChange={e => {
                    setInputValue(e.target.value);
                    setHighlightedIndex(0);
                  }}
                />
              </div>

              <BaseSelectMenuItemsContainer {...getMenuProps()}>
                {inputValue && !filteredItems?.length && (
                  <li className="mb-2 text-center text-slate-green-500">
                    No results...
                  </li>
                )}

                {!inputValue && !filteredItems?.length && (
                  <li className="mb-2 text-center text-slate-green-500">
                    No options...
                  </li>
                )}

                {filteredItems?.map((item, i) => (
                  <BaseSelectMenuItem
                    highlighted={highlightedIndex === i}
                    selected={item.id === selectedItem?.id}
                    key={item.id}
                    {...getItemProps({
                      item,
                      index: i,
                    })}
                  >
                    {actuallyRenderItem(item, i)}
                  </BaseSelectMenuItem>
                ))}

                {postItemsContent && (
                  <li className="flex px-4 pt-4">{postItemsContent}</li>
                )}
              </BaseSelectMenuItemsContainer>
            </>
          )}
        </BaseSelectMenu>
      </div>
    </div>
  );
}

export default SearchableSelect;
