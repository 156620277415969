import React from 'react';
import { Customer } from '../../api/intf/customer';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchQuotesByCustomer } from '../../api/item';
import { Link, useNavigate } from 'react-router-dom';
import { postQuote } from '../../api/customer';
import { SUBMITTED_BY, TYPE } from '../CustomerForm/CreateCustomerForm';
import ChevronRight from '../Icons/ChevronRight';
import { useEventCustomerQuoteQuery } from '../../hooks/routing/useEventCustomerQuoteQuery';
import { getErrorMessage } from '../../api/ApiError';
import Message from '../Form/Message';
import LoadingIndicator from '../Form/LoadingIndicator';
import formatPhoneNumber from '../../utils/formatPhoneNumber';
import qsStringify from '../../utils/qsStringify';
import { useLocation } from 'react-router';

interface Props {
  customer: Customer;
}

const CustomerCard: React.FC<Props> = ({ customer }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { eventId } = useEventCustomerQuoteQuery();
  const queryClient = useQueryClient();
  const quotes = useQuery(
    ['customer-quotes', eventId, customer.id],
    () => fetchQuotesByCustomer(customer.id, eventId),
    {
      retry: false,
    },
  );

  const {
    mutate: createNewQuote,
    isLoading: isCreatingQuote,
    error: createNewQuoteError,
  } = useMutation(() => postQuote(customer.id, eventId, TYPE, SUBMITTED_BY), {
    onSuccess: data => {
      queryClient.invalidateQueries('quotes', {
        exact: false,
      });

      navigate(
        `/event/${eventId}/customer/${customer.id}/quote/${data.id}/listing/search?show_print=1`,
      );
    },
  });

  return (
    <div className="rounded border px-3 py-2 shadow">
      <div className="mb-2 text-lg font-semibold">
        {customer.first_name} {customer.last_name}
      </div>

      <div>{customer.email}</div>
      <div className="mb-4">{formatPhoneNumber(customer.phone)}</div>

      {!!createNewQuoteError && (
        <Message error>
          Failed to create a new quote: {getErrorMessage(createNewQuoteError)}
        </Message>
      )}

      <div className="mb-4 flex space-x-4">
        <Link
          to={`/event/${eventId}/customer/${customer.id}/edit${qsStringify({
            backDestination: encodeURIComponent(
              location.pathname + location.search,
            ),
            updatedLocation: encodeURIComponent(
              location.pathname + location.search,
            ),
          })}`}
          className="font-semibold underline"
        >
          Edit Customer
        </Link>

        <div className="flex items-center space-x-2">
          <button
            className="font-semibold underline disabled:text-slate-green-500"
            onClick={() => createNewQuote()}
            disabled={isCreatingQuote}
          >
            {isCreatingQuote ? 'Creating' : 'Create'} Quote
          </button>

          {isCreatingQuote && <LoadingIndicator />}
        </div>
      </div>

      {quotes.isSuccess && quotes.data?.length === 0 && (
        <div className="mt-4 italic">No quotes found</div>
      )}

      {!!quotes.error && (
        <Message error>
          Failed to fetch user's quotes: {getErrorMessage(quotes.error)}
        </Message>
      )}

      {!!quotes.data && (
        <div className="space-y-2">
          {quotes.data.map(quote => (
            <Link
              to={`/event/${quote.trade_in_event_id}/customer/${quote.contact_id}/quote/${quote.id}/summary`}
              key={quote.id}
              className="flex items-center justify-between rounded bg-green-200 px-2 py-1.5 text-sm transition-colors duration-100 hover:bg-green-300"
            >
              <div className="grid flex-1 grid-cols-2 gap-2">
                <div>Quote ID: {quote.display_id}</div>
                <div>
                  Created: {new Date(quote.created_at).toLocaleDateString()}
                </div>
                <div>Items: {quote.listing_count}</div>
                <div>Status: {quote.status}</div>
              </div>

              <div>
                <ChevronRight />
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomerCard;
