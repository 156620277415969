import axios from 'axios';
import { ApiResponse } from './intf/generic';
import { TokenRole } from '../lib/auth/roles';

export interface IamPartner {
  uuid: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  org_id: string;
  org_name: string;
  role: string;
  federated_id?: string;
}

export interface IamPartnersResponse {
  trade_in_users: IamPartner[];
}

export type IamPartnerBody = Omit<
  IamPartner,
  'uuid' | 'actions' | 'first_name' | 'last_name' | 'org_name' | 'email'
> & {
  email?: string;
  first_name?: string;
  last_name?: string;
  password?: string;
  new_password?: string; // for editing only
  federated_id?: string;
};

interface SearchParams {
  q?: string;
  page?: number | string;
  sort?: string;
}

export interface IamPartnerUserMeta {
  page: number;
  page_size: number;
  total_pages: number;
}

export const fetchIamPartnerUsers = async (
  orgId: string,
  params: SearchParams,
) => {
  const { data } = await axios.get<
    ApiResponse<IamPartnersResponse, IamPartnerUserMeta>
  >(`/iam/auth/v1/trade_in/orgs/${orgId}/users`, {
    params: {
      ...params,
      // subtract 1 from the page count so React can deal with starting at 1, but the API starts at 0
      page: params.page ? (Number(params.page) || 1) - 1 : 0,
    },
  });
  return data;
};

export const fetchIamPartnerUser = async (orgId: string, partnerId: string) => {
  const { data } = await axios.get<ApiResponse<IamPartner>>(
    `/iam/auth/v1/trade_in/orgs/${orgId}/users/${partnerId}`,
    {
      params: {},
    },
  );
  return data.data;
};

export const postCreateIamPartnerUser = async (body: IamPartnerBody) => {
  const { data } = await axios.post<ApiResponse<IamPartner>>(
    `/iam/auth/v1/trade_in/users`,
    body,
    {
      params: {},
    },
  );
  return data.data;
};

export const putUpdateIamPartnerUser = async (
  partnerId: string,
  body: IamPartnerBody,
) => {
  const { data } = await axios.put<ApiResponse<IamPartner>>(
    `/iam/auth/v1/trade_in/users/${partnerId}`,
    body,
    {
      params: {},
    },
  );
  return data.data;
};

export const deleteIamPartnerUser = async (orgId: string, user: IamPartner) => {
  const { data } = await axios<ApiResponse<IamPartner>>({
    url: `/iam/auth/v1/trade_in/users/${user.uuid}`,
    method: 'DELETE',
    params: {},
    data: {
      org_id: orgId,
    },
  });
  return data.data;
};

export interface IamPartnerMetaRolesResponse {
  manageable_roles: { label: string; role: TokenRole }[];
  all_roles: { label: string; role: TokenRole }[];
}

export const fetchIamPartnerMetaRoles = async () => {
  const { data } = await axios.get<ApiResponse<IamPartnerMetaRolesResponse>>(
    `/iam/auth/v1/trade_in/meta/roles`,
    {
      params: {},
    },
  );
  return data.data;
};
