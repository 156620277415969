import getEnv from './getEnv';
import {
  ORG_ID_DSG,
  ORG_ID_RAWLINGS,
  ORG_ID_SIDELINESWAP_ENGINEERING,
} from '../constants/knownOrgIds';

interface TrainingManual {
  title: string;
  url: string;
}

// MonkeySports - 4c2bc86f-9dd7-477d-84b3-1442fe6f431a

export default function getTrainingManualLinksForOrg(
  orgId: string,
): TrainingManual[] | null {
  // DSG
  if (orgId === ORG_ID_DSG) {
    return [
      {
        url: 'https://docs.google.com/document/d/1MblK48tA3Kp-bhZptGLPkgXSTDlZbmi4/edit',
        title: 'Training Manual',
      },
    ];
  }

  // Rawlings
  if (orgId === ORG_ID_RAWLINGS) {
    return [
      {
        url: 'https://docs.google.com/document/d/1fG6ANED0jW6A2gcxpU5G0IQ9elYVb3kLhz5KRPvyutI/edit',
        title: 'Training Manual',
      },
    ];
  }

  // SidelineSwap Engineering, or testing
  if (orgId === ORG_ID_SIDELINESWAP_ENGINEERING || getEnv() !== 'production') {
    return [
      {
        url: 'https://docs.google.com/document/d/1MblK48tA3Kp-bhZptGLPkgXSTDlZbmi4/edit',
        title: 'TEST Training Manual',
      },
    ];
  }

  return null;
}
