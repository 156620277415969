import React, { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { useQuery } from 'react-query';
import { fetchTradeInModelVersions } from '../../../api/item';
import { z } from 'zod';
import { warehouseAuditSchema } from '../../../pages/warehouse/audit/warehouseAuditItemDetailsPage';
import { PartnerTransferItem } from '../../../api/partnerTransfers';
import { FullCategory, TradeInModelVersion } from '../../../api/intf/item';
import Field from '../../Form/Field';
import FieldLabel from '../../Form/FieldLabel';
import AuditFieldDifference from './AuditFieldDifference';
import SimpleModal from '../../Modal/SimpleModal';
import { Button } from '../../Form/Button';
import {
  getCashOfferAmountForModelVersionAndQuality,
  getListPriceForModelVersion,
  getListPriceForModelVersionAndQuality,
  getTradeInValueForModelVersion,
} from '../../../utils/modelVersionValues';
import classNames from 'classnames';
import SimpleHeadlessCombobox from '../../Form/Select/SimpleHeadlessCombobox';
import LoadingIndicator from '../../Form/LoadingIndicator';

interface Props {
  item: PartnerTransferItem;
  fullCategory: FullCategory;
}

const AuditModelVersionSelector: React.FC<Props> = ({ fullCategory, item }) => {
  const { setValues, values } =
    useFormikContext<z.input<typeof warehouseAuditSchema>>();
  const [modalOpen, setModalOpen] = useState(false);

  function onClose() {
    setModalOpen(false);
  }

  const modelUuid = fullCategory.models?.find(
    model => model.legacy_id == values.modelId,
  )?.id;

  // Craziness. Map over the details object to connect legacy_ids to uuids
  const detailsUuids = useMemo(
    () =>
      (Object.entries(values.details)
        .map(
          ([detailId, optionId]) =>
            fullCategory.details
              ?.find(
                detailGroup =>
                  detailGroup.trade_in_required &&
                  detailGroup.legacy_id === Number(detailId),
              )
              ?.options?.find(option => option.legacy_id === optionId)?.id,
        )
        ?.filter(Boolean) as string[]) || [],
    [values.details, fullCategory.details],
  );

  const { data: modelVersions, isLoading } = useQuery(
    ['model-versions', modelUuid, detailsUuids, item.item_id] as const,
    ({ queryKey }) => {
      const [, modelId, uuids, item_id] = queryKey;
      return fetchTradeInModelVersions(modelId!, uuids, item_id);
    },
    {
      staleTime: Infinity,
      onSuccess: data => {
        const initialSelectedVersion = data.find(
          modelVersion => modelVersion.id === values.modelVersionUuid,
        );

        if (initialSelectedVersion && !values.modelVersion) {
          setValues({
            ...values,
            modelVersion: initialSelectedVersion,
          });
        }
      },
    },
  );

  const selectedModelVersion = modelVersions?.find(
    modelVersion => modelVersion.id === values.modelVersionUuid,
  );

  function handleChangeModelVersion(modelVersion: TradeInModelVersion | null) {
    if (modelVersion) {
      const newDetails = {
        ...values.details,
        ...Object.fromEntries(
          modelVersion.details?.map(detail => [
            detail.legacy_id,
            detail.options?.[0]?.legacy_id,
          ]) || [],
        ),
      };

      if (values.quality) {
        setValues({
          ...values,
          modelVersion,
          modelVersionUuid: modelVersion.id,
          cashOfferAmount: getCashOfferAmountForModelVersionAndQuality(
            values.quality,
            modelVersion,
          ),
          resaleAmount: getListPriceForModelVersionAndQuality(
            values.quality,
            modelVersion,
          ),
          retailPrice: modelVersion.price_statistics.price_retail,
          details: newDetails,
          name: modelVersion.name,
        });
      } else {
        setValues({
          ...values,
          modelVersion,
          modelVersionUuid: modelVersion.id,
          cashOfferAmount: getTradeInValueForModelVersion(modelVersion),
          resaleAmount: getListPriceForModelVersion(modelVersion),
          retailPrice: modelVersion.price_statistics.price_retail,
          details: newDetails,
          name: modelVersion.name,
        });
      }
    } else {
      setValues({
        ...values,
        modelVersion,
        modelVersionUuid: null,
        name: null,
      });
    }

    onClose();
  }

  return (
    <>
      <Field>
        <FieldLabel>Model Version</FieldLabel>

        {selectedModelVersion ? (
          <div className="flex items-start justify-between space-x-4">
            <div>
              <img
                src={selectedModelVersion.primary_image?.small_url}
                className="mb-4 w-40"
              />
              <div className="font-semibold">{selectedModelVersion.name}</div>
            </div>
            <Button variant="outlined" onClick={() => setModalOpen(true)}>
              Change
            </Button>
          </div>
        ) : (
          <Button variant="outlined" onClick={() => setModalOpen(true)}>
            Select Model Version
          </Button>
        )}
      </Field>

      <AuditFieldDifference
        formField="modelVersionUuid"
        originalValue={item.original_data?.model_version_id}
        format={uuid => modelVersions?.find(mv => mv.id === uuid)?.name}
      />

      <SimpleModal
        open={modalOpen}
        onClose={onClose}
        title="Select Model Version"
        className="w-full max-w-2xl"
      >
        <div className="mb-8 space-y-4 md:space-y-6">
          {fullCategory?.details
            ?.filter(d => d.trade_in_required)
            ?.map(detailGroup => {
              const detailGroupId = detailGroup.legacy_id;
              const selectedDetail = detailGroup.options?.find(
                option => option.legacy_id === values.details[detailGroupId],
              );

              return (
                <SimpleHeadlessCombobox
                  key={detailGroupId}
                  label={detailGroup?.name}
                  items={detailGroup.options || []}
                  clearable={!detailGroup.required}
                  className={
                    selectedDetail ? undefined : 'ring-4 ring-yellow-500/70'
                  }
                  onChange={newDetail => {
                    if (newDetail?.id) {
                      setValues({
                        ...values,
                        details: {
                          ...values.details,
                          [detailGroupId]: newDetail.legacy_id,
                        },
                      });
                    } else {
                      setValues({
                        ...values,
                        details: {
                          ...values.details,
                          [detailGroupId]: undefined,
                        },
                      });
                    }
                  }}
                  value={selectedDetail}
                  itemToString={item => item?.name || ''}
                  filterItem={(item, inputValue) =>
                    item.name.toLowerCase().includes(inputValue.toLowerCase())
                  }
                  renderItem={item => item?.name || ''}
                />
              );
            })}
        </div>
        <div className="mb-8 space-y-4 md:space-y-6">
          {isLoading ? (
            <LoadingIndicator size="large" className="my-8 mx-auto" />
          ) : (
            modelVersions?.map(modelVersion => (
              <LargeModelVersionTile
                key={modelVersion.id}
                modelVersion={modelVersion}
                selected={selectedModelVersion?.id === modelVersion.id}
                onSelect={handleChangeModelVersion}
              />
            ))
          )}
        </div>

        <div className="grid grid-cols-2 gap-4">
          <Button fluid variant="outlined" onClick={onClose}>
            Cancel
          </Button>

          <Button color="danger" onClick={() => handleChangeModelVersion(null)}>
            Unselect
          </Button>
        </div>
      </SimpleModal>
    </>
  );
};

interface LargeModelVersionTileProps {
  modelVersion: TradeInModelVersion;
  selected: boolean;
  onSelect: (modelVersion: TradeInModelVersion) => void;
}

const LargeModelVersionTile: React.FC<LargeModelVersionTileProps> = ({
  modelVersion,
  onSelect,
  selected,
}) => (
  <button
    type="button"
    className={classNames(
      'flex w-full space-x-6 rounded border-2 border-transparent px-3 py-2 text-left hover:border-grey-500',
      selected ? 'border-green-500' : 'border-transparent',
    )}
    onClick={() => onSelect(modelVersion)}
  >
    <img src={modelVersion.primary_image?.small_url} className="w-40" />

    <div className="flex-1">
      <div className="mb-2 text-lg font-semibold">{modelVersion.name}</div>

      <div className="grid grid-cols-2 gap-x-4 gap-y-2">
        {modelVersion.sku && (
          <div>
            <div className="text-sm font-semibold">SKU</div>
            <div className="text-sm">{modelVersion.sku}</div>
          </div>
        )}

        {modelVersion.details?.map(detail => (
          <div key={detail.id}>
            <div className="text-sm font-semibold">
              {detail.name}
              {detail.trade_in_required && (
                <span className="ml-0.5 text-red-500">*</span>
              )}
            </div>
            <div className="text-sm">
              {detail.options?.[0]?.name || 'Not Specified'}
            </div>
          </div>
        ))}
      </div>
    </div>
  </button>
);

export default AuditModelVersionSelector;
