import { compareDesc } from 'date-fns';
import { useFormikContext } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchRecentlySoldItems, RecentlySoldItem } from '../../../api/item';
import pluralize from '../../../utils/pluralize';
import qsStringify from '../../../utils/qsStringify';
import SimpleSelect from '../../Form/Select/SimpleSelect';
import SimpleModal, { ModalProps } from '../../Modal/SimpleModal';
import NewTabLink from '../../NewTabLink';
import { CreateItemFormValues } from '../CreateItemForm';
import calculateDom from '../../../utils/calculateDom';
import RecentlySoldModalItemCard from './RecentlySoldModalItemCard';
import useMobile from '../../../hooks/useMobile';
import { useWindowSize } from 'react-use';
import classNames from 'classnames';
import clampf from '../../../utils/clampf';
import displayPrice from '../../../utils/displayPrice';
import thousandsSeparator from '../../../utils/thousandsSeparator';
import Kbd from '../../Kbd';
import { getErrorMessage } from '../../../api/ApiError';
import Message from '../../Form/Message';
import LoadingIndicator from '../../Form/LoadingIndicator';
import generateFastlyImageUrl from '../../../utils/generateFastlyImage';
import median from '../../../utils/median';
import { Button } from '../../Form/Button';
import {
  ArrowTopRightOnSquareIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';

const re = /\W*/g;

function itemKey(item: RecentlySoldItem) {
  return item.item_id + item.sold_at.replace(re, '');
}

interface Props extends ModalProps {
  platform: string;
  valueGuideFields: Record<string, { label: string; value: string }>;
}

type SortOrder = 'recentlySoldDesc' | 'domAsc';

const sortLabels: Record<SortOrder, string> = {
  domAsc: 'Sort By: Days on Market',
  recentlySoldDesc: 'Sort By: Recently Sold',
};

const RecentlySoldModal: React.FC<Props> = ({
  open,
  platform,
  onClose,
  valueGuideFields,
  ...props
}) => {
  const formik = useFormikContext<CreateItemFormValues>();
  const {
    data: recentlySoldItems,
    isLoading,
    isSuccess,
    error,
  } = useQuery(
    ['recently-sold-items', valueGuideFields, platform],
    () => fetchRecentlySoldItems(valueGuideFields, platform),
    {
      enabled: open,
    },
  );

  const [sortOrder, setSortOrder] = useState<SortOrder>('recentlySoldDesc');

  const details = useMemo(
    () =>
      Object.values(formik.values).filter(
        v => v != null && typeof v === 'object' && 'label' in v,
      ) as { label: string; value: string }[],
    [formik.values],
  );

  const mobile = useMobile();
  const { width } = useWindowSize();

  let columns: number;
  if (mobile) {
    columns = 1;
  } else if (width < 1024) {
    columns = 2;
  } else if (width < 1200) {
    columns = 2;
  } else if (width < 1796) {
    columns = 3;
  } else {
    columns = 4;
  }

  // Keep track of positions out of the filter mode
  const preFilterIndex = useRef(-1);

  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [selectedItems, setSelectedItems] = useState<RecentlySoldItem[]>([]);
  const [showOnlySelectedItems, setShowOnlySelectedItems] = useState(false);

  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxImageIndex, setLightboxImageIndex] = useState(0);

  const handleClose = () => {
    onClose();
    setHighlightedIndex(0);
    setShowOnlySelectedItems(false);
    preFilterIndex.current = 0;
    setLightboxOpen(false);
    setLightboxImageIndex(0);
    setSelectedItems([]);
  };

  function handleCommitFilteredData() {
    formik.setFieldValue('valueGuideInput', averages.salePrice);
    handleClose();
  }

  const items = showOnlySelectedItems ? selectedItems : recentlySoldItems;
  const selectedItem = items?.[highlightedIndex];

  const averages = useMemo(() => {
    let avgSalePrice = 0;
    let avgTimeToSell = 0;
    let totalTimeToSell = 0;

    if (items) {
      avgSalePrice = median(
        items.map(item => Number(item.sold_price)).filter(Boolean),
      );
      for (let i = 0; i < items.length; i++) {
        totalTimeToSell += items[i].time_to_sell;
      }

      avgTimeToSell = totalTimeToSell / items.length;
    }

    return {
      salePrice: avgSalePrice || 0,
      timeToSell: avgTimeToSell || 0,
    };
  }, [items]);

  function lightboxImageMove(where: 'next' | 'prev') {
    if (where === 'next') {
      setLightboxImageIndex(i =>
        clampf(i + 1, 0, selectedItem ? selectedItem?.images?.length - 1 : 0),
      );
    } else {
      setLightboxImageIndex(i =>
        clampf(i - 1, 0, selectedItem ? selectedItem?.images?.length - 1 : 0),
      );
    }
  }

  useEffect(() => {
    function handleMove(e: KeyboardEvent) {
      switch (e.key) {
        case 'ArrowLeft':
          e.stopPropagation();
          e.preventDefault();
          if (lightboxOpen) {
            lightboxImageMove('prev');
            break;
          }
          setHighlightedIndex(i =>
            clampf(i - 1, 0, items ? items.length - 1 : i),
          );
          break;

        case 'ArrowRight':
          e.stopPropagation();
          e.preventDefault();
          if (lightboxOpen) {
            lightboxImageMove('next');
            break;
          }
          setHighlightedIndex(i =>
            clampf(i + 1, 0, items ? items.length - 1 : i),
          );
          break;

        case 'ArrowUp':
          e.stopPropagation();
          e.preventDefault();
          if (lightboxOpen) {
            break;
          }
          setHighlightedIndex(i =>
            clampf(i - columns, 0, items ? items.length - 1 : i),
          );
          break;

        case 'ArrowDown':
          e.stopPropagation();
          e.preventDefault();
          if (lightboxOpen) {
            break;
          }
          setHighlightedIndex(i =>
            clampf(i + columns, 0, items ? items.length - 1 : i),
          );
          break;

        // Select/deselect
        case ' ':
          e.stopPropagation();
          e.preventDefault();
          if (lightboxOpen) {
            break;
          }
          // First find this item by index
          if (!selectedItem) {
            // shrug
            break;
          }

          const key = itemKey(selectedItem);
          // Is it selected?
          const found = selectedItems.find(item => itemKey(item) === key);
          if (found) {
            // Create a new list with it filtered out
            setSelectedItems(items =>
              items.filter(item => itemKey(item) !== key),
            );
          } else {
            setSelectedItems(items => [...items, selectedItem]);
          }
          break;

        case 'f':
          e.stopPropagation();
          e.preventDefault();
          if (lightboxOpen) {
            break;
          }

          if (!showOnlySelectedItems) {
            preFilterIndex.current = highlightedIndex;
            setHighlightedIndex(0);
          } else {
            // Resume position when _leaving_ filter view
            setHighlightedIndex(preFilterIndex.current);
          }
          setShowOnlySelectedItems(f => !f);
          break;

        case 'z':
          e.stopPropagation();
          e.preventDefault();
          if (highlightedIndex >= 0) {
            setLightboxImageIndex(0);
            // Toggle
            setLightboxOpen(o => !o);
          }
          break;

        case 'Enter':
          e.stopPropagation();
          e.preventDefault();
          handleCommitFilteredData();
          break;
      }
    }

    // Unbind these keys if the lightbox is open
    if (open) {
      window.addEventListener('keydown', handleMove);
      return () => window.removeEventListener('keydown', handleMove);
    }
  }, [
    columns,
    highlightedIndex,
    lightboxOpen,
    items,
    open,
    items?.length,
    selectedItems,
    showOnlySelectedItems,
  ]);

  useEffect(() => {
    const item = selectedItem;

    if (item) {
      const el = document.getElementById(`recently-sold-${itemKey(item)}`);

      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [highlightedIndex, showOnlySelectedItems]);

  useEffect(() => {
    const max = items?.length;
    if (max != null && highlightedIndex > max - 1) {
      setHighlightedIndex(max - 1);
    }
  }, [
    highlightedIndex,
    recentlySoldItems,
    selectedItems,
    showOnlySelectedItems,
  ]);

  const sorted = useMemo(
    () =>
      items?.sort((a, b) => {
        if (!a.sold_at && !b.sold_at) {
          return 0;
        }

        if (sortOrder === 'recentlySoldDesc') {
          return compareDesc(new Date(a.sold_at), new Date(b.sold_at));
        } else if (sortOrder === 'domAsc') {
          return calculateDom(a) - calculateDom(b);
        } else {
          return 0;
        }
      }),
    [recentlySoldItems, selectedItems, showOnlySelectedItems, sortOrder],
  );

  return (
    <SimpleModal
      onClose={handleClose}
      open={open}
      {...props}
      title={`Recently Sold (${platform})`}
      className="w-full"
      style={{
        maxWidth: mobile ? undefined : '90%',
      }}
    >
      <div className="mb-2 flex flex-wrap items-center text-xs text-slate-green-500">
        <span className="mb-2 mr-2 font-semibold">Shortcuts:</span>

        <span className="mb-2 mr-4">
          <Kbd>z</Kbd> = Open/close images
        </span>

        <span className="mb-2 mr-4">
          <Kbd>Space</Kbd> = Select/unselect
        </span>

        <span className="mb-2 mr-4">
          <Kbd>Arrow Keys</Kbd> = Move
        </span>

        <span className="mb-2 mr-4">
          <Kbd>f</Kbd> = Toggle show only selected items
        </span>

        <span className="mb-2 mr-4">
          <Kbd>Enter</Kbd> = Use filtered price
        </span>
      </div>

      <div className="mb-4 flex items-center space-x-4">
        <div
          className={classNames(
            'grid flex-1 grid-cols-3 gap-4 rounded px-4 py-1.5 text-lg transition-colors duration-200',
            showOnlySelectedItems && 'bg-green-500 text-white',
          )}
        >
          <div>
            {sorted?.length === 100 ? '> 100' : sorted?.length}{' '}
            {pluralize(sorted?.length, 'Result', 'Results')}{' '}
            <span className="text-sm">
              (
              <NewTabLink
                href={`https://sidelineswap.com/search?src=trade-in-tool&recently_sold=1&${qsStringify(
                  valueGuideFields,
                  {
                    addQueryPrefix: false,
                  },
                )}`}
                className="font-semibold underline"
              >
                View on SidelineSwap{' '}
                <ArrowTopRightOnSquareIcon className="inline-block h-4 w-4" />
              </NewTabLink>
              )
            </span>
          </div>

          <div>
            Avg. Resale Price: <b>{displayPrice(averages.salePrice)}</b>
          </div>

          <div>
            Time to Sell:{' '}
            <b>
              {thousandsSeparator(Math.round(averages.timeToSell))}{' '}
              {pluralize(Math.round(averages.timeToSell), 'day', 'days')}
            </b>
          </div>
        </div>

        <Button
          onClick={handleCommitFilteredData}
          disabled={!showOnlySelectedItems}
        >
          Use Filtered Price
        </Button>

        <div>
          <SimpleSelect
            itemToString={i => (i == null ? '' : sortLabels[i])}
            items={['recentlySoldDesc', 'domAsc'] as SortOrder[]}
            value={sortOrder}
            onChange={v => (v != null ? setSortOrder(v) : null)}
          />
        </div>
      </div>

      <div className="mb-2 flex flex-wrap">
        <b className="mr-2">Details</b>
        {details.map(detail => (
          <div
            key={detail.value}
            className="mb-2 mr-2 rounded-full border px-2.5 py-1 text-xs font-semibold text-slate-green-500"
          >
            {detail.label}
          </div>
        ))}
      </div>

      {isSuccess && !sorted?.length && (
        <div className="my-8 text-center text-2xl text-slate-green-500">
          No recently sold data...
        </div>
      )}

      {!!error && <Message error>{getErrorMessage(error)}</Message>}

      {isLoading && <LoadingIndicator size="large" className="mx-auto" />}

      <div
        className={classNames('grid gap-4', {
          'grid-cols-1': columns === 1,
          'grid-cols-2': columns === 2,
          'grid-cols-3': columns === 3,
          'grid-cols-4': columns === 4,
        })}
        onClick={() => setHighlightedIndex(-1)}
      >
        {sorted?.map((item, i) => {
          const key = itemKey(item);
          return (
            <RecentlySoldModalItemCard
              key={key}
              id={`recently-sold-${key}`}
              item={item}
              highlighted={highlightedIndex === i}
              selected={!!selectedItems.find(it => itemKey(it) === key)}
              onChange={selected => {
                if (selected) {
                  setSelectedItems(items => [...items, item]);
                } else {
                  // Filter out this selection
                  setSelectedItems(items =>
                    items.filter(it => itemKey(it) !== key),
                  );
                }
              }}
              onZoom={e => {
                e.stopPropagation();
                setHighlightedIndex(i);
                setLightboxImageIndex(0);
                setLightboxOpen(true);
              }}
            />
          );
        })}
      </div>

      <SimpleModal
        onClose={() => setLightboxOpen(false)}
        open={lightboxOpen}
        style={{
          width: '90%',
          minHeight: '80vh',
        }}
      >
        <div className="relative mb-4">
          <div className="flex items-center justify-center">
            <img
              src={selectedItem?.images?.[lightboxImageIndex]}
              className="block object-contain"
              style={{
                maxHeight: '72vh',
              }}
            />
          </div>
          <div className="absolute bottom-0 left-0 top-0 flex items-center">
            <button
              type="button"
              className="rounded-sm bg-white bg-opacity-30 px-4 py-2.5"
              onClick={() => lightboxImageMove('prev')}
            >
              <ChevronLeftIcon className="h-8 w-8" />
            </button>
          </div>
          <div className="absolute bottom-0 right-0 top-0 flex items-center">
            <button
              type="button"
              className="rounded-sm bg-white bg-opacity-30 px-4 py-2.5"
              onClick={() => lightboxImageMove('next')}
            >
              <ChevronRightIcon className="h-8 w-8" />
            </button>
          </div>
        </div>

        <div className="flex justify-center space-x-4">
          {selectedItem?.images?.map((image, i) => (
            <button
              key={image}
              type="button"
              onClick={() => setLightboxImageIndex(i)}
            >
              <img
                src={generateFastlyImageUrl(image, {
                  height: 40,
                  width: 40,
                })}
                className={classNames(
                  'h-12 w-12 rounded-sm transition duration-200',
                  lightboxImageIndex === i ? '' : 'opacity-40',
                )}
              />
            </button>
          ))}
        </div>
      </SimpleModal>
    </SimpleModal>
  );
};

export default RecentlySoldModal;
