import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import CreateItemForm from '../../../components/ItemForms/CreateItemForm';
import { fetchCategories, fetchCategoryById } from '../../../api/item';
import useRouteQuery from '../../../hooks/useRouteQuery';
import { getCookie } from '../../../lib/KnownCookies';
import decodeJwt from '../../../utils/decodeJwt';
import ItemFormFields from '../../../components/ItemForms/ItemFormFields';
import { useEventCustomerQuoteQuery } from '../../../hooks/routing/useEventCustomerQuoteQuery';
import PageLayout from '../../../components/Layout/PageLayout';
import PageHeader from '../../../components/Layout/PageHeader';
import { getErrorMessage } from '../../../api/ApiError';
import Message from '../../../components/Form/Message';
import { fetchOrg } from '../../../api/orgs';

const CreateItemPage: React.FC = () => {
  const { quoteId, customerId } = useEventCustomerQuoteQuery();

  const accessToken = getCookie('access_token');
  const decodedToken = decodeJwt(accessToken);
  const orgId = decodedToken?.org_id || '';
  const itemQuery = useRouteQuery<any>();
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(
    itemQuery?.category?.[0] ? parseInt(itemQuery.category[0], 10) : -1,
  );
  const categoriesQuery = useQuery('fetchCategories', fetchCategories);
  const categoryQuery = useQuery(
    ['fetchCategoryById', selectedCategoryId],
    () => fetchCategoryById(selectedCategoryId),
    {
      enabled: selectedCategoryId !== undefined,
    },
  );
  const orgQuery = useQuery(['fetchOrg', orgId], () => fetchOrg(orgId));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <PageLayout
      header={
        <>
          <PageHeader>Add Item</PageHeader>
        </>
      }
    >
      <CreateItemForm
        categoryItem={categoryQuery.data}
        quoteId={quoteId}
        customerId={customerId}
        categoryId={selectedCategoryId}
        organization={orgQuery.data}
        itemQuery={itemQuery}
      >
        {!!categoriesQuery?.error && (
          <Message error>
            Failed to fetch categories: {getErrorMessage(categoriesQuery.error)}
          </Message>
        )}
        {!!categoryQuery?.error && (
          <Message error>
            Failed to fetch category ID <code>selectedCategoryId</code>:{' '}
            {getErrorMessage(categoryQuery.error)}
          </Message>
        )}

        <ItemFormFields
          categories={categoriesQuery.data}
          categoryItem={categoryQuery.data}
          setSelectedCategoryId={setSelectedCategoryId}
          organization={orgQuery.data}
        />
      </CreateItemForm>
    </PageLayout>
  );
};

export default CreateItemPage;
