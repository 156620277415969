import React from 'react';
import classNames from 'classnames';
import Footer from '../Footer';
import QuoteSummaryPanel from '../../QuoteSummary/QuoteSummaryPanel';
import { useEventCustomerQuoteQuery } from '../../../hooks/routing/useEventCustomerQuoteQuery';
import SideMenu from '../SideMenu/SideMenu';
import useMobile from '../../../hooks/useMobile';
import MobileHeaderAndDrawer from '../SideMenu/MobileHeaderAndDrawer';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router';
import {
  ArrowRightOnRectangleIcon,
  CalendarIcon,
  CogIcon,
  DocumentMagnifyingGlassIcon,
  HomeIcon,
  PlusCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import SideMenuEventCard from '../SideMenu/SideMenuEventCard';
import SideMenuLink from '../SideMenu/SideMenuLink';

const menuItems = (eventId?: string) => [
  {
    link: eventId ? `/event/${eventId}` : '/',
    text: 'Home',
    icon: <HomeIcon className="h-6 w-6" />,
  },
  {
    link: eventId
      ? `/event/${eventId}/customer/create`
      : `/event?to=${encodeURIComponent('/customer/create')}`,
    text: 'Create Customer',
    icon: <PlusCircleIcon className="h-6 w-6" />,
  },
  {
    link: eventId
      ? `/event/${eventId}/customer/search`
      : `/event?to=${encodeURIComponent('/customer/search')}`,
    text: 'Search for Customers',
    icon: <UserGroupIcon className="h-6 w-6" />,
  },
  {
    link: eventId
      ? `/event/${eventId}/quote/search`
      : `/event?to=${encodeURIComponent('/quote/search')}`,
    text: 'Search for Quotes',
    icon: <DocumentMagnifyingGlassIcon className="h-6 w-6" />,
  },
  {
    link: '/settings',
    text: 'Settings',
    icon: <CogIcon className="h-6 w-6" />,
  },
  {
    link: '/event',
    text: 'Change Event',
    icon: <CalendarIcon className="h-6 w-6" />,
  },
];

interface Props {
  className?: string;
  container?: boolean;
  quoteId?: string;
  customerId?: string;
}

const TradeInLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
}) => {
  const { customerId, eventId, quoteId } = useEventCustomerQuoteQuery();
  const location = useLocation();

  const mobile = useMobile();

  const hideQuoteSummaryPanel = location.pathname.includes('/summary');

  const sideMenuContent = (
    <>
      {eventId && <SideMenuEventCard eventId={eventId} />}

      <div className="flex-1 space-y-2 p-2 text-white">
        {menuItems(eventId).map(({ link, text, icon }, index) => (
          <SideMenuLink key={index} to={link} text={text} icon={icon} />
        ))}
      </div>

      <div className="p-2">
        <SideMenuLink
          text="Change Mode"
          to="/"
          icon={<ArrowRightOnRectangleIcon className="h-6 w-6" />}
        />
      </div>
    </>
  );

  return (
    <>
      {mobile && (
        <MobileHeaderAndDrawer>{sideMenuContent}</MobileHeaderAndDrawer>
      )}

      {!mobile && (
        <div className="fixed inset-y-0 flex w-80 flex-col bg-turf-green-500 shadow">
          <SideMenu>{sideMenuContent}</SideMenu>
        </div>
      )}

      <div className="flex h-full">
        {!mobile && <div className="w-80" />}

        <div className="flex-1 pb-20">
          <Outlet />
        </div>

        {quoteId && customerId && !hideQuoteSummaryPanel && !mobile && (
          <div className="md:w-80" />
        )}
      </div>

      {quoteId && customerId && !hideQuoteSummaryPanel && (
        <div
          className={classNames(
            'inset-y-0 right-0 bg-off-white-500 shadow md:fixed md:w-80 md:pl-0',
            !mobile && 'pl-80',
          )}
        >
          <QuoteSummaryPanel quoteId={quoteId} customerId={customerId} />
        </div>
      )}

      <Footer />
    </>
  );
};

export default TradeInLayout;
