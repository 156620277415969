import React from 'react';
import {
  fetchIamPartnerMetaRoles,
  IamPartner,
  IamPartnerBody,
  postCreateIamPartnerUser,
  putUpdateIamPartnerUser,
} from '../../../api/partnerUsers';
import { z } from 'zod';
import { Form } from 'formik';
import FormikZodSchema from '../../Form/FormikZodSchema';
import { formatFormikError } from '../../../api/ApiError';
import FormikField from '../../Form/FormikField';
import FormikNativeSelectField from '../../Form/FormikSelect/FormikNativeSelectField';
import FormikErrorMessage from '../../Form/FormikErrorMessage';
import { useQuery } from 'react-query';
import PartnerStoreSelector from './PartnerStoreSelector';
import FormikNumberField from '../../Form/FormikNumberField';
import useOrgConfigQuery from '../../../hooks/query/useOrgConfigQuery';
import FormikPasswordField from '../../Form/FormikPasswordField';

interface Props {
  children?: React.ReactNode;
  editingUser?: IamPartner;
  onSuccessfulSubmit?: (user: IamPartner) => void;
}

const baseSchema = z.object({
  role: z.string({
    required_error: 'Role is required',
  }),
  store: z.string({
    required_error: 'Store is required',
  }),
});

const defaultSchema = baseSchema.extend({
  firstName: z.string({
    required_error: 'First name is required',
  }),
  lastName: z.string({
    required_error: 'Last name is required',
  }),
  email: z
    .string({
      required_error: 'Email is required',
    })
    .email('Invalid email address'),
  password: z.string({
    required_error: 'Password is required',
  }),
});

const dsgSchema = baseSchema.extend({
  employeeId: z
    .string({
      required_error: 'Employee ID is required',
    })
    .transform(v => v.replace(/^DKS/, '')),
});

const PartnerManageUserForm: React.FC<Props> = ({
  children,
  editingUser,
  onSuccessfulSubmit,
}) => {
  const { data: roles } = useQuery(
    ['iam-partner-roles'],
    fetchIamPartnerMetaRoles,
  );
  const { data: orgConfig } = useOrgConfigQuery();

  const commonFormElements = (
    <>
      <FormikNativeSelectField name="role" label="Role">
        <option value="" disabled>
          Select a role
        </option>
        {roles?.manageable_roles?.map(({ label, role }) => (
          <option key={role} value={role}>
            {label}
          </option>
        ))}
      </FormikNativeSelectField>

      <PartnerStoreSelector name="store" />

      <FormikErrorMessage />

      {children}
    </>
  );

  if (!orgConfig) {
    return null;
  }

  if (orgConfig.trade_in_experience === 'DSG') {
    return (
      <FormikZodSchema
        schema={dsgSchema}
        initialValues={{
          store: editingUser?.org_id || '',
          role: editingUser?.role || '',
          employeeId: editingUser?.federated_id || '',
        }}
        onSubmit={async (values, formikHelpers) => {
          try {
            const body: IamPartnerBody = {
              org_id: values.store,
              role: values.role,
              federated_id: values.employeeId,
            };

            if (editingUser) {
              const user = await putUpdateIamPartnerUser(
                editingUser.uuid,
                body,
              );
              onSuccessfulSubmit?.(user);
            } else {
              const user = await postCreateIamPartnerUser(body);
              onSuccessfulSubmit?.(user);
            }
          } catch (e) {
            formikHelpers.setErrors(
              formatFormikError(e, {
                defaultMessage: 'Something went wrong',
              }),
            );
          }
        }}
      >
        <Form className="mb-4 space-y-4">
          <FormikNumberField
            name="employeeId"
            prefix="DKS"
            label="DKS ID"
            allowEmptyFormatting
            allowLeadingZeros
            disabled={!!editingUser?.federated_id}
          />

          {commonFormElements}
        </Form>
      </FormikZodSchema>
    );
  }

  return (
    <FormikZodSchema
      schema={defaultSchema}
      initialValues={{
        firstName: editingUser?.first_name || '',
        lastName: editingUser?.last_name || '',
        email: editingUser?.email || '',
        password: '',
        role: editingUser?.role || '',
        store: editingUser?.org_id || '',
      }}
      onSubmit={async (values, formikHelpers) => {
        try {
          const body: IamPartnerBody = {
            org_id: values.store,
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            role: values.role,
          };

          if (editingUser) {
            const user = await putUpdateIamPartnerUser(editingUser.uuid, {
              ...body,
              new_password: values.password,
            });
            onSuccessfulSubmit?.(user);
          } else {
            const user = await postCreateIamPartnerUser({
              ...body,
              password: values.password,
            });
            onSuccessfulSubmit?.(user);
          }
        } catch (e) {
          formikHelpers.setErrors(
            formatFormikError(e, {
              defaultMessage: 'Something went wrong',
            }),
          );
        }
      }}
    >
      <Form className="mb-4 space-y-4">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <FormikField name="firstName" label="First Name" />
          <FormikField name="lastName" label="Last Name" />
        </div>

        <FormikField type="email" name="email" label="Email" />
        <FormikPasswordField
          name="password"
          label="Password"
          hint="Optional, only for changing passwords."
        />

        {commonFormElements}
      </Form>
    </FormikZodSchema>
  );
};

export default PartnerManageUserForm;
