import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

const LinkOrSpan: React.FC<
  LinkProps | React.HTMLAttributes<HTMLSpanElement>
> = ({ children, ...props }) => {
  if ('to' in props && !!props.to) {
    return <Link {...props}>{children}</Link>;
  } else {
    return <span {...props}>{children}</span>;
  }
};

export default LinkOrSpan;
