import React, { useState } from 'react';
import { WarehouseSwap } from '../../../api/intf/warehouseSwap';
import SimpleModal, { ModalProps } from '../../Modal/SimpleModal';
import { Button } from '../../Form/Button';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import CheckboxWithLabel from '../../Form/CheckboxWithLabel';

interface Props extends ModalProps {
  swaps?: WarehouseSwap[];
  newOrders?: boolean;
}

const PrintBulkModal: React.FC<Props> = ({ newOrders, swaps, ...props }) => {
  const warehouseId = useCurrentWarehouseId();
  const [packingSlips, setPackingSlips] = useState(true);
  const [instructions, setInstructions] = useState(true);

  return (
    <SimpleModal
      title={
        (newOrders ? 'Print New Orders' : 'Print Bulk') +
        ` (${swaps?.length ?? 0})`
      }
      {...props}
      className="w-full max-w-md"
    >
      <form action={`/warehouse/${warehouseId}/print_bulk`} target="_blank">
        <input
          type="hidden"
          name="swap_ids"
          value={swaps?.map(swap => swap.id).join(',')}
        />

        {!packingSlips && (
          <input type="hidden" name="hide_packing_slips" value="1" />
        )}

        {!instructions && (
          <input type="hidden" name="hide_instructions" value="1" />
        )}

        <ul className="mb-4 space-y-2">
          <li>
            <CheckboxWithLabel disabled checked>
              Shipping Label
            </CheckboxWithLabel>
          </li>

          {/*          <li>
            <label>
              <Checkbox defaultChecked name="scan_sheets" value="1" /> Scan
              Sheet
            </label>
          </li>

          <li>
            <label>
              <Checkbox
                checked={packingSlips}
                onChange={e => setPackingSlips(e.target.checked)}
                name="packing_slips"
                value="1"
              />{' '}
              Packing Slip
            </label>
          </li>*/}
        </ul>

        <div className="grid grid-cols-2 gap-2">
          <Button variant="text" onClick={props.onClose}>
            Cancel
          </Button>
          <Button type="submit">Print</Button>
        </div>
      </form>
    </SimpleModal>
  );
};

export default PrintBulkModal;
