import axios from 'axios';
import { getBaseUrl } from './axiosInitialization';
import { ApiResponse, Uuid } from './intf/generic';
import { BonusCode, CreateBonusCode } from './intf/bonusCodes';
import { set as setDate } from 'date-fns';

export const fetchBonusCodes = async () => {
  const { data } = await axios.get<ApiResponse<BonusCode[]>>(
    '/trade-in/v1/bonus-codes',
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch bonus codes error');
};

export const fetchBonusCodeById = async (id: Uuid) => {
  const { data } = await axios.get<ApiResponse<BonusCode>>(
    `/trade-in/v1/bonus-codes/${id}`,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Fetch bonus code by id error');
};

function formatBonusCodeForSubmission(bonusCode: CreateBonusCode) {
  return {
    ...bonusCode,
    expires_at: bonusCode.expires_at
      ? setDate(new Date(bonusCode.expires_at), {
          hours: 23,
          minutes: 59,
          seconds: 59,
        }).toJSON()
      : undefined,
    start_at: bonusCode.start_at
      ? setDate(new Date(bonusCode.start_at), {
          hours: 0,
          minutes: 0,
          seconds: 0,
        }).toJSON()
      : undefined,
  };
}

export const postCreateBonusCode = async (bonusCode: CreateBonusCode) => {
  const { data } = await axios.post<ApiResponse<BonusCode>>(
    '/trade-in/v1/bonus-codes',
    formatBonusCodeForSubmission(bonusCode),
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Create bonus code error');
};

export const putUpdateBonusCode = async (
  id: Uuid,
  bonusCode: CreateBonusCode,
) => {
  const { data } = await axios.put<ApiResponse<BonusCode>>(
    `/trade-in/v1/bonus-codes/${id}`,
    formatBonusCodeForSubmission(bonusCode),
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Create bonus code error');
};

export const postAddBonusCodeToQuote = async (
  quoteId: Uuid,
  codeString: string,
) => {
  const { data } = await axios.post<ApiResponse<BonusCode>>(
    `/trade-in/v1/quotes/${quoteId}/bonus-codes/${codeString}`,
    undefined,
    {
      baseURL: getBaseUrl('inventoryApi'),
    },
  );

  if (data.data) {
    return data.data;
  }

  throw new Error('Add bonus code to quote error');
};
