import classNames from 'classnames';
import React from 'react';

interface Props extends React.HTMLAttributes<HTMLElement> {}

const PageSubheader: React.FC<Props> = ({ children, className, ...props }) => (
  <h2 className={classNames('mt-2', className)} {...props}>
    {children}
  </h2>
);

export default PageSubheader;
