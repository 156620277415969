import React from 'react';
import FullLogo from '../../Icons/FullLogo';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

interface Props {
  className?: string;
  left?: React.ReactNode;
  logoUrl?: string;
  right?: React.ReactNode;
}

const Header: React.FC<Props> = ({ className, left, logoUrl, right }) => {
  return (
    <header
      className={classNames(
        'flex items-center bg-turf-green-500 px-4 py-2',
        className,
      )}
    >
      <div className="flex flex-1 items-center">{left}</div>

      <Link to={logoUrl ?? '/'} className="text-white">
        <FullLogo className="h-8" />
      </Link>

      <div className="flex-1 flex-shrink-0">{right}</div>
    </header>
  );
};

export default Header;
