import { CreateItemFormValues } from '../components/ItemForms/CreateItemForm';
import { FullCategory, TitleName } from '../api/intf/item';
import { SelectOption } from '../components/Form/FormikSelect/DetailSelect';
import { getLast } from './getLast';

// hardcode category to 101
// hardcode model to 100

function childToString(child: {
  title_name?: TitleName;
  name?: string;
}): string | undefined {
  // Skip over title names that are simply empty strings.
  if (child.title_name === '{BLANK}') {
    return;
  }

  return child.title_name || child.name;
}

export default function generateItemTitle(
  values: CreateItemFormValues,
  categoryItem: FullCategory,
) {
  const out: string[] = [];

  if (
    !categoryItem ||
    !categoryItem.details ||
    categoryItem.details.length === 0
  ) {
    return '';
  }

  const orderedDetails = [
    { name: 'Brand', title_order: 100 },
    { name: 'Category', title_order: 101 },
    { name: 'Model', title_order: 100, models: [] },
    ...categoryItem.details,
  ]
    // Only take the ones that have a title order
    .filter(d => !!d.title_order)

    // For the ones that have a title order, sort them ascending by their order
    .sort((a, b) => a.title_order! - b.title_order!);

  for (let i = 0; i < orderedDetails.length; i += 1) {
    const detail = orderedDetails[i];

    // Check if the values object contains this detail. Wow I hate this.
    // @ts-ignore
    const selectedValue = (values[detail.slug || detail.name.toLowerCase()] ||
      // @ts-ignore
      values[detail.name]) as SelectOption;
    // Try to match against the `SelectOption` type
    if (
      !selectedValue ||
      typeof selectedValue !== 'object' ||
      !selectedValue.value
    ) {
      continue;
    }

    if (detail.name === 'Category') {
      const name = childToString({
        ...categoryItem,
        name:
          childToString(categoryItem) ||
          getLast(categoryItem.full_name.split(' > ')),
      });
      if (name) {
        out.push(name);
      }
      continue;
    }

    if (detail.name === 'Model' && categoryItem.models) {
      const detailOption = categoryItem.models.find(
        // @ts-ignore
        child => child.legacy_id === Number(values['model'].value),
      );

      if (!detailOption) {
        continue;
      }

      const name = childToString(detailOption);

      if (name) {
        out.push(name);
      }
      continue;
    }

    if (detail.name === 'Brand' && categoryItem.brands) {
      const detailOption = categoryItem.brands.find(
        // @ts-ignore
        child => child.legacy_id === Number(values['brand'].value),
      );

      if (!detailOption) {
        continue;
      }

      const name = childToString(detailOption);

      if (name) {
        out.push(name);
      }
      continue;
    }

    if ('options' in detail) {
      // Now we need to go find this exact selected option...
      const detailOption = detail.options.find(
        child => child.legacy_id === Number(selectedValue.value),
      );

      if (!detailOption) {
        continue;
      }

      const name = childToString(detailOption);

      if (name) {
        out.push(name);
      }
    }
  }

  return out.join(' ').trim();
}
