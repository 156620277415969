import React from 'react';
import FormikCheckboxWithLabel from '../../Form/FormikCheckboxWithLabel';
import FormikFieldError from '../../Form/FormikFieldError';

const DEFECTS = [
  {
    value: 'dented',
    label: 'Dented',
  },
  {
    value: 'cracked',
    label: 'Cracked',
  },
  {
    value: 'bent',
    label: 'Bent',
  },
  {
    value: 'endCap',
    label: 'End Cap',
  },
  {
    value: 'rattles',
    label: 'Rattles',
  },
];

interface Props {}

const WarehouseAuditDefectsList: React.FC<Props> = () => {
  // const form = useFormikContext<WarehouseAuditConditionFormValues>();

  /*useHotkeys(
    useMemo(() => DEFECTS.map((_, i) => String(i + 1)), []),
    (_, handler) => {
      if (form.values.approval === 'rejected') {
        const index = Number(handler.keys?.join('')) - 1;
        const defect = DEFECTS[index];

        if ((form.values.defects || []).includes(defect.value)) {
          form.setFieldValue(
            'defects',
            (form.values.defects || []).filter(d => d !== defect.value),
          );
        } else {
          form.setFieldValue('defects', [
            ...(form.values.defects || []),
            defect.value,
          ]);
        }
      }
    },
    {},
  );*/

  return (
    <>
      {DEFECTS.map(defect => (
        <FormikCheckboxWithLabel
          key={defect.value}
          name="defects"
          value={defect.value}
          tabIndex={-1}
        >
          {defect.label}
        </FormikCheckboxWithLabel>
      ))}

      <FormikFieldError name="defects" />
    </>
  );
};

export default WarehouseAuditDefectsList;
