import React from 'react';
import { useQuery } from 'react-query';
import { fetchPartnerItemsToTag } from '../../api/partnerTagging';
import Message from '../Form/Message';
import pluralize from '../../utils/pluralize';
import { Link } from 'react-router-dom';

interface Props {}

export const UntaggedItemsWarning: React.FC<Props> = () => {
  const { data: itemsToTagData } = useQuery(['items-to-tag'], () =>
    fetchPartnerItemsToTag(),
  );

  if (!itemsToTagData || itemsToTagData.length === 0) {
    return null;
  }

  return (
    <Message warning>
      You have <b>{itemsToTagData.length}</b> untagged{' '}
      {pluralize(itemsToTagData.length, 'item', 'items')}.{' '}
      <Link to={`/partner/dashboard/tag`}>Tag them now</Link>.
    </Message>
  );
};
