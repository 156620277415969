import React from 'react';
import { useQuery } from 'react-query';
import { fetchRailsSubregions } from '../../api/regions';
import FormikSearchableSelect from './Select/FormikSearchableSelect';

interface Props {
  name: string;
  region?: string;
}

const FormikSubregionSearchableSelector: React.FC<Props> = ({
  name,
  region = 'US',
}) => {
  const { data: subregions } = useQuery(['regions', region], () =>
    fetchRailsSubregions(region),
  );

  return (
    <FormikSearchableSelect
      label="State"
      name={name}
      placeholder="Select state"
      inputPlaceholder="Search states..."
      items={subregions}
      itemToString={subregion => subregion?.name ?? ''}
      filterItem={(input, subregion) => {
        const upper = input.toUpperCase();
        return (
          subregion.name.toUpperCase().includes(upper) ||
          subregion.id.toUpperCase().includes(upper)
        );
      }}
    />
  );
};

export default FormikSubregionSearchableSelector;
