import React, { useState } from 'react';
import WarehouseAuditLayout from '../../../components/Layout/Layouts/WarehouseAuditLayout';
import Message from '../../../components/Form/Message';
import {
  postWarehouseAuditScan,
  postWarehouseAuditScanItem,
} from '../../../api/warehouseAudit';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Scanner from '../../../components/Scanner/Scanner';
import TagInput from '../../../components/Partner/TagInput';
import Field from '../../../components/Form/Field';
import FieldLabel from '../../../components/Form/FieldLabel';
import FieldHint from '../../../components/Form/FieldHint';
import { Button } from '../../../components/Form/Button';
import { getErrorMessage } from '../../../api/ApiError';
import Input from '../../../components/Form/Input';
import {
  PartnerTransfer,
  PartnerTransferItem,
} from '../../../api/partnerTransfers';
import qsStringify from '../../../utils/qsStringify';

interface Props {}

const WarehouseAuditScanPage: React.FC<Props> = () => {
  const warehouseId = useCurrentWarehouseId();
  const navigate = useNavigate();
  const [error, setError] = useState<null | Error>(null);
  const [input, setInput] = useState('');

  const [searchParams] = useSearchParams();
  const expectedLegacyId = searchParams?.get('expected_legacy_id');
  const type = (searchParams?.get('type') as 'item' | 'tag') ?? 'tag';

  async function handleSubmit(tag: string) {
    setError(null);

    try {
      let item: PartnerTransferItem;
      if (type === 'item') {
        item = await postWarehouseAuditScanItem(warehouseId!, tag);
      } else {
        item = await postWarehouseAuditScan(
          warehouseId!,
          tag.replace(/-/g, ''),
        );
      }

      if (item) {
        if (type === 'item') {
          navigate(
            `/warehouse/${warehouseId}/audit/${item.id}/condition${qsStringify({ transfer_id: item.transfer_id })}`,
          );
        } else {
          navigate(`/warehouse/${warehouseId}/audit/${item.id}/condition`);
        }
      } else {
        throw new Error('Item not found');
      }
    } catch (e) {
      setError(e as Error);
    }
  }

  return (
    <WarehouseAuditLayout exitButton>
      <div className="mx-auto max-w-screen-sm">
        <Scanner
          mode="qr"
          onScan={async data => {
            const url = new URL(data);
            const lastPath = url.pathname.split('/').pop();

            await handleSubmit(lastPath!);
          }}
        />
      </div>

      <form
        className="my-4 mx-auto max-w-sm"
        onSubmit={e => {
          e.preventDefault();
          handleSubmit(input);
        }}
      >
        <Field>
          <FieldLabel htmlFor="tag">Tag</FieldLabel>
          <div className="flex space-x-4">
            {type === 'tag' ? (
              <TagInput
                id="tag"
                value={input}
                onChange={e => setInput(e.target.value)}
              />
            ) : (
              <Input
                id="tag"
                value={input}
                onChange={e => setInput(e.target.value)}
              />
            )}
            <Button type="submit">Submit</Button>
          </div>

          <FieldHint>
            If the scanner isn't working, enter the tag here.
          </FieldHint>
        </Field>
      </form>

      {error && (
        <div className="mx-auto max-w-sm">
          <Message error>{getErrorMessage(error)}</Message>
        </div>
      )}
    </WarehouseAuditLayout>
  );
};

export default WarehouseAuditScanPage;
