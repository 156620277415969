import React, { useState } from 'react';
import formattedEventDates from '../../utils/formattedEventDates';
import IdBasedSelect from '../Form/Select/IdBasedSelect';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchEvents } from '../../api/events';
import { Button } from '../Form/Button';
import Message from '../Form/Message';
import { getErrorMessage } from '../../api/ApiError';
import { differenceInWeeks, isFuture } from 'date-fns';
import selectEnv from '../../utils/selectEnv';
import CheckboxWithLabel from '../Form/CheckboxWithLabel';

interface Props {
  destination?: (eventId: string) => string;
}

const EventSelector: React.FC<Props> = ({ destination }) => {
  const navigate = useNavigate();
  const { data: events, error, refetch } = useQuery(['events'], fetchEvents);

  const [showPastEvents, setShowPastEvents] = useState(() => {
    return selectEnv(false, true);
  });

  const filteredEvents = showPastEvents
    ? events
    : events?.filter(event => {
        const startDate = new Date(event.start_date);
        return (
          differenceInWeeks(new Date(), startDate) < 2 || isFuture(startDate)
        );
      });

  const eventsSortedByDate = filteredEvents?.sort((a, b) => {
    const aDate = new Date(a.start_date);
    const bDate = new Date(b.start_date);
    return bDate.getTime() - aDate.getTime();
  });

  return (
    <div>
      {!!error && (
        <Message error>
          <p>Failed to fetch events: "{getErrorMessage(error)}".</p>
          <Button onClick={() => refetch()}>Retry</Button>
        </Message>
      )}

      <IdBasedSelect
        label="Select an Event"
        items={eventsSortedByDate}
        onChange={event => {
          if (event) {
            navigate(destination?.(event.id) || `/event/${event.id}`);
          }
        }}
        renderItem={item => {
          const { startDate, endDate } = formattedEventDates(
            item.start_date,
            item.end_date,
          );

          return (
            <>
              <div>{item.partner.name}</div>
              <div className="text-xs">
                {item.partner_address.address_1}, {item.partner_address.city},{' '}
                {item.partner_address.state} {item.partner_address.zip}
              </div>
              <div className="text-xs">
                {startDate} - {endDate}
              </div>
            </>
          );
        }}
        // Don't bother assigning a value, selecting an item will navigate you
        value={null}
      />

      <CheckboxWithLabel
        className="mt-4"
        checked={showPastEvents}
        onChange={e => setShowPastEvents(e.target.checked)}
      >
        Show past events
      </CheckboxWithLabel>
    </div>
  );
};

export default EventSelector;
