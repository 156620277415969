import React from 'react';
import { PicklistItem } from '../../../api/intf/picklist';
import { useNavigate } from 'react-router-dom';

interface Props {
  picklistItems?: PicklistItem[];
  picklistId?: string;
  warehouseId?: string;
}

const PicklistTable: React.FC<Props> = ({
  picklistId,
  picklistItems,
  warehouseId,
}) => {
  const navigate = useNavigate();

  if (!picklistItems || picklistItems.length === 0) {
    return (
      <div>
        <p>No items in this pick list</p>
      </div>
    );
  }

  const tdCx = 'py-2';

  return (
    <>
      <table className="w-full">
        <thead>
          <tr>
            <th className="text-left">Location</th>
            <th className="text-left">Item ID</th>
            <th className="text-left">Status</th>
          </tr>
        </thead>
        <tbody>
          {picklistItems.map(item => (
            <tr
              key={item.item_id}
              onClick={() => {
                if (picklistId && warehouseId) {
                  navigate(
                    `/warehouse/${warehouseId}/picklist/${picklistId}/item/${item.item_id}`,
                  );
                }
              }}
            >
              <td className={tdCx}>
                <span className="text-slate-green-500">{item.location}</span>
              </td>
              <td className={tdCx}>{item.legacy_item_id}</td>
              <td className={tdCx}>
                {item.warehouse_order_state === 'PICKED' && (
                  <span className="font-semibold text-green-500">Picked</span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default PicklistTable;
