import React, { useEffect } from 'react';
import PageLayout from '../../../components/Layout/PageLayout';
import { Link, useParams } from 'react-router-dom';
import { fetchTransferById } from '../../../api/partnerTransfers';
import { useQuery } from 'react-query';
import TransferShipmentsTable from '../../../components/Partner/TransferShipmentsTable';
import TransferItemsTable from '../../../components/Partner/TransferItemsTable';
import { Button } from '../../../components/Form/Button';
import { fireVisitedTransfer } from '../../../services/analytics/partner/transfers';
import BackArrowLink from '../../../components/BackArrowLink';

interface Props {}

const PartnerDashboardTransferById: React.FC<Props> = () => {
  const { transferId } = useParams<{ transferId: string }>();
  const { data: transfer, error: transferError } = useQuery(
    ['transfer', transferId],
    () => fetchTransferById(transferId!),
  );

  useEffect(() => {
    if (transfer?.id) {
      fireVisitedTransfer(transfer.id);
    }
  }, [transfer?.id]);

  return (
    <PageLayout
      header={
        <>
          <BackArrowLink to="/partner/dashboard/transfers">
            Back to Shipments
          </BackArrowLink>
        </>
      }
    >
      <div className="mb-4 flex justify-end">
        <Link to={`/partner/dashboard/transfers/${transfer?.id}/print`}>
          <Button className="flex items-center space-x-3">
            <span>View Packing List</span>
          </Button>
        </Link>
      </div>

      <section aria-label="Boxes" className="mb-6 rounded border p-4">
        {transfer?.shipments && transfer.shipments.length > 0 && (
          <TransferShipmentsTable
            transferShipments={transfer?.shipments}
            transferId={transfer.id}
          />
        )}
      </section>

      <section aria-label="Items" className="rounded border p-4">
        {transfer?.items && transfer.items.length > 0 && (
          <TransferItemsTable transferItems={transfer?.items} />
        )}
      </section>
    </PageLayout>
  );
};

export default PartnerDashboardTransferById;
