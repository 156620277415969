import React from 'react';
import useCurrentWarehouseId from '../../hooks/warehouse/useCurrentWarehouseId';
import { useQuery } from 'react-query';
import { fetchWarehouseSwaps } from '../../api/warehouse';
import { NavLink, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

export const SHIP_NOW_SCOPE = 'ship_now';

interface Props {}

const WarehouseLayoutShipNowSubfolders: React.FC<Props> = () => {
  const warehouseId = useCurrentWarehouseId();
  const [params] = useSearchParams();

  const currentFilter = params.getAll('filter')?.[0];

  const { data: swaps } = useQuery(
    ['warehouse-swaps', warehouseId, { scope: SHIP_NOW_SCOPE }],
    () => fetchWarehouseSwaps(warehouseId!, { scope: SHIP_NOW_SCOPE }),
    {
      keepPreviousData: true,
      enabled: !!warehouseId,
    },
  );

  if (!warehouseId || !swaps?.meta?.filters) {
    return null;
  }

  return (
    <div className="space-y-1 py-1 pl-4">
      {swaps.meta.filters.map(filter => (
        <NavLink
          key={filter.slug}
          to={{
            search: `?scope=${SHIP_NOW_SCOPE}&filter=${filter.slug}`,
          }}
          className={classNames(
            'block',
            currentFilter === filter.slug ? 'font-semibold' : '',
          )}
        >
          {filter.label} ({filter.count})
        </NavLink>
      ))}
    </div>
  );
};

export default WarehouseLayoutShipNowSubfolders;
