import React from 'react';

interface Props {
  header?: React.ReactNode;
}

const PageLayout: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  header,
}) => {
  return (
    <>
      {header ? (
        <div className="mb-6 border-b py-6">
          <div className="qs-container">{header}</div>
        </div>
      ) : (
        <div className="py-6" />
      )}

      <div className="qs-container pb-20" data-testid="page-layout">
        {children}
      </div>
    </>
  );
};

export default PageLayout;
