import React from 'react';
import FieldLabel from '../Form/FieldLabel';
import FormikTextArea from '../Form/FormikTextArea';
import { useFormikContext } from 'formik';
import { CreateItemFormValues } from './CreateItemForm';
import Field from '../Form/Field';
import { QUALITY_DESCRIPTION } from '../../constants/valueMatrix';

interface Props {}

const ItemDescriptionSection: React.FC<Props> = () => {
  const { values } = useFormikContext<CreateItemFormValues>();

  return (
    <div className="mt-4 space-y-4">
      <Field>
        <FieldLabel>Quality Description</FieldLabel>
        <div className="whitespace-pre-wrap text-sm">
          {values.quality ? (
            QUALITY_DESCRIPTION[values.quality]
          ) : (
            <i>No quality selected</i>
          )}
        </div>
      </Field>

      <Field>
        <FieldLabel>Additional Product Notes (optional)</FieldLabel>
        <FormikTextArea name="description" />
      </Field>
    </div>
  );
};

export default ItemDescriptionSection;
