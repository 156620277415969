import React, { useRef, useState } from 'react';
import PageLayout from '../../../components/Layout/PageLayout';
import useCurrentWarehouseQuery from '../../../hooks/warehouse/useCurrentWarehouseQuery';
import { differenceInSeconds } from 'date-fns';
import { ApiError } from '../../../api/ApiError';
import ScanHistoryTable from '../../../components/Warehouse/Spawn/ScanHistoryTable';
import useCurrentWarehouseId from '../../../hooks/warehouse/useCurrentWarehouseId';
import { ScanHistory } from '../../../types/spawnScanning';
import {
  postWarehouseScanUpdateLocation,
  ReceiveError,
} from '../../../api/spawn';
import WarehouseBreadcrumbLink from '../../../components/Warehouse/WarehouseBreadcrumbLink';
import Scanner from '../../../components/Scanner/Scanner';

const THROTTLE_SECONDS = 3;

interface Props {}

const WarehouseLocationPage: React.FC<Props> = () => {
  const warehouseId = useCurrentWarehouseId();
  const { data: warehouse } = useCurrentWarehouseQuery();
  const [, setRerender] = useState<any>();
  const [location, setLocation] = useState<string | null>(null);
  const scanHistory = useRef<ScanHistory[]>([]);

  function rerender() {
    setRerender(new Date());
  }

  async function onScanData(data: string) {
    if (!location) {
      window.navigator?.vibrate?.(25);
      setLocation(data);
      return;
    }

    if (!data.startsWith('https://')) {
      return;
    }

    // Look for the LAST scan history
    let found: ScanHistory | undefined;
    for (let i = scanHistory.current.length - 1; i >= 0; i--) {
      if (scanHistory.current[i].data === data) {
        found = scanHistory.current[i];
        break;
      }
    }

    const now = new Date();

    let shouldProcess = false;
    if (!found) {
      shouldProcess = true;
    } else if (found.status !== 'loading') {
      if (
        differenceInSeconds(now, new Date(found.createdAt)) >= THROTTLE_SECONDS
      ) {
        shouldProcess = true;
      } else if (
        found.status === 'error' &&
        differenceInSeconds(now, new Date(found.createdAt)) >= THROTTLE_SECONDS
      ) {
        shouldProcess = true;
      }
    }

    if (shouldProcess) {
      window.navigator?.vibrate?.(25);

      const newLength = scanHistory.current.push({
        // action,
        data,
        status: 'loading',
        createdAt: Number(new Date()),
      });
      rerender();
      const index = newLength - 1;

      try {
        const response = await postWarehouseScanUpdateLocation(
          warehouseId!,
          location,
          data,
        );
        scanHistory.current[index].itemInfo = response;
        scanHistory.current[index].status = 'success';
        rerender();
      } catch (e) {
        const receiveError = e as ReceiveError;
        const apiError = e as ApiError;
        if (receiveError.errors.data) {
          scanHistory.current[index].itemInfo = receiveError.errors.data;
        } else if (Array.isArray(apiError.errors)) {
          scanHistory.current[index].error = apiError;
        }

        scanHistory.current[index].status = 'error';
        rerender();
      }
    }
  }

  return (
    <PageLayout
      header={
        <>
          <WarehouseBreadcrumbLink to="home" />

          <div>Receiving inventory into {warehouse?.facility}</div>
        </>
      }
    >
      {location ? (
        <Scanner mode="qr" onScan={onScanData} />
      ) : (
        <Scanner onScan={onScanData} mode={location ? 'qr' : 'barcode'} />
      )}

      <div className="mt-4">Moving items to:</div>

      <div className="mb-4">
        {location ? (
          <div className="text-2xl font-semibold">{location}</div>
        ) : (
          <div className="italic">Scan to select a location</div>
        )}
      </div>

      <ScanHistoryTable scanHistory={scanHistory.current} />
    </PageLayout>
  );
};

export default WarehouseLocationPage;
