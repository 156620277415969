import React from 'react';
import { useQuery } from 'react-query';
import { EventStats, fetchEventStats } from '../../../api/events';
import PageLayout from '../../../components/Layout/PageLayout';
import PageHeader from '../../../components/Layout/PageHeader';
import PageSubheader from '../../../components/Layout/PageSubheader';
import formattedEventDates from '../../../utils/formattedEventDates';
import { useEventCustomerQuoteQuery } from '../../../hooks/routing/useEventCustomerQuoteQuery';
import Message from '../../../components/Form/Message';
import { getErrorMessage } from '../../../api/ApiError';
import thousandsSeparator from '../../../utils/thousandsSeparator';
import classNames from 'classnames';
import useMobile from '../../../hooks/useMobile';
import displayPrice from '../../../utils/displayPrice';
import useCurrentEventQuery from '../../../hooks/query/useCurrentEventQuery';
import calculateMargin from '../../../utils/calculateMargin';

const LABELS: Record<keyof EventStats, string> = {
  inventory_cost: 'Inventory Cost',
  items_purchased: 'Items Purchased',
  items_quoted: 'Items Quoted',
  resale_value: 'Resale Value',
  total_customers: 'Total Customers',
};

const FORMATTERS: Record<keyof EventStats, (value: number) => string> = {
  inventory_cost: displayPrice,
  items_purchased: thousandsSeparator,
  items_quoted: thousandsSeparator,
  resale_value: displayPrice,
  total_customers: thousandsSeparator,
};

const EventStatsPage: React.FC = () => {
  const mobile = useMobile();
  const { eventId } = useEventCustomerQuoteQuery();
  const { data: event, error: eventError } = useCurrentEventQuery();

  const { data: stats, error: eventStatsError } = useQuery(
    ['stats', eventId],
    () => fetchEventStats(eventId),
  );

  const { startDate, endDate } = formattedEventDates(
    event?.start_date,
    event?.end_date,
  );

  const statsKeys = stats
    ? (Object.keys(stats) as (keyof EventStats)[])
    : undefined;

  const margin =
    stats?.inventory_cost &&
    stats?.resale_value &&
    calculateMargin(stats.inventory_cost, stats.resale_value);

  return (
    <PageLayout
      header={
        <div>
          <PageHeader>Event Stats</PageHeader>
          {event && (
            <PageSubheader>
              <div className="mb-1 text-lg leading-tight tracking-wide">
                {event.partner.name}
              </div>

              {event && (
                <div className="text-xs">
                  {event.partner_address.address_1},{' '}
                  {event.partner_address.city}, {event.partner_address.state}{' '}
                  {event.partner_address.zip}
                </div>
              )}

              <div className="text-xs">
                {startDate} - {endDate}
              </div>
            </PageSubheader>
          )}
        </div>
      }
    >
      {!!eventError && (
        <Message>Failed to load event: {getErrorMessage(eventError)}</Message>
      )}

      {!!eventStatsError && (
        <Message>
          Failed to load stats: {getErrorMessage(eventStatsError)}
        </Message>
      )}

      <div
        className={classNames(
          'grid gap-6 lg:grid-cols-4',
          mobile ? 'grid-cols-1' : 'grid-cols-3',
        )}
      >
        {stats && (
          <>
            <div className="rounded border px-3 py-2">
              <div className="mb-1 text-center text-2xl font-bold tracking-wider">
                {margin}%
              </div>
              <div className="text-center">Margin</div>
            </div>

            {statsKeys?.map(entryKey => (
              <div key={entryKey} className="rounded border px-3 py-2">
                <div className="mb-1 text-center text-2xl font-bold tracking-wider">
                  {stats[entryKey] != null
                    ? FORMATTERS[entryKey](stats[entryKey]!)
                    : stats[entryKey]}
                </div>
                <div className="text-center">
                  {LABELS[entryKey] || entryKey}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </PageLayout>
  );
};

export default EventStatsPage;
