import React from 'react';
import { Field, FieldProps } from 'formik';
import CheckboxWithLabel, { CheckboxWithLabelProps } from './CheckboxWithLabel';

type Props = Omit<CheckboxWithLabelProps, 'name' | 'onChange'> & {
  name: string;
  onChange?: never;
};

const FormikCheckboxWithLabel: React.FC<Props> = ({
  children,
  name,
  ...props
}) => {
  return (
    <Field name={name} type="checkbox" {...props}>
      {({ field }: FieldProps) => (
        <CheckboxWithLabel {...props} {...field}>
          {children}
        </CheckboxWithLabel>
      )}
    </Field>
  );
};

export default FormikCheckboxWithLabel;
