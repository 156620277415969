import React, { useEffect } from 'react';
import { postAuthLogin } from '../../api/auth';
import { formatFormikError } from '../../api/ApiError';
import { Form, Formik } from 'formik';
import FormikField from '../Form/FormikField';
import FormikErrorMessage from '../Form/FormikErrorMessage';
import { useAuth, useAuthDispatch } from '../../lib/auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import useRouteQuery from '../../hooks/useRouteQuery';
import * as yup from 'yup';
import { LoginPageQuery } from '../../pages/login';
import FormikSubmitButton from '../Form/FormikSubmitButton';
import FormikPasswordField from '../Form/FormikPasswordField';

interface LoginFormValues {
  username: string;
  password: string;
}

const schema: yup.SchemaOf<LoginFormValues> = yup.object({
  username: yup.string().required('Required'),
  password: yup.string().required('Required'),
});

interface Props {}

const LoginForm: React.FC<Props> = () => {
  const auth = useAuth();
  const authDispatch = useAuthDispatch();
  const navigate = useNavigate();
  const query = useRouteQuery<LoginPageQuery>();

  function goToDestination() {
    navigate(query.from || '/');
  }

  useEffect(() => {
    if (auth.loggedIn) {
      goToDestination();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.from, auth.loggedIn]);

  return (
    <Formik
      initialValues={
        {
          username: '',
          password: '',
        } as LoginFormValues
      }
      onSubmit={async (values, { setErrors }) => {
        try {
          const { access_token, refresh_token } = await postAuthLogin(
            values.username,
            values.password,
          );
          authDispatch({
            type: 'logged in',
            payload: {
              accessToken: access_token,
              refreshToken: refresh_token,
            },
          });

          goToDestination();
        } catch (e) {
          setErrors(formatFormikError(e));
        }
      }}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form className="mx-auto max-w-sm">
        <div className="space-y-4">
          <FormikField label="Username" name="username" />
          <FormikPasswordField label="Password" name="password" />

          <FormikErrorMessage />

          <div className="pt-4">
            <FormikSubmitButton fluid>Log in</FormikSubmitButton>
          </div>
        </div>
      </Form>
    </Formik>
  );
};

export default LoginForm;
