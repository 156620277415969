import React from 'react';
import FormikNumberField, { FormikNumberFieldProps } from './FormikNumberField';

interface Props extends FormikNumberFieldProps {
  label?: string;
}

const FormikPhoneNumberField: React.FC<Props> = props => (
  <FormikNumberField
    changeProperty="floatValue"
    autoComplete="tel-national"
    type="tel"
    prefix="+1 "
    format="+1 (###) ### - ####"
    placeholder="+1 (123) 456 - 7890"
    inputMode="tel"
    label="Mobile Phone Number"
    {...props}
  />
);

export default FormikPhoneNumberField;
