import React from 'react';
import PageLayout from '../../../components/Layout/PageLayout';
import PageHeader from '../../../components/Layout/PageHeader';
import PartnerManageUserForm from '../../../components/Partner/ManageUsers/PartnerManageUserForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import FormikSubmitButton from '../../../components/Form/FormikSubmitButton';
import { fetchIamPartnerUser } from '../../../api/partnerUsers';
import useOrgId from '../../../hooks/useOrgId';
import PageSubheader from '../../../components/Layout/PageSubheader';
import {
  fireEditedUser,
  fireVisitedEditUser,
} from '../../../services/analytics/partner/manageUsers';

interface Props {}

const PartnerAdminEditUserPage: React.FC<Props> = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const orgId = useOrgId();
  const { userId } = useParams<{ userId: string }>();
  const { data: user } = useQuery(
    ['partner-users', orgId, userId],
    () => fetchIamPartnerUser(orgId!, userId!),
    {
      enabled: !!userId && !!orgId,
      onSuccess: () => {
        fireVisitedEditUser();
      },
    },
  );

  return (
    <PageLayout
      header={
        <>
          <PageHeader>Edit User</PageHeader>
          {user && (
            <PageSubheader>
              {user.first_name} {user.last_name}
            </PageSubheader>
          )}
        </>
      }
    >
      {user && user.uuid && (
        <PartnerManageUserForm
          editingUser={user}
          onSuccessfulSubmit={async user => {
            navigate('/partner/manage-users');
            queryClient.invalidateQueries();

            fireEditedUser(user);
          }}
        >
          <FormikSubmitButton>Save</FormikSubmitButton>
        </PartnerManageUserForm>
      )}
    </PageLayout>
  );
};

export default PartnerAdminEditUserPage;
