import React, { useMemo } from 'react';
import BackArrowLink from '../BackArrowLink';

interface Props {
  page?: 'bonusCodes';
}

const SettingsBreadcrumbLink: React.FC<Props> = ({ page }) => {
  const { url, label } = useMemo<{ url: string; label: string }>(() => {
    switch (page) {
      case 'bonusCodes':
        return {
          url: '/settings/bonus-codes',
          label: 'Manage Bonus Codes',
        };

      default:
        return {
          url: '/settings',
          label: 'Settings',
        };
    }
  }, [page]);

  return <BackArrowLink to={url}>{label}</BackArrowLink>;
};

export default SettingsBreadcrumbLink;
